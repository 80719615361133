import { MIXPANEL_TOKEN, GOOGLE_ANALYTICS_TOKEN, ANALYTICS_PLATFORM_LABEL } from "@/application/config";
import Mixpanel from "mixpanel-browser";
import { GoogleAnalyticsShim } from "./GoogleAnalysticsShim";

const MX_Banned_Domains = ["127.0.0.1", ".web.app", "localhost"];

const Oval3_Domain = "oval3.game";

export enum MXP_Events {
  Loaded = ANALYTICS_PLATFORM_LABEL,
  "1MExplore" = "oval3.1MExplore",
  "1MAction" = "oval3.1MAction",
  "1MBidClick" = "oval3.1MBidClick",
  "1MBidSuccess" = "oval3.1MBidSuccess",
  "1MBidFailure" = "oval3.1MBidFailure",
  "2MBuyClick" = "oval3.2MBuyClick",
  "2MSaleSuccess" = "oval3.2MSaleSuccess",
  "2MSaleFailure" = "oval3.2MSaleFailure",
  "RegistrationSuccess" = "oval3.RegistrationSuccess",
  "RegistrationFail" = "oval3.RegistrationFail",
  "LoginSuccess" = "oval3.LoginSuccess",
  "LoginFail" = "oval3.LoginFail",
  "UpdatedComposition" = "oval3.UpdatedComposition",
}

class MixpanelService {
  mixpanel: Mixpanel;
  ga: GoogleAnalyticsShim;
  token: string;
  isActive: boolean = true;
  logLevel: number = 0;

  constructor(token: string = "", g_tag_id: string = "") {
    this.token = token;
    this.mixpanel = Mixpanel;
    this.mixpanel.init(this.token);
    this.ga = new GoogleAnalyticsShim(g_tag_id);
    this.addWindowHook();
    this.disableInDev();
    this.setLogLevel();
  }

  private disableInDev() {
    MX_Banned_Domains.some((domain) => {
      if (window?.location.host.includes(domain)) {
        this.isActive = false;
        this.log(`Disabled due to domain`, domain);
        return true;
      }
    });
  }
  public setLogLevel() {
    if (window?.location.host.includes(Oval3_Domain)) {
      // remove service logs in production
      this.logLevel = 0;
    } else {
      // show service logs in non-production, assumes isActive is only true in non-production setting
      this.logLevel = 1;
    }
  }

  private addWindowHook() {
    // @ts-ignore
    window["oval3"] = {
      mixpanel: {
        off: () => (this.isActive = false),
        on: () => (this.isActive = true),
        status: () => (this.isActive ? "Mixpanel IS tracking events" : "Mixpanel NOT tracking events"),
        getToken: () => this.token,
        setToken: (token: string) => (this.token = token),
        track: (message: MXP_Events, data: Object = {}) => this.track(message, data),
        setLogLevel: (level: number) => (this.logLevel = level),
      },
    };
  }

  private log(message: string, data: any = null) {
    if (this.logLevel === 0) return;
    const status = this.isActive ? "Sending" : "Not Sending";
    console.log(`MixpanelService (${status}): ${message}: `, data);
  }

  public track(message: MXP_Events, data: Object = {}) {
    this.log(`track`, { message, data });

    if (!this.isActive) return;
    try {
      this.mixpanel.track(message, data);
    } catch (e) {
      console.error(`caught mixpanel error`, e);
    }

    try {
      this.ga.track(message, data);
    } catch (e) {
      console.error(`caught ga error`, e);
    }
  }

  public logout() {
    this.log(`logout`);
    if (!this.isActive) return;
    this.mixpanel.reset();
    this.ga.reset();
  }

  public login(id: string) {
    this.log(`login`, { id });
    if (!this.isActive) return;
    this.mixpanel.identify(id);
    this.ga.identity(id);
  }
}

// singleton
const mixpanelService = new MixpanelService(MIXPANEL_TOKEN, GOOGLE_ANALYTICS_TOKEN);
mixpanelService.track(MXP_Events.Loaded);
export default mixpanelService;
