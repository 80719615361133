import { ContentObject, createToast } from "mosha-vue-toastify";

export enum ToastType {
  Danger = "danger",
  Success = "success",
  Info = "info",
}

export function fireToast(message: string | ContentObject, toastType: ToastType): void {
  if (message === "[object Object]") {
    console.error("Could not display proper error message");
    return;
  }

  let backgroundColor: string;
  switch (toastType) {
    case ToastType.Danger:
      backgroundColor = "#F9004B";
      break;
    case ToastType.Success:
      backgroundColor = "#3B8D07";
      break;
    default:
      backgroundColor = "#7187d9";
      break;
  }

  createToast(message, {
    type: toastType,
    toastBackgroundColor: backgroundColor,
  });
}
