import { defineStore } from "pinia";
import { markRaw } from "vue";

export type SlideoverStackedView = {
  view: object;
  props: object;
};

export type SlideoverStore = {
  isOpen: boolean;
  viewsStack: SlideoverStackedView[];
};

export const useSlideoverStore = defineStore("slide-over", {
  state: (): SlideoverStore => ({
    isOpen: false,
    viewsStack: [],
  }),
  getters: {
    canCloseStack: (state) => {
      return state.viewsStack.length > 1;
    },
  },
  actions: {
    /**
     * Reset stack, then open slideover to show view
     */
    open(view: object, props: object = {}) {
      this.viewsStack = [];
      this.openStack(view, props);
    },
    /**
     * Open slideover and append view to stack, showing view on top of stack
     */
    openStack(view: object, props: object = {}) {
      this.isOpen = true;

      this.viewsStack = [...this.viewsStack, { view: markRaw(view), props }];
    },
    /**
     * Close slideover and clear all stack
     */
    close() {
      this.isOpen = false;
      setTimeout(() => {
        this.viewsStack = [];
      }, 500);
    },
    /**
     * Close view on top of stack.
     * Close sideover if no stacked view left.
     */
    closeStack() {
      const newStack = [...this.viewsStack];
      newStack.pop();

      if (newStack.length === 0) {
        this.close();
        return;
      }

      this.viewsStack = newStack;
    },
  },
});
