<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-50" :open="true" @close="setIsOpen">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-primary/10 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto pointer-events-none">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden text-white bg-black px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 pointer-events-auto"
            >
              <div v-if="view">
                <component :is="view" v-model="model" />
              </div>
              <div class="mt-5 sm:mt-8 mb-6">
                <button @click="modalStore.close()" class="btn-primary m-auto px-24">
                  {{ $t("button.close") }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { useModalStore } from "@/stores/modalStore";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { reactive } from "vue";

const modalStore = useModalStore();
const model = reactive({});

const { isOpen, view } = storeToRefs(modalStore);

function setIsOpen(value: boolean) {
  if (value === false) {
    modalStore.close();
  }
}
</script>
