<template>
  <div>
    <TheHeader />
    <div ref="mobileMenu">
      <nav class="w-full h-[48px] px-[16px] flex items-center bg-primary">
        <IconOval3Logo :width="30" :height="30" fill="#000" />
        <button class="flex lg:hidden items-center ml-auto" @click="() => toggleMobileMenu()">
          <img src="/icons/common/burger.svg" alt="Menu" class="h-6 w-6" />
        </button>
        <div
          class="ml-10 hidden lg:flex flex-1 h-full justify-start gap-2 md:gap-8 lg:gap-10 items-center text-[12px] uppercase text-black font-bold"
        >
          <router-link
            :to="!userStore.isConnected ? '' : { name: 'my-club' }"
            @click="() => toggleMobileMenu(false)"
            class="flex items-center justify-center gap-3"
            exact
          >
            <icon-ov-chevron v-if="$route.name === 'my-club'" class="-rotate-90 inline" />
            {{ $t("nav.my_club") }}
          </router-link>

          <a
            :href="!userStore.isConnected ? '' : marketplaceRoutes.mycards"
            @click="() => toggleMobileMenu(false)"
            class="flex items-center justify-center gap-3"
          >
            <icon-ov-chevron v-if="$route.name === 'my-cards'" class="-rotate-90 inline" />
            {{ $t("nav.my_cards") }}
          </a>

          <router-link
            :to="!userStore.isConnected ? '' : { name: 'play' }"
            @click="() => toggleMobileMenu(false)"
            class="flex items-center justify-center gap-3"
            exact
          >
            <icon-ov-chevron v-if="$route.name === 'play'" class="-rotate-90 inline" />
            {{ $t("nav.play") }}
          </router-link>

          <a
            :href="!userStore.isConnected ? '' : marketplaceRoutes.newcards"
            @click="() => toggleMobileMenu(false)"
            class="flex items-center justify-center gap-3"
          >
            <icon-ov-chevron v-if="$route.name === 'newcards'" class="-rotate-90 inline" />
            {{ $t("nav.new_cards") }}
          </a>

          <a
            :href="!userStore.isConnected ? '' : marketplaceRoutes.trade"
            @click="() => toggleMobileMenu(false)"
            :class="{ active: $route.name === 'trade' }"
          >
            <icon-ov-chevron v-if="$route.name === 'trade'" class="-rotate-90 inline" />
            {{ $t("nav.trade") }}
          </a>

          <a
            :href="!userStore.isConnected ? '' : marketplaceRoutes.token"
            @click="() => toggleMobileMenu(false)"
            :class="{ active: $route.name === 'token' }"
          >
            <icon-ov-chevron v-if="$route.name === 'token'" class="-rotate-90 inline" />
            {{ $t("nav.token") }}
          </a>

          <router-link v-if="!userStore.isConnected" class="btn-secondary btn-sm ml-auto" to="/connect">{{ $t("nav.connect") }}</router-link>
        </div>

        <!-- User info -->
        <AccountStatus v-if="userStore.isConnected" class="hidden lg:flex" />
      </nav>
      <div class="lg:hidden h-full w-full justify-start bg-primary pb-4" v-if="mobileMenuOpen">
        <div>
          <div class="flex flex-col gap-2 items-center text-[12px] uppercase text-black font-bold">
            <router-link
              :to="!userStore.isConnected ? '' : { name: 'my-club' }"
              @click="() => toggleMobileMenu(false)"
              class="flex items-center justify-center gap-3"
              exact
            >
              <icon-ov-chevron v-if="$route.name === 'my-club'" class="-rotate-90 inline" />
              {{ $t("nav.my_club") }}
            </router-link>

            <a
              :href="!userStore.isConnected ? '' : marketplaceRoutes.mycards"
              @click="() => toggleMobileMenu(false)"
              class="flex items-center justify-center gap-3"
            >
              <icon-ov-chevron v-if="$route.name === 'my-cards'" class="-rotate-90 inline" />
              {{ $t("nav.my_cards") }}
            </a>

            <router-link
              :to="!userStore.isConnected ? '' : { name: 'play' }"
              @click="() => toggleMobileMenu(false)"
              class="flex items-center justify-center gap-3"
              exact
            >
              <icon-ov-chevron v-if="$route.name === 'play'" class="-rotate-90 inline" />
              {{ $t("nav.play") }}
            </router-link>

            <a
              :href="!userStore.isConnected ? '' : marketplaceRoutes.newcards"
              @click="() => toggleMobileMenu(false)"
              class="flex items-center justify-center gap-3"
            >
              <icon-ov-chevron v-if="$route.name === 'newcards'" class="-rotate-90 inline" />
              {{ $t("nav.new_cards") }}
            </a>

            <a
              :href="!userStore.isConnected ? '' : marketplaceRoutes.trade"
              @click="() => toggleMobileMenu(false)"
              :class="{ active: $route.name === 'trade' }"
            >
              <icon-ov-chevron v-if="$route.name === 'trade'" class="-rotate-90 inline" />
              {{ $t("nav.trade") }}
            </a>

            <a
              :href="!userStore.isConnected ? '' : marketplaceRoutes.token"
              @click="() => toggleMobileMenu(false)"
              :class="{ active: $route.name === 'token' }"
            >
              <icon-ov-chevron v-if="$route.name === 'token'" class="-rotate-90 inline" />
              {{ $t("nav.token") }}
            </a>

            <!-- User info -->
            <AccountStatus v-if="userStore.isConnected" class="mt-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import marketplaceRoutes from "@/router/marketplaceRoutes";
import { useUserStore } from "@/stores/userStore";
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";

const mobileMenuOpen = ref(false);
const mobileMenu = ref(null);

const userStore = useUserStore();

const toggleMobileMenu = (value?: boolean) => {
  mobileMenuOpen.value = value ?? !mobileMenuOpen.value;
};

onClickOutside(mobileMenu, () => toggleMobileMenu(false));
</script>

<style scoped></style>
