export enum GTAG_Events {
  Event = "event",
  Config = "config",
}

export interface GA_Event {
  event: string;
  data: any;
}

export class GoogleAnalyticsShim {
  private tag_id: string;
  private dataLayer: GA_Event[];
  constructor(tag_id: string) {
    this.tag_id = tag_id;
    // @ts-ignore
    this.dataLayer = window["dataLayer"] || [];
    // @ts-ignore
    window.dataLayer = this.dataLayer;
  }
  private gtag(message: string, data: any) {
    const gaMessage: GA_Event = { event: message, data };
    this.dataLayer.push(gaMessage);
  }
  public track(message: string, data: any = "") {
    this.gtag(message, data);
  }
  public identity(id: string) {
    const data = { userId: id };
    this.gtag(GTAG_Events.Config, data);
  }
  public reset() {
    // no action needed for ga, afaik
    return;
  }
}
