import { eraApolloClient } from "@/clients/eraApollo";
import * as eraOperations from "@/generated/graphqlEraOperations";
import * as loginLineOperations from "@/generated/graphqlLoginLineOperations";
import * as composables from "@vue/apollo-composable";
import gql from "graphql-tag";

const getReferralInformation = async () => {
  const { data, errors } = await composables.useApolloClient("era").client.query<eraOperations.ReferralInformationQuery>({
    query: eraOperations.ReferralInformationDocument,
  });
  return {
    data: data as eraOperations.ReferralInformationQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getUser = async () => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.UserQuery>({
    query: loginLineOperations.UserDocument,
  });
  return {
    data: data as loginLineOperations.UserQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getAllTournamentVariantsIds = async (filter?: loginLineOperations.TournamentVariantWhereInput) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.AllTournamentVariantsIdsQuery>({
    query: loginLineOperations.AllTournamentVariantsIdsDocument,
    variables: { filter },
  });
  return {
    data: data as loginLineOperations.AllTournamentVariantsIdsQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getDetailedTournamentVariant = async (tournamentVariantId: number, status?: string) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.DetailedTournamentVariantQuery>({
    query: loginLineOperations.DetailedTournamentVariantDocument,
    variables: { tournamentVariantId, status },
  });
  return {
    data: data as loginLineOperations.DetailedTournamentVariantQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getTournamentVariant = async (tournamentVariantId: number) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.TournamentVariantQuery>({
    query: loginLineOperations.TournamentVariantDocument,
    variables: { tournamentVariantId },
  });
  return {
    data: data as loginLineOperations.TournamentVariantQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getPlayerStats = async (optaId: string) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.PlayerScoringMarketPlaceQuery>({
    query: loginLineOperations.PlayerScoringMarketPlaceDocument,
    variables: { optaId },
  });
  return {
    data: data as loginLineOperations.PlayerScoringMarketPlaceQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getPlayerScore = async (id: number) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.Player>({
    query: loginLineOperations.PlayerScoreDocument,
    variables: { id },
  });
  return {
    data: data as loginLineOperations.Player,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getHasPlaysPlayedDuringGameWeekScore = async (playerOptaIds: Array<string>, gameWeekId: number) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.HasPlayersPlayedDuringGameWeekQuery>({
    query: loginLineOperations.HasPlayersPlayedDuringGameWeekDocument,
    variables: { playerOptaIds, gameWeekId },
  });
  return {
    data: data as loginLineOperations.HasPlayersPlayedDuringGameWeekQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getGameWeekLeaderboard = async (gameWeekId: number, take: number) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.GetLeaderboardQuery>({
    query: loginLineOperations.GetLeaderboardDocument,
    variables: {
      id: gameWeekId,
      take: take,
    },
  });
  return {
    data: data as loginLineOperations.GetLeaderboardQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getRewardClaimsForCurrentUser = async () => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.RewardClaimsFromCurrentUserQuery>({
    query: loginLineOperations.RewardClaimsFromCurrentUserDocument,
  });
  return {
    data: data as loginLineOperations.RewardClaimsFromCurrentUserQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const claimRewardClaimForCurrentUser = async (rewardClaimId: string, questionOutput: string) => {
  const { data, errors } = await composables
    .useApolloClient()
    .client.mutate<
      loginLineOperations.ClaimRewardClaimFromCurrentUserMutation,
      loginLineOperations.ClaimRewardClaimFromCurrentUserMutationVariables
    >({
      mutation: loginLineOperations.ClaimRewardClaimFromCurrentUserDocument,
      variables: {
        id: Number.parseInt(rewardClaimId),
        questionOutput,
      },
      errorPolicy: "all",
    });
  return {
    data: data as loginLineOperations.ClaimRewardClaimFromCurrentUserMutation,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getLivePlayerStats = async (optaId: string) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.GetLivePlayerStatsQuery>({
    query: loginLineOperations.GetLivePlayerStatsDocument,
    variables: { optaId },
  });
  return {
    data: data as loginLineOperations.GetLivePlayerStatsQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getPlayerUpcomingAwayRealGames = async (variables: loginLineOperations.PlayerUpcomingAwayRealGamesQueryVariables) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.PlayerUpcomingAwayRealGamesQuery>({
    query: loginLineOperations.PlayerUpcomingAwayRealGamesDocument,
    variables,
  });
  return {
    data: data as loginLineOperations.PlayerUpcomingAwayRealGamesQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getPlayerUpcomingHomeRealGames = async (variables: loginLineOperations.PlayerUpcomingHomeRealGamesQueryVariables) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.PlayerUpcomingHomeRealGamesQuery>({
    query: loginLineOperations.PlayerUpcomingHomeRealGamesDocument,
    variables,
  });
  return {
    data: data as loginLineOperations.PlayerUpcomingHomeRealGamesQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getAllUserCards = async (filter?: loginLineOperations.PlayerWhereInput, take?: number, skip?: number) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.CardsQuery>({
    query: loginLineOperations.CardsDocument,
    variables: { filter, take, skip },
  });
  return {
    data: data as loginLineOperations.CardsQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getCompetitionForCard = async (tokenId: string, take?: number, skip?: number) => {
  const data = await eraApolloClient.query({
    query: gql`
      query Card($tokenId: String!) {
        Card(tokenId: $tokenId) {
          competition
        }
      }
    `,
    variables: { tokenId, take, skip },
  });
  return data.data.Card.competition ?? undefined;
};

const getAllCommonCards = async () => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.AllCommonCardsQuery>({
    query: loginLineOperations.AllCommonCardsDocument,
  });
  return {
    data: data as loginLineOperations.AllCommonCardsQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getMarketPlaceCardPlayerCacheQuery = async (cardTokenId: string) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.MarketPlaceCardPlayerCacheQuery>({
    query: loginLineOperations.MarketPlaceCardPlayerCacheDocument,
    variables: { cardTokenId },
  });
  return {
    data: data as loginLineOperations.MarketPlaceCardPlayerCacheQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getAllCommonCardsByGameWeekId = async (gameWeekId: number) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.CommonCardsByGameWeekQuery>({
    query: loginLineOperations.CommonCardsByGameWeekDocument,
    variables: { gameWeekId },
  });
  return {
    data: data as loginLineOperations.CommonCardsByGameWeekQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getCompositionsForCurrentUser = async (status?: loginLineOperations.TournamentGameWeekStatus[]) => {
  const query = loginLineOperations.GetCompositionsForCurrentUserDocument;
  const variables = status ? { status } : {};
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.GetCompositionsForCurrentUserQuery>({
    query,
    variables,
  });
  return {
    data: data as loginLineOperations.GetCompositionsForCurrentUserQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getAllTournamentGameWeeks = async (variables: loginLineOperations.AllTournamentGameWeeksQueryVariables) => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.AllTournamentGameWeeksQuery>({
    query: loginLineOperations.AllTournamentGameWeeksDocument,
    variables,
  });
  return {
    data: data as loginLineOperations.AllTournamentGameWeeksQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getAllTeams = async () => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.AllTeamsQuery>({
    query: loginLineOperations.AllTeamsDocument,
  });
  return {
    data: data as loginLineOperations.AllTeamsQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const getAllChampionships = async () => {
  const { data, errors } = await composables.useApolloClient().client.query<loginLineOperations.AllChampionshipsQuery>({
    query: loginLineOperations.AllChampionshipsDocument,
  });
  return {
    data: data as loginLineOperations.AllChampionshipsQuery,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const createCompositionForCurrentUser = async (composition: loginLineOperations.CompositionCreateInput) => {
  const { data, errors } = await composables
    .useApolloClient()
    .client.mutate<
      loginLineOperations.CreateCompositionForCurrentUserMutation,
      loginLineOperations.CreateCompositionForCurrentUserMutationVariables
    >({
      mutation: loginLineOperations.CreateCompositionForCurrentUserDocument,
      variables: {
        arg: {
          ...composition,
        },
      },
      errorPolicy: "all",
    });
  return {
    data: data as loginLineOperations.CreateCompositionForCurrentUserMutation,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

const updateComposition = async (id: number, composition: loginLineOperations.CompositionCreateInput) => {
  const { data, errors } = await composables
    .useApolloClient()
    .client.mutate<
      loginLineOperations.UpdateCompositionForCurrentUserMutation,
      loginLineOperations.MutationUpdateCompositionForCurrentUserArgs
    >({
      mutation: loginLineOperations.UpdateCompositionForCurrentUserDocument,
      variables: {
        id: id,
        data: {
          ...composition,
        },
      },
      errorPolicy: "all",
    });
  return {
    data: data as loginLineOperations.UpdateCompositionForCurrentUserMutation,
    errors: errors?.length ? errors.map((e) => e.message).join(", ") : null,
  };
};

export const useApolloOperations = () => {
  return {
    composables,
    getReferralInformation,
    getUser,
    getAllUserCards,
    getMarketPlaceCardPlayerCacheQuery,
    getLivePlayerStats,
    getPlayerUpcomingAwayRealGames,
    getPlayerUpcomingHomeRealGames,
    getAllCommonCards,
    getAllCommonCardsByGameWeekId,
    getHasPlaysPlayedDuringGameWeekScore,
    getAllTournamentVariantsIds,
    getDetailedTournamentVariant,
    getTournamentVariant,
    getAllTeams,
    getAllChampionships,
    getPlayerStats,
    getPlayerScore,
    getGameWeekLeaderboard,
    getRewardClaimsForCurrentUser,
    claimRewardClaimForCurrentUser,
    getCompositionsForCurrentUser,
    getAllTournamentGameWeeks,
    createCompositionForCurrentUser,
    updateComposition,
    getCompetitionForCard,
  };
};
