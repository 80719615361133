import { REFERRAL_ENABLED } from "@/application/config";
import { RouteSecurity } from "@/application/enums/RouteSecurity";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  { path: "/", redirect: { name: "login" } },
  {
    path: "/login",
    component: () => import("@/views/Auth/LoginView.vue"),
    name: "login",
  },

  {
    path: "/logout",
    component: () => import("@/views/Auth/LoginView.vue"),
    name: "logout",
    meta: {
      security: RouteSecurity.Public,
    },
  },
  {
    path: "/onboarding",
    component: () => import("@/views/Onboarding/OnboardingView.vue"),
    name: "onboarding",
    meta: {
      security: RouteSecurity.Public,
    },
  },
  {
    path: "/common/tournament/:tournamentVariantId",
    component: () => import("@/views/TournamentCommon/TournamentCommonView.vue"),
    name: "tournament_common.edit",
    meta: {
      security: RouteSecurity.Public,
    },
  },
  {
    path: "/tournament/:tournamentVariantId/register",
    component: () => import("@/views/Tournament/TournamentView.vue"),
    name: "tournament.register",
  },
  {
    path: "/tournament/:tournamentVariantId/edit",
    component: () => import("@/views/Tournament/TournamentView.vue"),
    name: "tournament.edit",
  },
  {
    path: "/my-club",
    component: () => import("@/views/MyClub/MyClubView.vue"),
    name: "my-club",
    meta: {
      layout: "main-layout",
    },
  },
  {
    path: "/play",
    component: () => import("@/views/Game/PlayView.vue"),
    name: "play",
    meta: {
      layout: "main-layout",
    },
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("@/components/Maintenance/Maintenance.vue"),
    props: (route) => {
      const pageUnderMaintenance = route.query.page || "default";
      let message = "The page is currently under maintenance. Please check back later.";

      if (pageUnderMaintenance === "my-club") {
        message = "The my club page is currently under maintenance.";
      } else if (pageUnderMaintenance === "play") {
        message = "The play page is currently under maintenance.";
      }

      return {
        title: "Maintenance Mode",
        message: message,
      };
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("@/views/Error/Error404View.vue"),
  },
];

if (REFERRAL_ENABLED) {
  routes.push({
    path: "/referrals",
    component: () => import("@/views/Referrals/Referrals.vue"),
    name: "referrals",
    meta: {
      layout: "main-layout",
    },
  });
}

export default routes;
