import { defineStore } from "pinia";

export const useMaintenanceStore = defineStore("maintenanceStore", {
  state: () => ({
    showMyClubMaintenanceMode: false,
    showPlayMaintenanceMode: false,
    walletWhitelist: [""],
  }),
  actions: {
    setMyClubMaintenanceMode(value: boolean) {
      this.showMyClubMaintenanceMode = value;
    },
    setPlayMaintenanceMode(value: boolean) {
      this.showPlayMaintenanceMode = value;
    },
    setWalletWhitelist(values: string[]) {
      const lowercaseWallets = values.map((wallets) => wallets.toLowerCase());
      this.walletWhitelist.push(...lowercaseWallets);
    },
  },
});
