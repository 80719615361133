import { defineStore } from "pinia";
import { markRaw } from "vue";

export type ModalStore = {
  isOpen: boolean;
  view: object;
};

export const useModalStore = defineStore("modal", {
  state: (): ModalStore => ({
    isOpen: false,
    view: {},
  }),
  actions: {
    open(view: object) {
      this.isOpen = true;
      this.view = markRaw(view);
    },
    close() {
      this.isOpen = false;
      setTimeout(() => {
        this.view = {};
      }, 500);
    },
  },
});
