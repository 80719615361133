import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  JSON: { input: any; output: any };
  Timestamp: { input: any; output: any };
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BytesNullableFilter = {
  equals?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  not?: InputMaybe<NestedBytesNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type Championship = {
  __typename?: "Championship";
  _count: ChampionshipCount;
  championshipOptaId: Scalars["String"]["output"];
  championshipSeasons?: Maybe<Array<ChampionshipSeason>>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  shortName: Scalars["String"]["output"];
  tournamentChampionships?: Maybe<Array<TournamentChampionship>>;
};

export type ChampionshipCount = {
  __typename?: "ChampionshipCount";
  championshipSeasons: Scalars["Int"]["output"];
  tournamentChampionships: Scalars["Int"]["output"];
};

export type ChampionshipCreateInput = {
  championshipOptaId: Scalars["String"]["input"];
  championshipSeasons?: InputMaybe<ChampionshipSeasonCreateNestedManyWithoutChampionshipInput>;
  name: Scalars["String"]["input"];
  shortName: Scalars["String"]["input"];
  tournamentChampionships?: InputMaybe<TournamentChampionshipCreateNestedManyWithoutChampionshipInput>;
};

export type ChampionshipCreateNestedOneWithoutChampionshipSeasonsInput = {
  connect?: InputMaybe<ChampionshipWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChampionshipCreateOrConnectWithoutChampionshipSeasonsInput>;
  create?: InputMaybe<ChampionshipCreateWithoutChampionshipSeasonsInput>;
};

export type ChampionshipCreateNestedOneWithoutTournamentChampionshipsInput = {
  connect?: InputMaybe<ChampionshipWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChampionshipCreateOrConnectWithoutTournamentChampionshipsInput>;
  create?: InputMaybe<ChampionshipCreateWithoutTournamentChampionshipsInput>;
};

export type ChampionshipCreateOrConnectWithoutChampionshipSeasonsInput = {
  create: ChampionshipCreateWithoutChampionshipSeasonsInput;
  where: ChampionshipWhereUniqueInput;
};

export type ChampionshipCreateOrConnectWithoutTournamentChampionshipsInput = {
  create: ChampionshipCreateWithoutTournamentChampionshipsInput;
  where: ChampionshipWhereUniqueInput;
};

export type ChampionshipCreateWithoutChampionshipSeasonsInput = {
  championshipOptaId: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  shortName: Scalars["String"]["input"];
  tournamentChampionships?: InputMaybe<TournamentChampionshipCreateNestedManyWithoutChampionshipInput>;
};

export type ChampionshipCreateWithoutTournamentChampionshipsInput = {
  championshipOptaId: Scalars["String"]["input"];
  championshipSeasons?: InputMaybe<ChampionshipSeasonCreateNestedManyWithoutChampionshipInput>;
  name: Scalars["String"]["input"];
  shortName: Scalars["String"]["input"];
};

export enum ChampionshipOrderByRelevanceFieldEnum {
  ChampionshipOptaId = "championshipOptaId",
  Name = "name",
  ShortName = "shortName",
}

export type ChampionshipOrderByRelevanceInput = {
  fields: Array<ChampionshipOrderByRelevanceFieldEnum>;
  search: Scalars["String"]["input"];
  sort: SortOrder;
};

export type ChampionshipOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ChampionshipOrderByRelevanceInput>;
  championshipOptaId?: InputMaybe<SortOrder>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  tournamentChampionships?: InputMaybe<TournamentChampionshipOrderByRelationAggregateInput>;
};

export type ChampionshipRelationFilter = {
  is?: InputMaybe<ChampionshipWhereInput>;
  isNot?: InputMaybe<ChampionshipWhereInput>;
};

export enum ChampionshipScalarFieldEnum {
  ChampionshipOptaId = "championshipOptaId",
  Id = "id",
  Name = "name",
  ShortName = "shortName",
}

export type ChampionshipSeason = {
  __typename?: "ChampionshipSeason";
  _count: ChampionshipSeasonCount;
  championship: Championship;
  championshipId: Scalars["Int"]["output"];
  championshipOptaId: Scalars["String"]["output"];
  championshipSeasonTeamPlayers?: Maybe<Array<ChampionshipSeasonTeamPlayer>>;
  id: Scalars["ID"]["output"];
  realGames?: Maybe<Array<RealGame>>;
  seasonOptaId: Scalars["String"]["output"];
  teams?: Maybe<Array<Team>>;
  tournaments?: Maybe<Array<Tournament>>;
};

export type ChampionshipSeasonChampionshipOptaIdSeasonOptaIdCompoundUniqueInput = {
  championshipOptaId: Scalars["String"]["input"];
  seasonOptaId: Scalars["String"]["input"];
};

export type ChampionshipSeasonCount = {
  __typename?: "ChampionshipSeasonCount";
  championshipSeasonTeamPlayers: Scalars["Int"]["output"];
  realGames: Scalars["Int"]["output"];
  teams: Scalars["Int"]["output"];
  tournaments: Scalars["Int"]["output"];
};

export type ChampionshipSeasonCreateInput = {
  championshipOptaId: Scalars["Int"]["input"];
  season: Scalars["Int"]["input"];
};

export type ChampionshipSeasonCreateManyChampionshipInput = {
  championshipOptaId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  seasonOptaId: Scalars["String"]["input"];
};

export type ChampionshipSeasonCreateManyChampionshipInputEnvelope = {
  data: Array<ChampionshipSeasonCreateManyChampionshipInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ChampionshipSeasonCreateNestedManyWithoutChampionshipInput = {
  connect?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChampionshipSeasonCreateOrConnectWithoutChampionshipInput>>;
  create?: InputMaybe<Array<ChampionshipSeasonCreateWithoutChampionshipInput>>;
  createMany?: InputMaybe<ChampionshipSeasonCreateManyChampionshipInputEnvelope>;
};

export type ChampionshipSeasonCreateNestedManyWithoutTeamsInput = {
  connect?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChampionshipSeasonCreateOrConnectWithoutTeamsInput>>;
  create?: InputMaybe<Array<ChampionshipSeasonCreateWithoutTeamsInput>>;
};

export type ChampionshipSeasonCreateNestedManyWithoutTournamentsInput = {
  connect?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChampionshipSeasonCreateOrConnectWithoutTournamentsInput>>;
  create?: InputMaybe<Array<ChampionshipSeasonCreateWithoutTournamentsInput>>;
};

export type ChampionshipSeasonCreateNestedOneWithoutChampionshipSeasonTeamPlayersInput = {
  connect?: InputMaybe<ChampionshipSeasonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChampionshipSeasonCreateOrConnectWithoutChampionshipSeasonTeamPlayersInput>;
  create?: InputMaybe<ChampionshipSeasonCreateWithoutChampionshipSeasonTeamPlayersInput>;
};

export type ChampionshipSeasonCreateNestedOneWithoutRealGamesInput = {
  connect?: InputMaybe<ChampionshipSeasonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChampionshipSeasonCreateOrConnectWithoutRealGamesInput>;
  create?: InputMaybe<ChampionshipSeasonCreateWithoutRealGamesInput>;
};

export type ChampionshipSeasonCreateOrConnectWithoutChampionshipInput = {
  create: ChampionshipSeasonCreateWithoutChampionshipInput;
  where: ChampionshipSeasonWhereUniqueInput;
};

export type ChampionshipSeasonCreateOrConnectWithoutChampionshipSeasonTeamPlayersInput = {
  create: ChampionshipSeasonCreateWithoutChampionshipSeasonTeamPlayersInput;
  where: ChampionshipSeasonWhereUniqueInput;
};

export type ChampionshipSeasonCreateOrConnectWithoutRealGamesInput = {
  create: ChampionshipSeasonCreateWithoutRealGamesInput;
  where: ChampionshipSeasonWhereUniqueInput;
};

export type ChampionshipSeasonCreateOrConnectWithoutTeamsInput = {
  create: ChampionshipSeasonCreateWithoutTeamsInput;
  where: ChampionshipSeasonWhereUniqueInput;
};

export type ChampionshipSeasonCreateOrConnectWithoutTournamentsInput = {
  create: ChampionshipSeasonCreateWithoutTournamentsInput;
  where: ChampionshipSeasonWhereUniqueInput;
};

export type ChampionshipSeasonCreateWithoutChampionshipInput = {
  championshipOptaId: Scalars["String"]["input"];
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerCreateNestedManyWithoutChampionshipSeasonInput>;
  realGames?: InputMaybe<RealGameCreateNestedManyWithoutChampionshipSeasonInput>;
  seasonOptaId: Scalars["String"]["input"];
  teams?: InputMaybe<TeamCreateNestedManyWithoutChampionshipSeasonsInput>;
  tournaments?: InputMaybe<TournamentCreateNestedManyWithoutChampionshipSeasonsInput>;
};

export type ChampionshipSeasonCreateWithoutChampionshipSeasonTeamPlayersInput = {
  championship: ChampionshipCreateNestedOneWithoutChampionshipSeasonsInput;
  championshipOptaId: Scalars["String"]["input"];
  realGames?: InputMaybe<RealGameCreateNestedManyWithoutChampionshipSeasonInput>;
  seasonOptaId: Scalars["String"]["input"];
  teams?: InputMaybe<TeamCreateNestedManyWithoutChampionshipSeasonsInput>;
  tournaments?: InputMaybe<TournamentCreateNestedManyWithoutChampionshipSeasonsInput>;
};

export type ChampionshipSeasonCreateWithoutRealGamesInput = {
  championship: ChampionshipCreateNestedOneWithoutChampionshipSeasonsInput;
  championshipOptaId: Scalars["String"]["input"];
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerCreateNestedManyWithoutChampionshipSeasonInput>;
  seasonOptaId: Scalars["String"]["input"];
  teams?: InputMaybe<TeamCreateNestedManyWithoutChampionshipSeasonsInput>;
  tournaments?: InputMaybe<TournamentCreateNestedManyWithoutChampionshipSeasonsInput>;
};

export type ChampionshipSeasonCreateWithoutTeamsInput = {
  championship: ChampionshipCreateNestedOneWithoutChampionshipSeasonsInput;
  championshipOptaId: Scalars["String"]["input"];
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerCreateNestedManyWithoutChampionshipSeasonInput>;
  realGames?: InputMaybe<RealGameCreateNestedManyWithoutChampionshipSeasonInput>;
  seasonOptaId: Scalars["String"]["input"];
  tournaments?: InputMaybe<TournamentCreateNestedManyWithoutChampionshipSeasonsInput>;
};

export type ChampionshipSeasonCreateWithoutTournamentsInput = {
  championship: ChampionshipCreateNestedOneWithoutChampionshipSeasonsInput;
  championshipOptaId: Scalars["String"]["input"];
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerCreateNestedManyWithoutChampionshipSeasonInput>;
  realGames?: InputMaybe<RealGameCreateNestedManyWithoutChampionshipSeasonInput>;
  seasonOptaId: Scalars["String"]["input"];
  teams?: InputMaybe<TeamCreateNestedManyWithoutChampionshipSeasonsInput>;
};

export type ChampionshipSeasonListRelationFilter = {
  every?: InputMaybe<ChampionshipSeasonWhereInput>;
  none?: InputMaybe<ChampionshipSeasonWhereInput>;
  some?: InputMaybe<ChampionshipSeasonWhereInput>;
};

export type ChampionshipSeasonOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ChampionshipSeasonOrderByRelevanceFieldEnum {
  ChampionshipOptaId = "championshipOptaId",
  SeasonOptaId = "seasonOptaId",
}

export type ChampionshipSeasonOrderByRelevanceInput = {
  fields: Array<ChampionshipSeasonOrderByRelevanceFieldEnum>;
  search: Scalars["String"]["input"];
  sort: SortOrder;
};

export type ChampionshipSeasonOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ChampionshipSeasonOrderByRelevanceInput>;
  championship?: InputMaybe<ChampionshipOrderByWithRelationAndSearchRelevanceInput>;
  championshipId?: InputMaybe<SortOrder>;
  championshipOptaId?: InputMaybe<SortOrder>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  realGames?: InputMaybe<RealGameOrderByRelationAggregateInput>;
  seasonOptaId?: InputMaybe<SortOrder>;
  teams?: InputMaybe<TeamOrderByRelationAggregateInput>;
  tournaments?: InputMaybe<TournamentOrderByRelationAggregateInput>;
};

export type ChampionshipSeasonRelationFilter = {
  is?: InputMaybe<ChampionshipSeasonWhereInput>;
  isNot?: InputMaybe<ChampionshipSeasonWhereInput>;
};

export enum ChampionshipSeasonScalarFieldEnum {
  ChampionshipId = "championshipId",
  ChampionshipOptaId = "championshipOptaId",
  Id = "id",
  SeasonOptaId = "seasonOptaId",
}

export type ChampionshipSeasonScalarWhereInput = {
  AND?: InputMaybe<Array<ChampionshipSeasonScalarWhereInput>>;
  NOT?: InputMaybe<Array<ChampionshipSeasonScalarWhereInput>>;
  OR?: InputMaybe<Array<ChampionshipSeasonScalarWhereInput>>;
  championshipId?: InputMaybe<IntFilter>;
  championshipOptaId?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  seasonOptaId?: InputMaybe<StringFilter>;
};

export type ChampionshipSeasonTeamPlayer = {
  __typename?: "ChampionshipSeasonTeamPlayer";
  championshipSeason: ChampionshipSeason;
  championshipSeasonId: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  player: Player;
  playerId: Scalars["Int"]["output"];
  team: Team;
  teamId: Scalars["Int"]["output"];
};

export type ChampionshipSeasonTeamPlayerChampionshipSeasonIdTeamIdPlayerIdCompoundUniqueInput = {
  championshipSeasonId: Scalars["Int"]["input"];
  playerId: Scalars["Int"]["input"];
  teamId: Scalars["Int"]["input"];
};

export type ChampionshipSeasonTeamPlayerCreateManyChampionshipSeasonInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  playerId: Scalars["Int"]["input"];
  teamId: Scalars["Int"]["input"];
};

export type ChampionshipSeasonTeamPlayerCreateManyChampionshipSeasonInputEnvelope = {
  data: Array<ChampionshipSeasonTeamPlayerCreateManyChampionshipSeasonInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ChampionshipSeasonTeamPlayerCreateManyPlayerInput = {
  championshipSeasonId: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  teamId: Scalars["Int"]["input"];
};

export type ChampionshipSeasonTeamPlayerCreateManyPlayerInputEnvelope = {
  data: Array<ChampionshipSeasonTeamPlayerCreateManyPlayerInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ChampionshipSeasonTeamPlayerCreateManyTeamInput = {
  championshipSeasonId: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  playerId: Scalars["Int"]["input"];
};

export type ChampionshipSeasonTeamPlayerCreateManyTeamInputEnvelope = {
  data: Array<ChampionshipSeasonTeamPlayerCreateManyTeamInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ChampionshipSeasonTeamPlayerCreateNestedManyWithoutChampionshipSeasonInput = {
  connect?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChampionshipSeasonTeamPlayerCreateOrConnectWithoutChampionshipSeasonInput>>;
  create?: InputMaybe<Array<ChampionshipSeasonTeamPlayerCreateWithoutChampionshipSeasonInput>>;
  createMany?: InputMaybe<ChampionshipSeasonTeamPlayerCreateManyChampionshipSeasonInputEnvelope>;
};

export type ChampionshipSeasonTeamPlayerCreateNestedManyWithoutPlayerInput = {
  connect?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChampionshipSeasonTeamPlayerCreateOrConnectWithoutPlayerInput>>;
  create?: InputMaybe<Array<ChampionshipSeasonTeamPlayerCreateWithoutPlayerInput>>;
  createMany?: InputMaybe<ChampionshipSeasonTeamPlayerCreateManyPlayerInputEnvelope>;
};

export type ChampionshipSeasonTeamPlayerCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChampionshipSeasonTeamPlayerCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<ChampionshipSeasonTeamPlayerCreateWithoutTeamInput>>;
  createMany?: InputMaybe<ChampionshipSeasonTeamPlayerCreateManyTeamInputEnvelope>;
};

export type ChampionshipSeasonTeamPlayerCreateOrConnectWithoutChampionshipSeasonInput = {
  create: ChampionshipSeasonTeamPlayerCreateWithoutChampionshipSeasonInput;
  where: ChampionshipSeasonTeamPlayerWhereUniqueInput;
};

export type ChampionshipSeasonTeamPlayerCreateOrConnectWithoutPlayerInput = {
  create: ChampionshipSeasonTeamPlayerCreateWithoutPlayerInput;
  where: ChampionshipSeasonTeamPlayerWhereUniqueInput;
};

export type ChampionshipSeasonTeamPlayerCreateOrConnectWithoutTeamInput = {
  create: ChampionshipSeasonTeamPlayerCreateWithoutTeamInput;
  where: ChampionshipSeasonTeamPlayerWhereUniqueInput;
};

export type ChampionshipSeasonTeamPlayerCreateWithoutChampionshipSeasonInput = {
  player: PlayerCreateNestedOneWithoutChampionshipSeasonTeamPlayersInput;
  team: TeamCreateNestedOneWithoutChampionshipSeasonTeamPlayersInput;
};

export type ChampionshipSeasonTeamPlayerCreateWithoutPlayerInput = {
  championshipSeason: ChampionshipSeasonCreateNestedOneWithoutChampionshipSeasonTeamPlayersInput;
  team: TeamCreateNestedOneWithoutChampionshipSeasonTeamPlayersInput;
};

export type ChampionshipSeasonTeamPlayerCreateWithoutTeamInput = {
  championshipSeason: ChampionshipSeasonCreateNestedOneWithoutChampionshipSeasonTeamPlayersInput;
  player: PlayerCreateNestedOneWithoutChampionshipSeasonTeamPlayersInput;
};

export type ChampionshipSeasonTeamPlayerListRelationFilter = {
  every?: InputMaybe<ChampionshipSeasonTeamPlayerWhereInput>;
  none?: InputMaybe<ChampionshipSeasonTeamPlayerWhereInput>;
  some?: InputMaybe<ChampionshipSeasonTeamPlayerWhereInput>;
};

export type ChampionshipSeasonTeamPlayerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ChampionshipSeasonTeamPlayerScalarWhereInput = {
  AND?: InputMaybe<Array<ChampionshipSeasonTeamPlayerScalarWhereInput>>;
  NOT?: InputMaybe<Array<ChampionshipSeasonTeamPlayerScalarWhereInput>>;
  OR?: InputMaybe<Array<ChampionshipSeasonTeamPlayerScalarWhereInput>>;
  championshipSeasonId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  playerId?: InputMaybe<IntFilter>;
  teamId?: InputMaybe<IntFilter>;
};

export type ChampionshipSeasonTeamPlayerUncheckedUpdateManyWithoutChampionshipSeasonInput = {
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  playerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  teamId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ChampionshipSeasonTeamPlayerUncheckedUpdateManyWithoutPlayerInput = {
  championshipSeasonId?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  teamId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ChampionshipSeasonTeamPlayerUncheckedUpdateManyWithoutTeamInput = {
  championshipSeasonId?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  playerId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ChampionshipSeasonTeamPlayerUpdateManyWithWhereWithoutChampionshipSeasonInput = {
  data: ChampionshipSeasonTeamPlayerUncheckedUpdateManyWithoutChampionshipSeasonInput;
  where: ChampionshipSeasonTeamPlayerScalarWhereInput;
};

export type ChampionshipSeasonTeamPlayerUpdateManyWithWhereWithoutPlayerInput = {
  data: ChampionshipSeasonTeamPlayerUncheckedUpdateManyWithoutPlayerInput;
  where: ChampionshipSeasonTeamPlayerScalarWhereInput;
};

export type ChampionshipSeasonTeamPlayerUpdateManyWithWhereWithoutTeamInput = {
  data: ChampionshipSeasonTeamPlayerUncheckedUpdateManyWithoutTeamInput;
  where: ChampionshipSeasonTeamPlayerScalarWhereInput;
};

export type ChampionshipSeasonTeamPlayerUpdateManyWithoutChampionshipSeasonNestedInput = {
  connect?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChampionshipSeasonTeamPlayerCreateOrConnectWithoutChampionshipSeasonInput>>;
  create?: InputMaybe<Array<ChampionshipSeasonTeamPlayerCreateWithoutChampionshipSeasonInput>>;
  createMany?: InputMaybe<ChampionshipSeasonTeamPlayerCreateManyChampionshipSeasonInputEnvelope>;
  delete?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ChampionshipSeasonTeamPlayerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  set?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  update?: InputMaybe<Array<ChampionshipSeasonTeamPlayerUpdateWithWhereUniqueWithoutChampionshipSeasonInput>>;
  updateMany?: InputMaybe<Array<ChampionshipSeasonTeamPlayerUpdateManyWithWhereWithoutChampionshipSeasonInput>>;
  upsert?: InputMaybe<Array<ChampionshipSeasonTeamPlayerUpsertWithWhereUniqueWithoutChampionshipSeasonInput>>;
};

export type ChampionshipSeasonTeamPlayerUpdateManyWithoutPlayerNestedInput = {
  connect?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChampionshipSeasonTeamPlayerCreateOrConnectWithoutPlayerInput>>;
  create?: InputMaybe<Array<ChampionshipSeasonTeamPlayerCreateWithoutPlayerInput>>;
  createMany?: InputMaybe<ChampionshipSeasonTeamPlayerCreateManyPlayerInputEnvelope>;
  delete?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ChampionshipSeasonTeamPlayerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  set?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  update?: InputMaybe<Array<ChampionshipSeasonTeamPlayerUpdateWithWhereUniqueWithoutPlayerInput>>;
  updateMany?: InputMaybe<Array<ChampionshipSeasonTeamPlayerUpdateManyWithWhereWithoutPlayerInput>>;
  upsert?: InputMaybe<Array<ChampionshipSeasonTeamPlayerUpsertWithWhereUniqueWithoutPlayerInput>>;
};

export type ChampionshipSeasonTeamPlayerUpdateManyWithoutTeamNestedInput = {
  connect?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChampionshipSeasonTeamPlayerCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<ChampionshipSeasonTeamPlayerCreateWithoutTeamInput>>;
  createMany?: InputMaybe<ChampionshipSeasonTeamPlayerCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ChampionshipSeasonTeamPlayerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  set?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereUniqueInput>>;
  update?: InputMaybe<Array<ChampionshipSeasonTeamPlayerUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: InputMaybe<Array<ChampionshipSeasonTeamPlayerUpdateManyWithWhereWithoutTeamInput>>;
  upsert?: InputMaybe<Array<ChampionshipSeasonTeamPlayerUpsertWithWhereUniqueWithoutTeamInput>>;
};

export type ChampionshipSeasonTeamPlayerUpdateWithWhereUniqueWithoutChampionshipSeasonInput = {
  data: ChampionshipSeasonTeamPlayerUpdateWithoutChampionshipSeasonInput;
  where: ChampionshipSeasonTeamPlayerWhereUniqueInput;
};

export type ChampionshipSeasonTeamPlayerUpdateWithWhereUniqueWithoutPlayerInput = {
  data: ChampionshipSeasonTeamPlayerUpdateWithoutPlayerInput;
  where: ChampionshipSeasonTeamPlayerWhereUniqueInput;
};

export type ChampionshipSeasonTeamPlayerUpdateWithWhereUniqueWithoutTeamInput = {
  data: ChampionshipSeasonTeamPlayerUpdateWithoutTeamInput;
  where: ChampionshipSeasonTeamPlayerWhereUniqueInput;
};

export type ChampionshipSeasonTeamPlayerUpdateWithoutChampionshipSeasonInput = {
  player?: InputMaybe<PlayerUpdateOneRequiredWithoutChampionshipSeasonTeamPlayersNestedInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutChampionshipSeasonTeamPlayersNestedInput>;
};

export type ChampionshipSeasonTeamPlayerUpdateWithoutPlayerInput = {
  championshipSeason?: InputMaybe<ChampionshipSeasonUpdateOneRequiredWithoutChampionshipSeasonTeamPlayersNestedInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutChampionshipSeasonTeamPlayersNestedInput>;
};

export type ChampionshipSeasonTeamPlayerUpdateWithoutTeamInput = {
  championshipSeason?: InputMaybe<ChampionshipSeasonUpdateOneRequiredWithoutChampionshipSeasonTeamPlayersNestedInput>;
  player?: InputMaybe<PlayerUpdateOneRequiredWithoutChampionshipSeasonTeamPlayersNestedInput>;
};

export type ChampionshipSeasonTeamPlayerUpsertWithWhereUniqueWithoutChampionshipSeasonInput = {
  create: ChampionshipSeasonTeamPlayerCreateWithoutChampionshipSeasonInput;
  update: ChampionshipSeasonTeamPlayerUpdateWithoutChampionshipSeasonInput;
  where: ChampionshipSeasonTeamPlayerWhereUniqueInput;
};

export type ChampionshipSeasonTeamPlayerUpsertWithWhereUniqueWithoutPlayerInput = {
  create: ChampionshipSeasonTeamPlayerCreateWithoutPlayerInput;
  update: ChampionshipSeasonTeamPlayerUpdateWithoutPlayerInput;
  where: ChampionshipSeasonTeamPlayerWhereUniqueInput;
};

export type ChampionshipSeasonTeamPlayerUpsertWithWhereUniqueWithoutTeamInput = {
  create: ChampionshipSeasonTeamPlayerCreateWithoutTeamInput;
  update: ChampionshipSeasonTeamPlayerUpdateWithoutTeamInput;
  where: ChampionshipSeasonTeamPlayerWhereUniqueInput;
};

export type ChampionshipSeasonTeamPlayerWhereInput = {
  AND?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereInput>>;
  NOT?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereInput>>;
  OR?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereInput>>;
  championshipSeason?: InputMaybe<ChampionshipSeasonRelationFilter>;
  championshipSeasonId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  player?: InputMaybe<PlayerRelationFilter>;
  playerId?: InputMaybe<IntFilter>;
  team?: InputMaybe<TeamRelationFilter>;
  teamId?: InputMaybe<IntFilter>;
};

export type ChampionshipSeasonTeamPlayerWhereUniqueInput = {
  AND?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereInput>>;
  NOT?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereInput>>;
  OR?: InputMaybe<Array<ChampionshipSeasonTeamPlayerWhereInput>>;
  championshipSeason?: InputMaybe<ChampionshipSeasonRelationFilter>;
  championshipSeasonId?: InputMaybe<IntFilter>;
  championshipSeasonId_teamId_playerId?: InputMaybe<ChampionshipSeasonTeamPlayerChampionshipSeasonIdTeamIdPlayerIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  player?: InputMaybe<PlayerRelationFilter>;
  playerId?: InputMaybe<IntFilter>;
  team?: InputMaybe<TeamRelationFilter>;
  teamId?: InputMaybe<IntFilter>;
};

export type ChampionshipSeasonUpdateManyMutationInput = {
  championshipOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ChampionshipSeasonUpdateManyWithWhereWithoutChampionshipInput = {
  data: ChampionshipSeasonUpdateManyMutationInput;
  where: ChampionshipSeasonScalarWhereInput;
};

export type ChampionshipSeasonUpdateManyWithWhereWithoutTeamsInput = {
  data: ChampionshipSeasonUpdateManyMutationInput;
  where: ChampionshipSeasonScalarWhereInput;
};

export type ChampionshipSeasonUpdateManyWithWhereWithoutTournamentsInput = {
  data: ChampionshipSeasonUpdateManyMutationInput;
  where: ChampionshipSeasonScalarWhereInput;
};

export type ChampionshipSeasonUpdateManyWithoutChampionshipNestedInput = {
  connect?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChampionshipSeasonCreateOrConnectWithoutChampionshipInput>>;
  create?: InputMaybe<Array<ChampionshipSeasonCreateWithoutChampionshipInput>>;
  createMany?: InputMaybe<ChampionshipSeasonCreateManyChampionshipInputEnvelope>;
  delete?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ChampionshipSeasonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  set?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  update?: InputMaybe<Array<ChampionshipSeasonUpdateWithWhereUniqueWithoutChampionshipInput>>;
  updateMany?: InputMaybe<Array<ChampionshipSeasonUpdateManyWithWhereWithoutChampionshipInput>>;
  upsert?: InputMaybe<Array<ChampionshipSeasonUpsertWithWhereUniqueWithoutChampionshipInput>>;
};

export type ChampionshipSeasonUpdateManyWithoutTeamsNestedInput = {
  connect?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChampionshipSeasonCreateOrConnectWithoutTeamsInput>>;
  create?: InputMaybe<Array<ChampionshipSeasonCreateWithoutTeamsInput>>;
  delete?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ChampionshipSeasonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  set?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  update?: InputMaybe<Array<ChampionshipSeasonUpdateWithWhereUniqueWithoutTeamsInput>>;
  updateMany?: InputMaybe<Array<ChampionshipSeasonUpdateManyWithWhereWithoutTeamsInput>>;
  upsert?: InputMaybe<Array<ChampionshipSeasonUpsertWithWhereUniqueWithoutTeamsInput>>;
};

export type ChampionshipSeasonUpdateManyWithoutTournamentsNestedInput = {
  connect?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChampionshipSeasonCreateOrConnectWithoutTournamentsInput>>;
  create?: InputMaybe<Array<ChampionshipSeasonCreateWithoutTournamentsInput>>;
  delete?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ChampionshipSeasonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  set?: InputMaybe<Array<ChampionshipSeasonWhereUniqueInput>>;
  update?: InputMaybe<Array<ChampionshipSeasonUpdateWithWhereUniqueWithoutTournamentsInput>>;
  updateMany?: InputMaybe<Array<ChampionshipSeasonUpdateManyWithWhereWithoutTournamentsInput>>;
  upsert?: InputMaybe<Array<ChampionshipSeasonUpsertWithWhereUniqueWithoutTournamentsInput>>;
};

export type ChampionshipSeasonUpdateOneRequiredWithoutChampionshipSeasonTeamPlayersNestedInput = {
  connect?: InputMaybe<ChampionshipSeasonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChampionshipSeasonCreateOrConnectWithoutChampionshipSeasonTeamPlayersInput>;
  create?: InputMaybe<ChampionshipSeasonCreateWithoutChampionshipSeasonTeamPlayersInput>;
  update?: InputMaybe<ChampionshipSeasonUpdateToOneWithWhereWithoutChampionshipSeasonTeamPlayersInput>;
  upsert?: InputMaybe<ChampionshipSeasonUpsertWithoutChampionshipSeasonTeamPlayersInput>;
};

export type ChampionshipSeasonUpdateOneRequiredWithoutRealGamesNestedInput = {
  connect?: InputMaybe<ChampionshipSeasonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChampionshipSeasonCreateOrConnectWithoutRealGamesInput>;
  create?: InputMaybe<ChampionshipSeasonCreateWithoutRealGamesInput>;
  update?: InputMaybe<ChampionshipSeasonUpdateToOneWithWhereWithoutRealGamesInput>;
  upsert?: InputMaybe<ChampionshipSeasonUpsertWithoutRealGamesInput>;
};

export type ChampionshipSeasonUpdateToOneWithWhereWithoutChampionshipSeasonTeamPlayersInput = {
  data: ChampionshipSeasonUpdateWithoutChampionshipSeasonTeamPlayersInput;
  where?: InputMaybe<ChampionshipSeasonWhereInput>;
};

export type ChampionshipSeasonUpdateToOneWithWhereWithoutRealGamesInput = {
  data: ChampionshipSeasonUpdateWithoutRealGamesInput;
  where?: InputMaybe<ChampionshipSeasonWhereInput>;
};

export type ChampionshipSeasonUpdateWithWhereUniqueWithoutChampionshipInput = {
  data: ChampionshipSeasonUpdateWithoutChampionshipInput;
  where: ChampionshipSeasonWhereUniqueInput;
};

export type ChampionshipSeasonUpdateWithWhereUniqueWithoutTeamsInput = {
  data: ChampionshipSeasonUpdateWithoutTeamsInput;
  where: ChampionshipSeasonWhereUniqueInput;
};

export type ChampionshipSeasonUpdateWithWhereUniqueWithoutTournamentsInput = {
  data: ChampionshipSeasonUpdateWithoutTournamentsInput;
  where: ChampionshipSeasonWhereUniqueInput;
};

export type ChampionshipSeasonUpdateWithoutChampionshipInput = {
  championshipOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerUpdateManyWithoutChampionshipSeasonNestedInput>;
  realGames?: InputMaybe<RealGameUpdateManyWithoutChampionshipSeasonNestedInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutChampionshipSeasonsNestedInput>;
  tournaments?: InputMaybe<TournamentUpdateManyWithoutChampionshipSeasonsNestedInput>;
};

export type ChampionshipSeasonUpdateWithoutChampionshipSeasonTeamPlayersInput = {
  championship?: InputMaybe<ChampionshipUpdateOneRequiredWithoutChampionshipSeasonsNestedInput>;
  championshipOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGames?: InputMaybe<RealGameUpdateManyWithoutChampionshipSeasonNestedInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutChampionshipSeasonsNestedInput>;
  tournaments?: InputMaybe<TournamentUpdateManyWithoutChampionshipSeasonsNestedInput>;
};

export type ChampionshipSeasonUpdateWithoutRealGamesInput = {
  championship?: InputMaybe<ChampionshipUpdateOneRequiredWithoutChampionshipSeasonsNestedInput>;
  championshipOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerUpdateManyWithoutChampionshipSeasonNestedInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutChampionshipSeasonsNestedInput>;
  tournaments?: InputMaybe<TournamentUpdateManyWithoutChampionshipSeasonsNestedInput>;
};

export type ChampionshipSeasonUpdateWithoutTeamsInput = {
  championship?: InputMaybe<ChampionshipUpdateOneRequiredWithoutChampionshipSeasonsNestedInput>;
  championshipOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerUpdateManyWithoutChampionshipSeasonNestedInput>;
  realGames?: InputMaybe<RealGameUpdateManyWithoutChampionshipSeasonNestedInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  tournaments?: InputMaybe<TournamentUpdateManyWithoutChampionshipSeasonsNestedInput>;
};

export type ChampionshipSeasonUpdateWithoutTournamentsInput = {
  championship?: InputMaybe<ChampionshipUpdateOneRequiredWithoutChampionshipSeasonsNestedInput>;
  championshipOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerUpdateManyWithoutChampionshipSeasonNestedInput>;
  realGames?: InputMaybe<RealGameUpdateManyWithoutChampionshipSeasonNestedInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutChampionshipSeasonsNestedInput>;
};

export type ChampionshipSeasonUpsertWithWhereUniqueWithoutChampionshipInput = {
  create: ChampionshipSeasonCreateWithoutChampionshipInput;
  update: ChampionshipSeasonUpdateWithoutChampionshipInput;
  where: ChampionshipSeasonWhereUniqueInput;
};

export type ChampionshipSeasonUpsertWithWhereUniqueWithoutTeamsInput = {
  create: ChampionshipSeasonCreateWithoutTeamsInput;
  update: ChampionshipSeasonUpdateWithoutTeamsInput;
  where: ChampionshipSeasonWhereUniqueInput;
};

export type ChampionshipSeasonUpsertWithWhereUniqueWithoutTournamentsInput = {
  create: ChampionshipSeasonCreateWithoutTournamentsInput;
  update: ChampionshipSeasonUpdateWithoutTournamentsInput;
  where: ChampionshipSeasonWhereUniqueInput;
};

export type ChampionshipSeasonUpsertWithoutChampionshipSeasonTeamPlayersInput = {
  create: ChampionshipSeasonCreateWithoutChampionshipSeasonTeamPlayersInput;
  update: ChampionshipSeasonUpdateWithoutChampionshipSeasonTeamPlayersInput;
  where?: InputMaybe<ChampionshipSeasonWhereInput>;
};

export type ChampionshipSeasonUpsertWithoutRealGamesInput = {
  create: ChampionshipSeasonCreateWithoutRealGamesInput;
  update: ChampionshipSeasonUpdateWithoutRealGamesInput;
  where?: InputMaybe<ChampionshipSeasonWhereInput>;
};

export type ChampionshipSeasonWhereInput = {
  AND?: InputMaybe<Array<ChampionshipSeasonWhereInput>>;
  NOT?: InputMaybe<Array<ChampionshipSeasonWhereInput>>;
  OR?: InputMaybe<Array<ChampionshipSeasonWhereInput>>;
  championship?: InputMaybe<ChampionshipRelationFilter>;
  championshipId?: InputMaybe<IntFilter>;
  championshipOptaId?: InputMaybe<StringFilter>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  realGames?: InputMaybe<RealGameListRelationFilter>;
  seasonOptaId?: InputMaybe<StringFilter>;
  teams?: InputMaybe<TeamListRelationFilter>;
  tournaments?: InputMaybe<TournamentListRelationFilter>;
};

export type ChampionshipSeasonWhereUniqueInput = {
  AND?: InputMaybe<Array<ChampionshipSeasonWhereInput>>;
  NOT?: InputMaybe<Array<ChampionshipSeasonWhereInput>>;
  OR?: InputMaybe<Array<ChampionshipSeasonWhereInput>>;
  championship?: InputMaybe<ChampionshipRelationFilter>;
  championshipId?: InputMaybe<IntFilter>;
  championshipOptaId?: InputMaybe<StringFilter>;
  championshipOptaId_seasonOptaId?: InputMaybe<ChampionshipSeasonChampionshipOptaIdSeasonOptaIdCompoundUniqueInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerListRelationFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  realGames?: InputMaybe<RealGameListRelationFilter>;
  seasonOptaId?: InputMaybe<StringFilter>;
  teams?: InputMaybe<TeamListRelationFilter>;
  tournaments?: InputMaybe<TournamentListRelationFilter>;
};

export type ChampionshipUpdateOneRequiredWithoutChampionshipSeasonsNestedInput = {
  connect?: InputMaybe<ChampionshipWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChampionshipCreateOrConnectWithoutChampionshipSeasonsInput>;
  create?: InputMaybe<ChampionshipCreateWithoutChampionshipSeasonsInput>;
  update?: InputMaybe<ChampionshipUpdateToOneWithWhereWithoutChampionshipSeasonsInput>;
  upsert?: InputMaybe<ChampionshipUpsertWithoutChampionshipSeasonsInput>;
};

export type ChampionshipUpdateOneRequiredWithoutTournamentChampionshipsNestedInput = {
  connect?: InputMaybe<ChampionshipWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChampionshipCreateOrConnectWithoutTournamentChampionshipsInput>;
  create?: InputMaybe<ChampionshipCreateWithoutTournamentChampionshipsInput>;
  update?: InputMaybe<ChampionshipUpdateToOneWithWhereWithoutTournamentChampionshipsInput>;
  upsert?: InputMaybe<ChampionshipUpsertWithoutTournamentChampionshipsInput>;
};

export type ChampionshipUpdateToOneWithWhereWithoutChampionshipSeasonsInput = {
  data: ChampionshipUpdateWithoutChampionshipSeasonsInput;
  where?: InputMaybe<ChampionshipWhereInput>;
};

export type ChampionshipUpdateToOneWithWhereWithoutTournamentChampionshipsInput = {
  data: ChampionshipUpdateWithoutTournamentChampionshipsInput;
  where?: InputMaybe<ChampionshipWhereInput>;
};

export type ChampionshipUpdateWithoutChampionshipSeasonsInput = {
  championshipOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  tournamentChampionships?: InputMaybe<TournamentChampionshipUpdateManyWithoutChampionshipNestedInput>;
};

export type ChampionshipUpdateWithoutTournamentChampionshipsInput = {
  championshipOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonUpdateManyWithoutChampionshipNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ChampionshipUpsertWithoutChampionshipSeasonsInput = {
  create: ChampionshipCreateWithoutChampionshipSeasonsInput;
  update: ChampionshipUpdateWithoutChampionshipSeasonsInput;
  where?: InputMaybe<ChampionshipWhereInput>;
};

export type ChampionshipUpsertWithoutTournamentChampionshipsInput = {
  create: ChampionshipCreateWithoutTournamentChampionshipsInput;
  update: ChampionshipUpdateWithoutTournamentChampionshipsInput;
  where?: InputMaybe<ChampionshipWhereInput>;
};

export type ChampionshipWhereInput = {
  AND?: InputMaybe<Array<ChampionshipWhereInput>>;
  NOT?: InputMaybe<Array<ChampionshipWhereInput>>;
  OR?: InputMaybe<Array<ChampionshipWhereInput>>;
  championshipOptaId?: InputMaybe<StringFilter>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  shortName?: InputMaybe<StringFilter>;
  tournamentChampionships?: InputMaybe<TournamentChampionshipListRelationFilter>;
};

export type ChampionshipWhereUniqueInput = {
  AND?: InputMaybe<Array<ChampionshipWhereInput>>;
  NOT?: InputMaybe<Array<ChampionshipWhereInput>>;
  OR?: InputMaybe<Array<ChampionshipWhereInput>>;
  championshipOptaId?: InputMaybe<Scalars["String"]["input"]>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonListRelationFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<StringFilter>;
  shortName?: InputMaybe<StringFilter>;
  tournamentChampionships?: InputMaybe<TournamentChampionshipListRelationFilter>;
};

export type CommonCard = {
  __typename?: "CommonCard";
  _count: CommonCardCount;
  age: Scalars["Int"]["output"];
  championshipOptaId: Scalars["String"]["output"];
  compositionCards?: Maybe<Array<CompositionCard>>;
  id: Scalars["ID"]["output"];
  image: Scalars["String"]["output"];
  player: Player;
  playerId: Scalars["Int"]["output"];
  playerOptaId: Scalars["String"]["output"];
  seasonOptaId: Scalars["String"]["output"];
  teamOptaId: Scalars["String"]["output"];
};

export type CommonCardCount = {
  __typename?: "CommonCardCount";
  compositionCards: Scalars["Int"]["output"];
};

export type CommonCardCreateManyPlayerInput = {
  age: Scalars["Int"]["input"];
  championshipOptaId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  playerOptaId: Scalars["String"]["input"];
  seasonOptaId: Scalars["String"]["input"];
  teamOptaId: Scalars["String"]["input"];
};

export type CommonCardCreateManyPlayerInputEnvelope = {
  data: Array<CommonCardCreateManyPlayerInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CommonCardCreateNestedManyWithoutPlayerInput = {
  connect?: InputMaybe<Array<CommonCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommonCardCreateOrConnectWithoutPlayerInput>>;
  create?: InputMaybe<Array<CommonCardCreateWithoutPlayerInput>>;
  createMany?: InputMaybe<CommonCardCreateManyPlayerInputEnvelope>;
};

export type CommonCardCreateNestedOneWithoutCompositionCardsInput = {
  connect?: InputMaybe<CommonCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommonCardCreateOrConnectWithoutCompositionCardsInput>;
  create?: InputMaybe<CommonCardCreateWithoutCompositionCardsInput>;
};

export type CommonCardCreateOrConnectWithoutCompositionCardsInput = {
  create: CommonCardCreateWithoutCompositionCardsInput;
  where: CommonCardWhereUniqueInput;
};

export type CommonCardCreateOrConnectWithoutPlayerInput = {
  create: CommonCardCreateWithoutPlayerInput;
  where: CommonCardWhereUniqueInput;
};

export type CommonCardCreateWithoutCompositionCardsInput = {
  age: Scalars["Int"]["input"];
  championshipOptaId: Scalars["String"]["input"];
  player: PlayerCreateNestedOneWithoutCommonCardsInput;
  playerOptaId: Scalars["String"]["input"];
  seasonOptaId: Scalars["String"]["input"];
  teamOptaId: Scalars["String"]["input"];
};

export type CommonCardCreateWithoutPlayerInput = {
  age: Scalars["Int"]["input"];
  championshipOptaId: Scalars["String"]["input"];
  compositionCards?: InputMaybe<CompositionCardCreateNestedManyWithoutCommonCardInput>;
  playerOptaId: Scalars["String"]["input"];
  seasonOptaId: Scalars["String"]["input"];
  teamOptaId: Scalars["String"]["input"];
};

export type CommonCardHasPlayed = {
  __typename?: "CommonCardHasPlayed";
  commonCard?: Maybe<CommonCard>;
  hasPlayed?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CommonCardListRelationFilter = {
  every?: InputMaybe<CommonCardWhereInput>;
  none?: InputMaybe<CommonCardWhereInput>;
  some?: InputMaybe<CommonCardWhereInput>;
};

export type CommonCardNullableRelationFilter = {
  is?: InputMaybe<CommonCardWhereInput>;
  isNot?: InputMaybe<CommonCardWhereInput>;
};

export type CommonCardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum CommonCardOrderByRelevanceFieldEnum {
  ChampionshipOptaId = "championshipOptaId",
  PlayerOptaId = "playerOptaId",
  SeasonOptaId = "seasonOptaId",
  TeamOptaId = "teamOptaId",
}

export type CommonCardOrderByRelevanceInput = {
  fields: Array<CommonCardOrderByRelevanceFieldEnum>;
  search: Scalars["String"]["input"];
  sort: SortOrder;
};

export type CommonCardOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<CommonCardOrderByRelevanceInput>;
  age?: InputMaybe<SortOrder>;
  championshipOptaId?: InputMaybe<SortOrder>;
  compositionCards?: InputMaybe<CompositionCardOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  player?: InputMaybe<PlayerOrderByWithRelationAndSearchRelevanceInput>;
  playerId?: InputMaybe<SortOrder>;
  playerOptaId?: InputMaybe<SortOrder>;
  seasonOptaId?: InputMaybe<SortOrder>;
  teamOptaId?: InputMaybe<SortOrder>;
};

export type CommonCardPlayerOptaIdSeasonOptaIdTeamOptaIdChampionshipOptaIdCompoundUniqueInput = {
  championshipOptaId: Scalars["String"]["input"];
  playerOptaId: Scalars["String"]["input"];
  seasonOptaId: Scalars["String"]["input"];
  teamOptaId: Scalars["String"]["input"];
};

export enum CommonCardScalarFieldEnum {
  Age = "age",
  ChampionshipOptaId = "championshipOptaId",
  Id = "id",
  PlayerId = "playerId",
  PlayerOptaId = "playerOptaId",
  SeasonOptaId = "seasonOptaId",
  TeamOptaId = "teamOptaId",
}

export type CommonCardScalarWhereInput = {
  AND?: InputMaybe<Array<CommonCardScalarWhereInput>>;
  NOT?: InputMaybe<Array<CommonCardScalarWhereInput>>;
  OR?: InputMaybe<Array<CommonCardScalarWhereInput>>;
  age?: InputMaybe<IntFilter>;
  championshipOptaId?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  playerId?: InputMaybe<IntFilter>;
  playerOptaId?: InputMaybe<StringFilter>;
  seasonOptaId?: InputMaybe<StringFilter>;
  teamOptaId?: InputMaybe<StringFilter>;
};

export type CommonCardUpdateManyMutationInput = {
  age?: InputMaybe<IntFieldUpdateOperationsInput>;
  championshipOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CommonCardUpdateManyWithWhereWithoutPlayerInput = {
  data: CommonCardUpdateManyMutationInput;
  where: CommonCardScalarWhereInput;
};

export type CommonCardUpdateManyWithoutPlayerNestedInput = {
  connect?: InputMaybe<Array<CommonCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommonCardCreateOrConnectWithoutPlayerInput>>;
  create?: InputMaybe<Array<CommonCardCreateWithoutPlayerInput>>;
  createMany?: InputMaybe<CommonCardCreateManyPlayerInputEnvelope>;
  delete?: InputMaybe<Array<CommonCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommonCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommonCardWhereUniqueInput>>;
  set?: InputMaybe<Array<CommonCardWhereUniqueInput>>;
  update?: InputMaybe<Array<CommonCardUpdateWithWhereUniqueWithoutPlayerInput>>;
  updateMany?: InputMaybe<Array<CommonCardUpdateManyWithWhereWithoutPlayerInput>>;
  upsert?: InputMaybe<Array<CommonCardUpsertWithWhereUniqueWithoutPlayerInput>>;
};

export type CommonCardUpdateOneWithoutCompositionCardsNestedInput = {
  connect?: InputMaybe<CommonCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommonCardCreateOrConnectWithoutCompositionCardsInput>;
  create?: InputMaybe<CommonCardCreateWithoutCompositionCardsInput>;
  delete?: InputMaybe<CommonCardWhereInput>;
  disconnect?: InputMaybe<CommonCardWhereInput>;
  update?: InputMaybe<CommonCardUpdateToOneWithWhereWithoutCompositionCardsInput>;
  upsert?: InputMaybe<CommonCardUpsertWithoutCompositionCardsInput>;
};

export type CommonCardUpdateToOneWithWhereWithoutCompositionCardsInput = {
  data: CommonCardUpdateWithoutCompositionCardsInput;
  where?: InputMaybe<CommonCardWhereInput>;
};

export type CommonCardUpdateWithWhereUniqueWithoutPlayerInput = {
  data: CommonCardUpdateWithoutPlayerInput;
  where: CommonCardWhereUniqueInput;
};

export type CommonCardUpdateWithoutCompositionCardsInput = {
  age?: InputMaybe<IntFieldUpdateOperationsInput>;
  championshipOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  player?: InputMaybe<PlayerUpdateOneRequiredWithoutCommonCardsNestedInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CommonCardUpdateWithoutPlayerInput = {
  age?: InputMaybe<IntFieldUpdateOperationsInput>;
  championshipOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  compositionCards?: InputMaybe<CompositionCardUpdateManyWithoutCommonCardNestedInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CommonCardUpsertWithWhereUniqueWithoutPlayerInput = {
  create: CommonCardCreateWithoutPlayerInput;
  update: CommonCardUpdateWithoutPlayerInput;
  where: CommonCardWhereUniqueInput;
};

export type CommonCardUpsertWithoutCompositionCardsInput = {
  create: CommonCardCreateWithoutCompositionCardsInput;
  update: CommonCardUpdateWithoutCompositionCardsInput;
  where?: InputMaybe<CommonCardWhereInput>;
};

export type CommonCardWhereInput = {
  AND?: InputMaybe<Array<CommonCardWhereInput>>;
  NOT?: InputMaybe<Array<CommonCardWhereInput>>;
  OR?: InputMaybe<Array<CommonCardWhereInput>>;
  age?: InputMaybe<IntFilter>;
  championshipOptaId?: InputMaybe<StringFilter>;
  compositionCards?: InputMaybe<CompositionCardListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  player?: InputMaybe<PlayerRelationFilter>;
  playerId?: InputMaybe<IntFilter>;
  playerOptaId?: InputMaybe<StringFilter>;
  seasonOptaId?: InputMaybe<StringFilter>;
  teamOptaId?: InputMaybe<StringFilter>;
};

export type CommonCardWhereUniqueInput = {
  AND?: InputMaybe<Array<CommonCardWhereInput>>;
  NOT?: InputMaybe<Array<CommonCardWhereInput>>;
  OR?: InputMaybe<Array<CommonCardWhereInput>>;
  age?: InputMaybe<IntFilter>;
  championshipOptaId?: InputMaybe<StringFilter>;
  compositionCards?: InputMaybe<CompositionCardListRelationFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  player?: InputMaybe<PlayerRelationFilter>;
  playerId?: InputMaybe<IntFilter>;
  playerOptaId?: InputMaybe<StringFilter>;
  playerOptaId_seasonOptaId_teamOptaId_championshipOptaId?: InputMaybe<CommonCardPlayerOptaIdSeasonOptaIdTeamOptaIdChampionshipOptaIdCompoundUniqueInput>;
  seasonOptaId?: InputMaybe<StringFilter>;
  teamOptaId?: InputMaybe<StringFilter>;
};

export type Composition = {
  __typename?: "Composition";
  _count: CompositionCount;
  backCommonCardId?: Maybe<Scalars["Int"]["output"]>;
  backMarketPlaceCardTokenId?: Maybe<Scalars["String"]["output"]>;
  backPoints: Scalars["Float"]["output"];
  backRealGamePlayer?: Maybe<RealGamePlayer>;
  canBackBeChanged: Scalars["Boolean"]["output"];
  canExtraBeChanged: Scalars["Boolean"]["output"];
  canFirstRowBeChanged: Scalars["Boolean"]["output"];
  canHalfBeChanged: Scalars["Boolean"]["output"];
  canSecondRowBeChanged: Scalars["Boolean"]["output"];
  captainCommonCardId?: Maybe<Scalars["Int"]["output"]>;
  captainMarketPlaceCardTokenId?: Maybe<Scalars["String"]["output"]>;
  cheater: Scalars["Boolean"]["output"];
  compositionCards?: Maybe<Array<CompositionCard>>;
  extraCommonCardId?: Maybe<Scalars["Int"]["output"]>;
  extraMarketPlaceCardTokenId?: Maybe<Scalars["String"]["output"]>;
  extraPoints: Scalars["Float"]["output"];
  extraRealGamePlayer?: Maybe<RealGamePlayer>;
  firstRowCommonCardId?: Maybe<Scalars["Int"]["output"]>;
  firstRowMarketPlaceCardTokenId?: Maybe<Scalars["String"]["output"]>;
  firstRowPoints: Scalars["Float"]["output"];
  firstRowRealGamePlayer?: Maybe<RealGamePlayer>;
  gameWeek: TournamentGameWeek;
  gameWeekId?: Maybe<Scalars["Int"]["output"]>;
  halfCommonCardId?: Maybe<Scalars["Int"]["output"]>;
  halfMarketPlaceCardTokenId?: Maybe<Scalars["String"]["output"]>;
  halfPoints: Scalars["Float"]["output"];
  halfRealGamePlayer?: Maybe<RealGamePlayer>;
  id: Scalars["ID"]["output"];
  marketPlaceUserId: Scalars["String"]["output"];
  ranking?: Maybe<Scalars["Int"]["output"]>;
  rewardClaims?: Maybe<Array<RewardClaim>>;
  score?: Maybe<Scalars["Float"]["output"]>;
  secondRowCommonCardId?: Maybe<Scalars["Int"]["output"]>;
  secondRowMarketPlaceCardTokenId?: Maybe<Scalars["String"]["output"]>;
  secondRowPoints: Scalars["Float"]["output"];
  secondRowRealGamePlayer?: Maybe<RealGamePlayer>;
  totalPoints: Scalars["Float"]["output"];
  username?: Maybe<Scalars["String"]["output"]>;
};

export type CompositionCard = {
  __typename?: "CompositionCard";
  captainBonus?: Maybe<Scalars["Float"]["output"]>;
  commonCard?: Maybe<CommonCard>;
  commonCardId?: Maybe<Scalars["Int"]["output"]>;
  composition: Composition;
  compositionId: Scalars["Int"]["output"];
  expBonus?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  index: Scalars["Int"]["output"];
  marketPlaceCardPlayerCache?: Maybe<MarketPlaceCardPlayerCache>;
  marketPlaceCardTokenId?: Maybe<Scalars["String"]["output"]>;
  ownedByUser?: Maybe<Scalars["Boolean"]["output"]>;
  ownedByUserUpdatedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  playerOptaId?: Maybe<Scalars["String"]["output"]>;
  rarityBonus?: Maybe<Scalars["Float"]["output"]>;
  realGameOptaId?: Maybe<Scalars["String"]["output"]>;
  realGamePlayer?: Maybe<RealGamePlayer>;
  resultExpGain?: Maybe<Scalars["Float"]["output"]>;
  resultPoints?: Maybe<Scalars["Float"]["output"]>;
  resultUpdatedAt?: Maybe<Scalars["Timestamp"]["output"]>;
};

export type CompositionCardCompositionIdCommonCardIdMarketPlaceCardTokenIdCompoundUniqueInput = {
  commonCardId: Scalars["Int"]["input"];
  compositionId: Scalars["Int"]["input"];
  marketPlaceCardTokenId: Scalars["String"]["input"];
};

export type CompositionCardCreateManyCommonCardInput = {
  captainBonus?: InputMaybe<Scalars["Float"]["input"]>;
  compositionId: Scalars["Int"]["input"];
  expBonus?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  index: Scalars["Int"]["input"];
  marketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  ownedByUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  ownedByUserUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  playerOptaId?: InputMaybe<Scalars["String"]["input"]>;
  rarityBonus?: InputMaybe<Scalars["Float"]["input"]>;
  realGameOptaId?: InputMaybe<Scalars["String"]["input"]>;
  resultExpGain?: InputMaybe<Scalars["Float"]["input"]>;
  resultPoints?: InputMaybe<Scalars["Float"]["input"]>;
  resultUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type CompositionCardCreateManyCommonCardInputEnvelope = {
  data: Array<CompositionCardCreateManyCommonCardInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompositionCardCreateManyCompositionInput = {
  captainBonus?: InputMaybe<Scalars["Float"]["input"]>;
  commonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  expBonus?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  index: Scalars["Int"]["input"];
  marketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  ownedByUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  ownedByUserUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  playerOptaId?: InputMaybe<Scalars["String"]["input"]>;
  rarityBonus?: InputMaybe<Scalars["Float"]["input"]>;
  realGameOptaId?: InputMaybe<Scalars["String"]["input"]>;
  resultExpGain?: InputMaybe<Scalars["Float"]["input"]>;
  resultPoints?: InputMaybe<Scalars["Float"]["input"]>;
  resultUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type CompositionCardCreateManyCompositionInputEnvelope = {
  data: Array<CompositionCardCreateManyCompositionInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompositionCardCreateManyRealGamePlayerInput = {
  captainBonus?: InputMaybe<Scalars["Float"]["input"]>;
  commonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  compositionId: Scalars["Int"]["input"];
  expBonus?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  index: Scalars["Int"]["input"];
  marketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  ownedByUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  ownedByUserUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  rarityBonus?: InputMaybe<Scalars["Float"]["input"]>;
  resultExpGain?: InputMaybe<Scalars["Float"]["input"]>;
  resultPoints?: InputMaybe<Scalars["Float"]["input"]>;
  resultUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type CompositionCardCreateManyRealGamePlayerInputEnvelope = {
  data: Array<CompositionCardCreateManyRealGamePlayerInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompositionCardCreateNestedManyWithoutCommonCardInput = {
  connect?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompositionCardCreateOrConnectWithoutCommonCardInput>>;
  create?: InputMaybe<Array<CompositionCardCreateWithoutCommonCardInput>>;
  createMany?: InputMaybe<CompositionCardCreateManyCommonCardInputEnvelope>;
};

export type CompositionCardCreateNestedManyWithoutCompositionInput = {
  connect?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompositionCardCreateOrConnectWithoutCompositionInput>>;
  create?: InputMaybe<Array<CompositionCardCreateWithoutCompositionInput>>;
  createMany?: InputMaybe<CompositionCardCreateManyCompositionInputEnvelope>;
};

export type CompositionCardCreateNestedManyWithoutRealGamePlayerInput = {
  connect?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompositionCardCreateOrConnectWithoutRealGamePlayerInput>>;
  create?: InputMaybe<Array<CompositionCardCreateWithoutRealGamePlayerInput>>;
  createMany?: InputMaybe<CompositionCardCreateManyRealGamePlayerInputEnvelope>;
};

export type CompositionCardCreateOrConnectWithoutCommonCardInput = {
  create: CompositionCardCreateWithoutCommonCardInput;
  where: CompositionCardWhereUniqueInput;
};

export type CompositionCardCreateOrConnectWithoutCompositionInput = {
  create: CompositionCardCreateWithoutCompositionInput;
  where: CompositionCardWhereUniqueInput;
};

export type CompositionCardCreateOrConnectWithoutRealGamePlayerInput = {
  create: CompositionCardCreateWithoutRealGamePlayerInput;
  where: CompositionCardWhereUniqueInput;
};

export type CompositionCardCreateWithoutCommonCardInput = {
  captainBonus?: InputMaybe<Scalars["Float"]["input"]>;
  composition: CompositionCreateNestedOneWithoutCompositionCardsInput;
  expBonus?: InputMaybe<Scalars["Float"]["input"]>;
  index: Scalars["Int"]["input"];
  marketPlaceCardPlayerCache?: InputMaybe<MarketPlaceCardPlayerCacheCreateNestedOneWithoutCompositionCardInput>;
  ownedByUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  ownedByUserUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  rarityBonus?: InputMaybe<Scalars["Float"]["input"]>;
  realGamePlayer?: InputMaybe<RealGamePlayerCreateNestedOneWithoutCompositionCardsInput>;
  resultExpGain?: InputMaybe<Scalars["Float"]["input"]>;
  resultPoints?: InputMaybe<Scalars["Float"]["input"]>;
  resultUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type CompositionCardCreateWithoutCompositionInput = {
  captainBonus?: InputMaybe<Scalars["Float"]["input"]>;
  commonCard?: InputMaybe<CommonCardCreateNestedOneWithoutCompositionCardsInput>;
  expBonus?: InputMaybe<Scalars["Float"]["input"]>;
  index: Scalars["Int"]["input"];
  marketPlaceCardPlayerCache?: InputMaybe<MarketPlaceCardPlayerCacheCreateNestedOneWithoutCompositionCardInput>;
  ownedByUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  ownedByUserUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  rarityBonus?: InputMaybe<Scalars["Float"]["input"]>;
  realGamePlayer?: InputMaybe<RealGamePlayerCreateNestedOneWithoutCompositionCardsInput>;
  resultExpGain?: InputMaybe<Scalars["Float"]["input"]>;
  resultPoints?: InputMaybe<Scalars["Float"]["input"]>;
  resultUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type CompositionCardCreateWithoutRealGamePlayerInput = {
  captainBonus?: InputMaybe<Scalars["Float"]["input"]>;
  commonCard?: InputMaybe<CommonCardCreateNestedOneWithoutCompositionCardsInput>;
  composition: CompositionCreateNestedOneWithoutCompositionCardsInput;
  expBonus?: InputMaybe<Scalars["Float"]["input"]>;
  index: Scalars["Int"]["input"];
  marketPlaceCardPlayerCache?: InputMaybe<MarketPlaceCardPlayerCacheCreateNestedOneWithoutCompositionCardInput>;
  ownedByUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  ownedByUserUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  rarityBonus?: InputMaybe<Scalars["Float"]["input"]>;
  resultExpGain?: InputMaybe<Scalars["Float"]["input"]>;
  resultPoints?: InputMaybe<Scalars["Float"]["input"]>;
  resultUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type CompositionCardListRelationFilter = {
  every?: InputMaybe<CompositionCardWhereInput>;
  none?: InputMaybe<CompositionCardWhereInput>;
  some?: InputMaybe<CompositionCardWhereInput>;
};

export type CompositionCardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CompositionCardScalarWhereInput = {
  AND?: InputMaybe<Array<CompositionCardScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompositionCardScalarWhereInput>>;
  OR?: InputMaybe<Array<CompositionCardScalarWhereInput>>;
  captainBonus?: InputMaybe<FloatNullableFilter>;
  commonCardId?: InputMaybe<IntNullableFilter>;
  compositionId?: InputMaybe<IntFilter>;
  expBonus?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<IntFilter>;
  index?: InputMaybe<IntFilter>;
  marketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  ownedByUser?: InputMaybe<BoolNullableFilter>;
  ownedByUserUpdatedAt?: InputMaybe<DateTimeNullableFilter>;
  playerOptaId?: InputMaybe<StringNullableFilter>;
  rarityBonus?: InputMaybe<FloatNullableFilter>;
  realGameOptaId?: InputMaybe<StringNullableFilter>;
  resultExpGain?: InputMaybe<FloatNullableFilter>;
  resultPoints?: InputMaybe<FloatNullableFilter>;
  resultUpdatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type CompositionCardUpdateManyMutationInput = {
  captainBonus?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  expBonus?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  index?: InputMaybe<IntFieldUpdateOperationsInput>;
  ownedByUser?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ownedByUserUpdatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rarityBonus?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resultExpGain?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resultPoints?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resultUpdatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CompositionCardUpdateManyWithWhereWithoutCommonCardInput = {
  data: CompositionCardUpdateManyMutationInput;
  where: CompositionCardScalarWhereInput;
};

export type CompositionCardUpdateManyWithWhereWithoutCompositionInput = {
  data: CompositionCardUpdateManyMutationInput;
  where: CompositionCardScalarWhereInput;
};

export type CompositionCardUpdateManyWithWhereWithoutRealGamePlayerInput = {
  data: CompositionCardUpdateManyMutationInput;
  where: CompositionCardScalarWhereInput;
};

export type CompositionCardUpdateManyWithoutCommonCardNestedInput = {
  connect?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompositionCardCreateOrConnectWithoutCommonCardInput>>;
  create?: InputMaybe<Array<CompositionCardCreateWithoutCommonCardInput>>;
  createMany?: InputMaybe<CompositionCardCreateManyCommonCardInputEnvelope>;
  delete?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompositionCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  set?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  update?: InputMaybe<Array<CompositionCardUpdateWithWhereUniqueWithoutCommonCardInput>>;
  updateMany?: InputMaybe<Array<CompositionCardUpdateManyWithWhereWithoutCommonCardInput>>;
  upsert?: InputMaybe<Array<CompositionCardUpsertWithWhereUniqueWithoutCommonCardInput>>;
};

export type CompositionCardUpdateManyWithoutCompositionNestedInput = {
  connect?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompositionCardCreateOrConnectWithoutCompositionInput>>;
  create?: InputMaybe<Array<CompositionCardCreateWithoutCompositionInput>>;
  createMany?: InputMaybe<CompositionCardCreateManyCompositionInputEnvelope>;
  delete?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompositionCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  set?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  update?: InputMaybe<Array<CompositionCardUpdateWithWhereUniqueWithoutCompositionInput>>;
  updateMany?: InputMaybe<Array<CompositionCardUpdateManyWithWhereWithoutCompositionInput>>;
  upsert?: InputMaybe<Array<CompositionCardUpsertWithWhereUniqueWithoutCompositionInput>>;
};

export type CompositionCardUpdateManyWithoutRealGamePlayerNestedInput = {
  connect?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompositionCardCreateOrConnectWithoutRealGamePlayerInput>>;
  create?: InputMaybe<Array<CompositionCardCreateWithoutRealGamePlayerInput>>;
  createMany?: InputMaybe<CompositionCardCreateManyRealGamePlayerInputEnvelope>;
  delete?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompositionCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  set?: InputMaybe<Array<CompositionCardWhereUniqueInput>>;
  update?: InputMaybe<Array<CompositionCardUpdateWithWhereUniqueWithoutRealGamePlayerInput>>;
  updateMany?: InputMaybe<Array<CompositionCardUpdateManyWithWhereWithoutRealGamePlayerInput>>;
  upsert?: InputMaybe<Array<CompositionCardUpsertWithWhereUniqueWithoutRealGamePlayerInput>>;
};

export type CompositionCardUpdateWithWhereUniqueWithoutCommonCardInput = {
  data: CompositionCardUpdateWithoutCommonCardInput;
  where: CompositionCardWhereUniqueInput;
};

export type CompositionCardUpdateWithWhereUniqueWithoutCompositionInput = {
  data: CompositionCardUpdateWithoutCompositionInput;
  where: CompositionCardWhereUniqueInput;
};

export type CompositionCardUpdateWithWhereUniqueWithoutRealGamePlayerInput = {
  data: CompositionCardUpdateWithoutRealGamePlayerInput;
  where: CompositionCardWhereUniqueInput;
};

export type CompositionCardUpdateWithoutCommonCardInput = {
  captainBonus?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  composition?: InputMaybe<CompositionUpdateOneRequiredWithoutCompositionCardsNestedInput>;
  expBonus?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  index?: InputMaybe<IntFieldUpdateOperationsInput>;
  marketPlaceCardPlayerCache?: InputMaybe<MarketPlaceCardPlayerCacheUpdateOneWithoutCompositionCardNestedInput>;
  ownedByUser?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ownedByUserUpdatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rarityBonus?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  realGamePlayer?: InputMaybe<RealGamePlayerUpdateOneWithoutCompositionCardsNestedInput>;
  resultExpGain?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resultPoints?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resultUpdatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CompositionCardUpdateWithoutCompositionInput = {
  captainBonus?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  commonCard?: InputMaybe<CommonCardUpdateOneWithoutCompositionCardsNestedInput>;
  expBonus?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  index?: InputMaybe<IntFieldUpdateOperationsInput>;
  marketPlaceCardPlayerCache?: InputMaybe<MarketPlaceCardPlayerCacheUpdateOneWithoutCompositionCardNestedInput>;
  ownedByUser?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ownedByUserUpdatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rarityBonus?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  realGamePlayer?: InputMaybe<RealGamePlayerUpdateOneWithoutCompositionCardsNestedInput>;
  resultExpGain?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resultPoints?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resultUpdatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CompositionCardUpdateWithoutRealGamePlayerInput = {
  captainBonus?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  commonCard?: InputMaybe<CommonCardUpdateOneWithoutCompositionCardsNestedInput>;
  composition?: InputMaybe<CompositionUpdateOneRequiredWithoutCompositionCardsNestedInput>;
  expBonus?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  index?: InputMaybe<IntFieldUpdateOperationsInput>;
  marketPlaceCardPlayerCache?: InputMaybe<MarketPlaceCardPlayerCacheUpdateOneWithoutCompositionCardNestedInput>;
  ownedByUser?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ownedByUserUpdatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rarityBonus?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resultExpGain?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resultPoints?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resultUpdatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CompositionCardUpsertWithWhereUniqueWithoutCommonCardInput = {
  create: CompositionCardCreateWithoutCommonCardInput;
  update: CompositionCardUpdateWithoutCommonCardInput;
  where: CompositionCardWhereUniqueInput;
};

export type CompositionCardUpsertWithWhereUniqueWithoutCompositionInput = {
  create: CompositionCardCreateWithoutCompositionInput;
  update: CompositionCardUpdateWithoutCompositionInput;
  where: CompositionCardWhereUniqueInput;
};

export type CompositionCardUpsertWithWhereUniqueWithoutRealGamePlayerInput = {
  create: CompositionCardCreateWithoutRealGamePlayerInput;
  update: CompositionCardUpdateWithoutRealGamePlayerInput;
  where: CompositionCardWhereUniqueInput;
};

export type CompositionCardWhereInput = {
  AND?: InputMaybe<Array<CompositionCardWhereInput>>;
  NOT?: InputMaybe<Array<CompositionCardWhereInput>>;
  OR?: InputMaybe<Array<CompositionCardWhereInput>>;
  captainBonus?: InputMaybe<FloatNullableFilter>;
  commonCard?: InputMaybe<CommonCardNullableRelationFilter>;
  commonCardId?: InputMaybe<IntNullableFilter>;
  composition?: InputMaybe<CompositionRelationFilter>;
  compositionId?: InputMaybe<IntFilter>;
  expBonus?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<IntFilter>;
  index?: InputMaybe<IntFilter>;
  marketPlaceCardPlayerCache?: InputMaybe<MarketPlaceCardPlayerCacheNullableRelationFilter>;
  marketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  ownedByUser?: InputMaybe<BoolNullableFilter>;
  ownedByUserUpdatedAt?: InputMaybe<DateTimeNullableFilter>;
  playerOptaId?: InputMaybe<StringNullableFilter>;
  rarityBonus?: InputMaybe<FloatNullableFilter>;
  realGameOptaId?: InputMaybe<StringNullableFilter>;
  realGamePlayer?: InputMaybe<RealGamePlayerNullableRelationFilter>;
  resultExpGain?: InputMaybe<FloatNullableFilter>;
  resultPoints?: InputMaybe<FloatNullableFilter>;
  resultUpdatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type CompositionCardWhereUniqueInput = {
  AND?: InputMaybe<Array<CompositionCardWhereInput>>;
  NOT?: InputMaybe<Array<CompositionCardWhereInput>>;
  OR?: InputMaybe<Array<CompositionCardWhereInput>>;
  captainBonus?: InputMaybe<FloatNullableFilter>;
  commonCard?: InputMaybe<CommonCardNullableRelationFilter>;
  commonCardId?: InputMaybe<IntNullableFilter>;
  composition?: InputMaybe<CompositionRelationFilter>;
  compositionId?: InputMaybe<IntFilter>;
  compositionId_commonCardId_marketPlaceCardTokenId?: InputMaybe<CompositionCardCompositionIdCommonCardIdMarketPlaceCardTokenIdCompoundUniqueInput>;
  expBonus?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  index?: InputMaybe<IntFilter>;
  marketPlaceCardPlayerCache?: InputMaybe<MarketPlaceCardPlayerCacheNullableRelationFilter>;
  marketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  ownedByUser?: InputMaybe<BoolNullableFilter>;
  ownedByUserUpdatedAt?: InputMaybe<DateTimeNullableFilter>;
  playerOptaId?: InputMaybe<StringNullableFilter>;
  rarityBonus?: InputMaybe<FloatNullableFilter>;
  realGameOptaId?: InputMaybe<StringNullableFilter>;
  realGamePlayer?: InputMaybe<RealGamePlayerNullableRelationFilter>;
  resultExpGain?: InputMaybe<FloatNullableFilter>;
  resultPoints?: InputMaybe<FloatNullableFilter>;
  resultUpdatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type CompositionCount = {
  __typename?: "CompositionCount";
  compositionCards: Scalars["Int"]["output"];
  rewardClaims: Scalars["Int"]["output"];
};

export type CompositionCreateInput = {
  backCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  backMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  captainCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  captainMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  extraCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  extraMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  firstRowCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  gameWeekId?: InputMaybe<Scalars["Int"]["input"]>;
  halfCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  halfMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  marketPlaceUserId: Scalars["String"]["input"];
  ranking?: InputMaybe<Scalars["Int"]["input"]>;
  score?: InputMaybe<Scalars["Float"]["input"]>;
  secondRowCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompositionCreateManyGameWeekInput = {
  backCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  backMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  captainCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  captainMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  cheater?: InputMaybe<Scalars["Boolean"]["input"]>;
  extraCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  extraMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  firstRowCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  halfCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  halfMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  marketPlaceUserId: Scalars["String"]["input"];
  ranking?: InputMaybe<Scalars["Int"]["input"]>;
  score?: InputMaybe<Scalars["Float"]["input"]>;
  secondRowCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompositionCreateManyGameWeekInputEnvelope = {
  data: Array<CompositionCreateManyGameWeekInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompositionCreateNestedManyWithoutGameWeekInput = {
  connect?: InputMaybe<Array<CompositionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompositionCreateOrConnectWithoutGameWeekInput>>;
  create?: InputMaybe<Array<CompositionCreateWithoutGameWeekInput>>;
  createMany?: InputMaybe<CompositionCreateManyGameWeekInputEnvelope>;
};

export type CompositionCreateNestedOneWithoutCompositionCardsInput = {
  connect?: InputMaybe<CompositionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompositionCreateOrConnectWithoutCompositionCardsInput>;
  create?: InputMaybe<CompositionCreateWithoutCompositionCardsInput>;
};

export type CompositionCreateNestedOneWithoutRewardClaimsInput = {
  connect?: InputMaybe<CompositionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompositionCreateOrConnectWithoutRewardClaimsInput>;
  create?: InputMaybe<CompositionCreateWithoutRewardClaimsInput>;
};

export type CompositionCreateOrConnectWithoutCompositionCardsInput = {
  create: CompositionCreateWithoutCompositionCardsInput;
  where: CompositionWhereUniqueInput;
};

export type CompositionCreateOrConnectWithoutGameWeekInput = {
  create: CompositionCreateWithoutGameWeekInput;
  where: CompositionWhereUniqueInput;
};

export type CompositionCreateOrConnectWithoutRewardClaimsInput = {
  create: CompositionCreateWithoutRewardClaimsInput;
  where: CompositionWhereUniqueInput;
};

export type CompositionCreateWithoutCompositionCardsInput = {
  backCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  backMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  captainCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  captainMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  cheater?: InputMaybe<Scalars["Boolean"]["input"]>;
  extraCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  extraMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  firstRowCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  gameWeek?: InputMaybe<TournamentGameWeekCreateNestedOneWithoutCompositionsInput>;
  halfCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  halfMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  marketPlaceUserId: Scalars["String"]["input"];
  ranking?: InputMaybe<Scalars["Int"]["input"]>;
  rewardClaims?: InputMaybe<RewardClaimCreateNestedManyWithoutCompositionInput>;
  score?: InputMaybe<Scalars["Float"]["input"]>;
  secondRowCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompositionCreateWithoutGameWeekInput = {
  backCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  backMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  captainCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  captainMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  cheater?: InputMaybe<Scalars["Boolean"]["input"]>;
  compositionCards?: InputMaybe<CompositionCardCreateNestedManyWithoutCompositionInput>;
  extraCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  extraMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  firstRowCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  halfCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  halfMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  marketPlaceUserId: Scalars["String"]["input"];
  ranking?: InputMaybe<Scalars["Int"]["input"]>;
  rewardClaims?: InputMaybe<RewardClaimCreateNestedManyWithoutCompositionInput>;
  score?: InputMaybe<Scalars["Float"]["input"]>;
  secondRowCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompositionCreateWithoutRewardClaimsInput = {
  backCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  backMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  captainCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  captainMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  cheater?: InputMaybe<Scalars["Boolean"]["input"]>;
  compositionCards?: InputMaybe<CompositionCardCreateNestedManyWithoutCompositionInput>;
  extraCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  extraMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  firstRowCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  gameWeek?: InputMaybe<TournamentGameWeekCreateNestedOneWithoutCompositionsInput>;
  halfCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  halfMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  marketPlaceUserId: Scalars["String"]["input"];
  ranking?: InputMaybe<Scalars["Int"]["input"]>;
  score?: InputMaybe<Scalars["Float"]["input"]>;
  secondRowCommonCardId?: InputMaybe<Scalars["Int"]["input"]>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompositionListRelationFilter = {
  every?: InputMaybe<CompositionWhereInput>;
  none?: InputMaybe<CompositionWhereInput>;
  some?: InputMaybe<CompositionWhereInput>;
};

export type CompositionMarketPlaceUserIdGameWeekIdCompoundUniqueInput = {
  gameWeekId: Scalars["Int"]["input"];
  marketPlaceUserId: Scalars["String"]["input"];
};

export type CompositionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum CompositionOrderByRelevanceFieldEnum {
  BackMarketPlaceCardTokenId = "backMarketPlaceCardTokenId",
  CaptainMarketPlaceCardTokenId = "captainMarketPlaceCardTokenId",
  ExtraMarketPlaceCardTokenId = "extraMarketPlaceCardTokenId",
  FirstRowMarketPlaceCardTokenId = "firstRowMarketPlaceCardTokenId",
  HalfMarketPlaceCardTokenId = "halfMarketPlaceCardTokenId",
  MarketPlaceUserId = "marketPlaceUserId",
  SecondRowMarketPlaceCardTokenId = "secondRowMarketPlaceCardTokenId",
  Username = "username",
}

export type CompositionOrderByRelevanceInput = {
  fields: Array<CompositionOrderByRelevanceFieldEnum>;
  search: Scalars["String"]["input"];
  sort: SortOrder;
};

export type CompositionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<CompositionOrderByRelevanceInput>;
  backCommonCardId?: InputMaybe<SortOrderInput>;
  backMarketPlaceCardTokenId?: InputMaybe<SortOrderInput>;
  captainCommonCardId?: InputMaybe<SortOrderInput>;
  captainMarketPlaceCardTokenId?: InputMaybe<SortOrderInput>;
  cheater?: InputMaybe<SortOrder>;
  compositionCards?: InputMaybe<CompositionCardOrderByRelationAggregateInput>;
  extraCommonCardId?: InputMaybe<SortOrderInput>;
  extraMarketPlaceCardTokenId?: InputMaybe<SortOrderInput>;
  firstRowCommonCardId?: InputMaybe<SortOrderInput>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<SortOrderInput>;
  gameWeek?: InputMaybe<TournamentGameWeekOrderByWithRelationAndSearchRelevanceInput>;
  gameWeekId?: InputMaybe<SortOrderInput>;
  halfCommonCardId?: InputMaybe<SortOrderInput>;
  halfMarketPlaceCardTokenId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  marketPlaceUserId?: InputMaybe<SortOrder>;
  ranking?: InputMaybe<SortOrderInput>;
  rewardClaims?: InputMaybe<RewardClaimOrderByRelationAggregateInput>;
  score?: InputMaybe<SortOrderInput>;
  secondRowCommonCardId?: InputMaybe<SortOrderInput>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<SortOrderInput>;
  username?: InputMaybe<SortOrderInput>;
};

export type CompositionRelationFilter = {
  is?: InputMaybe<CompositionWhereInput>;
  isNot?: InputMaybe<CompositionWhereInput>;
};

export enum CompositionScalarFieldEnum {
  BackCommonCardId = "backCommonCardId",
  BackMarketPlaceCardTokenId = "backMarketPlaceCardTokenId",
  CaptainCommonCardId = "captainCommonCardId",
  CaptainMarketPlaceCardTokenId = "captainMarketPlaceCardTokenId",
  Cheater = "cheater",
  ExtraCommonCardId = "extraCommonCardId",
  ExtraMarketPlaceCardTokenId = "extraMarketPlaceCardTokenId",
  FirstRowCommonCardId = "firstRowCommonCardId",
  FirstRowMarketPlaceCardTokenId = "firstRowMarketPlaceCardTokenId",
  GameWeekId = "gameWeekId",
  HalfCommonCardId = "halfCommonCardId",
  HalfMarketPlaceCardTokenId = "halfMarketPlaceCardTokenId",
  Id = "id",
  MarketPlaceUserId = "marketPlaceUserId",
  Ranking = "ranking",
  Score = "score",
  SecondRowCommonCardId = "secondRowCommonCardId",
  SecondRowMarketPlaceCardTokenId = "secondRowMarketPlaceCardTokenId",
  Username = "username",
}

export type CompositionScalarWhereInput = {
  AND?: InputMaybe<Array<CompositionScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompositionScalarWhereInput>>;
  OR?: InputMaybe<Array<CompositionScalarWhereInput>>;
  backCommonCardId?: InputMaybe<IntNullableFilter>;
  backMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  captainCommonCardId?: InputMaybe<IntNullableFilter>;
  captainMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  cheater?: InputMaybe<BoolFilter>;
  extraCommonCardId?: InputMaybe<IntNullableFilter>;
  extraMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  firstRowCommonCardId?: InputMaybe<IntNullableFilter>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  gameWeekId?: InputMaybe<IntNullableFilter>;
  halfCommonCardId?: InputMaybe<IntNullableFilter>;
  halfMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  marketPlaceUserId?: InputMaybe<StringFilter>;
  ranking?: InputMaybe<IntNullableFilter>;
  score?: InputMaybe<FloatNullableFilter>;
  secondRowCommonCardId?: InputMaybe<IntNullableFilter>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  username?: InputMaybe<StringNullableFilter>;
};

export type CompositionUpdateInput = {
  backCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  backMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  captainCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  captainMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  extraCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  extraMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstRowCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gameWeekId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  halfCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  halfMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondRowCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompositionUpdateManyMutationInput = {
  backCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  backMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  captainCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  captainMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cheater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  extraCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  extraMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstRowCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  halfCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  halfMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  marketPlaceUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  score?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  secondRowCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompositionUpdateManyWithWhereWithoutGameWeekInput = {
  data: CompositionUpdateManyMutationInput;
  where: CompositionScalarWhereInput;
};

export type CompositionUpdateManyWithoutGameWeekNestedInput = {
  connect?: InputMaybe<Array<CompositionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompositionCreateOrConnectWithoutGameWeekInput>>;
  create?: InputMaybe<Array<CompositionCreateWithoutGameWeekInput>>;
  createMany?: InputMaybe<CompositionCreateManyGameWeekInputEnvelope>;
  delete?: InputMaybe<Array<CompositionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompositionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompositionWhereUniqueInput>>;
  set?: InputMaybe<Array<CompositionWhereUniqueInput>>;
  update?: InputMaybe<Array<CompositionUpdateWithWhereUniqueWithoutGameWeekInput>>;
  updateMany?: InputMaybe<Array<CompositionUpdateManyWithWhereWithoutGameWeekInput>>;
  upsert?: InputMaybe<Array<CompositionUpsertWithWhereUniqueWithoutGameWeekInput>>;
};

export type CompositionUpdateOneRequiredWithoutCompositionCardsNestedInput = {
  connect?: InputMaybe<CompositionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompositionCreateOrConnectWithoutCompositionCardsInput>;
  create?: InputMaybe<CompositionCreateWithoutCompositionCardsInput>;
  update?: InputMaybe<CompositionUpdateToOneWithWhereWithoutCompositionCardsInput>;
  upsert?: InputMaybe<CompositionUpsertWithoutCompositionCardsInput>;
};

export type CompositionUpdateOneRequiredWithoutRewardClaimsNestedInput = {
  connect?: InputMaybe<CompositionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompositionCreateOrConnectWithoutRewardClaimsInput>;
  create?: InputMaybe<CompositionCreateWithoutRewardClaimsInput>;
  update?: InputMaybe<CompositionUpdateToOneWithWhereWithoutRewardClaimsInput>;
  upsert?: InputMaybe<CompositionUpsertWithoutRewardClaimsInput>;
};

export type CompositionUpdateToOneWithWhereWithoutCompositionCardsInput = {
  data: CompositionUpdateWithoutCompositionCardsInput;
  where?: InputMaybe<CompositionWhereInput>;
};

export type CompositionUpdateToOneWithWhereWithoutRewardClaimsInput = {
  data: CompositionUpdateWithoutRewardClaimsInput;
  where?: InputMaybe<CompositionWhereInput>;
};

export type CompositionUpdateWithWhereUniqueWithoutGameWeekInput = {
  data: CompositionUpdateWithoutGameWeekInput;
  where: CompositionWhereUniqueInput;
};

export type CompositionUpdateWithoutCompositionCardsInput = {
  backCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  backMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  captainCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  captainMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cheater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  extraCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  extraMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstRowCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gameWeek?: InputMaybe<TournamentGameWeekUpdateOneWithoutCompositionsNestedInput>;
  halfCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  halfMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  marketPlaceUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rewardClaims?: InputMaybe<RewardClaimUpdateManyWithoutCompositionNestedInput>;
  score?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  secondRowCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompositionUpdateWithoutGameWeekInput = {
  backCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  backMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  captainCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  captainMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cheater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  compositionCards?: InputMaybe<CompositionCardUpdateManyWithoutCompositionNestedInput>;
  extraCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  extraMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstRowCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  halfCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  halfMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  marketPlaceUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rewardClaims?: InputMaybe<RewardClaimUpdateManyWithoutCompositionNestedInput>;
  score?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  secondRowCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompositionUpdateWithoutRewardClaimsInput = {
  backCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  backMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  captainCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  captainMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cheater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  compositionCards?: InputMaybe<CompositionCardUpdateManyWithoutCompositionNestedInput>;
  extraCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  extraMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstRowCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gameWeek?: InputMaybe<TournamentGameWeekUpdateOneWithoutCompositionsNestedInput>;
  halfCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  halfMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  marketPlaceUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  score?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  secondRowCommonCardId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CompositionUpsertWithWhereUniqueWithoutGameWeekInput = {
  create: CompositionCreateWithoutGameWeekInput;
  update: CompositionUpdateWithoutGameWeekInput;
  where: CompositionWhereUniqueInput;
};

export type CompositionUpsertWithoutCompositionCardsInput = {
  create: CompositionCreateWithoutCompositionCardsInput;
  update: CompositionUpdateWithoutCompositionCardsInput;
  where?: InputMaybe<CompositionWhereInput>;
};

export type CompositionUpsertWithoutRewardClaimsInput = {
  create: CompositionCreateWithoutRewardClaimsInput;
  update: CompositionUpdateWithoutRewardClaimsInput;
  where?: InputMaybe<CompositionWhereInput>;
};

export type CompositionWhereInput = {
  AND?: InputMaybe<Array<CompositionWhereInput>>;
  NOT?: InputMaybe<Array<CompositionWhereInput>>;
  OR?: InputMaybe<Array<CompositionWhereInput>>;
  backCommonCardId?: InputMaybe<IntNullableFilter>;
  backMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  captainCommonCardId?: InputMaybe<IntNullableFilter>;
  captainMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  cheater?: InputMaybe<BoolFilter>;
  compositionCards?: InputMaybe<CompositionCardListRelationFilter>;
  extraCommonCardId?: InputMaybe<IntNullableFilter>;
  extraMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  firstRowCommonCardId?: InputMaybe<IntNullableFilter>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  gameWeek?: InputMaybe<TournamentGameWeekNullableRelationFilter>;
  gameWeekId?: InputMaybe<IntNullableFilter>;
  halfCommonCardId?: InputMaybe<IntNullableFilter>;
  halfMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  marketPlaceUserId?: InputMaybe<StringFilter>;
  ranking?: InputMaybe<IntNullableFilter>;
  rewardClaims?: InputMaybe<RewardClaimListRelationFilter>;
  score?: InputMaybe<FloatNullableFilter>;
  secondRowCommonCardId?: InputMaybe<IntNullableFilter>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  username?: InputMaybe<StringNullableFilter>;
};

export type CompositionWhereUniqueInput = {
  AND?: InputMaybe<Array<CompositionWhereInput>>;
  NOT?: InputMaybe<Array<CompositionWhereInput>>;
  OR?: InputMaybe<Array<CompositionWhereInput>>;
  backCommonCardId?: InputMaybe<IntNullableFilter>;
  backMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  captainCommonCardId?: InputMaybe<IntNullableFilter>;
  captainMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  cheater?: InputMaybe<BoolFilter>;
  compositionCards?: InputMaybe<CompositionCardListRelationFilter>;
  extraCommonCardId?: InputMaybe<IntNullableFilter>;
  extraMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  firstRowCommonCardId?: InputMaybe<IntNullableFilter>;
  firstRowMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  gameWeek?: InputMaybe<TournamentGameWeekNullableRelationFilter>;
  gameWeekId?: InputMaybe<IntNullableFilter>;
  halfCommonCardId?: InputMaybe<IntNullableFilter>;
  halfMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  marketPlaceUserId?: InputMaybe<StringFilter>;
  marketPlaceUserId_gameWeekId?: InputMaybe<CompositionMarketPlaceUserIdGameWeekIdCompoundUniqueInput>;
  ranking?: InputMaybe<IntNullableFilter>;
  rewardClaims?: InputMaybe<RewardClaimListRelationFilter>;
  score?: InputMaybe<FloatNullableFilter>;
  secondRowCommonCardId?: InputMaybe<IntNullableFilter>;
  secondRowMarketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  username?: InputMaybe<StringNullableFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars["Timestamp"]["input"]>;
  gt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  gte?: InputMaybe<Scalars["Timestamp"]["input"]>;
  in?: InputMaybe<Array<Scalars["Timestamp"]["input"]>>;
  lt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  lte?: InputMaybe<Scalars["Timestamp"]["input"]>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["Timestamp"]["input"]>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars["Timestamp"]["input"]>;
  gt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  gte?: InputMaybe<Scalars["Timestamp"]["input"]>;
  in?: InputMaybe<Array<Scalars["Timestamp"]["input"]>>;
  lt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  lte?: InputMaybe<Scalars["Timestamp"]["input"]>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Timestamp"]["input"]>>;
};

export type EnumPositionTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<PositionType>;
};

export type EnumPositionTypeFilter = {
  equals?: InputMaybe<PositionType>;
  in?: InputMaybe<Array<PositionType>>;
  not?: InputMaybe<NestedEnumPositionTypeFilter>;
  notIn?: InputMaybe<Array<PositionType>>;
};

export type EnumRealGameStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<RealGameStatus>;
};

export type EnumRealGameStatusFilter = {
  equals?: InputMaybe<RealGameStatus>;
  in?: InputMaybe<Array<RealGameStatus>>;
  not?: InputMaybe<NestedEnumRealGameStatusFilter>;
  notIn?: InputMaybe<Array<RealGameStatus>>;
};

export type EnumRewardClaimStateFieldUpdateOperationsInput = {
  set?: InputMaybe<RewardClaimState>;
};

export type EnumRewardClaimStateFilter = {
  equals?: InputMaybe<RewardClaimState>;
  in?: InputMaybe<Array<RewardClaimState>>;
  not?: InputMaybe<NestedEnumRewardClaimStateFilter>;
  notIn?: InputMaybe<Array<RewardClaimState>>;
};

export type EnumRewardCriteriaFieldUpdateOperationsInput = {
  set?: InputMaybe<RewardCriteria>;
};

export type EnumRewardCriteriaFilter = {
  equals?: InputMaybe<RewardCriteria>;
  in?: InputMaybe<Array<RewardCriteria>>;
  not?: InputMaybe<NestedEnumRewardCriteriaFilter>;
  notIn?: InputMaybe<Array<RewardCriteria>>;
};

export type EnumRewardTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<RewardType>;
};

export type EnumRewardTypeFilter = {
  equals?: InputMaybe<RewardType>;
  in?: InputMaybe<Array<RewardType>>;
  not?: InputMaybe<NestedEnumRewardTypeFilter>;
  notIn?: InputMaybe<Array<RewardType>>;
};

export type EnumRoundTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<RoundType>;
};

export type EnumRoundTypeFilter = {
  equals?: InputMaybe<RoundType>;
  in?: InputMaybe<Array<RoundType>>;
  not?: InputMaybe<NestedEnumRoundTypeFilter>;
  notIn?: InputMaybe<Array<RoundType>>;
};

export type EnumScarcityTypeNullableFilter = {
  equals?: InputMaybe<ScarcityType>;
  in?: InputMaybe<Array<ScarcityType>>;
  not?: InputMaybe<NestedEnumScarcityTypeNullableFilter>;
  notIn?: InputMaybe<Array<ScarcityType>>;
};

export type EnumTournamentGameWeekStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<TournamentGameWeekStatus>;
};

export type EnumTournamentGameWeekStatusFilter = {
  equals?: InputMaybe<TournamentGameWeekStatus>;
  in?: InputMaybe<Array<TournamentGameWeekStatus>>;
  not?: InputMaybe<NestedEnumTournamentGameWeekStatusFilter>;
  notIn?: InputMaybe<Array<TournamentGameWeekStatus>>;
};

export type EnumTournamentStateFieldUpdateOperationsInput = {
  set?: InputMaybe<TournamentState>;
};

export type EnumTournamentStateFilter = {
  equals?: InputMaybe<TournamentState>;
  in?: InputMaybe<Array<TournamentState>>;
  not?: InputMaybe<NestedEnumTournamentStateFilter>;
  notIn?: InputMaybe<Array<TournamentState>>;
};

export type EnumTournamentVariantRulesTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TournamentVariantRulesType>;
};

export type EnumTournamentVariantRulesTypeFilter = {
  equals?: InputMaybe<TournamentVariantRulesType>;
  in?: InputMaybe<Array<TournamentVariantRulesType>>;
  not?: InputMaybe<NestedEnumTournamentVariantRulesTypeFilter>;
  notIn?: InputMaybe<Array<TournamentVariantRulesType>>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Float"]["input"]>;
  divide?: InputMaybe<Scalars["Float"]["input"]>;
  increment?: InputMaybe<Scalars["Float"]["input"]>;
  multiply?: InputMaybe<Scalars["Float"]["input"]>;
  set?: InputMaybe<Scalars["Float"]["input"]>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  gt?: InputMaybe<Scalars["Float"]["input"]>;
  gte?: InputMaybe<Scalars["Float"]["input"]>;
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  lt?: InputMaybe<Scalars["Float"]["input"]>;
  lte?: InputMaybe<Scalars["Float"]["input"]>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]["input"]>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  gt?: InputMaybe<Scalars["Float"]["input"]>;
  gte?: InputMaybe<Scalars["Float"]["input"]>;
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  lt?: InputMaybe<Scalars["Float"]["input"]>;
  lte?: InputMaybe<Scalars["Float"]["input"]>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]["input"]>>;
};

export type GameWeekReward = {
  __typename?: "GameWeekReward";
  _count: GameWeekRewardCount;
  gameWeek: TournamentGameWeek;
  gameWeekId: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  reward: Reward;
  rewardClaims?: Maybe<Array<RewardClaim>>;
  rewardId: Scalars["Int"]["output"];
};

export type GameWeekRewardCount = {
  __typename?: "GameWeekRewardCount";
  rewardClaims: Scalars["Int"]["output"];
};

export type GameWeekRewardCreateInput = {
  gameWeekId: Scalars["Int"]["input"];
  rewardId: Scalars["Int"]["input"];
};

export type GameWeekRewardCreateManyGameWeekInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  rewardId: Scalars["Int"]["input"];
};

export type GameWeekRewardCreateManyGameWeekInputEnvelope = {
  data: Array<GameWeekRewardCreateManyGameWeekInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GameWeekRewardCreateManyRewardInput = {
  gameWeekId: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GameWeekRewardCreateManyRewardInputEnvelope = {
  data: Array<GameWeekRewardCreateManyRewardInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GameWeekRewardCreateNestedManyWithoutGameWeekInput = {
  connect?: InputMaybe<Array<GameWeekRewardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GameWeekRewardCreateOrConnectWithoutGameWeekInput>>;
  create?: InputMaybe<Array<GameWeekRewardCreateWithoutGameWeekInput>>;
  createMany?: InputMaybe<GameWeekRewardCreateManyGameWeekInputEnvelope>;
};

export type GameWeekRewardCreateNestedManyWithoutRewardInput = {
  connect?: InputMaybe<Array<GameWeekRewardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GameWeekRewardCreateOrConnectWithoutRewardInput>>;
  create?: InputMaybe<Array<GameWeekRewardCreateWithoutRewardInput>>;
  createMany?: InputMaybe<GameWeekRewardCreateManyRewardInputEnvelope>;
};

export type GameWeekRewardCreateNestedOneWithoutRewardClaimsInput = {
  connect?: InputMaybe<GameWeekRewardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GameWeekRewardCreateOrConnectWithoutRewardClaimsInput>;
  create?: InputMaybe<GameWeekRewardCreateWithoutRewardClaimsInput>;
};

export type GameWeekRewardCreateOrConnectWithoutGameWeekInput = {
  create: GameWeekRewardCreateWithoutGameWeekInput;
  where: GameWeekRewardWhereUniqueInput;
};

export type GameWeekRewardCreateOrConnectWithoutRewardClaimsInput = {
  create: GameWeekRewardCreateWithoutRewardClaimsInput;
  where: GameWeekRewardWhereUniqueInput;
};

export type GameWeekRewardCreateOrConnectWithoutRewardInput = {
  create: GameWeekRewardCreateWithoutRewardInput;
  where: GameWeekRewardWhereUniqueInput;
};

export type GameWeekRewardCreateWithoutGameWeekInput = {
  reward: RewardCreateNestedOneWithoutGameWeekRewardsInput;
  rewardClaims?: InputMaybe<RewardClaimCreateNestedManyWithoutGameWeekRewardInput>;
};

export type GameWeekRewardCreateWithoutRewardClaimsInput = {
  gameWeek: TournamentGameWeekCreateNestedOneWithoutGameWeekRewardsInput;
  reward: RewardCreateNestedOneWithoutGameWeekRewardsInput;
};

export type GameWeekRewardCreateWithoutRewardInput = {
  gameWeek: TournamentGameWeekCreateNestedOneWithoutGameWeekRewardsInput;
  rewardClaims?: InputMaybe<RewardClaimCreateNestedManyWithoutGameWeekRewardInput>;
};

export type GameWeekRewardListRelationFilter = {
  every?: InputMaybe<GameWeekRewardWhereInput>;
  none?: InputMaybe<GameWeekRewardWhereInput>;
  some?: InputMaybe<GameWeekRewardWhereInput>;
};

export type GameWeekRewardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type GameWeekRewardOrderByWithRelationAndSearchRelevanceInput = {
  gameWeek?: InputMaybe<TournamentGameWeekOrderByWithRelationAndSearchRelevanceInput>;
  gameWeekId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reward?: InputMaybe<RewardOrderByWithRelationAndSearchRelevanceInput>;
  rewardClaims?: InputMaybe<RewardClaimOrderByRelationAggregateInput>;
  rewardId?: InputMaybe<SortOrder>;
};

export type GameWeekRewardRelationFilter = {
  is?: InputMaybe<GameWeekRewardWhereInput>;
  isNot?: InputMaybe<GameWeekRewardWhereInput>;
};

export enum GameWeekRewardScalarFieldEnum {
  GameWeekId = "gameWeekId",
  Id = "id",
  RewardId = "rewardId",
}

export type GameWeekRewardScalarWhereInput = {
  AND?: InputMaybe<Array<GameWeekRewardScalarWhereInput>>;
  NOT?: InputMaybe<Array<GameWeekRewardScalarWhereInput>>;
  OR?: InputMaybe<Array<GameWeekRewardScalarWhereInput>>;
  gameWeekId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  rewardId?: InputMaybe<IntFilter>;
};

export type GameWeekRewardUncheckedUpdateManyWithoutGameWeekInput = {
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  rewardId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type GameWeekRewardUncheckedUpdateManyWithoutRewardInput = {
  gameWeekId?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type GameWeekRewardUpdateInput = {
  gameWeekId?: InputMaybe<IntFieldUpdateOperationsInput>;
  rewardId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type GameWeekRewardUpdateManyWithWhereWithoutGameWeekInput = {
  data: GameWeekRewardUncheckedUpdateManyWithoutGameWeekInput;
  where: GameWeekRewardScalarWhereInput;
};

export type GameWeekRewardUpdateManyWithWhereWithoutRewardInput = {
  data: GameWeekRewardUncheckedUpdateManyWithoutRewardInput;
  where: GameWeekRewardScalarWhereInput;
};

export type GameWeekRewardUpdateManyWithoutGameWeekNestedInput = {
  connect?: InputMaybe<Array<GameWeekRewardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GameWeekRewardCreateOrConnectWithoutGameWeekInput>>;
  create?: InputMaybe<Array<GameWeekRewardCreateWithoutGameWeekInput>>;
  createMany?: InputMaybe<GameWeekRewardCreateManyGameWeekInputEnvelope>;
  delete?: InputMaybe<Array<GameWeekRewardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GameWeekRewardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GameWeekRewardWhereUniqueInput>>;
  set?: InputMaybe<Array<GameWeekRewardWhereUniqueInput>>;
  update?: InputMaybe<Array<GameWeekRewardUpdateWithWhereUniqueWithoutGameWeekInput>>;
  updateMany?: InputMaybe<Array<GameWeekRewardUpdateManyWithWhereWithoutGameWeekInput>>;
  upsert?: InputMaybe<Array<GameWeekRewardUpsertWithWhereUniqueWithoutGameWeekInput>>;
};

export type GameWeekRewardUpdateManyWithoutRewardNestedInput = {
  connect?: InputMaybe<Array<GameWeekRewardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GameWeekRewardCreateOrConnectWithoutRewardInput>>;
  create?: InputMaybe<Array<GameWeekRewardCreateWithoutRewardInput>>;
  createMany?: InputMaybe<GameWeekRewardCreateManyRewardInputEnvelope>;
  delete?: InputMaybe<Array<GameWeekRewardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GameWeekRewardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GameWeekRewardWhereUniqueInput>>;
  set?: InputMaybe<Array<GameWeekRewardWhereUniqueInput>>;
  update?: InputMaybe<Array<GameWeekRewardUpdateWithWhereUniqueWithoutRewardInput>>;
  updateMany?: InputMaybe<Array<GameWeekRewardUpdateManyWithWhereWithoutRewardInput>>;
  upsert?: InputMaybe<Array<GameWeekRewardUpsertWithWhereUniqueWithoutRewardInput>>;
};

export type GameWeekRewardUpdateOneRequiredWithoutRewardClaimsNestedInput = {
  connect?: InputMaybe<GameWeekRewardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GameWeekRewardCreateOrConnectWithoutRewardClaimsInput>;
  create?: InputMaybe<GameWeekRewardCreateWithoutRewardClaimsInput>;
  update?: InputMaybe<GameWeekRewardUpdateToOneWithWhereWithoutRewardClaimsInput>;
  upsert?: InputMaybe<GameWeekRewardUpsertWithoutRewardClaimsInput>;
};

export type GameWeekRewardUpdateToOneWithWhereWithoutRewardClaimsInput = {
  data: GameWeekRewardUpdateWithoutRewardClaimsInput;
  where?: InputMaybe<GameWeekRewardWhereInput>;
};

export type GameWeekRewardUpdateWithWhereUniqueWithoutGameWeekInput = {
  data: GameWeekRewardUpdateWithoutGameWeekInput;
  where: GameWeekRewardWhereUniqueInput;
};

export type GameWeekRewardUpdateWithWhereUniqueWithoutRewardInput = {
  data: GameWeekRewardUpdateWithoutRewardInput;
  where: GameWeekRewardWhereUniqueInput;
};

export type GameWeekRewardUpdateWithoutGameWeekInput = {
  reward?: InputMaybe<RewardUpdateOneRequiredWithoutGameWeekRewardsNestedInput>;
  rewardClaims?: InputMaybe<RewardClaimUpdateManyWithoutGameWeekRewardNestedInput>;
};

export type GameWeekRewardUpdateWithoutRewardClaimsInput = {
  gameWeek?: InputMaybe<TournamentGameWeekUpdateOneRequiredWithoutGameWeekRewardsNestedInput>;
  reward?: InputMaybe<RewardUpdateOneRequiredWithoutGameWeekRewardsNestedInput>;
};

export type GameWeekRewardUpdateWithoutRewardInput = {
  gameWeek?: InputMaybe<TournamentGameWeekUpdateOneRequiredWithoutGameWeekRewardsNestedInput>;
  rewardClaims?: InputMaybe<RewardClaimUpdateManyWithoutGameWeekRewardNestedInput>;
};

export type GameWeekRewardUpsertWithWhereUniqueWithoutGameWeekInput = {
  create: GameWeekRewardCreateWithoutGameWeekInput;
  update: GameWeekRewardUpdateWithoutGameWeekInput;
  where: GameWeekRewardWhereUniqueInput;
};

export type GameWeekRewardUpsertWithWhereUniqueWithoutRewardInput = {
  create: GameWeekRewardCreateWithoutRewardInput;
  update: GameWeekRewardUpdateWithoutRewardInput;
  where: GameWeekRewardWhereUniqueInput;
};

export type GameWeekRewardUpsertWithoutRewardClaimsInput = {
  create: GameWeekRewardCreateWithoutRewardClaimsInput;
  update: GameWeekRewardUpdateWithoutRewardClaimsInput;
  where?: InputMaybe<GameWeekRewardWhereInput>;
};

export type GameWeekRewardWhereInput = {
  AND?: InputMaybe<Array<GameWeekRewardWhereInput>>;
  NOT?: InputMaybe<Array<GameWeekRewardWhereInput>>;
  OR?: InputMaybe<Array<GameWeekRewardWhereInput>>;
  gameWeek?: InputMaybe<TournamentGameWeekRelationFilter>;
  gameWeekId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  reward?: InputMaybe<RewardRelationFilter>;
  rewardClaims?: InputMaybe<RewardClaimListRelationFilter>;
  rewardId?: InputMaybe<IntFilter>;
};

export type GameWeekRewardWhereUniqueInput = {
  AND?: InputMaybe<Array<GameWeekRewardWhereInput>>;
  NOT?: InputMaybe<Array<GameWeekRewardWhereInput>>;
  OR?: InputMaybe<Array<GameWeekRewardWhereInput>>;
  gameWeek?: InputMaybe<TournamentGameWeekRelationFilter>;
  gameWeekId?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  reward?: InputMaybe<RewardRelationFilter>;
  rewardClaims?: InputMaybe<RewardClaimListRelationFilter>;
  rewardId?: InputMaybe<IntFilter>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Int"]["input"]>;
  divide?: InputMaybe<Scalars["Int"]["input"]>;
  increment?: InputMaybe<Scalars["Int"]["input"]>;
  multiply?: InputMaybe<Scalars["Int"]["input"]>;
  set?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]["input"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]["input"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<Scalars["JSON"]["input"]>;
  path?: InputMaybe<Array<Scalars["String"]["input"]>>;
  string_contains?: InputMaybe<Scalars["String"]["input"]>;
  string_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  string_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export type Language = {
  __typename?: "Language";
  _count: LanguageCount;
  code: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  translations?: Maybe<Array<Translation>>;
};

export type LanguageCount = {
  __typename?: "LanguageCount";
  translations: Scalars["Int"]["output"];
};

export type LanguageCreateInput = {
  code: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type LanguageCreateNestedOneWithoutTranslationsInput = {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LanguageCreateOrConnectWithoutTranslationsInput>;
  create?: InputMaybe<LanguageCreateWithoutTranslationsInput>;
};

export type LanguageCreateOrConnectWithoutTranslationsInput = {
  create: LanguageCreateWithoutTranslationsInput;
  where: LanguageWhereUniqueInput;
};

export type LanguageCreateWithoutTranslationsInput = {
  code: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export enum LanguageOrderByRelevanceFieldEnum {
  Code = "code",
  Name = "name",
}

export type LanguageOrderByRelevanceInput = {
  fields: Array<LanguageOrderByRelevanceFieldEnum>;
  search: Scalars["String"]["input"];
  sort: SortOrder;
};

export type LanguageOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<LanguageOrderByRelevanceInput>;
  code?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  translations?: InputMaybe<TranslationOrderByRelationAggregateInput>;
};

export type LanguageRelationFilter = {
  is?: InputMaybe<LanguageWhereInput>;
  isNot?: InputMaybe<LanguageWhereInput>;
};

export enum LanguageScalarFieldEnum {
  Code = "code",
  Id = "id",
  Name = "name",
}

export type LanguageUpdateOneRequiredWithoutTranslationsNestedInput = {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LanguageCreateOrConnectWithoutTranslationsInput>;
  create?: InputMaybe<LanguageCreateWithoutTranslationsInput>;
  update?: InputMaybe<LanguageUpdateToOneWithWhereWithoutTranslationsInput>;
  upsert?: InputMaybe<LanguageUpsertWithoutTranslationsInput>;
};

export type LanguageUpdateToOneWithWhereWithoutTranslationsInput = {
  data: LanguageUpdateWithoutTranslationsInput;
  where?: InputMaybe<LanguageWhereInput>;
};

export type LanguageUpdateWithoutTranslationsInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type LanguageUpsertWithoutTranslationsInput = {
  create: LanguageCreateWithoutTranslationsInput;
  update: LanguageUpdateWithoutTranslationsInput;
  where?: InputMaybe<LanguageWhereInput>;
};

export type LanguageWhereInput = {
  AND?: InputMaybe<Array<LanguageWhereInput>>;
  NOT?: InputMaybe<Array<LanguageWhereInput>>;
  OR?: InputMaybe<Array<LanguageWhereInput>>;
  code?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  translations?: InputMaybe<TranslationListRelationFilter>;
};

export type LanguageWhereUniqueInput = {
  AND?: InputMaybe<Array<LanguageWhereInput>>;
  NOT?: InputMaybe<Array<LanguageWhereInput>>;
  OR?: InputMaybe<Array<LanguageWhereInput>>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<StringFilter>;
  translations?: InputMaybe<TranslationListRelationFilter>;
};

export type ListMetadata = {
  __typename?: "ListMetadata";
  count: Scalars["Int"]["output"];
};

export type MarketPlaceCardPlayerCache = {
  __typename?: "MarketPlaceCardPlayerCache";
  CompositionCard?: Maybe<Array<CompositionCard>>;
  _count: MarketPlaceCardPlayerCacheCount;
  age?: Maybe<Scalars["Int"]["output"]>;
  cardRarity?: Maybe<ScarcityType>;
  cardTokenId: Scalars["ID"]["output"];
  edition?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  nationality?: Maybe<Scalars["String"]["output"]>;
  optaId: Scalars["String"]["output"];
  player: MarketPlacePlayerType;
  playerFirstName?: Maybe<Scalars["String"]["output"]>;
  playerLastName?: Maybe<Scalars["String"]["output"]>;
  playerOptaId: Scalars["String"]["output"];
  position?: Maybe<Scalars["String"]["output"]>;
  rarity: ScarcityType;
  tokenId: Scalars["String"]["output"];
  updatedAt: Scalars["Timestamp"]["output"];
  xp?: Maybe<Scalars["Float"]["output"]>;
};

export type MarketPlaceCardPlayerCacheCount = {
  __typename?: "MarketPlaceCardPlayerCacheCount";
  CompositionCard: Scalars["Int"]["output"];
};

export type MarketPlaceCardPlayerCacheCreateNestedOneWithoutCompositionCardInput = {
  connect?: InputMaybe<MarketPlaceCardPlayerCacheWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MarketPlaceCardPlayerCacheCreateOrConnectWithoutCompositionCardInput>;
  create?: InputMaybe<MarketPlaceCardPlayerCacheCreateWithoutCompositionCardInput>;
};

export type MarketPlaceCardPlayerCacheCreateOrConnectWithoutCompositionCardInput = {
  create: MarketPlaceCardPlayerCacheCreateWithoutCompositionCardInput;
  where: MarketPlaceCardPlayerCacheWhereUniqueInput;
};

export type MarketPlaceCardPlayerCacheCreateWithoutCompositionCardInput = {
  age?: InputMaybe<Scalars["Int"]["input"]>;
  cardRarity?: InputMaybe<ScarcityType>;
  cardTokenId: Scalars["String"]["input"];
  edition?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  nationality?: InputMaybe<Scalars["String"]["input"]>;
  playerFirstName?: InputMaybe<Scalars["String"]["input"]>;
  playerLastName?: InputMaybe<Scalars["String"]["input"]>;
  playerOptaId: Scalars["String"]["input"];
  position?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  xp?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MarketPlaceCardPlayerCacheNullableRelationFilter = {
  is?: InputMaybe<MarketPlaceCardPlayerCacheWhereInput>;
  isNot?: InputMaybe<MarketPlaceCardPlayerCacheWhereInput>;
};

export type MarketPlaceCardPlayerCacheUpdateOneWithoutCompositionCardNestedInput = {
  connect?: InputMaybe<MarketPlaceCardPlayerCacheWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MarketPlaceCardPlayerCacheCreateOrConnectWithoutCompositionCardInput>;
  create?: InputMaybe<MarketPlaceCardPlayerCacheCreateWithoutCompositionCardInput>;
  delete?: InputMaybe<MarketPlaceCardPlayerCacheWhereInput>;
  disconnect?: InputMaybe<MarketPlaceCardPlayerCacheWhereInput>;
  update?: InputMaybe<MarketPlaceCardPlayerCacheUpdateToOneWithWhereWithoutCompositionCardInput>;
  upsert?: InputMaybe<MarketPlaceCardPlayerCacheUpsertWithoutCompositionCardInput>;
};

export type MarketPlaceCardPlayerCacheUpdateToOneWithWhereWithoutCompositionCardInput = {
  data: MarketPlaceCardPlayerCacheUpdateWithoutCompositionCardInput;
  where?: InputMaybe<MarketPlaceCardPlayerCacheWhereInput>;
};

export type MarketPlaceCardPlayerCacheUpdateWithoutCompositionCardInput = {
  age?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cardRarity?: InputMaybe<NullableEnumScarcityTypeFieldUpdateOperationsInput>;
  cardTokenId?: InputMaybe<StringFieldUpdateOperationsInput>;
  edition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playerFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playerLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  xp?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type MarketPlaceCardPlayerCacheUpsertWithoutCompositionCardInput = {
  create: MarketPlaceCardPlayerCacheCreateWithoutCompositionCardInput;
  update: MarketPlaceCardPlayerCacheUpdateWithoutCompositionCardInput;
  where?: InputMaybe<MarketPlaceCardPlayerCacheWhereInput>;
};

export type MarketPlaceCardPlayerCacheWhereInput = {
  AND?: InputMaybe<Array<MarketPlaceCardPlayerCacheWhereInput>>;
  CompositionCard?: InputMaybe<CompositionCardListRelationFilter>;
  NOT?: InputMaybe<Array<MarketPlaceCardPlayerCacheWhereInput>>;
  OR?: InputMaybe<Array<MarketPlaceCardPlayerCacheWhereInput>>;
  age?: InputMaybe<IntNullableFilter>;
  cardRarity?: InputMaybe<EnumScarcityTypeNullableFilter>;
  cardTokenId?: InputMaybe<StringFilter>;
  edition?: InputMaybe<StringNullableFilter>;
  image?: InputMaybe<StringNullableFilter>;
  nationality?: InputMaybe<StringNullableFilter>;
  playerFirstName?: InputMaybe<StringNullableFilter>;
  playerLastName?: InputMaybe<StringNullableFilter>;
  playerOptaId?: InputMaybe<StringFilter>;
  position?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  xp?: InputMaybe<FloatNullableFilter>;
};

export type MarketPlaceCardPlayerCacheWhereUniqueInput = {
  AND?: InputMaybe<Array<MarketPlaceCardPlayerCacheWhereInput>>;
  CompositionCard?: InputMaybe<CompositionCardListRelationFilter>;
  NOT?: InputMaybe<Array<MarketPlaceCardPlayerCacheWhereInput>>;
  OR?: InputMaybe<Array<MarketPlaceCardPlayerCacheWhereInput>>;
  age?: InputMaybe<IntNullableFilter>;
  cardRarity?: InputMaybe<EnumScarcityTypeNullableFilter>;
  cardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  edition?: InputMaybe<StringNullableFilter>;
  image?: InputMaybe<StringNullableFilter>;
  nationality?: InputMaybe<StringNullableFilter>;
  playerFirstName?: InputMaybe<StringNullableFilter>;
  playerLastName?: InputMaybe<StringNullableFilter>;
  playerOptaId?: InputMaybe<StringFilter>;
  position?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  xp?: InputMaybe<FloatNullableFilter>;
};

export type MarketPlaceCardType = {
  __typename?: "MarketPlaceCardType";
  age?: Maybe<Scalars["Int"]["output"]>;
  competition?: Maybe<MarketPlaceCompetitionType>;
  edition?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  nationality?: Maybe<MarketPlaceNationalityType>;
  optaId?: Maybe<Scalars["Int"]["output"]>;
  owner?: Maybe<MarketPlaceWalletType>;
  player?: Maybe<MarketPlacePlayerType>;
  position?: Maybe<MarketPlacePositionType>;
  rarity?: Maybe<MarketPlaceRarity>;
  tokenId?: Maybe<Scalars["String"]["output"]>;
  xp?: Maybe<Scalars["Float"]["output"]>;
};

export type MarketPlaceCompetitionType = {
  __typename?: "MarketPlaceCompetitionType";
  name?: Maybe<Scalars["String"]["output"]>;
  optaId?: Maybe<Scalars["String"]["output"]>;
};

export type MarketPlaceNationalityType = {
  __typename?: "MarketPlaceNationalityType";
  codeIso?: Maybe<Scalars["String"]["output"]>;
  nationality?: Maybe<Scalars["String"]["output"]>;
};

export type MarketPlacePlayerStatsType = {
  __typename?: "MarketPlacePlayerStatsType";
  attack?: Maybe<Scalars["Float"]["output"]>;
  defense?: Maybe<Scalars["Float"]["output"]>;
  impact?: Maybe<Scalars["Float"]["output"]>;
  scoring?: Maybe<Scalars["Float"]["output"]>;
  skills?: Maybe<Scalars["Float"]["output"]>;
  strength?: Maybe<Scalars["Float"]["output"]>;
};

export type MarketPlacePlayerType = {
  __typename?: "MarketPlacePlayerType";
  currentCompetition?: Maybe<MarketPlaceCompetitionType>;
  currentNationality?: Maybe<MarketPlaceNationalityType>;
  currentPosition?: Maybe<MarketPlacePositionType>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  optaId?: Maybe<Scalars["String"]["output"]>;
  playerStats?: Maybe<MarketPlacePlayerStatsType>;
};

export type MarketPlacePositionType = {
  __typename?: "MarketPlacePositionType";
  position?: Maybe<Scalars["String"]["output"]>;
};

export enum MarketPlaceRarity {
  Common = "Common",
  Limited = "Limited",
  Rare = "Rare",
  SuperRare = "SuperRare",
  Unique = "Unique",
}

export type MarketPlaceUserType = {
  __typename?: "MarketPlaceUserType";
  id: Scalars["String"]["output"];
  username: Scalars["String"]["output"];
  wallet: MarketPlaceWalletType;
};

export type MarketPlaceWalletType = {
  __typename?: "MarketPlaceWalletType";
  address?: Maybe<Scalars["String"]["output"]>;
  cards?: Maybe<Array<MarketPlaceCardType>>;
};

export type Mutation = {
  __typename?: "Mutation";
  claimRewardClaimFromCurrentUser?: Maybe<RewardClaim>;
  copyComposition: Array<Composition>;
  createChampionship: Championship;
  createChampionshipSeason?: Maybe<ChampionshipSeason>;
  createComposition: Composition;
  createCompositionForCurrentUser: Composition;
  createGameWeekReward: GameWeekReward;
  createLanguage?: Maybe<Language>;
  createReward: Reward;
  createTeam: Team;
  createTournament: Tournament;
  createTournamentGameWeek?: Maybe<TournamentGameWeek>;
  createTournamentVariant: TournamentVariant;
  createTournamentVariantFormTournament?: Maybe<Tournament>;
  createTournamentVariantGameWeek?: Maybe<TournamentVariant>;
  createTranslatable?: Maybe<Translatable>;
  createTranslation?: Maybe<Translation>;
  deleteComposition?: Maybe<Composition>;
  deleteGameWeekReward?: Maybe<GameWeekReward>;
  deletePlayer: Player;
  deleteReward?: Maybe<Reward>;
  deleteRewardClaim?: Maybe<RewardClaim>;
  deleteTournament?: Maybe<Tournament>;
  deleteTournamentChampionship?: Maybe<TournamentChampionship>;
  deleteTournamentGameWeek?: Maybe<TournamentGameWeek>;
  deleteTournamentVariant?: Maybe<TournamentVariant>;
  dev_allTournamentGameWeeks_games_to_status: Scalars["String"]["output"];
  populateUsernames: ListMetadata;
  updateComposition: Composition;
  updateCompositionForCurrentUser: Composition;
  updateGameWeekReward?: Maybe<GameWeekReward>;
  updatePlayer: Player;
  updateReward?: Maybe<Reward>;
  updateRewardClaim?: Maybe<RewardClaim>;
  updateTournament?: Maybe<Tournament>;
  updateTournamentGameWeek?: Maybe<TournamentGameWeek>;
  updateTournamentVariant?: Maybe<TournamentVariant>;
  updateTournamentVariantRewards?: Maybe<TournamentVariant>;
  updateTranslatable?: Maybe<Translatable>;
  updateTranslation?: Maybe<Translation>;
};

export type MutationClaimRewardClaimFromCurrentUserArgs = {
  id: Scalars["Float"]["input"];
  questionOutput?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCopyCompositionArgs = {
  sourceTWGId: Scalars["Float"]["input"];
  targetTWGId: Scalars["Float"]["input"];
};

export type MutationCreateChampionshipArgs = {
  data: ChampionshipCreateInput;
};

export type MutationCreateChampionshipSeasonArgs = {
  data: ChampionshipSeasonCreateInput;
};

export type MutationCreateCompositionArgs = {
  data: CompositionCreateInput;
};

export type MutationCreateCompositionForCurrentUserArgs = {
  data: CompositionCreateInput;
};

export type MutationCreateGameWeekRewardArgs = {
  data: GameWeekRewardCreateInput;
};

export type MutationCreateLanguageArgs = {
  data: LanguageCreateInput;
};

export type MutationCreateRewardArgs = {
  data: RewardCreateInput;
};

export type MutationCreateTeamArgs = {
  data: TeamCreateInput;
};

export type MutationCreateTournamentArgs = {
  data: TournamentCreateInput;
};

export type MutationCreateTournamentGameWeekArgs = {
  data: TournamentGameWeekCreateInput;
};

export type MutationCreateTournamentVariantArgs = {
  data: TournamentVariantCreateInput;
};

export type MutationCreateTournamentVariantFormTournamentArgs = {
  id: Scalars["Float"]["input"];
  rule: Scalars["String"]["input"];
};

export type MutationCreateTournamentVariantGameWeekArgs = {
  data: TournamentVariantGameWeekCreateInput;
  id: Scalars["Float"]["input"];
};

export type MutationCreateTranslatableArgs = {
  data: TranslatableCreateInput;
};

export type MutationCreateTranslationArgs = {
  data: TranslationCreateInput;
};

export type MutationDeleteCompositionArgs = {
  id: Scalars["Float"]["input"];
};

export type MutationDeleteGameWeekRewardArgs = {
  id: Scalars["Float"]["input"];
};

export type MutationDeletePlayerArgs = {
  id: Scalars["Float"]["input"];
};

export type MutationDeleteRewardArgs = {
  id: Scalars["Float"]["input"];
};

export type MutationDeleteRewardClaimArgs = {
  id: Scalars["Float"]["input"];
};

export type MutationDeleteTournamentArgs = {
  id: Scalars["Float"]["input"];
};

export type MutationDeleteTournamentChampionshipArgs = {
  id: Scalars["Float"]["input"];
};

export type MutationDeleteTournamentGameWeekArgs = {
  id: Scalars["Float"]["input"];
};

export type MutationDeleteTournamentVariantArgs = {
  id: Scalars["Float"]["input"];
};

export type MutationDev_AllTournamentGameWeeks_Games_To_StatusArgs = {
  id: Scalars["Float"]["input"];
  status: Scalars["String"]["input"];
};

export type MutationUpdateCompositionArgs = {
  data: CompositionUpdateInput;
  id: Scalars["Float"]["input"];
};

export type MutationUpdateCompositionForCurrentUserArgs = {
  data: CompositionCreateInput;
  id: Scalars["Float"]["input"];
};

export type MutationUpdateGameWeekRewardArgs = {
  data: GameWeekRewardUpdateInput;
  id: Scalars["Float"]["input"];
};

export type MutationUpdatePlayerArgs = {
  data: PlayerUpdateInput;
  id: Scalars["Float"]["input"];
};

export type MutationUpdateRewardArgs = {
  data: RewardUpdateInput;
  id: Scalars["Float"]["input"];
};

export type MutationUpdateRewardClaimArgs = {
  data: RewardClaimUpdateInput;
  id: Scalars["Float"]["input"];
};

export type MutationUpdateTournamentArgs = {
  data: TournamentUpdateInput;
  id: Scalars["Float"]["input"];
};

export type MutationUpdateTournamentGameWeekArgs = {
  data: TournamentGameWeekUpdateInput;
  id: Scalars["Float"]["input"];
};

export type MutationUpdateTournamentVariantArgs = {
  data: TournamentVariantUpdateInput;
  id: Scalars["Float"]["input"];
};

export type MutationUpdateTournamentVariantRewardsArgs = {
  data: TournamentVariantRewardsUpdateInput;
  id: Scalars["Float"]["input"];
};

export type MutationUpdateTranslatableArgs = {
  data: TranslatableUpdateInput;
  id: Scalars["Float"]["input"];
};

export type MutationUpdateTranslationArgs = {
  data: TranslationUpdateInput;
  id: Scalars["Float"]["input"];
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBytesNullableFilter = {
  equals?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  not?: InputMaybe<NestedBytesNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars["Timestamp"]["input"]>;
  gt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  gte?: InputMaybe<Scalars["Timestamp"]["input"]>;
  in?: InputMaybe<Array<Scalars["Timestamp"]["input"]>>;
  lt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  lte?: InputMaybe<Scalars["Timestamp"]["input"]>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["Timestamp"]["input"]>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars["Timestamp"]["input"]>;
  gt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  gte?: InputMaybe<Scalars["Timestamp"]["input"]>;
  in?: InputMaybe<Array<Scalars["Timestamp"]["input"]>>;
  lt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  lte?: InputMaybe<Scalars["Timestamp"]["input"]>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Timestamp"]["input"]>>;
};

export type NestedEnumPositionTypeFilter = {
  equals?: InputMaybe<PositionType>;
  in?: InputMaybe<Array<PositionType>>;
  not?: InputMaybe<NestedEnumPositionTypeFilter>;
  notIn?: InputMaybe<Array<PositionType>>;
};

export type NestedEnumRealGameStatusFilter = {
  equals?: InputMaybe<RealGameStatus>;
  in?: InputMaybe<Array<RealGameStatus>>;
  not?: InputMaybe<NestedEnumRealGameStatusFilter>;
  notIn?: InputMaybe<Array<RealGameStatus>>;
};

export type NestedEnumRewardClaimStateFilter = {
  equals?: InputMaybe<RewardClaimState>;
  in?: InputMaybe<Array<RewardClaimState>>;
  not?: InputMaybe<NestedEnumRewardClaimStateFilter>;
  notIn?: InputMaybe<Array<RewardClaimState>>;
};

export type NestedEnumRewardCriteriaFilter = {
  equals?: InputMaybe<RewardCriteria>;
  in?: InputMaybe<Array<RewardCriteria>>;
  not?: InputMaybe<NestedEnumRewardCriteriaFilter>;
  notIn?: InputMaybe<Array<RewardCriteria>>;
};

export type NestedEnumRewardTypeFilter = {
  equals?: InputMaybe<RewardType>;
  in?: InputMaybe<Array<RewardType>>;
  not?: InputMaybe<NestedEnumRewardTypeFilter>;
  notIn?: InputMaybe<Array<RewardType>>;
};

export type NestedEnumRoundTypeFilter = {
  equals?: InputMaybe<RoundType>;
  in?: InputMaybe<Array<RoundType>>;
  not?: InputMaybe<NestedEnumRoundTypeFilter>;
  notIn?: InputMaybe<Array<RoundType>>;
};

export type NestedEnumScarcityTypeNullableFilter = {
  equals?: InputMaybe<ScarcityType>;
  in?: InputMaybe<Array<ScarcityType>>;
  not?: InputMaybe<NestedEnumScarcityTypeNullableFilter>;
  notIn?: InputMaybe<Array<ScarcityType>>;
};

export type NestedEnumTournamentGameWeekStatusFilter = {
  equals?: InputMaybe<TournamentGameWeekStatus>;
  in?: InputMaybe<Array<TournamentGameWeekStatus>>;
  not?: InputMaybe<NestedEnumTournamentGameWeekStatusFilter>;
  notIn?: InputMaybe<Array<TournamentGameWeekStatus>>;
};

export type NestedEnumTournamentStateFilter = {
  equals?: InputMaybe<TournamentState>;
  in?: InputMaybe<Array<TournamentState>>;
  not?: InputMaybe<NestedEnumTournamentStateFilter>;
  notIn?: InputMaybe<Array<TournamentState>>;
};

export type NestedEnumTournamentVariantRulesTypeFilter = {
  equals?: InputMaybe<TournamentVariantRulesType>;
  in?: InputMaybe<Array<TournamentVariantRulesType>>;
  not?: InputMaybe<NestedEnumTournamentVariantRulesTypeFilter>;
  notIn?: InputMaybe<Array<TournamentVariantRulesType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  gt?: InputMaybe<Scalars["Float"]["input"]>;
  gte?: InputMaybe<Scalars["Float"]["input"]>;
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  lt?: InputMaybe<Scalars["Float"]["input"]>;
  lte?: InputMaybe<Scalars["Float"]["input"]>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]["input"]>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  gt?: InputMaybe<Scalars["Float"]["input"]>;
  gte?: InputMaybe<Scalars["Float"]["input"]>;
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  lt?: InputMaybe<Scalars["Float"]["input"]>;
  lte?: InputMaybe<Scalars["Float"]["input"]>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]["input"]>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type NullableBytesFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["String"]["input"]>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type NullableEnumScarcityTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ScarcityType>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Float"]["input"]>;
  divide?: InputMaybe<Scalars["Float"]["input"]>;
  increment?: InputMaybe<Scalars["Float"]["input"]>;
  multiply?: InputMaybe<Scalars["Float"]["input"]>;
  set?: InputMaybe<Scalars["Float"]["input"]>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Int"]["input"]>;
  divide?: InputMaybe<Scalars["Int"]["input"]>;
  increment?: InputMaybe<Scalars["Int"]["input"]>;
  multiply?: InputMaybe<Scalars["Int"]["input"]>;
  set?: InputMaybe<Scalars["Int"]["input"]>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["String"]["input"]>;
};

export enum NullsOrder {
  First = "first",
  Last = "last",
}

export type Player = {
  __typename?: "Player";
  _count: PlayerCount;
  attack: Scalars["Float"]["output"];
  championshipSeasonTeamPlayers?: Maybe<Array<ChampionshipSeasonTeamPlayer>>;
  commonCards?: Maybe<Array<CommonCard>>;
  defense: Scalars["Float"]["output"];
  firstname: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  impact: Scalars["Float"]["output"];
  lastname: Scalars["String"]["output"];
  livePoints?: Maybe<Scalars["Float"]["output"]>;
  optaPosition: Scalars["String"]["output"];
  playerOptaId: Scalars["String"]["output"];
  position: PositionType;
  realGamePlayers?: Maybe<Array<RealGamePlayer>>;
  scoring: Scalars["Float"]["output"];
  skills: Scalars["Float"]["output"];
  strength: Scalars["Float"]["output"];
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars["Int"]["output"]>;
  teamOptaId: Scalars["String"]["output"];
};

export type PlayerCount = {
  __typename?: "PlayerCount";
  championshipSeasonTeamPlayers: Scalars["Int"]["output"];
  commonCards: Scalars["Int"]["output"];
  realGamePlayers: Scalars["Int"]["output"];
};

export type PlayerCreateManyTeamInput = {
  attack?: InputMaybe<Scalars["Float"]["input"]>;
  defense?: InputMaybe<Scalars["Float"]["input"]>;
  firstname: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  impact?: InputMaybe<Scalars["Float"]["input"]>;
  lastname: Scalars["String"]["input"];
  optaPosition: Scalars["String"]["input"];
  playerOptaId: Scalars["String"]["input"];
  position: PositionType;
  scoring?: InputMaybe<Scalars["Float"]["input"]>;
  skills?: InputMaybe<Scalars["Float"]["input"]>;
  strength?: InputMaybe<Scalars["Float"]["input"]>;
  teamOptaId: Scalars["String"]["input"];
};

export type PlayerCreateManyTeamInputEnvelope = {
  data: Array<PlayerCreateManyTeamInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PlayerCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<PlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlayerCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<PlayerCreateWithoutTeamInput>>;
  createMany?: InputMaybe<PlayerCreateManyTeamInputEnvelope>;
};

export type PlayerCreateNestedOneWithoutChampionshipSeasonTeamPlayersInput = {
  connect?: InputMaybe<PlayerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlayerCreateOrConnectWithoutChampionshipSeasonTeamPlayersInput>;
  create?: InputMaybe<PlayerCreateWithoutChampionshipSeasonTeamPlayersInput>;
};

export type PlayerCreateNestedOneWithoutCommonCardsInput = {
  connect?: InputMaybe<PlayerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlayerCreateOrConnectWithoutCommonCardsInput>;
  create?: InputMaybe<PlayerCreateWithoutCommonCardsInput>;
};

export type PlayerCreateNestedOneWithoutRealGamePlayersInput = {
  connect?: InputMaybe<PlayerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlayerCreateOrConnectWithoutRealGamePlayersInput>;
  create?: InputMaybe<PlayerCreateWithoutRealGamePlayersInput>;
};

export type PlayerCreateOrConnectWithoutChampionshipSeasonTeamPlayersInput = {
  create: PlayerCreateWithoutChampionshipSeasonTeamPlayersInput;
  where: PlayerWhereUniqueInput;
};

export type PlayerCreateOrConnectWithoutCommonCardsInput = {
  create: PlayerCreateWithoutCommonCardsInput;
  where: PlayerWhereUniqueInput;
};

export type PlayerCreateOrConnectWithoutRealGamePlayersInput = {
  create: PlayerCreateWithoutRealGamePlayersInput;
  where: PlayerWhereUniqueInput;
};

export type PlayerCreateOrConnectWithoutTeamInput = {
  create: PlayerCreateWithoutTeamInput;
  where: PlayerWhereUniqueInput;
};

export type PlayerCreateWithoutChampionshipSeasonTeamPlayersInput = {
  attack?: InputMaybe<Scalars["Float"]["input"]>;
  commonCards?: InputMaybe<CommonCardCreateNestedManyWithoutPlayerInput>;
  defense?: InputMaybe<Scalars["Float"]["input"]>;
  firstname: Scalars["String"]["input"];
  impact?: InputMaybe<Scalars["Float"]["input"]>;
  lastname: Scalars["String"]["input"];
  optaPosition: Scalars["String"]["input"];
  playerOptaId: Scalars["String"]["input"];
  position: PositionType;
  realGamePlayers?: InputMaybe<RealGamePlayerCreateNestedManyWithoutPlayerInput>;
  scoring?: InputMaybe<Scalars["Float"]["input"]>;
  skills?: InputMaybe<Scalars["Float"]["input"]>;
  strength?: InputMaybe<Scalars["Float"]["input"]>;
  team?: InputMaybe<TeamCreateNestedOneWithoutPlayersInput>;
  teamOptaId: Scalars["String"]["input"];
};

export type PlayerCreateWithoutCommonCardsInput = {
  attack?: InputMaybe<Scalars["Float"]["input"]>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerCreateNestedManyWithoutPlayerInput>;
  defense?: InputMaybe<Scalars["Float"]["input"]>;
  firstname: Scalars["String"]["input"];
  impact?: InputMaybe<Scalars["Float"]["input"]>;
  lastname: Scalars["String"]["input"];
  optaPosition: Scalars["String"]["input"];
  playerOptaId: Scalars["String"]["input"];
  position: PositionType;
  realGamePlayers?: InputMaybe<RealGamePlayerCreateNestedManyWithoutPlayerInput>;
  scoring?: InputMaybe<Scalars["Float"]["input"]>;
  skills?: InputMaybe<Scalars["Float"]["input"]>;
  strength?: InputMaybe<Scalars["Float"]["input"]>;
  team?: InputMaybe<TeamCreateNestedOneWithoutPlayersInput>;
  teamOptaId: Scalars["String"]["input"];
};

export type PlayerCreateWithoutRealGamePlayersInput = {
  attack?: InputMaybe<Scalars["Float"]["input"]>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerCreateNestedManyWithoutPlayerInput>;
  commonCards?: InputMaybe<CommonCardCreateNestedManyWithoutPlayerInput>;
  defense?: InputMaybe<Scalars["Float"]["input"]>;
  firstname: Scalars["String"]["input"];
  impact?: InputMaybe<Scalars["Float"]["input"]>;
  lastname: Scalars["String"]["input"];
  optaPosition: Scalars["String"]["input"];
  playerOptaId: Scalars["String"]["input"];
  position: PositionType;
  scoring?: InputMaybe<Scalars["Float"]["input"]>;
  skills?: InputMaybe<Scalars["Float"]["input"]>;
  strength?: InputMaybe<Scalars["Float"]["input"]>;
  team?: InputMaybe<TeamCreateNestedOneWithoutPlayersInput>;
  teamOptaId: Scalars["String"]["input"];
};

export type PlayerCreateWithoutTeamInput = {
  attack?: InputMaybe<Scalars["Float"]["input"]>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerCreateNestedManyWithoutPlayerInput>;
  commonCards?: InputMaybe<CommonCardCreateNestedManyWithoutPlayerInput>;
  defense?: InputMaybe<Scalars["Float"]["input"]>;
  firstname: Scalars["String"]["input"];
  impact?: InputMaybe<Scalars["Float"]["input"]>;
  lastname: Scalars["String"]["input"];
  optaPosition: Scalars["String"]["input"];
  playerOptaId: Scalars["String"]["input"];
  position: PositionType;
  realGamePlayers?: InputMaybe<RealGamePlayerCreateNestedManyWithoutPlayerInput>;
  scoring?: InputMaybe<Scalars["Float"]["input"]>;
  skills?: InputMaybe<Scalars["Float"]["input"]>;
  strength?: InputMaybe<Scalars["Float"]["input"]>;
  teamOptaId: Scalars["String"]["input"];
};

export type PlayerHasPlayed = {
  __typename?: "PlayerHasPlayed";
  hasPlayed: Scalars["Boolean"]["output"];
  playerOptaId: Scalars["String"]["output"];
};

export type PlayerListRelationFilter = {
  every?: InputMaybe<PlayerWhereInput>;
  none?: InputMaybe<PlayerWhereInput>;
  some?: InputMaybe<PlayerWhereInput>;
};

export type PlayerNullableRelationFilter = {
  is?: InputMaybe<PlayerWhereInput>;
  isNot?: InputMaybe<PlayerWhereInput>;
};

export type PlayerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum PlayerOrderByRelevanceFieldEnum {
  Firstname = "firstname",
  Lastname = "lastname",
  OptaPosition = "optaPosition",
  PlayerOptaId = "playerOptaId",
  TeamOptaId = "teamOptaId",
}

export type PlayerOrderByRelevanceInput = {
  fields: Array<PlayerOrderByRelevanceFieldEnum>;
  search: Scalars["String"]["input"];
  sort: SortOrder;
};

export type PlayerOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<PlayerOrderByRelevanceInput>;
  attack?: InputMaybe<SortOrder>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerOrderByRelationAggregateInput>;
  commonCards?: InputMaybe<CommonCardOrderByRelationAggregateInput>;
  defense?: InputMaybe<SortOrder>;
  firstname?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  impact?: InputMaybe<SortOrder>;
  lastname?: InputMaybe<SortOrder>;
  optaPosition?: InputMaybe<SortOrder>;
  playerOptaId?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  realGamePlayers?: InputMaybe<RealGamePlayerOrderByRelationAggregateInput>;
  scoring?: InputMaybe<SortOrder>;
  skills?: InputMaybe<SortOrder>;
  strength?: InputMaybe<SortOrder>;
  team?: InputMaybe<TeamOrderByWithRelationAndSearchRelevanceInput>;
  teamId?: InputMaybe<SortOrderInput>;
  teamOptaId?: InputMaybe<SortOrder>;
};

export type PlayerRelationFilter = {
  is?: InputMaybe<PlayerWhereInput>;
  isNot?: InputMaybe<PlayerWhereInput>;
};

export enum PlayerScalarFieldEnum {
  Attack = "attack",
  Defense = "defense",
  Firstname = "firstname",
  Id = "id",
  Impact = "impact",
  Lastname = "lastname",
  OptaPosition = "optaPosition",
  PlayerOptaId = "playerOptaId",
  Position = "position",
  Scoring = "scoring",
  Skills = "skills",
  Strength = "strength",
  TeamId = "teamId",
  TeamOptaId = "teamOptaId",
}

export type PlayerScalarWhereInput = {
  AND?: InputMaybe<Array<PlayerScalarWhereInput>>;
  NOT?: InputMaybe<Array<PlayerScalarWhereInput>>;
  OR?: InputMaybe<Array<PlayerScalarWhereInput>>;
  attack?: InputMaybe<FloatFilter>;
  defense?: InputMaybe<FloatFilter>;
  firstname?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  impact?: InputMaybe<FloatFilter>;
  lastname?: InputMaybe<StringFilter>;
  optaPosition?: InputMaybe<StringFilter>;
  playerOptaId?: InputMaybe<StringFilter>;
  position?: InputMaybe<EnumPositionTypeFilter>;
  scoring?: InputMaybe<FloatFilter>;
  skills?: InputMaybe<FloatFilter>;
  strength?: InputMaybe<FloatFilter>;
  teamId?: InputMaybe<IntNullableFilter>;
  teamOptaId?: InputMaybe<StringFilter>;
};

export type PlayerScore = {
  __typename?: "PlayerScore";
  playerPoints?: Maybe<Scalars["Float"]["output"]>;
  playerStats?: Maybe<Scalars["String"]["output"]>;
};

export type PlayerScoringGameType = {
  __typename?: "PlayerScoringGameType";
  attack?: Maybe<Scalars["String"]["output"]>;
  defense?: Maybe<Scalars["String"]["output"]>;
  game_date?: Maybe<Scalars["String"]["output"]>;
  impact?: Maybe<Scalars["String"]["output"]>;
  metadata_total?: Maybe<Scalars["String"]["output"]>;
  skills?: Maybe<Scalars["String"]["output"]>;
  strength?: Maybe<Scalars["String"]["output"]>;
};

export type PlayerScoringType = {
  __typename?: "PlayerScoringType";
  appearances?: Maybe<Scalars["String"]["output"]>;
  attack: Scalars["Float"]["output"];
  defense: Scalars["Float"]["output"];
  evolution?: Maybe<Scalars["String"]["output"]>;
  impact: Scalars["Float"]["output"];
  last_scoring?: Maybe<Scalars["Float"]["output"]>;
  minutes_played_total?: Maybe<Scalars["String"]["output"]>;
  nb_games?: Maybe<Array<PlayerScoringGameType>>;
  player_id?: Maybe<Scalars["Int"]["output"]>;
  red_cards?: Maybe<Scalars["String"]["output"]>;
  scoring?: Maybe<Scalars["Float"]["output"]>;
  skills: Scalars["Float"]["output"];
  strength: Scalars["Float"]["output"];
  tries?: Maybe<Scalars["String"]["output"]>;
  yellow_cards?: Maybe<Scalars["String"]["output"]>;
};

export type PlayerUpdateInput = {
  attack?: InputMaybe<FloatFieldUpdateOperationsInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerUpdateManyWithoutPlayerNestedInput>;
  commonCards?: InputMaybe<CommonCardUpdateManyWithoutPlayerNestedInput>;
  defense?: InputMaybe<FloatFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  impact?: InputMaybe<FloatFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  optaPosition?: InputMaybe<StringFieldUpdateOperationsInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<EnumPositionTypeFieldUpdateOperationsInput>;
  realGamePlayers?: InputMaybe<RealGamePlayerUpdateManyWithoutPlayerNestedInput>;
  scoring?: InputMaybe<FloatFieldUpdateOperationsInput>;
  skills?: InputMaybe<FloatFieldUpdateOperationsInput>;
  strength?: InputMaybe<FloatFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutPlayersNestedInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PlayerUpdateManyMutationInput = {
  attack?: InputMaybe<FloatFieldUpdateOperationsInput>;
  defense?: InputMaybe<FloatFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  impact?: InputMaybe<FloatFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  optaPosition?: InputMaybe<StringFieldUpdateOperationsInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<EnumPositionTypeFieldUpdateOperationsInput>;
  scoring?: InputMaybe<FloatFieldUpdateOperationsInput>;
  skills?: InputMaybe<FloatFieldUpdateOperationsInput>;
  strength?: InputMaybe<FloatFieldUpdateOperationsInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PlayerUpdateManyWithWhereWithoutTeamInput = {
  data: PlayerUpdateManyMutationInput;
  where: PlayerScalarWhereInput;
};

export type PlayerUpdateManyWithoutTeamNestedInput = {
  connect?: InputMaybe<Array<PlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlayerCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<PlayerCreateWithoutTeamInput>>;
  createMany?: InputMaybe<PlayerCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<PlayerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PlayerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PlayerWhereUniqueInput>>;
  set?: InputMaybe<Array<PlayerWhereUniqueInput>>;
  update?: InputMaybe<Array<PlayerUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: InputMaybe<Array<PlayerUpdateManyWithWhereWithoutTeamInput>>;
  upsert?: InputMaybe<Array<PlayerUpsertWithWhereUniqueWithoutTeamInput>>;
};

export type PlayerUpdateOneRequiredWithoutChampionshipSeasonTeamPlayersNestedInput = {
  connect?: InputMaybe<PlayerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlayerCreateOrConnectWithoutChampionshipSeasonTeamPlayersInput>;
  create?: InputMaybe<PlayerCreateWithoutChampionshipSeasonTeamPlayersInput>;
  update?: InputMaybe<PlayerUpdateToOneWithWhereWithoutChampionshipSeasonTeamPlayersInput>;
  upsert?: InputMaybe<PlayerUpsertWithoutChampionshipSeasonTeamPlayersInput>;
};

export type PlayerUpdateOneRequiredWithoutCommonCardsNestedInput = {
  connect?: InputMaybe<PlayerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlayerCreateOrConnectWithoutCommonCardsInput>;
  create?: InputMaybe<PlayerCreateWithoutCommonCardsInput>;
  update?: InputMaybe<PlayerUpdateToOneWithWhereWithoutCommonCardsInput>;
  upsert?: InputMaybe<PlayerUpsertWithoutCommonCardsInput>;
};

export type PlayerUpdateOneWithoutRealGamePlayersNestedInput = {
  connect?: InputMaybe<PlayerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlayerCreateOrConnectWithoutRealGamePlayersInput>;
  create?: InputMaybe<PlayerCreateWithoutRealGamePlayersInput>;
  delete?: InputMaybe<PlayerWhereInput>;
  disconnect?: InputMaybe<PlayerWhereInput>;
  update?: InputMaybe<PlayerUpdateToOneWithWhereWithoutRealGamePlayersInput>;
  upsert?: InputMaybe<PlayerUpsertWithoutRealGamePlayersInput>;
};

export type PlayerUpdateToOneWithWhereWithoutChampionshipSeasonTeamPlayersInput = {
  data: PlayerUpdateWithoutChampionshipSeasonTeamPlayersInput;
  where?: InputMaybe<PlayerWhereInput>;
};

export type PlayerUpdateToOneWithWhereWithoutCommonCardsInput = {
  data: PlayerUpdateWithoutCommonCardsInput;
  where?: InputMaybe<PlayerWhereInput>;
};

export type PlayerUpdateToOneWithWhereWithoutRealGamePlayersInput = {
  data: PlayerUpdateWithoutRealGamePlayersInput;
  where?: InputMaybe<PlayerWhereInput>;
};

export type PlayerUpdateWithWhereUniqueWithoutTeamInput = {
  data: PlayerUpdateWithoutTeamInput;
  where: PlayerWhereUniqueInput;
};

export type PlayerUpdateWithoutChampionshipSeasonTeamPlayersInput = {
  attack?: InputMaybe<FloatFieldUpdateOperationsInput>;
  commonCards?: InputMaybe<CommonCardUpdateManyWithoutPlayerNestedInput>;
  defense?: InputMaybe<FloatFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  impact?: InputMaybe<FloatFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  optaPosition?: InputMaybe<StringFieldUpdateOperationsInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<EnumPositionTypeFieldUpdateOperationsInput>;
  realGamePlayers?: InputMaybe<RealGamePlayerUpdateManyWithoutPlayerNestedInput>;
  scoring?: InputMaybe<FloatFieldUpdateOperationsInput>;
  skills?: InputMaybe<FloatFieldUpdateOperationsInput>;
  strength?: InputMaybe<FloatFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutPlayersNestedInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PlayerUpdateWithoutCommonCardsInput = {
  attack?: InputMaybe<FloatFieldUpdateOperationsInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerUpdateManyWithoutPlayerNestedInput>;
  defense?: InputMaybe<FloatFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  impact?: InputMaybe<FloatFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  optaPosition?: InputMaybe<StringFieldUpdateOperationsInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<EnumPositionTypeFieldUpdateOperationsInput>;
  realGamePlayers?: InputMaybe<RealGamePlayerUpdateManyWithoutPlayerNestedInput>;
  scoring?: InputMaybe<FloatFieldUpdateOperationsInput>;
  skills?: InputMaybe<FloatFieldUpdateOperationsInput>;
  strength?: InputMaybe<FloatFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutPlayersNestedInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PlayerUpdateWithoutRealGamePlayersInput = {
  attack?: InputMaybe<FloatFieldUpdateOperationsInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerUpdateManyWithoutPlayerNestedInput>;
  commonCards?: InputMaybe<CommonCardUpdateManyWithoutPlayerNestedInput>;
  defense?: InputMaybe<FloatFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  impact?: InputMaybe<FloatFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  optaPosition?: InputMaybe<StringFieldUpdateOperationsInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<EnumPositionTypeFieldUpdateOperationsInput>;
  scoring?: InputMaybe<FloatFieldUpdateOperationsInput>;
  skills?: InputMaybe<FloatFieldUpdateOperationsInput>;
  strength?: InputMaybe<FloatFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutPlayersNestedInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PlayerUpdateWithoutTeamInput = {
  attack?: InputMaybe<FloatFieldUpdateOperationsInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerUpdateManyWithoutPlayerNestedInput>;
  commonCards?: InputMaybe<CommonCardUpdateManyWithoutPlayerNestedInput>;
  defense?: InputMaybe<FloatFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  impact?: InputMaybe<FloatFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  optaPosition?: InputMaybe<StringFieldUpdateOperationsInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<EnumPositionTypeFieldUpdateOperationsInput>;
  realGamePlayers?: InputMaybe<RealGamePlayerUpdateManyWithoutPlayerNestedInput>;
  scoring?: InputMaybe<FloatFieldUpdateOperationsInput>;
  skills?: InputMaybe<FloatFieldUpdateOperationsInput>;
  strength?: InputMaybe<FloatFieldUpdateOperationsInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PlayerUpsertWithWhereUniqueWithoutTeamInput = {
  create: PlayerCreateWithoutTeamInput;
  update: PlayerUpdateWithoutTeamInput;
  where: PlayerWhereUniqueInput;
};

export type PlayerUpsertWithoutChampionshipSeasonTeamPlayersInput = {
  create: PlayerCreateWithoutChampionshipSeasonTeamPlayersInput;
  update: PlayerUpdateWithoutChampionshipSeasonTeamPlayersInput;
  where?: InputMaybe<PlayerWhereInput>;
};

export type PlayerUpsertWithoutCommonCardsInput = {
  create: PlayerCreateWithoutCommonCardsInput;
  update: PlayerUpdateWithoutCommonCardsInput;
  where?: InputMaybe<PlayerWhereInput>;
};

export type PlayerUpsertWithoutRealGamePlayersInput = {
  create: PlayerCreateWithoutRealGamePlayersInput;
  update: PlayerUpdateWithoutRealGamePlayersInput;
  where?: InputMaybe<PlayerWhereInput>;
};

export type PlayerWhereInput = {
  AND?: InputMaybe<Array<PlayerWhereInput>>;
  NOT?: InputMaybe<Array<PlayerWhereInput>>;
  OR?: InputMaybe<Array<PlayerWhereInput>>;
  attack?: InputMaybe<FloatFilter>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerListRelationFilter>;
  commonCards?: InputMaybe<CommonCardListRelationFilter>;
  defense?: InputMaybe<FloatFilter>;
  firstname?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  impact?: InputMaybe<FloatFilter>;
  lastname?: InputMaybe<StringFilter>;
  optaPosition?: InputMaybe<StringFilter>;
  playerOptaId?: InputMaybe<StringFilter>;
  position?: InputMaybe<EnumPositionTypeFilter>;
  realGamePlayers?: InputMaybe<RealGamePlayerListRelationFilter>;
  scoring?: InputMaybe<FloatFilter>;
  skills?: InputMaybe<FloatFilter>;
  strength?: InputMaybe<FloatFilter>;
  team?: InputMaybe<TeamNullableRelationFilter>;
  teamId?: InputMaybe<IntNullableFilter>;
  teamOptaId?: InputMaybe<StringFilter>;
};

export type PlayerWhereUniqueInput = {
  AND?: InputMaybe<Array<PlayerWhereInput>>;
  NOT?: InputMaybe<Array<PlayerWhereInput>>;
  OR?: InputMaybe<Array<PlayerWhereInput>>;
  attack?: InputMaybe<FloatFilter>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerListRelationFilter>;
  commonCards?: InputMaybe<CommonCardListRelationFilter>;
  defense?: InputMaybe<FloatFilter>;
  firstname?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  impact?: InputMaybe<FloatFilter>;
  lastname?: InputMaybe<StringFilter>;
  optaPosition?: InputMaybe<StringFilter>;
  playerOptaId?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<EnumPositionTypeFilter>;
  realGamePlayers?: InputMaybe<RealGamePlayerListRelationFilter>;
  scoring?: InputMaybe<FloatFilter>;
  skills?: InputMaybe<FloatFilter>;
  strength?: InputMaybe<FloatFilter>;
  team?: InputMaybe<TeamNullableRelationFilter>;
  teamId?: InputMaybe<IntNullableFilter>;
  teamOptaId?: InputMaybe<StringFilter>;
};

export enum PositionType {
  Back = "BACK",
  Extra = "EXTRA",
  Firstrow = "FIRSTROW",
  Half = "HALF",
  Secondrow = "SECONDROW",
}

export type Query = {
  __typename?: "Query";
  Championship?: Maybe<Championship>;
  CommonCard?: Maybe<CommonCard>;
  Composition: Composition;
  DetailedTournamentVariant?: Maybe<TournamentVariant>;
  GameWeekReward?: Maybe<GameWeekReward>;
  Language?: Maybe<Language>;
  Player: Player;
  RealGame?: Maybe<RealGame>;
  Reward?: Maybe<Reward>;
  RewardClaim?: Maybe<RewardClaim>;
  Tournament?: Maybe<Tournament>;
  TournamentGameWeek?: Maybe<TournamentGameWeek>;
  TournamentVariant?: Maybe<TournamentVariant>;
  Translatable?: Maybe<Translatable>;
  Translation?: Maybe<Translation>;
  _allChampionshipSeasonsMeta?: Maybe<ListMetadata>;
  _allCompositionsMeta: ListMetadata;
  _allGameWeekRewardsMeta?: Maybe<ListMetadata>;
  _allLanguagesMeta?: Maybe<ListMetadata>;
  _allRealGamesMeta?: Maybe<ListMetadata>;
  _allRewardClaimsMeta: ListMetadata;
  _allRewardsMeta?: Maybe<ListMetadata>;
  _allTeamsMeta?: Maybe<ListMetadata>;
  _allTournamentChampionshipsMeta: ListMetadata;
  _allTournamentGameWeeksMeta?: Maybe<ListMetadata>;
  _allTournamentVariantsMeta?: Maybe<ListMetadata>;
  _allTournamentsMeta?: Maybe<ListMetadata>;
  _allTranslatablesMeta?: Maybe<ListMetadata>;
  _allTranslationsMeta?: Maybe<ListMetadata>;
  _getManyChampionshipSeasonsMeta?: Maybe<ListMetadata>;
  _getManyChampionshipsMeta?: Maybe<ListMetadata>;
  _getManyCommonCardsMeta: ListMetadata;
  _getManyCompositionsMeta: ListMetadata;
  _getManyGameWeekRewardsMeta?: Maybe<ListMetadata>;
  _getManyLanguagesMeta?: Maybe<ListMetadata>;
  _getManyPlayersMeta?: Maybe<ListMetadata>;
  _getManyRealGamesMeta?: Maybe<ListMetadata>;
  _getManyRewardClaimsMeta: ListMetadata;
  _getManyRewardsMeta?: Maybe<ListMetadata>;
  _getManyTeamsMeta?: Maybe<ListMetadata>;
  _getManyTournamentGameWeeksMeta?: Maybe<ListMetadata>;
  _getManyTournamentVariantsMeta?: Maybe<ListMetadata>;
  _getManyTournamentsMeta?: Maybe<ListMetadata>;
  _getManyTranslatablesMeta?: Maybe<ListMetadata>;
  _getManyTranslationsMeta?: Maybe<ListMetadata>;
  allChampionshipSeasons?: Maybe<Array<ChampionshipSeason>>;
  allChampionships?: Maybe<Array<Championship>>;
  allCommonCards: Array<CommonCard>;
  allCompositions: Array<Composition>;
  allGameWeekRewards?: Maybe<Array<GameWeekReward>>;
  allLanguages?: Maybe<Array<Language>>;
  allPlayers?: Maybe<Array<Player>>;
  allRealGames?: Maybe<Array<RealGame>>;
  allRewardClaims?: Maybe<Array<RewardClaim>>;
  allRewards?: Maybe<Array<Reward>>;
  allTeams?: Maybe<Array<Team>>;
  allTournamentChampionships?: Maybe<Array<TournamentChampionship>>;
  allTournamentGameWeeks?: Maybe<Array<TournamentGameWeek>>;
  allTournamentVariants?: Maybe<Array<TournamentVariant>>;
  allTournaments?: Maybe<Array<Tournament>>;
  allTranslatables?: Maybe<Array<Translatable>>;
  allTranslations?: Maybe<Array<Translation>>;
  championshipSeason?: Maybe<ChampionshipSeason>;
  getCommonCards?: Maybe<Array<CommonCardHasPlayed>>;
  getCompositionsForCurrentUser: Array<Composition>;
  getLeaderboard: Array<Composition>;
  getManyChampionshipSeasons?: Maybe<Array<ChampionshipSeason>>;
  getManyChampionships?: Maybe<Array<Championship>>;
  getManyCommonCards: Array<CommonCard>;
  getManyCompositions: Array<Composition>;
  getManyGameWeekRewards?: Maybe<Array<GameWeekReward>>;
  getManyLanguages?: Maybe<Array<Language>>;
  getManyPlayers?: Maybe<Array<Player>>;
  getManyRealGamePlayers: Array<RealGamePlayer>;
  getManyRealGames?: Maybe<Array<RealGame>>;
  getManyRewardClaims?: Maybe<Array<RewardClaim>>;
  getManyRewards?: Maybe<Array<Reward>>;
  getManyTeams?: Maybe<Array<Team>>;
  getManyTournamentGameWeeks?: Maybe<Array<TournamentGameWeek>>;
  getManyTournamentVariants?: Maybe<Array<TournamentVariant>>;
  getManyTournaments?: Maybe<Array<Tournament>>;
  getManyTranslatables?: Maybe<Array<Translatable>>;
  getManyTranslations?: Maybe<Array<Translation>>;
  hasPlayersPlayedDuringGameWeek: Array<PlayerHasPlayed>;
  marketPlaceCardPlayerCache?: Maybe<MarketPlaceCardPlayerCache>;
  playerScoringMarketPlace: PlayerScoringType;
  realGameByRealGameOptaId?: Maybe<RealGame>;
  realGamePlayersByRealGame?: Maybe<Array<RealGamePlayer>>;
  realGamePlayersByRealGameOptaId?: Maybe<Array<RealGamePlayer>>;
  rewardClaimsFromCurrentUser?: Maybe<Array<RewardClaim>>;
  teamPlayers: Array<Player>;
  tournamentChampionship?: Maybe<TournamentChampionship>;
  user?: Maybe<MarketPlaceUserType>;
  userCompositions: Array<Composition>;
  userCompositionsCount: Scalars["String"]["output"];
  whoAmI?: Maybe<Scalars["String"]["output"]>;
  whoAmIAdmin?: Maybe<Scalars["String"]["output"]>;
};

export type QueryChampionshipArgs = {
  where: ChampionshipWhereUniqueInput;
};

export type QueryCommonCardArgs = {
  where: CommonCardWhereUniqueInput;
};

export type QueryCompositionArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryDetailedTournamentVariantArgs = {
  status?: InputMaybe<Scalars["String"]["input"]>;
  tournamentVariantId: Scalars["Float"]["input"];
};

export type QueryGameWeekRewardArgs = {
  id?: InputMaybe<Scalars["Float"]["input"]>;
  where?: InputMaybe<GameWeekRewardWhereUniqueInput>;
};

export type QueryLanguageArgs = {
  where: LanguageWhereUniqueInput;
};

export type QueryPlayerArgs = {
  where: PlayerWhereUniqueInput;
};

export type QueryRealGameArgs = {
  where: RealGameWhereUniqueInput;
};

export type QueryRewardArgs = {
  where: RewardWhereUniqueInput;
};

export type QueryRewardClaimArgs = {
  where: RewardClaimWhereUniqueInput;
};

export type QueryTournamentArgs = {
  where: TournamentWhereUniqueInput;
};

export type QueryTournamentGameWeekArgs = {
  where: TournamentGameWeekWhereUniqueInput;
};

export type QueryTournamentVariantArgs = {
  id?: InputMaybe<Scalars["Float"]["input"]>;
  where?: InputMaybe<TournamentVariantWhereUniqueInput>;
};

export type QueryTranslatableArgs = {
  where: TranslatableWhereUniqueInput;
};

export type QueryTranslationArgs = {
  where: TranslationWhereUniqueInput;
};

export type Query_AllChampionshipSeasonsMetaArgs = {
  cursor?: InputMaybe<ChampionshipSeasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChampionshipSeasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChampionshipSeasonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChampionshipSeasonWhereInput>;
};

export type Query_AllCompositionsMetaArgs = {
  filter?: InputMaybe<CompositionWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_AllGameWeekRewardsMetaArgs = {
  filter?: InputMaybe<GameWeekRewardWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_AllLanguagesMetaArgs = {
  filter?: InputMaybe<LanguageWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_AllRealGamesMetaArgs = {
  filter?: InputMaybe<RealGameWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_AllRewardClaimsMetaArgs = {
  filter?: InputMaybe<RewardClaimWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_AllRewardsMetaArgs = {
  filter?: InputMaybe<RewardWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_AllTeamsMetaArgs = {
  filter?: InputMaybe<TeamWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_AllTournamentChampionshipsMetaArgs = {
  filter?: InputMaybe<TournamentChampionshipWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_AllTournamentGameWeeksMetaArgs = {
  filter?: InputMaybe<TournamentGameWeekWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_AllTournamentVariantsMetaArgs = {
  filter?: InputMaybe<TournamentVariantWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_AllTournamentsMetaArgs = {
  filter?: InputMaybe<TournamentWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_AllTranslatablesMetaArgs = {
  filter?: InputMaybe<TranslatableWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_AllTranslationsMetaArgs = {
  filter?: InputMaybe<TranslationWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Query_GetManyChampionshipSeasonsMetaArgs = {
  cursor?: InputMaybe<ChampionshipSeasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChampionshipSeasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChampionshipSeasonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChampionshipSeasonWhereInput>;
};

export type Query_GetManyChampionshipsMetaArgs = {
  cursor?: InputMaybe<ChampionshipWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChampionshipScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChampionshipOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChampionshipWhereInput>;
};

export type Query_GetManyCommonCardsMetaArgs = {
  cursor?: InputMaybe<CommonCardWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommonCardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommonCardOrderByWithRelationAndSearchRelevanceInput>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CommonCardWhereInput>;
};

export type Query_GetManyCompositionsMetaArgs = {
  cursor?: InputMaybe<CompositionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompositionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompositionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CompositionWhereInput>;
};

export type Query_GetManyGameWeekRewardsMetaArgs = {
  cursor?: InputMaybe<GameWeekRewardWhereUniqueInput>;
  distinct?: InputMaybe<Array<GameWeekRewardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GameWeekRewardOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<GameWeekRewardWhereInput>;
};

export type Query_GetManyLanguagesMetaArgs = {
  cursor?: InputMaybe<LanguageWhereUniqueInput>;
  distinct?: InputMaybe<Array<LanguageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LanguageOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LanguageWhereInput>;
};

export type Query_GetManyPlayersMetaArgs = {
  cursor?: InputMaybe<PlayerWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlayerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlayerOrderByWithRelationAndSearchRelevanceInput>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PlayerWhereInput>;
};

export type Query_GetManyRealGamesMetaArgs = {
  cursor?: InputMaybe<RealGameWhereUniqueInput>;
  distinct?: InputMaybe<Array<RealGameScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RealGameOrderByWithRelationAndSearchRelevanceInput>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<RealGameWhereInput>;
};

export type Query_GetManyRewardClaimsMetaArgs = {
  cursor?: InputMaybe<RewardClaimWhereUniqueInput>;
  distinct?: InputMaybe<Array<RewardClaimScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RewardClaimOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<RewardClaimWhereInput>;
};

export type Query_GetManyRewardsMetaArgs = {
  cursor?: InputMaybe<RewardWhereUniqueInput>;
  distinct?: InputMaybe<Array<RewardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RewardOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<RewardWhereInput>;
};

export type Query_GetManyTeamsMetaArgs = {
  cursor?: InputMaybe<TeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamOrderByWithRelationAndSearchRelevanceInput>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TeamWhereInput>;
};

export type Query_GetManyTournamentGameWeeksMetaArgs = {
  cursor?: InputMaybe<TournamentGameWeekWhereUniqueInput>;
  distinct?: InputMaybe<Array<TournamentGameWeekScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TournamentGameWeekOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TournamentGameWeekWhereInput>;
};

export type Query_GetManyTournamentVariantsMetaArgs = {
  cursor?: InputMaybe<TournamentVariantWhereUniqueInput>;
  distinct?: InputMaybe<Array<TournamentVariantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TournamentVariantOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TournamentVariantWhereInput>;
};

export type Query_GetManyTournamentsMetaArgs = {
  cursor?: InputMaybe<TournamentWhereUniqueInput>;
  distinct?: InputMaybe<Array<TournamentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TournamentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TournamentWhereInput>;
};

export type Query_GetManyTranslatablesMetaArgs = {
  cursor?: InputMaybe<TranslatableWhereUniqueInput>;
  distinct?: InputMaybe<Array<TranslatableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TranslatableOrderByWithRelationAndSearchRelevanceInput>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TranslatableWhereInput>;
};

export type Query_GetManyTranslationsMetaArgs = {
  cursor?: InputMaybe<TranslationWhereUniqueInput>;
  distinct?: InputMaybe<Array<TranslationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TranslationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TranslationWhereInput>;
};

export type QueryAllCompositionsArgs = {
  filter?: InputMaybe<CompositionWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllGameWeekRewardsArgs = {
  filter?: InputMaybe<GameWeekRewardWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllLanguagesArgs = {
  filter?: InputMaybe<LanguageWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllPlayersArgs = {
  cursor?: InputMaybe<PlayerWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlayerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlayerOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PlayerWhereInput>;
};

export type QueryAllRealGamesArgs = {
  filter?: InputMaybe<RealGameWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllRewardClaimsArgs = {
  filter?: InputMaybe<RewardClaimWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllRewardsArgs = {
  filter?: InputMaybe<RewardWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllTeamsArgs = {
  filter?: InputMaybe<TeamWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllTournamentChampionshipsArgs = {
  filter?: InputMaybe<TournamentChampionshipWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllTournamentGameWeeksArgs = {
  filter?: InputMaybe<TournamentGameWeekWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllTournamentVariantsArgs = {
  filter?: InputMaybe<TournamentVariantWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllTournamentsArgs = {
  filter?: InputMaybe<TournamentWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllTranslatablesArgs = {
  filter?: InputMaybe<TranslatableWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAllTranslationsArgs = {
  filter?: InputMaybe<TranslationWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryChampionshipSeasonArgs = {
  where: ChampionshipSeasonWhereUniqueInput;
};

export type QueryGetCommonCardsArgs = {
  cursor?: InputMaybe<CommonCardWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommonCardScalarFieldEnum>>;
  gameWeekId?: InputMaybe<Scalars["Float"]["input"]>;
  orderBy?: InputMaybe<Array<CommonCardOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CommonCardWhereInput>;
};

export type QueryGetCompositionsForCurrentUserArgs = {
  status?: InputMaybe<Array<TournamentGameWeekStatus>>;
};

export type QueryGetLeaderboardArgs = {
  id: Scalars["Float"]["input"];
  take: Scalars["Float"]["input"];
};

export type QueryGetManyChampionshipSeasonsArgs = {
  cursor?: InputMaybe<ChampionshipSeasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChampionshipSeasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChampionshipSeasonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChampionshipSeasonWhereInput>;
};

export type QueryGetManyChampionshipsArgs = {
  cursor?: InputMaybe<ChampionshipWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChampionshipScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChampionshipOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChampionshipWhereInput>;
};

export type QueryGetManyCommonCardsArgs = {
  cursor?: InputMaybe<CommonCardWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommonCardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommonCardOrderByWithRelationAndSearchRelevanceInput>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CommonCardWhereInput>;
};

export type QueryGetManyCompositionsArgs = {
  cursor?: InputMaybe<CompositionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompositionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompositionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CompositionWhereInput>;
};

export type QueryGetManyGameWeekRewardsArgs = {
  cursor?: InputMaybe<GameWeekRewardWhereUniqueInput>;
  distinct?: InputMaybe<Array<GameWeekRewardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GameWeekRewardOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<GameWeekRewardWhereInput>;
};

export type QueryGetManyLanguagesArgs = {
  cursor?: InputMaybe<LanguageWhereUniqueInput>;
  distinct?: InputMaybe<Array<LanguageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LanguageOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LanguageWhereInput>;
};

export type QueryGetManyPlayersArgs = {
  cursor?: InputMaybe<PlayerWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlayerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlayerOrderByWithRelationAndSearchRelevanceInput>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PlayerWhereInput>;
};

export type QueryGetManyRealGamePlayersArgs = {
  cursor?: InputMaybe<RealGamePlayerWhereUniqueInput>;
  distinct?: InputMaybe<Array<RealGamePlayerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RealGamePlayerOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<RealGamePlayerWhereInput>;
};

export type QueryGetManyRealGamesArgs = {
  cursor?: InputMaybe<RealGameWhereUniqueInput>;
  distinct?: InputMaybe<Array<RealGameScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RealGameOrderByWithRelationAndSearchRelevanceInput>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<RealGameWhereInput>;
};

export type QueryGetManyRewardClaimsArgs = {
  cursor?: InputMaybe<RewardClaimWhereUniqueInput>;
  distinct?: InputMaybe<Array<RewardClaimScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RewardClaimOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<RewardClaimWhereInput>;
};

export type QueryGetManyRewardsArgs = {
  cursor?: InputMaybe<RewardWhereUniqueInput>;
  distinct?: InputMaybe<Array<RewardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RewardOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<RewardWhereInput>;
};

export type QueryGetManyTeamsArgs = {
  cursor?: InputMaybe<TeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamOrderByWithRelationAndSearchRelevanceInput>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TeamWhereInput>;
};

export type QueryGetManyTournamentGameWeeksArgs = {
  cursor?: InputMaybe<TournamentGameWeekWhereUniqueInput>;
  distinct?: InputMaybe<Array<TournamentGameWeekScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TournamentGameWeekOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TournamentGameWeekWhereInput>;
};

export type QueryGetManyTournamentVariantsArgs = {
  cursor?: InputMaybe<TournamentVariantWhereUniqueInput>;
  distinct?: InputMaybe<Array<TournamentVariantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TournamentVariantOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TournamentVariantWhereInput>;
};

export type QueryGetManyTournamentsArgs = {
  cursor?: InputMaybe<TournamentWhereUniqueInput>;
  distinct?: InputMaybe<Array<TournamentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TournamentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TournamentWhereInput>;
};

export type QueryGetManyTranslatablesArgs = {
  cursor?: InputMaybe<TranslatableWhereUniqueInput>;
  distinct?: InputMaybe<Array<TranslatableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TranslatableOrderByWithRelationAndSearchRelevanceInput>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TranslatableWhereInput>;
};

export type QueryGetManyTranslationsArgs = {
  cursor?: InputMaybe<TranslationWhereUniqueInput>;
  distinct?: InputMaybe<Array<TranslationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TranslationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TranslationWhereInput>;
};

export type QueryHasPlayersPlayedDuringGameWeekArgs = {
  gameWeekId: Scalars["Int"]["input"];
  playerOptaIds: Array<Scalars["String"]["input"]>;
};

export type QueryMarketPlaceCardPlayerCacheArgs = {
  cardTokenId: Scalars["String"]["input"];
};

export type QueryPlayerScoringMarketPlaceArgs = {
  playerId: Scalars["String"]["input"];
};

export type QueryRealGameByRealGameOptaIdArgs = {
  realGameOptaId: Scalars["String"]["input"];
};

export type QueryRealGamePlayersByRealGameArgs = {
  realGameId: Scalars["Float"]["input"];
};

export type QueryRealGamePlayersByRealGameOptaIdArgs = {
  realGameOptaId: Scalars["String"]["input"];
};

export type QueryTeamPlayersArgs = {
  teamId: Scalars["Float"]["input"];
};

export type QueryTournamentChampionshipArgs = {
  where: TournamentChampionshipWhereUniqueInput;
};

export type QueryUserCompositionsArgs = {
  marketPlaceUserId: Scalars["String"]["input"];
};

export type QueryUserCompositionsCountArgs = {
  marketPlaceUserId: Scalars["String"]["input"];
};

export enum QueryMode {
  Default = "default",
  Insensitive = "insensitive",
}

export type RealGame = {
  __typename?: "RealGame";
  _count: RealGameCount;
  awayScore: Scalars["Int"]["output"];
  awayTeam?: Maybe<Team>;
  awayTeamId?: Maybe<Scalars["Int"]["output"]>;
  awayTeamName?: Maybe<Scalars["String"]["output"]>;
  awayTeamOptaId?: Maybe<Scalars["String"]["output"]>;
  championshipSeason: ChampionshipSeason;
  championshipSeasonId: Scalars["Int"]["output"];
  compName: Scalars["String"]["output"];
  date?: Maybe<Scalars["String"]["output"]>;
  datetime?: Maybe<Scalars["Timestamp"]["output"]>;
  homeScore: Scalars["Int"]["output"];
  homeTeam?: Maybe<Team>;
  homeTeamId?: Maybe<Scalars["Int"]["output"]>;
  homeTeamName: Scalars["String"]["output"];
  homeTeamOptaId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  liveGameScore?: Maybe<Scalars["String"]["output"]>;
  realGameOptaId: Scalars["String"]["output"];
  realGamePlayers?: Maybe<Array<RealGamePlayer>>;
  round: Scalars["Int"]["output"];
  roundType: RoundType;
  seasonOptaId: Scalars["String"]["output"];
  status: RealGameStatus;
  tournamentGameWeeks?: Maybe<Array<TournamentGameWeek>>;
  tournamentGameWeeksRealGames?: Maybe<Array<TournamentGameWeekRealGame>>;
  winnerTeamOptaId?: Maybe<Scalars["String"]["output"]>;
};

export type RealGameCount = {
  __typename?: "RealGameCount";
  realGamePlayers: Scalars["Int"]["output"];
  tournamentGameWeeksRealGames: Scalars["Int"]["output"];
};

export type RealGameCreateManyAwayTeamInput = {
  awayScore?: InputMaybe<Scalars["Int"]["input"]>;
  awayTeamName?: InputMaybe<Scalars["String"]["input"]>;
  awayTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
  championshipSeasonId: Scalars["Int"]["input"];
  compName: Scalars["String"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  datetime?: InputMaybe<Scalars["Timestamp"]["input"]>;
  homeScore?: InputMaybe<Scalars["Int"]["input"]>;
  homeTeamId?: InputMaybe<Scalars["Int"]["input"]>;
  homeTeamName: Scalars["String"]["input"];
  homeTeamOptaId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  realGameOptaId: Scalars["String"]["input"];
  round?: InputMaybe<Scalars["Int"]["input"]>;
  roundType?: InputMaybe<RoundType>;
  seasonOptaId: Scalars["String"]["input"];
  status: RealGameStatus;
  winnerTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type RealGameCreateManyAwayTeamInputEnvelope = {
  data: Array<RealGameCreateManyAwayTeamInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RealGameCreateManyChampionshipSeasonInput = {
  awayScore?: InputMaybe<Scalars["Int"]["input"]>;
  awayTeamId?: InputMaybe<Scalars["Int"]["input"]>;
  awayTeamName?: InputMaybe<Scalars["String"]["input"]>;
  awayTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
  compName: Scalars["String"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  datetime?: InputMaybe<Scalars["Timestamp"]["input"]>;
  homeScore?: InputMaybe<Scalars["Int"]["input"]>;
  homeTeamId?: InputMaybe<Scalars["Int"]["input"]>;
  homeTeamName: Scalars["String"]["input"];
  homeTeamOptaId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  realGameOptaId: Scalars["String"]["input"];
  round?: InputMaybe<Scalars["Int"]["input"]>;
  roundType?: InputMaybe<RoundType>;
  seasonOptaId: Scalars["String"]["input"];
  status: RealGameStatus;
  winnerTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type RealGameCreateManyChampionshipSeasonInputEnvelope = {
  data: Array<RealGameCreateManyChampionshipSeasonInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RealGameCreateManyHomeTeamInput = {
  awayScore?: InputMaybe<Scalars["Int"]["input"]>;
  awayTeamId?: InputMaybe<Scalars["Int"]["input"]>;
  awayTeamName?: InputMaybe<Scalars["String"]["input"]>;
  awayTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
  championshipSeasonId: Scalars["Int"]["input"];
  compName: Scalars["String"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  datetime?: InputMaybe<Scalars["Timestamp"]["input"]>;
  homeScore?: InputMaybe<Scalars["Int"]["input"]>;
  homeTeamName: Scalars["String"]["input"];
  homeTeamOptaId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  realGameOptaId: Scalars["String"]["input"];
  round?: InputMaybe<Scalars["Int"]["input"]>;
  roundType?: InputMaybe<RoundType>;
  seasonOptaId: Scalars["String"]["input"];
  status: RealGameStatus;
  winnerTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type RealGameCreateManyHomeTeamInputEnvelope = {
  data: Array<RealGameCreateManyHomeTeamInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RealGameCreateNestedManyWithoutAwayTeamInput = {
  connect?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RealGameCreateOrConnectWithoutAwayTeamInput>>;
  create?: InputMaybe<Array<RealGameCreateWithoutAwayTeamInput>>;
  createMany?: InputMaybe<RealGameCreateManyAwayTeamInputEnvelope>;
};

export type RealGameCreateNestedManyWithoutChampionshipSeasonInput = {
  connect?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RealGameCreateOrConnectWithoutChampionshipSeasonInput>>;
  create?: InputMaybe<Array<RealGameCreateWithoutChampionshipSeasonInput>>;
  createMany?: InputMaybe<RealGameCreateManyChampionshipSeasonInputEnvelope>;
};

export type RealGameCreateNestedManyWithoutHomeTeamInput = {
  connect?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RealGameCreateOrConnectWithoutHomeTeamInput>>;
  create?: InputMaybe<Array<RealGameCreateWithoutHomeTeamInput>>;
  createMany?: InputMaybe<RealGameCreateManyHomeTeamInputEnvelope>;
};

export type RealGameCreateNestedOneWithoutRealGamePlayersInput = {
  connect?: InputMaybe<RealGameWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RealGameCreateOrConnectWithoutRealGamePlayersInput>;
  create?: InputMaybe<RealGameCreateWithoutRealGamePlayersInput>;
};

export type RealGameCreateNestedOneWithoutTournamentGameWeeksRealGamesInput = {
  connect?: InputMaybe<RealGameWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RealGameCreateOrConnectWithoutTournamentGameWeeksRealGamesInput>;
  create?: InputMaybe<RealGameCreateWithoutTournamentGameWeeksRealGamesInput>;
};

export type RealGameCreateOrConnectWithoutAwayTeamInput = {
  create: RealGameCreateWithoutAwayTeamInput;
  where: RealGameWhereUniqueInput;
};

export type RealGameCreateOrConnectWithoutChampionshipSeasonInput = {
  create: RealGameCreateWithoutChampionshipSeasonInput;
  where: RealGameWhereUniqueInput;
};

export type RealGameCreateOrConnectWithoutHomeTeamInput = {
  create: RealGameCreateWithoutHomeTeamInput;
  where: RealGameWhereUniqueInput;
};

export type RealGameCreateOrConnectWithoutRealGamePlayersInput = {
  create: RealGameCreateWithoutRealGamePlayersInput;
  where: RealGameWhereUniqueInput;
};

export type RealGameCreateOrConnectWithoutTournamentGameWeeksRealGamesInput = {
  create: RealGameCreateWithoutTournamentGameWeeksRealGamesInput;
  where: RealGameWhereUniqueInput;
};

export type RealGameCreateWithoutAwayTeamInput = {
  awayScore?: InputMaybe<Scalars["Int"]["input"]>;
  awayTeamName?: InputMaybe<Scalars["String"]["input"]>;
  awayTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
  championshipSeason: ChampionshipSeasonCreateNestedOneWithoutRealGamesInput;
  compName: Scalars["String"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  datetime?: InputMaybe<Scalars["Timestamp"]["input"]>;
  homeScore?: InputMaybe<Scalars["Int"]["input"]>;
  homeTeam?: InputMaybe<TeamCreateNestedOneWithoutAwayRealGamesInput>;
  homeTeamName: Scalars["String"]["input"];
  homeTeamOptaId: Scalars["String"]["input"];
  realGameOptaId: Scalars["String"]["input"];
  realGamePlayers?: InputMaybe<RealGamePlayerCreateNestedManyWithoutRealGameInput>;
  round?: InputMaybe<Scalars["Int"]["input"]>;
  roundType?: InputMaybe<RoundType>;
  seasonOptaId: Scalars["String"]["input"];
  status: RealGameStatus;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameCreateNestedManyWithoutRealGameInput>;
  winnerTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type RealGameCreateWithoutChampionshipSeasonInput = {
  awayScore?: InputMaybe<Scalars["Int"]["input"]>;
  awayTeam?: InputMaybe<TeamCreateNestedOneWithoutHomeRealGamesInput>;
  awayTeamName?: InputMaybe<Scalars["String"]["input"]>;
  awayTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
  compName: Scalars["String"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  datetime?: InputMaybe<Scalars["Timestamp"]["input"]>;
  homeScore?: InputMaybe<Scalars["Int"]["input"]>;
  homeTeam?: InputMaybe<TeamCreateNestedOneWithoutAwayRealGamesInput>;
  homeTeamName: Scalars["String"]["input"];
  homeTeamOptaId: Scalars["String"]["input"];
  realGameOptaId: Scalars["String"]["input"];
  realGamePlayers?: InputMaybe<RealGamePlayerCreateNestedManyWithoutRealGameInput>;
  round?: InputMaybe<Scalars["Int"]["input"]>;
  roundType?: InputMaybe<RoundType>;
  seasonOptaId: Scalars["String"]["input"];
  status: RealGameStatus;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameCreateNestedManyWithoutRealGameInput>;
  winnerTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type RealGameCreateWithoutHomeTeamInput = {
  awayScore?: InputMaybe<Scalars["Int"]["input"]>;
  awayTeam?: InputMaybe<TeamCreateNestedOneWithoutHomeRealGamesInput>;
  awayTeamName?: InputMaybe<Scalars["String"]["input"]>;
  awayTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
  championshipSeason: ChampionshipSeasonCreateNestedOneWithoutRealGamesInput;
  compName: Scalars["String"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  datetime?: InputMaybe<Scalars["Timestamp"]["input"]>;
  homeScore?: InputMaybe<Scalars["Int"]["input"]>;
  homeTeamName: Scalars["String"]["input"];
  homeTeamOptaId: Scalars["String"]["input"];
  realGameOptaId: Scalars["String"]["input"];
  realGamePlayers?: InputMaybe<RealGamePlayerCreateNestedManyWithoutRealGameInput>;
  round?: InputMaybe<Scalars["Int"]["input"]>;
  roundType?: InputMaybe<RoundType>;
  seasonOptaId: Scalars["String"]["input"];
  status: RealGameStatus;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameCreateNestedManyWithoutRealGameInput>;
  winnerTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type RealGameCreateWithoutRealGamePlayersInput = {
  awayScore?: InputMaybe<Scalars["Int"]["input"]>;
  awayTeam?: InputMaybe<TeamCreateNestedOneWithoutHomeRealGamesInput>;
  awayTeamName?: InputMaybe<Scalars["String"]["input"]>;
  awayTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
  championshipSeason: ChampionshipSeasonCreateNestedOneWithoutRealGamesInput;
  compName: Scalars["String"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  datetime?: InputMaybe<Scalars["Timestamp"]["input"]>;
  homeScore?: InputMaybe<Scalars["Int"]["input"]>;
  homeTeam?: InputMaybe<TeamCreateNestedOneWithoutAwayRealGamesInput>;
  homeTeamName: Scalars["String"]["input"];
  homeTeamOptaId: Scalars["String"]["input"];
  realGameOptaId: Scalars["String"]["input"];
  round?: InputMaybe<Scalars["Int"]["input"]>;
  roundType?: InputMaybe<RoundType>;
  seasonOptaId: Scalars["String"]["input"];
  status: RealGameStatus;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameCreateNestedManyWithoutRealGameInput>;
  winnerTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type RealGameCreateWithoutTournamentGameWeeksRealGamesInput = {
  awayScore?: InputMaybe<Scalars["Int"]["input"]>;
  awayTeam?: InputMaybe<TeamCreateNestedOneWithoutHomeRealGamesInput>;
  awayTeamName?: InputMaybe<Scalars["String"]["input"]>;
  awayTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
  championshipSeason: ChampionshipSeasonCreateNestedOneWithoutRealGamesInput;
  compName: Scalars["String"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  datetime?: InputMaybe<Scalars["Timestamp"]["input"]>;
  homeScore?: InputMaybe<Scalars["Int"]["input"]>;
  homeTeam?: InputMaybe<TeamCreateNestedOneWithoutAwayRealGamesInput>;
  homeTeamName: Scalars["String"]["input"];
  homeTeamOptaId: Scalars["String"]["input"];
  realGameOptaId: Scalars["String"]["input"];
  realGamePlayers?: InputMaybe<RealGamePlayerCreateNestedManyWithoutRealGameInput>;
  round?: InputMaybe<Scalars["Int"]["input"]>;
  roundType?: InputMaybe<RoundType>;
  seasonOptaId: Scalars["String"]["input"];
  status: RealGameStatus;
  winnerTeamOptaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type RealGameListRelationFilter = {
  every?: InputMaybe<RealGameWhereInput>;
  none?: InputMaybe<RealGameWhereInput>;
  some?: InputMaybe<RealGameWhereInput>;
};

export type RealGameOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum RealGameOrderByRelevanceFieldEnum {
  AwayTeamName = "awayTeamName",
  AwayTeamOptaId = "awayTeamOptaId",
  CompName = "compName",
  Date = "date",
  HomeTeamName = "homeTeamName",
  HomeTeamOptaId = "homeTeamOptaId",
  RealGameOptaId = "realGameOptaId",
  SeasonOptaId = "seasonOptaId",
  WinnerTeamOptaId = "winnerTeamOptaId",
}

export type RealGameOrderByRelevanceInput = {
  fields: Array<RealGameOrderByRelevanceFieldEnum>;
  search: Scalars["String"]["input"];
  sort: SortOrder;
};

export type RealGameOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<RealGameOrderByRelevanceInput>;
  awayScore?: InputMaybe<SortOrder>;
  awayTeam?: InputMaybe<TeamOrderByWithRelationAndSearchRelevanceInput>;
  awayTeamId?: InputMaybe<SortOrderInput>;
  awayTeamName?: InputMaybe<SortOrderInput>;
  awayTeamOptaId?: InputMaybe<SortOrderInput>;
  championshipSeason?: InputMaybe<ChampionshipSeasonOrderByWithRelationAndSearchRelevanceInput>;
  championshipSeasonId?: InputMaybe<SortOrder>;
  compName?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrderInput>;
  datetime?: InputMaybe<SortOrderInput>;
  homeScore?: InputMaybe<SortOrder>;
  homeTeam?: InputMaybe<TeamOrderByWithRelationAndSearchRelevanceInput>;
  homeTeamId?: InputMaybe<SortOrderInput>;
  homeTeamName?: InputMaybe<SortOrder>;
  homeTeamOptaId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  realGameOptaId?: InputMaybe<SortOrder>;
  realGamePlayers?: InputMaybe<RealGamePlayerOrderByRelationAggregateInput>;
  round?: InputMaybe<SortOrder>;
  roundType?: InputMaybe<SortOrder>;
  seasonOptaId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameOrderByRelationAggregateInput>;
  winnerTeamOptaId?: InputMaybe<SortOrderInput>;
};

export type RealGamePlayer = {
  __typename?: "RealGamePlayer";
  _count: RealGamePlayerCount;
  compositionCards?: Maybe<Array<CompositionCard>>;
  livePlayerScore?: Maybe<Scalars["Float"]["output"]>;
  livePlayerStats?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  optaPosition?: Maybe<Scalars["String"]["output"]>;
  player?: Maybe<Player>;
  playerId?: Maybe<Scalars["Int"]["output"]>;
  playerOptaId: Scalars["String"]["output"];
  playerPoints?: Maybe<Scalars["Float"]["output"]>;
  playerScore?: Maybe<PlayerScore>;
  playerStats: Scalars["JSON"]["output"];
  playerStatusUpdateAt?: Maybe<Scalars["Timestamp"]["output"]>;
  position: PositionType;
  positionOptaId: Scalars["String"]["output"];
  realGame: RealGame;
  realGameId: Scalars["Int"]["output"];
  realGameOptaId: Scalars["String"]["output"];
  teamId?: Maybe<Scalars["Int"]["output"]>;
  teamOptaId: Scalars["String"]["output"];
};

export type RealGamePlayerCount = {
  __typename?: "RealGamePlayerCount";
  compositionCards: Scalars["Int"]["output"];
};

export type RealGamePlayerCreateManyPlayerInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  optaPosition?: InputMaybe<Scalars["String"]["input"]>;
  playerOptaId: Scalars["String"]["input"];
  playerPoints?: InputMaybe<Scalars["Float"]["input"]>;
  playerStats: Scalars["JSON"]["input"];
  playerStatusUpdateAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  position: PositionType;
  positionOptaId: Scalars["String"]["input"];
  realGameId: Scalars["Int"]["input"];
  realGameOptaId: Scalars["String"]["input"];
  teamId?: InputMaybe<Scalars["Int"]["input"]>;
  teamOptaId: Scalars["String"]["input"];
};

export type RealGamePlayerCreateManyPlayerInputEnvelope = {
  data: Array<RealGamePlayerCreateManyPlayerInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RealGamePlayerCreateManyRealGameInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  optaPosition?: InputMaybe<Scalars["String"]["input"]>;
  playerId?: InputMaybe<Scalars["Int"]["input"]>;
  playerOptaId: Scalars["String"]["input"];
  playerPoints?: InputMaybe<Scalars["Float"]["input"]>;
  playerStats: Scalars["JSON"]["input"];
  playerStatusUpdateAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  position: PositionType;
  positionOptaId: Scalars["String"]["input"];
  realGameOptaId: Scalars["String"]["input"];
  teamId?: InputMaybe<Scalars["Int"]["input"]>;
  teamOptaId: Scalars["String"]["input"];
};

export type RealGamePlayerCreateManyRealGameInputEnvelope = {
  data: Array<RealGamePlayerCreateManyRealGameInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RealGamePlayerCreateNestedManyWithoutPlayerInput = {
  connect?: InputMaybe<Array<RealGamePlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RealGamePlayerCreateOrConnectWithoutPlayerInput>>;
  create?: InputMaybe<Array<RealGamePlayerCreateWithoutPlayerInput>>;
  createMany?: InputMaybe<RealGamePlayerCreateManyPlayerInputEnvelope>;
};

export type RealGamePlayerCreateNestedManyWithoutRealGameInput = {
  connect?: InputMaybe<Array<RealGamePlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RealGamePlayerCreateOrConnectWithoutRealGameInput>>;
  create?: InputMaybe<Array<RealGamePlayerCreateWithoutRealGameInput>>;
  createMany?: InputMaybe<RealGamePlayerCreateManyRealGameInputEnvelope>;
};

export type RealGamePlayerCreateNestedOneWithoutCompositionCardsInput = {
  connect?: InputMaybe<RealGamePlayerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RealGamePlayerCreateOrConnectWithoutCompositionCardsInput>;
  create?: InputMaybe<RealGamePlayerCreateWithoutCompositionCardsInput>;
};

export type RealGamePlayerCreateOrConnectWithoutCompositionCardsInput = {
  create: RealGamePlayerCreateWithoutCompositionCardsInput;
  where: RealGamePlayerWhereUniqueInput;
};

export type RealGamePlayerCreateOrConnectWithoutPlayerInput = {
  create: RealGamePlayerCreateWithoutPlayerInput;
  where: RealGamePlayerWhereUniqueInput;
};

export type RealGamePlayerCreateOrConnectWithoutRealGameInput = {
  create: RealGamePlayerCreateWithoutRealGameInput;
  where: RealGamePlayerWhereUniqueInput;
};

export type RealGamePlayerCreateWithoutCompositionCardsInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  optaPosition?: InputMaybe<Scalars["String"]["input"]>;
  player?: InputMaybe<PlayerCreateNestedOneWithoutRealGamePlayersInput>;
  playerOptaId: Scalars["String"]["input"];
  playerPoints?: InputMaybe<Scalars["Float"]["input"]>;
  playerStats: Scalars["JSON"]["input"];
  playerStatusUpdateAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  position: PositionType;
  positionOptaId: Scalars["String"]["input"];
  realGame: RealGameCreateNestedOneWithoutRealGamePlayersInput;
  realGameOptaId: Scalars["String"]["input"];
  teamId?: InputMaybe<Scalars["Int"]["input"]>;
  teamOptaId: Scalars["String"]["input"];
};

export type RealGamePlayerCreateWithoutPlayerInput = {
  compositionCards?: InputMaybe<CompositionCardCreateNestedManyWithoutRealGamePlayerInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  optaPosition?: InputMaybe<Scalars["String"]["input"]>;
  playerOptaId: Scalars["String"]["input"];
  playerPoints?: InputMaybe<Scalars["Float"]["input"]>;
  playerStats: Scalars["JSON"]["input"];
  playerStatusUpdateAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  position: PositionType;
  positionOptaId: Scalars["String"]["input"];
  realGame: RealGameCreateNestedOneWithoutRealGamePlayersInput;
  realGameOptaId: Scalars["String"]["input"];
  teamId?: InputMaybe<Scalars["Int"]["input"]>;
  teamOptaId: Scalars["String"]["input"];
};

export type RealGamePlayerCreateWithoutRealGameInput = {
  compositionCards?: InputMaybe<CompositionCardCreateNestedManyWithoutRealGamePlayerInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  optaPosition?: InputMaybe<Scalars["String"]["input"]>;
  player?: InputMaybe<PlayerCreateNestedOneWithoutRealGamePlayersInput>;
  playerOptaId: Scalars["String"]["input"];
  playerPoints?: InputMaybe<Scalars["Float"]["input"]>;
  playerStats: Scalars["JSON"]["input"];
  playerStatusUpdateAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  position: PositionType;
  positionOptaId: Scalars["String"]["input"];
  realGameOptaId: Scalars["String"]["input"];
  teamId?: InputMaybe<Scalars["Int"]["input"]>;
  teamOptaId: Scalars["String"]["input"];
};

export type RealGamePlayerListRelationFilter = {
  every?: InputMaybe<RealGamePlayerWhereInput>;
  none?: InputMaybe<RealGamePlayerWhereInput>;
  some?: InputMaybe<RealGamePlayerWhereInput>;
};

export type RealGamePlayerNullableRelationFilter = {
  is?: InputMaybe<RealGamePlayerWhereInput>;
  isNot?: InputMaybe<RealGamePlayerWhereInput>;
};

export type RealGamePlayerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum RealGamePlayerOrderByRelevanceFieldEnum {
  Name = "name",
  OptaPosition = "optaPosition",
  PlayerOptaId = "playerOptaId",
  PositionOptaId = "positionOptaId",
  RealGameOptaId = "realGameOptaId",
  TeamOptaId = "teamOptaId",
}

export type RealGamePlayerOrderByRelevanceInput = {
  fields: Array<RealGamePlayerOrderByRelevanceFieldEnum>;
  search: Scalars["String"]["input"];
  sort: SortOrder;
};

export type RealGamePlayerOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<RealGamePlayerOrderByRelevanceInput>;
  compositionCards?: InputMaybe<CompositionCardOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrderInput>;
  optaPosition?: InputMaybe<SortOrderInput>;
  player?: InputMaybe<PlayerOrderByWithRelationAndSearchRelevanceInput>;
  playerId?: InputMaybe<SortOrderInput>;
  playerOptaId?: InputMaybe<SortOrder>;
  playerPoints?: InputMaybe<SortOrderInput>;
  playerStats?: InputMaybe<SortOrder>;
  playerStatusUpdateAt?: InputMaybe<SortOrderInput>;
  position?: InputMaybe<SortOrder>;
  positionOptaId?: InputMaybe<SortOrder>;
  realGame?: InputMaybe<RealGameOrderByWithRelationAndSearchRelevanceInput>;
  realGameId?: InputMaybe<SortOrder>;
  realGameOptaId?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrderInput>;
  teamOptaId?: InputMaybe<SortOrder>;
};

export type RealGamePlayerRealGameOptaIdPlayerOptaIdCompoundUniqueInput = {
  playerOptaId: Scalars["String"]["input"];
  realGameOptaId: Scalars["String"]["input"];
};

export enum RealGamePlayerScalarFieldEnum {
  Name = "name",
  OptaPosition = "optaPosition",
  PlayerId = "playerId",
  PlayerOptaId = "playerOptaId",
  PlayerPoints = "playerPoints",
  PlayerStats = "playerStats",
  PlayerStatusUpdateAt = "playerStatusUpdateAt",
  Position = "position",
  PositionOptaId = "positionOptaId",
  RealGameId = "realGameId",
  RealGameOptaId = "realGameOptaId",
  TeamId = "teamId",
  TeamOptaId = "teamOptaId",
}

export type RealGamePlayerScalarWhereInput = {
  AND?: InputMaybe<Array<RealGamePlayerScalarWhereInput>>;
  NOT?: InputMaybe<Array<RealGamePlayerScalarWhereInput>>;
  OR?: InputMaybe<Array<RealGamePlayerScalarWhereInput>>;
  name?: InputMaybe<StringNullableFilter>;
  optaPosition?: InputMaybe<StringNullableFilter>;
  playerId?: InputMaybe<IntNullableFilter>;
  playerOptaId?: InputMaybe<StringFilter>;
  playerPoints?: InputMaybe<FloatNullableFilter>;
  playerStats?: InputMaybe<JsonFilter>;
  playerStatusUpdateAt?: InputMaybe<DateTimeNullableFilter>;
  position?: InputMaybe<EnumPositionTypeFilter>;
  positionOptaId?: InputMaybe<StringFilter>;
  realGameId?: InputMaybe<IntFilter>;
  realGameOptaId?: InputMaybe<StringFilter>;
  teamId?: InputMaybe<IntNullableFilter>;
  teamOptaId?: InputMaybe<StringFilter>;
};

export type RealGamePlayerUpdateManyMutationInput = {
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  optaPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  playerPoints?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playerStats?: InputMaybe<Scalars["JSON"]["input"]>;
  playerStatusUpdateAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  position?: InputMaybe<EnumPositionTypeFieldUpdateOperationsInput>;
  positionOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGameOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  teamId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RealGamePlayerUpdateManyWithWhereWithoutPlayerInput = {
  data: RealGamePlayerUpdateManyMutationInput;
  where: RealGamePlayerScalarWhereInput;
};

export type RealGamePlayerUpdateManyWithWhereWithoutRealGameInput = {
  data: RealGamePlayerUpdateManyMutationInput;
  where: RealGamePlayerScalarWhereInput;
};

export type RealGamePlayerUpdateManyWithoutPlayerNestedInput = {
  connect?: InputMaybe<Array<RealGamePlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RealGamePlayerCreateOrConnectWithoutPlayerInput>>;
  create?: InputMaybe<Array<RealGamePlayerCreateWithoutPlayerInput>>;
  createMany?: InputMaybe<RealGamePlayerCreateManyPlayerInputEnvelope>;
  delete?: InputMaybe<Array<RealGamePlayerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RealGamePlayerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RealGamePlayerWhereUniqueInput>>;
  set?: InputMaybe<Array<RealGamePlayerWhereUniqueInput>>;
  update?: InputMaybe<Array<RealGamePlayerUpdateWithWhereUniqueWithoutPlayerInput>>;
  updateMany?: InputMaybe<Array<RealGamePlayerUpdateManyWithWhereWithoutPlayerInput>>;
  upsert?: InputMaybe<Array<RealGamePlayerUpsertWithWhereUniqueWithoutPlayerInput>>;
};

export type RealGamePlayerUpdateManyWithoutRealGameNestedInput = {
  connect?: InputMaybe<Array<RealGamePlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RealGamePlayerCreateOrConnectWithoutRealGameInput>>;
  create?: InputMaybe<Array<RealGamePlayerCreateWithoutRealGameInput>>;
  createMany?: InputMaybe<RealGamePlayerCreateManyRealGameInputEnvelope>;
  delete?: InputMaybe<Array<RealGamePlayerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RealGamePlayerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RealGamePlayerWhereUniqueInput>>;
  set?: InputMaybe<Array<RealGamePlayerWhereUniqueInput>>;
  update?: InputMaybe<Array<RealGamePlayerUpdateWithWhereUniqueWithoutRealGameInput>>;
  updateMany?: InputMaybe<Array<RealGamePlayerUpdateManyWithWhereWithoutRealGameInput>>;
  upsert?: InputMaybe<Array<RealGamePlayerUpsertWithWhereUniqueWithoutRealGameInput>>;
};

export type RealGamePlayerUpdateOneWithoutCompositionCardsNestedInput = {
  connect?: InputMaybe<RealGamePlayerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RealGamePlayerCreateOrConnectWithoutCompositionCardsInput>;
  create?: InputMaybe<RealGamePlayerCreateWithoutCompositionCardsInput>;
  delete?: InputMaybe<RealGamePlayerWhereInput>;
  disconnect?: InputMaybe<RealGamePlayerWhereInput>;
  update?: InputMaybe<RealGamePlayerUpdateToOneWithWhereWithoutCompositionCardsInput>;
  upsert?: InputMaybe<RealGamePlayerUpsertWithoutCompositionCardsInput>;
};

export type RealGamePlayerUpdateToOneWithWhereWithoutCompositionCardsInput = {
  data: RealGamePlayerUpdateWithoutCompositionCardsInput;
  where?: InputMaybe<RealGamePlayerWhereInput>;
};

export type RealGamePlayerUpdateWithWhereUniqueWithoutPlayerInput = {
  data: RealGamePlayerUpdateWithoutPlayerInput;
  where: RealGamePlayerWhereUniqueInput;
};

export type RealGamePlayerUpdateWithWhereUniqueWithoutRealGameInput = {
  data: RealGamePlayerUpdateWithoutRealGameInput;
  where: RealGamePlayerWhereUniqueInput;
};

export type RealGamePlayerUpdateWithoutCompositionCardsInput = {
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  optaPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  player?: InputMaybe<PlayerUpdateOneWithoutRealGamePlayersNestedInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  playerPoints?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playerStats?: InputMaybe<Scalars["JSON"]["input"]>;
  playerStatusUpdateAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  position?: InputMaybe<EnumPositionTypeFieldUpdateOperationsInput>;
  positionOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGame?: InputMaybe<RealGameUpdateOneRequiredWithoutRealGamePlayersNestedInput>;
  realGameOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  teamId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RealGamePlayerUpdateWithoutPlayerInput = {
  compositionCards?: InputMaybe<CompositionCardUpdateManyWithoutRealGamePlayerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  optaPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  playerPoints?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playerStats?: InputMaybe<Scalars["JSON"]["input"]>;
  playerStatusUpdateAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  position?: InputMaybe<EnumPositionTypeFieldUpdateOperationsInput>;
  positionOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGame?: InputMaybe<RealGameUpdateOneRequiredWithoutRealGamePlayersNestedInput>;
  realGameOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  teamId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RealGamePlayerUpdateWithoutRealGameInput = {
  compositionCards?: InputMaybe<CompositionCardUpdateManyWithoutRealGamePlayerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  optaPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  player?: InputMaybe<PlayerUpdateOneWithoutRealGamePlayersNestedInput>;
  playerOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  playerPoints?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playerStats?: InputMaybe<Scalars["JSON"]["input"]>;
  playerStatusUpdateAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  position?: InputMaybe<EnumPositionTypeFieldUpdateOperationsInput>;
  positionOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGameOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  teamId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RealGamePlayerUpsertWithWhereUniqueWithoutPlayerInput = {
  create: RealGamePlayerCreateWithoutPlayerInput;
  update: RealGamePlayerUpdateWithoutPlayerInput;
  where: RealGamePlayerWhereUniqueInput;
};

export type RealGamePlayerUpsertWithWhereUniqueWithoutRealGameInput = {
  create: RealGamePlayerCreateWithoutRealGameInput;
  update: RealGamePlayerUpdateWithoutRealGameInput;
  where: RealGamePlayerWhereUniqueInput;
};

export type RealGamePlayerUpsertWithoutCompositionCardsInput = {
  create: RealGamePlayerCreateWithoutCompositionCardsInput;
  update: RealGamePlayerUpdateWithoutCompositionCardsInput;
  where?: InputMaybe<RealGamePlayerWhereInput>;
};

export type RealGamePlayerWhereInput = {
  AND?: InputMaybe<Array<RealGamePlayerWhereInput>>;
  NOT?: InputMaybe<Array<RealGamePlayerWhereInput>>;
  OR?: InputMaybe<Array<RealGamePlayerWhereInput>>;
  compositionCards?: InputMaybe<CompositionCardListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
  optaPosition?: InputMaybe<StringNullableFilter>;
  player?: InputMaybe<PlayerNullableRelationFilter>;
  playerId?: InputMaybe<IntNullableFilter>;
  playerOptaId?: InputMaybe<StringFilter>;
  playerPoints?: InputMaybe<FloatNullableFilter>;
  playerStats?: InputMaybe<JsonFilter>;
  playerStatusUpdateAt?: InputMaybe<DateTimeNullableFilter>;
  position?: InputMaybe<EnumPositionTypeFilter>;
  positionOptaId?: InputMaybe<StringFilter>;
  realGame?: InputMaybe<RealGameRelationFilter>;
  realGameId?: InputMaybe<IntFilter>;
  realGameOptaId?: InputMaybe<StringFilter>;
  teamId?: InputMaybe<IntNullableFilter>;
  teamOptaId?: InputMaybe<StringFilter>;
};

export type RealGamePlayerWhereUniqueInput = {
  AND?: InputMaybe<Array<RealGamePlayerWhereInput>>;
  NOT?: InputMaybe<Array<RealGamePlayerWhereInput>>;
  OR?: InputMaybe<Array<RealGamePlayerWhereInput>>;
  compositionCards?: InputMaybe<CompositionCardListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
  optaPosition?: InputMaybe<StringNullableFilter>;
  player?: InputMaybe<PlayerNullableRelationFilter>;
  playerId?: InputMaybe<IntNullableFilter>;
  playerOptaId?: InputMaybe<StringFilter>;
  playerPoints?: InputMaybe<FloatNullableFilter>;
  playerStats?: InputMaybe<JsonFilter>;
  playerStatusUpdateAt?: InputMaybe<DateTimeNullableFilter>;
  position?: InputMaybe<EnumPositionTypeFilter>;
  positionOptaId?: InputMaybe<StringFilter>;
  realGame?: InputMaybe<RealGameRelationFilter>;
  realGameId?: InputMaybe<IntFilter>;
  realGameOptaId?: InputMaybe<StringFilter>;
  realGameOptaId_playerOptaId?: InputMaybe<RealGamePlayerRealGameOptaIdPlayerOptaIdCompoundUniqueInput>;
  teamId?: InputMaybe<IntNullableFilter>;
  teamOptaId?: InputMaybe<StringFilter>;
};

export type RealGameRelationFilter = {
  is?: InputMaybe<RealGameWhereInput>;
  isNot?: InputMaybe<RealGameWhereInput>;
};

export enum RealGameScalarFieldEnum {
  AwayScore = "awayScore",
  AwayTeamId = "awayTeamId",
  AwayTeamName = "awayTeamName",
  AwayTeamOptaId = "awayTeamOptaId",
  ChampionshipSeasonId = "championshipSeasonId",
  CompName = "compName",
  Date = "date",
  Datetime = "datetime",
  HomeScore = "homeScore",
  HomeTeamId = "homeTeamId",
  HomeTeamName = "homeTeamName",
  HomeTeamOptaId = "homeTeamOptaId",
  Id = "id",
  RealGameOptaId = "realGameOptaId",
  Round = "round",
  RoundType = "roundType",
  SeasonOptaId = "seasonOptaId",
  Status = "status",
  WinnerTeamOptaId = "winnerTeamOptaId",
}

export type RealGameScalarWhereInput = {
  AND?: InputMaybe<Array<RealGameScalarWhereInput>>;
  NOT?: InputMaybe<Array<RealGameScalarWhereInput>>;
  OR?: InputMaybe<Array<RealGameScalarWhereInput>>;
  awayScore?: InputMaybe<IntFilter>;
  awayTeamId?: InputMaybe<IntNullableFilter>;
  awayTeamName?: InputMaybe<StringNullableFilter>;
  awayTeamOptaId?: InputMaybe<StringNullableFilter>;
  championshipSeasonId?: InputMaybe<IntFilter>;
  compName?: InputMaybe<StringFilter>;
  date?: InputMaybe<StringNullableFilter>;
  datetime?: InputMaybe<DateTimeNullableFilter>;
  homeScore?: InputMaybe<IntFilter>;
  homeTeamId?: InputMaybe<IntNullableFilter>;
  homeTeamName?: InputMaybe<StringFilter>;
  homeTeamOptaId?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  realGameOptaId?: InputMaybe<StringFilter>;
  round?: InputMaybe<IntFilter>;
  roundType?: InputMaybe<EnumRoundTypeFilter>;
  seasonOptaId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumRealGameStatusFilter>;
  winnerTeamOptaId?: InputMaybe<StringNullableFilter>;
};

export enum RealGameStatus {
  Cancelled = "CANCELLED",
  Fixture = "FIXTURE",
  Live = "LIVE",
  Postponed = "POSTPONED",
  Result = "RESULT",
  Unknown = "UNKNOWN",
}

export type RealGameUpdateManyMutationInput = {
  awayScore?: InputMaybe<IntFieldUpdateOperationsInput>;
  awayTeamName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  awayTeamOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  compName?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  datetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  homeScore?: InputMaybe<IntFieldUpdateOperationsInput>;
  homeTeamName?: InputMaybe<StringFieldUpdateOperationsInput>;
  homeTeamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGameOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  round?: InputMaybe<IntFieldUpdateOperationsInput>;
  roundType?: InputMaybe<EnumRoundTypeFieldUpdateOperationsInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumRealGameStatusFieldUpdateOperationsInput>;
  winnerTeamOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type RealGameUpdateManyWithWhereWithoutAwayTeamInput = {
  data: RealGameUpdateManyMutationInput;
  where: RealGameScalarWhereInput;
};

export type RealGameUpdateManyWithWhereWithoutChampionshipSeasonInput = {
  data: RealGameUpdateManyMutationInput;
  where: RealGameScalarWhereInput;
};

export type RealGameUpdateManyWithWhereWithoutHomeTeamInput = {
  data: RealGameUpdateManyMutationInput;
  where: RealGameScalarWhereInput;
};

export type RealGameUpdateManyWithoutAwayTeamNestedInput = {
  connect?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RealGameCreateOrConnectWithoutAwayTeamInput>>;
  create?: InputMaybe<Array<RealGameCreateWithoutAwayTeamInput>>;
  createMany?: InputMaybe<RealGameCreateManyAwayTeamInputEnvelope>;
  delete?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RealGameScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  set?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  update?: InputMaybe<Array<RealGameUpdateWithWhereUniqueWithoutAwayTeamInput>>;
  updateMany?: InputMaybe<Array<RealGameUpdateManyWithWhereWithoutAwayTeamInput>>;
  upsert?: InputMaybe<Array<RealGameUpsertWithWhereUniqueWithoutAwayTeamInput>>;
};

export type RealGameUpdateManyWithoutChampionshipSeasonNestedInput = {
  connect?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RealGameCreateOrConnectWithoutChampionshipSeasonInput>>;
  create?: InputMaybe<Array<RealGameCreateWithoutChampionshipSeasonInput>>;
  createMany?: InputMaybe<RealGameCreateManyChampionshipSeasonInputEnvelope>;
  delete?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RealGameScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  set?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  update?: InputMaybe<Array<RealGameUpdateWithWhereUniqueWithoutChampionshipSeasonInput>>;
  updateMany?: InputMaybe<Array<RealGameUpdateManyWithWhereWithoutChampionshipSeasonInput>>;
  upsert?: InputMaybe<Array<RealGameUpsertWithWhereUniqueWithoutChampionshipSeasonInput>>;
};

export type RealGameUpdateManyWithoutHomeTeamNestedInput = {
  connect?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RealGameCreateOrConnectWithoutHomeTeamInput>>;
  create?: InputMaybe<Array<RealGameCreateWithoutHomeTeamInput>>;
  createMany?: InputMaybe<RealGameCreateManyHomeTeamInputEnvelope>;
  delete?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RealGameScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  set?: InputMaybe<Array<RealGameWhereUniqueInput>>;
  update?: InputMaybe<Array<RealGameUpdateWithWhereUniqueWithoutHomeTeamInput>>;
  updateMany?: InputMaybe<Array<RealGameUpdateManyWithWhereWithoutHomeTeamInput>>;
  upsert?: InputMaybe<Array<RealGameUpsertWithWhereUniqueWithoutHomeTeamInput>>;
};

export type RealGameUpdateOneRequiredWithoutRealGamePlayersNestedInput = {
  connect?: InputMaybe<RealGameWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RealGameCreateOrConnectWithoutRealGamePlayersInput>;
  create?: InputMaybe<RealGameCreateWithoutRealGamePlayersInput>;
  update?: InputMaybe<RealGameUpdateToOneWithWhereWithoutRealGamePlayersInput>;
  upsert?: InputMaybe<RealGameUpsertWithoutRealGamePlayersInput>;
};

export type RealGameUpdateOneRequiredWithoutTournamentGameWeeksRealGamesNestedInput = {
  connect?: InputMaybe<RealGameWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RealGameCreateOrConnectWithoutTournamentGameWeeksRealGamesInput>;
  create?: InputMaybe<RealGameCreateWithoutTournamentGameWeeksRealGamesInput>;
  update?: InputMaybe<RealGameUpdateToOneWithWhereWithoutTournamentGameWeeksRealGamesInput>;
  upsert?: InputMaybe<RealGameUpsertWithoutTournamentGameWeeksRealGamesInput>;
};

export type RealGameUpdateToOneWithWhereWithoutRealGamePlayersInput = {
  data: RealGameUpdateWithoutRealGamePlayersInput;
  where?: InputMaybe<RealGameWhereInput>;
};

export type RealGameUpdateToOneWithWhereWithoutTournamentGameWeeksRealGamesInput = {
  data: RealGameUpdateWithoutTournamentGameWeeksRealGamesInput;
  where?: InputMaybe<RealGameWhereInput>;
};

export type RealGameUpdateWithWhereUniqueWithoutAwayTeamInput = {
  data: RealGameUpdateWithoutAwayTeamInput;
  where: RealGameWhereUniqueInput;
};

export type RealGameUpdateWithWhereUniqueWithoutChampionshipSeasonInput = {
  data: RealGameUpdateWithoutChampionshipSeasonInput;
  where: RealGameWhereUniqueInput;
};

export type RealGameUpdateWithWhereUniqueWithoutHomeTeamInput = {
  data: RealGameUpdateWithoutHomeTeamInput;
  where: RealGameWhereUniqueInput;
};

export type RealGameUpdateWithoutAwayTeamInput = {
  awayScore?: InputMaybe<IntFieldUpdateOperationsInput>;
  awayTeamName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  awayTeamOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  championshipSeason?: InputMaybe<ChampionshipSeasonUpdateOneRequiredWithoutRealGamesNestedInput>;
  compName?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  datetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  homeScore?: InputMaybe<IntFieldUpdateOperationsInput>;
  homeTeam?: InputMaybe<TeamUpdateOneWithoutAwayRealGamesNestedInput>;
  homeTeamName?: InputMaybe<StringFieldUpdateOperationsInput>;
  homeTeamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGameOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGamePlayers?: InputMaybe<RealGamePlayerUpdateManyWithoutRealGameNestedInput>;
  round?: InputMaybe<IntFieldUpdateOperationsInput>;
  roundType?: InputMaybe<EnumRoundTypeFieldUpdateOperationsInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumRealGameStatusFieldUpdateOperationsInput>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameUpdateManyWithoutRealGameNestedInput>;
  winnerTeamOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type RealGameUpdateWithoutChampionshipSeasonInput = {
  awayScore?: InputMaybe<IntFieldUpdateOperationsInput>;
  awayTeam?: InputMaybe<TeamUpdateOneWithoutHomeRealGamesNestedInput>;
  awayTeamName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  awayTeamOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  compName?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  datetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  homeScore?: InputMaybe<IntFieldUpdateOperationsInput>;
  homeTeam?: InputMaybe<TeamUpdateOneWithoutAwayRealGamesNestedInput>;
  homeTeamName?: InputMaybe<StringFieldUpdateOperationsInput>;
  homeTeamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGameOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGamePlayers?: InputMaybe<RealGamePlayerUpdateManyWithoutRealGameNestedInput>;
  round?: InputMaybe<IntFieldUpdateOperationsInput>;
  roundType?: InputMaybe<EnumRoundTypeFieldUpdateOperationsInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumRealGameStatusFieldUpdateOperationsInput>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameUpdateManyWithoutRealGameNestedInput>;
  winnerTeamOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type RealGameUpdateWithoutHomeTeamInput = {
  awayScore?: InputMaybe<IntFieldUpdateOperationsInput>;
  awayTeam?: InputMaybe<TeamUpdateOneWithoutHomeRealGamesNestedInput>;
  awayTeamName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  awayTeamOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  championshipSeason?: InputMaybe<ChampionshipSeasonUpdateOneRequiredWithoutRealGamesNestedInput>;
  compName?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  datetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  homeScore?: InputMaybe<IntFieldUpdateOperationsInput>;
  homeTeamName?: InputMaybe<StringFieldUpdateOperationsInput>;
  homeTeamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGameOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGamePlayers?: InputMaybe<RealGamePlayerUpdateManyWithoutRealGameNestedInput>;
  round?: InputMaybe<IntFieldUpdateOperationsInput>;
  roundType?: InputMaybe<EnumRoundTypeFieldUpdateOperationsInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumRealGameStatusFieldUpdateOperationsInput>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameUpdateManyWithoutRealGameNestedInput>;
  winnerTeamOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type RealGameUpdateWithoutRealGamePlayersInput = {
  awayScore?: InputMaybe<IntFieldUpdateOperationsInput>;
  awayTeam?: InputMaybe<TeamUpdateOneWithoutHomeRealGamesNestedInput>;
  awayTeamName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  awayTeamOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  championshipSeason?: InputMaybe<ChampionshipSeasonUpdateOneRequiredWithoutRealGamesNestedInput>;
  compName?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  datetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  homeScore?: InputMaybe<IntFieldUpdateOperationsInput>;
  homeTeam?: InputMaybe<TeamUpdateOneWithoutAwayRealGamesNestedInput>;
  homeTeamName?: InputMaybe<StringFieldUpdateOperationsInput>;
  homeTeamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGameOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  round?: InputMaybe<IntFieldUpdateOperationsInput>;
  roundType?: InputMaybe<EnumRoundTypeFieldUpdateOperationsInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumRealGameStatusFieldUpdateOperationsInput>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameUpdateManyWithoutRealGameNestedInput>;
  winnerTeamOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type RealGameUpdateWithoutTournamentGameWeeksRealGamesInput = {
  awayScore?: InputMaybe<IntFieldUpdateOperationsInput>;
  awayTeam?: InputMaybe<TeamUpdateOneWithoutHomeRealGamesNestedInput>;
  awayTeamName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  awayTeamOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  championshipSeason?: InputMaybe<ChampionshipSeasonUpdateOneRequiredWithoutRealGamesNestedInput>;
  compName?: InputMaybe<StringFieldUpdateOperationsInput>;
  date?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  datetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  homeScore?: InputMaybe<IntFieldUpdateOperationsInput>;
  homeTeam?: InputMaybe<TeamUpdateOneWithoutAwayRealGamesNestedInput>;
  homeTeamName?: InputMaybe<StringFieldUpdateOperationsInput>;
  homeTeamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGameOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  realGamePlayers?: InputMaybe<RealGamePlayerUpdateManyWithoutRealGameNestedInput>;
  round?: InputMaybe<IntFieldUpdateOperationsInput>;
  roundType?: InputMaybe<EnumRoundTypeFieldUpdateOperationsInput>;
  seasonOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumRealGameStatusFieldUpdateOperationsInput>;
  winnerTeamOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type RealGameUpsertWithWhereUniqueWithoutAwayTeamInput = {
  create: RealGameCreateWithoutAwayTeamInput;
  update: RealGameUpdateWithoutAwayTeamInput;
  where: RealGameWhereUniqueInput;
};

export type RealGameUpsertWithWhereUniqueWithoutChampionshipSeasonInput = {
  create: RealGameCreateWithoutChampionshipSeasonInput;
  update: RealGameUpdateWithoutChampionshipSeasonInput;
  where: RealGameWhereUniqueInput;
};

export type RealGameUpsertWithWhereUniqueWithoutHomeTeamInput = {
  create: RealGameCreateWithoutHomeTeamInput;
  update: RealGameUpdateWithoutHomeTeamInput;
  where: RealGameWhereUniqueInput;
};

export type RealGameUpsertWithoutRealGamePlayersInput = {
  create: RealGameCreateWithoutRealGamePlayersInput;
  update: RealGameUpdateWithoutRealGamePlayersInput;
  where?: InputMaybe<RealGameWhereInput>;
};

export type RealGameUpsertWithoutTournamentGameWeeksRealGamesInput = {
  create: RealGameCreateWithoutTournamentGameWeeksRealGamesInput;
  update: RealGameUpdateWithoutTournamentGameWeeksRealGamesInput;
  where?: InputMaybe<RealGameWhereInput>;
};

export type RealGameWhereInput = {
  AND?: InputMaybe<Array<RealGameWhereInput>>;
  NOT?: InputMaybe<Array<RealGameWhereInput>>;
  OR?: InputMaybe<Array<RealGameWhereInput>>;
  awayScore?: InputMaybe<IntFilter>;
  awayTeam?: InputMaybe<TeamNullableRelationFilter>;
  awayTeamId?: InputMaybe<IntNullableFilter>;
  awayTeamName?: InputMaybe<StringNullableFilter>;
  awayTeamOptaId?: InputMaybe<StringNullableFilter>;
  championshipSeason?: InputMaybe<ChampionshipSeasonRelationFilter>;
  championshipSeasonId?: InputMaybe<IntFilter>;
  compName?: InputMaybe<StringFilter>;
  date?: InputMaybe<StringNullableFilter>;
  datetime?: InputMaybe<DateTimeNullableFilter>;
  homeScore?: InputMaybe<IntFilter>;
  homeTeam?: InputMaybe<TeamNullableRelationFilter>;
  homeTeamId?: InputMaybe<IntNullableFilter>;
  homeTeamName?: InputMaybe<StringFilter>;
  homeTeamOptaId?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  realGameOptaId?: InputMaybe<StringFilter>;
  realGamePlayers?: InputMaybe<RealGamePlayerListRelationFilter>;
  round?: InputMaybe<IntFilter>;
  roundType?: InputMaybe<EnumRoundTypeFilter>;
  seasonOptaId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumRealGameStatusFilter>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameListRelationFilter>;
  winnerTeamOptaId?: InputMaybe<StringNullableFilter>;
};

export type RealGameWhereUniqueInput = {
  AND?: InputMaybe<Array<RealGameWhereInput>>;
  NOT?: InputMaybe<Array<RealGameWhereInput>>;
  OR?: InputMaybe<Array<RealGameWhereInput>>;
  awayScore?: InputMaybe<IntFilter>;
  awayTeam?: InputMaybe<TeamNullableRelationFilter>;
  awayTeamId?: InputMaybe<IntNullableFilter>;
  awayTeamName?: InputMaybe<StringNullableFilter>;
  awayTeamOptaId?: InputMaybe<StringNullableFilter>;
  championshipSeason?: InputMaybe<ChampionshipSeasonRelationFilter>;
  championshipSeasonId?: InputMaybe<IntFilter>;
  compName?: InputMaybe<StringFilter>;
  date?: InputMaybe<StringNullableFilter>;
  datetime?: InputMaybe<DateTimeNullableFilter>;
  homeScore?: InputMaybe<IntFilter>;
  homeTeam?: InputMaybe<TeamNullableRelationFilter>;
  homeTeamId?: InputMaybe<IntNullableFilter>;
  homeTeamName?: InputMaybe<StringFilter>;
  homeTeamOptaId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  realGameOptaId?: InputMaybe<Scalars["String"]["input"]>;
  realGamePlayers?: InputMaybe<RealGamePlayerListRelationFilter>;
  round?: InputMaybe<IntFilter>;
  roundType?: InputMaybe<EnumRoundTypeFilter>;
  seasonOptaId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumRealGameStatusFilter>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameListRelationFilter>;
  winnerTeamOptaId?: InputMaybe<StringNullableFilter>;
};

export type Reward = {
  __typename?: "Reward";
  _count: RewardCount;
  criteria: RewardCriteria;
  displayedQuantity?: Maybe<Scalars["Int"]["output"]>;
  gameWeekRewards?: Maybe<Array<GameWeekReward>>;
  id: Scalars["ID"]["output"];
  name?: Maybe<Translatable>;
  nameId?: Maybe<Scalars["Int"]["output"]>;
  pointsMin: Scalars["Int"]["output"];
  questionInput?: Maybe<Translatable>;
  questionInputId?: Maybe<Scalars["Int"]["output"]>;
  rankingFrom: Scalars["Int"]["output"];
  rankingTo: Scalars["Int"]["output"];
  rewardType: RewardType;
};

export type RewardClaim = {
  __typename?: "RewardClaim";
  composition: Composition;
  compositionId: Scalars["Int"]["output"];
  gameWeekReward: GameWeekReward;
  gameWeekRewardId: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  marketPlaceCardTokenId?: Maybe<Scalars["String"]["output"]>;
  marketPlaceUserId: Scalars["String"]["output"];
  questionOutput?: Maybe<Scalars["String"]["output"]>;
  rewardClaimState: RewardClaimState;
};

export type RewardClaimCreateManyCompositionInput = {
  gameWeekRewardId: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  marketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  marketPlaceUserId: Scalars["String"]["input"];
  questionOutput?: InputMaybe<Scalars["String"]["input"]>;
  rewardClaimState: RewardClaimState;
};

export type RewardClaimCreateManyCompositionInputEnvelope = {
  data: Array<RewardClaimCreateManyCompositionInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RewardClaimCreateManyGameWeekRewardInput = {
  compositionId: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  marketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  marketPlaceUserId: Scalars["String"]["input"];
  questionOutput?: InputMaybe<Scalars["String"]["input"]>;
  rewardClaimState: RewardClaimState;
};

export type RewardClaimCreateManyGameWeekRewardInputEnvelope = {
  data: Array<RewardClaimCreateManyGameWeekRewardInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RewardClaimCreateNestedManyWithoutCompositionInput = {
  connect?: InputMaybe<Array<RewardClaimWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RewardClaimCreateOrConnectWithoutCompositionInput>>;
  create?: InputMaybe<Array<RewardClaimCreateWithoutCompositionInput>>;
  createMany?: InputMaybe<RewardClaimCreateManyCompositionInputEnvelope>;
};

export type RewardClaimCreateNestedManyWithoutGameWeekRewardInput = {
  connect?: InputMaybe<Array<RewardClaimWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RewardClaimCreateOrConnectWithoutGameWeekRewardInput>>;
  create?: InputMaybe<Array<RewardClaimCreateWithoutGameWeekRewardInput>>;
  createMany?: InputMaybe<RewardClaimCreateManyGameWeekRewardInputEnvelope>;
};

export type RewardClaimCreateOrConnectWithoutCompositionInput = {
  create: RewardClaimCreateWithoutCompositionInput;
  where: RewardClaimWhereUniqueInput;
};

export type RewardClaimCreateOrConnectWithoutGameWeekRewardInput = {
  create: RewardClaimCreateWithoutGameWeekRewardInput;
  where: RewardClaimWhereUniqueInput;
};

export type RewardClaimCreateWithoutCompositionInput = {
  gameWeekReward: GameWeekRewardCreateNestedOneWithoutRewardClaimsInput;
  marketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  marketPlaceUserId: Scalars["String"]["input"];
  questionOutput?: InputMaybe<Scalars["String"]["input"]>;
  rewardClaimState: RewardClaimState;
};

export type RewardClaimCreateWithoutGameWeekRewardInput = {
  composition: CompositionCreateNestedOneWithoutRewardClaimsInput;
  marketPlaceCardTokenId?: InputMaybe<Scalars["String"]["input"]>;
  marketPlaceUserId: Scalars["String"]["input"];
  questionOutput?: InputMaybe<Scalars["String"]["input"]>;
  rewardClaimState: RewardClaimState;
};

export type RewardClaimListRelationFilter = {
  every?: InputMaybe<RewardClaimWhereInput>;
  none?: InputMaybe<RewardClaimWhereInput>;
  some?: InputMaybe<RewardClaimWhereInput>;
};

export type RewardClaimOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum RewardClaimOrderByRelevanceFieldEnum {
  MarketPlaceCardTokenId = "marketPlaceCardTokenId",
  MarketPlaceUserId = "marketPlaceUserId",
  QuestionOutput = "questionOutput",
}

export type RewardClaimOrderByRelevanceInput = {
  fields: Array<RewardClaimOrderByRelevanceFieldEnum>;
  search: Scalars["String"]["input"];
  sort: SortOrder;
};

export type RewardClaimOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<RewardClaimOrderByRelevanceInput>;
  composition?: InputMaybe<CompositionOrderByWithRelationAndSearchRelevanceInput>;
  compositionId?: InputMaybe<SortOrder>;
  gameWeekReward?: InputMaybe<GameWeekRewardOrderByWithRelationAndSearchRelevanceInput>;
  gameWeekRewardId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  marketPlaceCardTokenId?: InputMaybe<SortOrderInput>;
  marketPlaceUserId?: InputMaybe<SortOrder>;
  questionOutput?: InputMaybe<SortOrderInput>;
  rewardClaimState?: InputMaybe<SortOrder>;
};

export enum RewardClaimScalarFieldEnum {
  CompositionId = "compositionId",
  GameWeekRewardId = "gameWeekRewardId",
  Id = "id",
  MarketPlaceCardTokenId = "marketPlaceCardTokenId",
  MarketPlaceUserId = "marketPlaceUserId",
  QuestionOutput = "questionOutput",
  RewardClaimState = "rewardClaimState",
}

export type RewardClaimScalarWhereInput = {
  AND?: InputMaybe<Array<RewardClaimScalarWhereInput>>;
  NOT?: InputMaybe<Array<RewardClaimScalarWhereInput>>;
  OR?: InputMaybe<Array<RewardClaimScalarWhereInput>>;
  compositionId?: InputMaybe<IntFilter>;
  gameWeekRewardId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  marketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  marketPlaceUserId?: InputMaybe<StringFilter>;
  questionOutput?: InputMaybe<StringNullableFilter>;
  rewardClaimState?: InputMaybe<EnumRewardClaimStateFilter>;
};

export enum RewardClaimState {
  Canceled = "CANCELED",
  Claimed = "CLAIMED",
  Created = "CREATED",
  Done = "DONE",
  Sent = "SENT",
}

export type RewardClaimUpdateInput = {
  gameWeekRewardId?: InputMaybe<IntFieldUpdateOperationsInput>;
  marketPlaceUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionOutput: StringFieldUpdateOperationsInput;
  rewardClaimState: EnumRewardClaimStateFieldUpdateOperationsInput;
};

export type RewardClaimUpdateManyMutationInput = {
  marketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  marketPlaceUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionOutput?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rewardClaimState?: InputMaybe<EnumRewardClaimStateFieldUpdateOperationsInput>;
};

export type RewardClaimUpdateManyWithWhereWithoutCompositionInput = {
  data: RewardClaimUpdateManyMutationInput;
  where: RewardClaimScalarWhereInput;
};

export type RewardClaimUpdateManyWithWhereWithoutGameWeekRewardInput = {
  data: RewardClaimUpdateManyMutationInput;
  where: RewardClaimScalarWhereInput;
};

export type RewardClaimUpdateManyWithoutCompositionNestedInput = {
  connect?: InputMaybe<Array<RewardClaimWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RewardClaimCreateOrConnectWithoutCompositionInput>>;
  create?: InputMaybe<Array<RewardClaimCreateWithoutCompositionInput>>;
  createMany?: InputMaybe<RewardClaimCreateManyCompositionInputEnvelope>;
  delete?: InputMaybe<Array<RewardClaimWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RewardClaimScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RewardClaimWhereUniqueInput>>;
  set?: InputMaybe<Array<RewardClaimWhereUniqueInput>>;
  update?: InputMaybe<Array<RewardClaimUpdateWithWhereUniqueWithoutCompositionInput>>;
  updateMany?: InputMaybe<Array<RewardClaimUpdateManyWithWhereWithoutCompositionInput>>;
  upsert?: InputMaybe<Array<RewardClaimUpsertWithWhereUniqueWithoutCompositionInput>>;
};

export type RewardClaimUpdateManyWithoutGameWeekRewardNestedInput = {
  connect?: InputMaybe<Array<RewardClaimWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RewardClaimCreateOrConnectWithoutGameWeekRewardInput>>;
  create?: InputMaybe<Array<RewardClaimCreateWithoutGameWeekRewardInput>>;
  createMany?: InputMaybe<RewardClaimCreateManyGameWeekRewardInputEnvelope>;
  delete?: InputMaybe<Array<RewardClaimWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RewardClaimScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RewardClaimWhereUniqueInput>>;
  set?: InputMaybe<Array<RewardClaimWhereUniqueInput>>;
  update?: InputMaybe<Array<RewardClaimUpdateWithWhereUniqueWithoutGameWeekRewardInput>>;
  updateMany?: InputMaybe<Array<RewardClaimUpdateManyWithWhereWithoutGameWeekRewardInput>>;
  upsert?: InputMaybe<Array<RewardClaimUpsertWithWhereUniqueWithoutGameWeekRewardInput>>;
};

export type RewardClaimUpdateWithWhereUniqueWithoutCompositionInput = {
  data: RewardClaimUpdateWithoutCompositionInput;
  where: RewardClaimWhereUniqueInput;
};

export type RewardClaimUpdateWithWhereUniqueWithoutGameWeekRewardInput = {
  data: RewardClaimUpdateWithoutGameWeekRewardInput;
  where: RewardClaimWhereUniqueInput;
};

export type RewardClaimUpdateWithoutCompositionInput = {
  gameWeekReward?: InputMaybe<GameWeekRewardUpdateOneRequiredWithoutRewardClaimsNestedInput>;
  marketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  marketPlaceUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionOutput?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rewardClaimState?: InputMaybe<EnumRewardClaimStateFieldUpdateOperationsInput>;
};

export type RewardClaimUpdateWithoutGameWeekRewardInput = {
  composition?: InputMaybe<CompositionUpdateOneRequiredWithoutRewardClaimsNestedInput>;
  marketPlaceCardTokenId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  marketPlaceUserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionOutput?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rewardClaimState?: InputMaybe<EnumRewardClaimStateFieldUpdateOperationsInput>;
};

export type RewardClaimUpsertWithWhereUniqueWithoutCompositionInput = {
  create: RewardClaimCreateWithoutCompositionInput;
  update: RewardClaimUpdateWithoutCompositionInput;
  where: RewardClaimWhereUniqueInput;
};

export type RewardClaimUpsertWithWhereUniqueWithoutGameWeekRewardInput = {
  create: RewardClaimCreateWithoutGameWeekRewardInput;
  update: RewardClaimUpdateWithoutGameWeekRewardInput;
  where: RewardClaimWhereUniqueInput;
};

export type RewardClaimWhereInput = {
  AND?: InputMaybe<Array<RewardClaimWhereInput>>;
  NOT?: InputMaybe<Array<RewardClaimWhereInput>>;
  OR?: InputMaybe<Array<RewardClaimWhereInput>>;
  composition?: InputMaybe<CompositionRelationFilter>;
  compositionId?: InputMaybe<IntFilter>;
  gameWeekReward?: InputMaybe<GameWeekRewardRelationFilter>;
  gameWeekRewardId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  marketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  marketPlaceUserId?: InputMaybe<StringFilter>;
  questionOutput?: InputMaybe<StringNullableFilter>;
  rewardClaimState?: InputMaybe<EnumRewardClaimStateFilter>;
};

export type RewardClaimWhereUniqueInput = {
  AND?: InputMaybe<Array<RewardClaimWhereInput>>;
  NOT?: InputMaybe<Array<RewardClaimWhereInput>>;
  OR?: InputMaybe<Array<RewardClaimWhereInput>>;
  composition?: InputMaybe<CompositionRelationFilter>;
  compositionId?: InputMaybe<IntFilter>;
  gameWeekReward?: InputMaybe<GameWeekRewardRelationFilter>;
  gameWeekRewardId?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  marketPlaceCardTokenId?: InputMaybe<StringNullableFilter>;
  marketPlaceUserId?: InputMaybe<StringFilter>;
  questionOutput?: InputMaybe<StringNullableFilter>;
  rewardClaimState?: InputMaybe<EnumRewardClaimStateFilter>;
};

export type RewardCount = {
  __typename?: "RewardCount";
  gameWeekRewards: Scalars["Int"]["output"];
};

export type RewardCreateInput = {
  criteria: RewardCriteria;
  displayedQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  nameId: Scalars["Int"]["input"];
  pointsMin: Scalars["Int"]["input"];
  questionInputId: Scalars["Int"]["input"];
  rankingFrom: Scalars["Int"]["input"];
  rankingTo: Scalars["Int"]["input"];
  rewardType: RewardType;
};

export type RewardCreateManyNameInput = {
  criteria: RewardCriteria;
  displayedQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  pointsMin: Scalars["Int"]["input"];
  questionInputId?: InputMaybe<Scalars["Int"]["input"]>;
  rankingFrom: Scalars["Int"]["input"];
  rankingTo: Scalars["Int"]["input"];
  rewardType: RewardType;
};

export type RewardCreateManyNameInputEnvelope = {
  data: Array<RewardCreateManyNameInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RewardCreateManyQuestionInputInput = {
  criteria: RewardCriteria;
  displayedQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  nameId?: InputMaybe<Scalars["Int"]["input"]>;
  pointsMin: Scalars["Int"]["input"];
  rankingFrom: Scalars["Int"]["input"];
  rankingTo: Scalars["Int"]["input"];
  rewardType: RewardType;
};

export type RewardCreateManyQuestionInputInputEnvelope = {
  data: Array<RewardCreateManyQuestionInputInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RewardCreateNestedManyWithoutNameInput = {
  connect?: InputMaybe<Array<RewardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RewardCreateOrConnectWithoutNameInput>>;
  create?: InputMaybe<Array<RewardCreateWithoutNameInput>>;
  createMany?: InputMaybe<RewardCreateManyNameInputEnvelope>;
};

export type RewardCreateNestedManyWithoutQuestionInputInput = {
  connect?: InputMaybe<Array<RewardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RewardCreateOrConnectWithoutQuestionInputInput>>;
  create?: InputMaybe<Array<RewardCreateWithoutQuestionInputInput>>;
  createMany?: InputMaybe<RewardCreateManyQuestionInputInputEnvelope>;
};

export type RewardCreateNestedOneWithoutGameWeekRewardsInput = {
  connect?: InputMaybe<RewardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RewardCreateOrConnectWithoutGameWeekRewardsInput>;
  create?: InputMaybe<RewardCreateWithoutGameWeekRewardsInput>;
};

export type RewardCreateOrConnectWithoutGameWeekRewardsInput = {
  create: RewardCreateWithoutGameWeekRewardsInput;
  where: RewardWhereUniqueInput;
};

export type RewardCreateOrConnectWithoutNameInput = {
  create: RewardCreateWithoutNameInput;
  where: RewardWhereUniqueInput;
};

export type RewardCreateOrConnectWithoutQuestionInputInput = {
  create: RewardCreateWithoutQuestionInputInput;
  where: RewardWhereUniqueInput;
};

export type RewardCreateWithoutGameWeekRewardsInput = {
  criteria: RewardCriteria;
  displayedQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<TranslatableCreateNestedOneWithoutRewardsInput>;
  pointsMin: Scalars["Int"]["input"];
  questionInput?: InputMaybe<TranslatableCreateNestedOneWithoutQuestionsInput>;
  rankingFrom: Scalars["Int"]["input"];
  rankingTo: Scalars["Int"]["input"];
  rewardType: RewardType;
};

export type RewardCreateWithoutNameInput = {
  criteria: RewardCriteria;
  displayedQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  gameWeekRewards?: InputMaybe<GameWeekRewardCreateNestedManyWithoutRewardInput>;
  pointsMin: Scalars["Int"]["input"];
  questionInput?: InputMaybe<TranslatableCreateNestedOneWithoutQuestionsInput>;
  rankingFrom: Scalars["Int"]["input"];
  rankingTo: Scalars["Int"]["input"];
  rewardType: RewardType;
};

export type RewardCreateWithoutQuestionInputInput = {
  criteria: RewardCriteria;
  displayedQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  gameWeekRewards?: InputMaybe<GameWeekRewardCreateNestedManyWithoutRewardInput>;
  name?: InputMaybe<TranslatableCreateNestedOneWithoutRewardsInput>;
  pointsMin: Scalars["Int"]["input"];
  rankingFrom: Scalars["Int"]["input"];
  rankingTo: Scalars["Int"]["input"];
  rewardType: RewardType;
};

export enum RewardCriteria {
  Points = "POINTS",
  Ranking = "RANKING",
}

export type RewardListRelationFilter = {
  every?: InputMaybe<RewardWhereInput>;
  none?: InputMaybe<RewardWhereInput>;
  some?: InputMaybe<RewardWhereInput>;
};

export type RewardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RewardOrderByWithRelationAndSearchRelevanceInput = {
  criteria?: InputMaybe<SortOrder>;
  displayedQuantity?: InputMaybe<SortOrderInput>;
  gameWeekRewards?: InputMaybe<GameWeekRewardOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<TranslatableOrderByWithRelationAndSearchRelevanceInput>;
  nameId?: InputMaybe<SortOrderInput>;
  pointsMin?: InputMaybe<SortOrder>;
  questionInput?: InputMaybe<TranslatableOrderByWithRelationAndSearchRelevanceInput>;
  questionInputId?: InputMaybe<SortOrderInput>;
  rankingFrom?: InputMaybe<SortOrder>;
  rankingTo?: InputMaybe<SortOrder>;
  rewardType?: InputMaybe<SortOrder>;
};

export type RewardRelationFilter = {
  is?: InputMaybe<RewardWhereInput>;
  isNot?: InputMaybe<RewardWhereInput>;
};

export enum RewardScalarFieldEnum {
  Criteria = "criteria",
  DisplayedQuantity = "displayedQuantity",
  Id = "id",
  NameId = "nameId",
  PointsMin = "pointsMin",
  QuestionInputId = "questionInputId",
  RankingFrom = "rankingFrom",
  RankingTo = "rankingTo",
  RewardType = "rewardType",
}

export type RewardScalarWhereInput = {
  AND?: InputMaybe<Array<RewardScalarWhereInput>>;
  NOT?: InputMaybe<Array<RewardScalarWhereInput>>;
  OR?: InputMaybe<Array<RewardScalarWhereInput>>;
  criteria?: InputMaybe<EnumRewardCriteriaFilter>;
  displayedQuantity?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  nameId?: InputMaybe<IntNullableFilter>;
  pointsMin?: InputMaybe<IntFilter>;
  questionInputId?: InputMaybe<IntNullableFilter>;
  rankingFrom?: InputMaybe<IntFilter>;
  rankingTo?: InputMaybe<IntFilter>;
  rewardType?: InputMaybe<EnumRewardTypeFilter>;
};

export enum RewardType {
  CardLimited = "CARD_LIMITED",
  CardRare = "CARD_RARE",
  CardSuperRare = "CARD_SUPER_RARE",
  CardUnique = "CARD_UNIQUE",
  Item = "ITEM",
  Matic = "MATIC",
  Oval3Token = "OVAL3_TOKEN",
}

export type RewardUpdateInput = {
  criteria?: InputMaybe<EnumRewardCriteriaFieldUpdateOperationsInput>;
  displayedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  nameId?: InputMaybe<IntFieldUpdateOperationsInput>;
  pointsMin?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionInputId?: InputMaybe<IntFieldUpdateOperationsInput>;
  rankingFrom?: InputMaybe<IntFieldUpdateOperationsInput>;
  rankingTo?: InputMaybe<IntFieldUpdateOperationsInput>;
  rewardType?: InputMaybe<EnumRewardTypeFieldUpdateOperationsInput>;
};

export type RewardUpdateManyMutationInput = {
  criteria?: InputMaybe<EnumRewardCriteriaFieldUpdateOperationsInput>;
  displayedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pointsMin?: InputMaybe<IntFieldUpdateOperationsInput>;
  rankingFrom?: InputMaybe<IntFieldUpdateOperationsInput>;
  rankingTo?: InputMaybe<IntFieldUpdateOperationsInput>;
  rewardType?: InputMaybe<EnumRewardTypeFieldUpdateOperationsInput>;
};

export type RewardUpdateManyWithWhereWithoutNameInput = {
  data: RewardUpdateManyMutationInput;
  where: RewardScalarWhereInput;
};

export type RewardUpdateManyWithWhereWithoutQuestionInputInput = {
  data: RewardUpdateManyMutationInput;
  where: RewardScalarWhereInput;
};

export type RewardUpdateManyWithoutNameNestedInput = {
  connect?: InputMaybe<Array<RewardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RewardCreateOrConnectWithoutNameInput>>;
  create?: InputMaybe<Array<RewardCreateWithoutNameInput>>;
  createMany?: InputMaybe<RewardCreateManyNameInputEnvelope>;
  delete?: InputMaybe<Array<RewardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RewardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RewardWhereUniqueInput>>;
  set?: InputMaybe<Array<RewardWhereUniqueInput>>;
  update?: InputMaybe<Array<RewardUpdateWithWhereUniqueWithoutNameInput>>;
  updateMany?: InputMaybe<Array<RewardUpdateManyWithWhereWithoutNameInput>>;
  upsert?: InputMaybe<Array<RewardUpsertWithWhereUniqueWithoutNameInput>>;
};

export type RewardUpdateManyWithoutQuestionInputNestedInput = {
  connect?: InputMaybe<Array<RewardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RewardCreateOrConnectWithoutQuestionInputInput>>;
  create?: InputMaybe<Array<RewardCreateWithoutQuestionInputInput>>;
  createMany?: InputMaybe<RewardCreateManyQuestionInputInputEnvelope>;
  delete?: InputMaybe<Array<RewardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RewardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RewardWhereUniqueInput>>;
  set?: InputMaybe<Array<RewardWhereUniqueInput>>;
  update?: InputMaybe<Array<RewardUpdateWithWhereUniqueWithoutQuestionInputInput>>;
  updateMany?: InputMaybe<Array<RewardUpdateManyWithWhereWithoutQuestionInputInput>>;
  upsert?: InputMaybe<Array<RewardUpsertWithWhereUniqueWithoutQuestionInputInput>>;
};

export type RewardUpdateOneRequiredWithoutGameWeekRewardsNestedInput = {
  connect?: InputMaybe<RewardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RewardCreateOrConnectWithoutGameWeekRewardsInput>;
  create?: InputMaybe<RewardCreateWithoutGameWeekRewardsInput>;
  update?: InputMaybe<RewardUpdateToOneWithWhereWithoutGameWeekRewardsInput>;
  upsert?: InputMaybe<RewardUpsertWithoutGameWeekRewardsInput>;
};

export type RewardUpdateToOneWithWhereWithoutGameWeekRewardsInput = {
  data: RewardUpdateWithoutGameWeekRewardsInput;
  where?: InputMaybe<RewardWhereInput>;
};

export type RewardUpdateWithWhereUniqueWithoutNameInput = {
  data: RewardUpdateWithoutNameInput;
  where: RewardWhereUniqueInput;
};

export type RewardUpdateWithWhereUniqueWithoutQuestionInputInput = {
  data: RewardUpdateWithoutQuestionInputInput;
  where: RewardWhereUniqueInput;
};

export type RewardUpdateWithoutGameWeekRewardsInput = {
  criteria?: InputMaybe<EnumRewardCriteriaFieldUpdateOperationsInput>;
  displayedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<TranslatableUpdateOneWithoutRewardsNestedInput>;
  pointsMin?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionInput?: InputMaybe<TranslatableUpdateOneWithoutQuestionsNestedInput>;
  rankingFrom?: InputMaybe<IntFieldUpdateOperationsInput>;
  rankingTo?: InputMaybe<IntFieldUpdateOperationsInput>;
  rewardType?: InputMaybe<EnumRewardTypeFieldUpdateOperationsInput>;
};

export type RewardUpdateWithoutNameInput = {
  criteria?: InputMaybe<EnumRewardCriteriaFieldUpdateOperationsInput>;
  displayedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  gameWeekRewards?: InputMaybe<GameWeekRewardUpdateManyWithoutRewardNestedInput>;
  pointsMin?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionInput?: InputMaybe<TranslatableUpdateOneWithoutQuestionsNestedInput>;
  rankingFrom?: InputMaybe<IntFieldUpdateOperationsInput>;
  rankingTo?: InputMaybe<IntFieldUpdateOperationsInput>;
  rewardType?: InputMaybe<EnumRewardTypeFieldUpdateOperationsInput>;
};

export type RewardUpdateWithoutQuestionInputInput = {
  criteria?: InputMaybe<EnumRewardCriteriaFieldUpdateOperationsInput>;
  displayedQuantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  gameWeekRewards?: InputMaybe<GameWeekRewardUpdateManyWithoutRewardNestedInput>;
  name?: InputMaybe<TranslatableUpdateOneWithoutRewardsNestedInput>;
  pointsMin?: InputMaybe<IntFieldUpdateOperationsInput>;
  rankingFrom?: InputMaybe<IntFieldUpdateOperationsInput>;
  rankingTo?: InputMaybe<IntFieldUpdateOperationsInput>;
  rewardType?: InputMaybe<EnumRewardTypeFieldUpdateOperationsInput>;
};

export type RewardUpsertInput = {
  displayedQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  pointsMin?: InputMaybe<Scalars["Int"]["input"]>;
  rankingFrom?: InputMaybe<Scalars["Int"]["input"]>;
  rankingTo?: InputMaybe<Scalars["Int"]["input"]>;
  rewardType?: InputMaybe<RewardType>;
  topPercent?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RewardUpsertOperationInput = {
  set?: InputMaybe<Array<RewardUpsertInput>>;
};

export type RewardUpsertWithWhereUniqueWithoutNameInput = {
  create: RewardCreateWithoutNameInput;
  update: RewardUpdateWithoutNameInput;
  where: RewardWhereUniqueInput;
};

export type RewardUpsertWithWhereUniqueWithoutQuestionInputInput = {
  create: RewardCreateWithoutQuestionInputInput;
  update: RewardUpdateWithoutQuestionInputInput;
  where: RewardWhereUniqueInput;
};

export type RewardUpsertWithoutGameWeekRewardsInput = {
  create: RewardCreateWithoutGameWeekRewardsInput;
  update: RewardUpdateWithoutGameWeekRewardsInput;
  where?: InputMaybe<RewardWhereInput>;
};

export type RewardWhereInput = {
  AND?: InputMaybe<Array<RewardWhereInput>>;
  NOT?: InputMaybe<Array<RewardWhereInput>>;
  OR?: InputMaybe<Array<RewardWhereInput>>;
  criteria?: InputMaybe<EnumRewardCriteriaFilter>;
  displayedQuantity?: InputMaybe<IntNullableFilter>;
  gameWeekRewards?: InputMaybe<GameWeekRewardListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<TranslatableNullableRelationFilter>;
  nameId?: InputMaybe<IntNullableFilter>;
  pointsMin?: InputMaybe<IntFilter>;
  questionInput?: InputMaybe<TranslatableNullableRelationFilter>;
  questionInputId?: InputMaybe<IntNullableFilter>;
  rankingFrom?: InputMaybe<IntFilter>;
  rankingTo?: InputMaybe<IntFilter>;
  rewardType?: InputMaybe<EnumRewardTypeFilter>;
};

export type RewardWhereUniqueInput = {
  AND?: InputMaybe<Array<RewardWhereInput>>;
  NOT?: InputMaybe<Array<RewardWhereInput>>;
  OR?: InputMaybe<Array<RewardWhereInput>>;
  criteria?: InputMaybe<EnumRewardCriteriaFilter>;
  displayedQuantity?: InputMaybe<IntNullableFilter>;
  gameWeekRewards?: InputMaybe<GameWeekRewardListRelationFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<TranslatableNullableRelationFilter>;
  nameId?: InputMaybe<IntNullableFilter>;
  pointsMin?: InputMaybe<IntFilter>;
  questionInput?: InputMaybe<TranslatableNullableRelationFilter>;
  questionInputId?: InputMaybe<IntNullableFilter>;
  rankingFrom?: InputMaybe<IntFilter>;
  rankingTo?: InputMaybe<IntFilter>;
  rewardType?: InputMaybe<EnumRewardTypeFilter>;
};

export enum RoundType {
  Group = "GROUP",
  Playoff = "PLAYOFF",
  Unknown = "UNKNOWN",
}

export enum ScarcityType {
  Common = "COMMON",
  Limited = "LIMITED",
  Rare = "RARE",
  SuperRare = "SUPER_RARE",
  Unique = "UNIQUE",
}

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringArrayFieldUpdateOperationsInput = {
  push?: InputMaybe<Array<Scalars["String"]["input"]>>;
  set?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type Team = {
  __typename?: "Team";
  _count: TeamCount;
  awayRealGames?: Maybe<Array<RealGame>>;
  championshipSeasonTeamPlayers?: Maybe<Array<ChampionshipSeasonTeamPlayer>>;
  championshipSeasons?: Maybe<Array<ChampionshipSeason>>;
  coachOptaId?: Maybe<Scalars["String"]["output"]>;
  homeRealGames?: Maybe<Array<RealGame>>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  players?: Maybe<Array<Player>>;
  teamOptaId: Scalars["String"]["output"];
};

export type TeamCount = {
  __typename?: "TeamCount";
  awayRealGames: Scalars["Int"]["output"];
  championshipSeasonTeamPlayers: Scalars["Int"]["output"];
  championshipSeasons: Scalars["Int"]["output"];
  homeRealGames: Scalars["Int"]["output"];
  players: Scalars["Int"]["output"];
};

export type TeamCreateInput = {
  awayRealGames?: InputMaybe<RealGameCreateNestedManyWithoutHomeTeamInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerCreateNestedManyWithoutTeamInput>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonCreateNestedManyWithoutTeamsInput>;
  coachOptaId?: InputMaybe<Scalars["String"]["input"]>;
  homeRealGames?: InputMaybe<RealGameCreateNestedManyWithoutAwayTeamInput>;
  name: Scalars["String"]["input"];
  players?: InputMaybe<PlayerCreateNestedManyWithoutTeamInput>;
  teamOptaId: Scalars["String"]["input"];
};

export type TeamCreateNestedManyWithoutChampionshipSeasonsInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutChampionshipSeasonsInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutChampionshipSeasonsInput>>;
};

export type TeamCreateNestedOneWithoutAwayRealGamesInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutAwayRealGamesInput>;
  create?: InputMaybe<TeamCreateWithoutAwayRealGamesInput>;
};

export type TeamCreateNestedOneWithoutChampionshipSeasonTeamPlayersInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutChampionshipSeasonTeamPlayersInput>;
  create?: InputMaybe<TeamCreateWithoutChampionshipSeasonTeamPlayersInput>;
};

export type TeamCreateNestedOneWithoutHomeRealGamesInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutHomeRealGamesInput>;
  create?: InputMaybe<TeamCreateWithoutHomeRealGamesInput>;
};

export type TeamCreateNestedOneWithoutPlayersInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutPlayersInput>;
  create?: InputMaybe<TeamCreateWithoutPlayersInput>;
};

export type TeamCreateOrConnectWithoutAwayRealGamesInput = {
  create: TeamCreateWithoutAwayRealGamesInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutChampionshipSeasonTeamPlayersInput = {
  create: TeamCreateWithoutChampionshipSeasonTeamPlayersInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutChampionshipSeasonsInput = {
  create: TeamCreateWithoutChampionshipSeasonsInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutHomeRealGamesInput = {
  create: TeamCreateWithoutHomeRealGamesInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutPlayersInput = {
  create: TeamCreateWithoutPlayersInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateWithoutAwayRealGamesInput = {
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerCreateNestedManyWithoutTeamInput>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonCreateNestedManyWithoutTeamsInput>;
  coachOptaId?: InputMaybe<Scalars["String"]["input"]>;
  homeRealGames?: InputMaybe<RealGameCreateNestedManyWithoutAwayTeamInput>;
  name: Scalars["String"]["input"];
  players?: InputMaybe<PlayerCreateNestedManyWithoutTeamInput>;
  teamOptaId: Scalars["String"]["input"];
};

export type TeamCreateWithoutChampionshipSeasonTeamPlayersInput = {
  awayRealGames?: InputMaybe<RealGameCreateNestedManyWithoutHomeTeamInput>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonCreateNestedManyWithoutTeamsInput>;
  coachOptaId?: InputMaybe<Scalars["String"]["input"]>;
  homeRealGames?: InputMaybe<RealGameCreateNestedManyWithoutAwayTeamInput>;
  name: Scalars["String"]["input"];
  players?: InputMaybe<PlayerCreateNestedManyWithoutTeamInput>;
  teamOptaId: Scalars["String"]["input"];
};

export type TeamCreateWithoutChampionshipSeasonsInput = {
  awayRealGames?: InputMaybe<RealGameCreateNestedManyWithoutHomeTeamInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerCreateNestedManyWithoutTeamInput>;
  coachOptaId?: InputMaybe<Scalars["String"]["input"]>;
  homeRealGames?: InputMaybe<RealGameCreateNestedManyWithoutAwayTeamInput>;
  name: Scalars["String"]["input"];
  players?: InputMaybe<PlayerCreateNestedManyWithoutTeamInput>;
  teamOptaId: Scalars["String"]["input"];
};

export type TeamCreateWithoutHomeRealGamesInput = {
  awayRealGames?: InputMaybe<RealGameCreateNestedManyWithoutHomeTeamInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerCreateNestedManyWithoutTeamInput>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonCreateNestedManyWithoutTeamsInput>;
  coachOptaId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  players?: InputMaybe<PlayerCreateNestedManyWithoutTeamInput>;
  teamOptaId: Scalars["String"]["input"];
};

export type TeamCreateWithoutPlayersInput = {
  awayRealGames?: InputMaybe<RealGameCreateNestedManyWithoutHomeTeamInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerCreateNestedManyWithoutTeamInput>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonCreateNestedManyWithoutTeamsInput>;
  coachOptaId?: InputMaybe<Scalars["String"]["input"]>;
  homeRealGames?: InputMaybe<RealGameCreateNestedManyWithoutAwayTeamInput>;
  name: Scalars["String"]["input"];
  teamOptaId: Scalars["String"]["input"];
};

export type TeamListRelationFilter = {
  every?: InputMaybe<TeamWhereInput>;
  none?: InputMaybe<TeamWhereInput>;
  some?: InputMaybe<TeamWhereInput>;
};

export type TeamNullableRelationFilter = {
  is?: InputMaybe<TeamWhereInput>;
  isNot?: InputMaybe<TeamWhereInput>;
};

export type TeamOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum TeamOrderByRelevanceFieldEnum {
  CoachOptaId = "coachOptaId",
  Name = "name",
  TeamOptaId = "teamOptaId",
}

export type TeamOrderByRelevanceInput = {
  fields: Array<TeamOrderByRelevanceFieldEnum>;
  search: Scalars["String"]["input"];
  sort: SortOrder;
};

export type TeamOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<TeamOrderByRelevanceInput>;
  awayRealGames?: InputMaybe<RealGameOrderByRelationAggregateInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerOrderByRelationAggregateInput>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonOrderByRelationAggregateInput>;
  coachOptaId?: InputMaybe<SortOrderInput>;
  homeRealGames?: InputMaybe<RealGameOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  players?: InputMaybe<PlayerOrderByRelationAggregateInput>;
  teamOptaId?: InputMaybe<SortOrder>;
};

export type TeamRelationFilter = {
  is?: InputMaybe<TeamWhereInput>;
  isNot?: InputMaybe<TeamWhereInput>;
};

export enum TeamScalarFieldEnum {
  CoachOptaId = "coachOptaId",
  Id = "id",
  Name = "name",
  TeamOptaId = "teamOptaId",
}

export type TeamScalarWhereInput = {
  AND?: InputMaybe<Array<TeamScalarWhereInput>>;
  NOT?: InputMaybe<Array<TeamScalarWhereInput>>;
  OR?: InputMaybe<Array<TeamScalarWhereInput>>;
  coachOptaId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  teamOptaId?: InputMaybe<StringFilter>;
};

export type TeamUpdateManyMutationInput = {
  coachOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TeamUpdateManyWithWhereWithoutChampionshipSeasonsInput = {
  data: TeamUpdateManyMutationInput;
  where: TeamScalarWhereInput;
};

export type TeamUpdateManyWithoutChampionshipSeasonsNestedInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutChampionshipSeasonsInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutChampionshipSeasonsInput>>;
  delete?: InputMaybe<Array<TeamWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamUpdateWithWhereUniqueWithoutChampionshipSeasonsInput>>;
  updateMany?: InputMaybe<Array<TeamUpdateManyWithWhereWithoutChampionshipSeasonsInput>>;
  upsert?: InputMaybe<Array<TeamUpsertWithWhereUniqueWithoutChampionshipSeasonsInput>>;
};

export type TeamUpdateOneRequiredWithoutChampionshipSeasonTeamPlayersNestedInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutChampionshipSeasonTeamPlayersInput>;
  create?: InputMaybe<TeamCreateWithoutChampionshipSeasonTeamPlayersInput>;
  update?: InputMaybe<TeamUpdateToOneWithWhereWithoutChampionshipSeasonTeamPlayersInput>;
  upsert?: InputMaybe<TeamUpsertWithoutChampionshipSeasonTeamPlayersInput>;
};

export type TeamUpdateOneWithoutAwayRealGamesNestedInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutAwayRealGamesInput>;
  create?: InputMaybe<TeamCreateWithoutAwayRealGamesInput>;
  delete?: InputMaybe<TeamWhereInput>;
  disconnect?: InputMaybe<TeamWhereInput>;
  update?: InputMaybe<TeamUpdateToOneWithWhereWithoutAwayRealGamesInput>;
  upsert?: InputMaybe<TeamUpsertWithoutAwayRealGamesInput>;
};

export type TeamUpdateOneWithoutHomeRealGamesNestedInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutHomeRealGamesInput>;
  create?: InputMaybe<TeamCreateWithoutHomeRealGamesInput>;
  delete?: InputMaybe<TeamWhereInput>;
  disconnect?: InputMaybe<TeamWhereInput>;
  update?: InputMaybe<TeamUpdateToOneWithWhereWithoutHomeRealGamesInput>;
  upsert?: InputMaybe<TeamUpsertWithoutHomeRealGamesInput>;
};

export type TeamUpdateOneWithoutPlayersNestedInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutPlayersInput>;
  create?: InputMaybe<TeamCreateWithoutPlayersInput>;
  delete?: InputMaybe<TeamWhereInput>;
  disconnect?: InputMaybe<TeamWhereInput>;
  update?: InputMaybe<TeamUpdateToOneWithWhereWithoutPlayersInput>;
  upsert?: InputMaybe<TeamUpsertWithoutPlayersInput>;
};

export type TeamUpdateToOneWithWhereWithoutAwayRealGamesInput = {
  data: TeamUpdateWithoutAwayRealGamesInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpdateToOneWithWhereWithoutChampionshipSeasonTeamPlayersInput = {
  data: TeamUpdateWithoutChampionshipSeasonTeamPlayersInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpdateToOneWithWhereWithoutHomeRealGamesInput = {
  data: TeamUpdateWithoutHomeRealGamesInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpdateToOneWithWhereWithoutPlayersInput = {
  data: TeamUpdateWithoutPlayersInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpdateWithWhereUniqueWithoutChampionshipSeasonsInput = {
  data: TeamUpdateWithoutChampionshipSeasonsInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpdateWithoutAwayRealGamesInput = {
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerUpdateManyWithoutTeamNestedInput>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonUpdateManyWithoutTeamsNestedInput>;
  coachOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homeRealGames?: InputMaybe<RealGameUpdateManyWithoutAwayTeamNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  players?: InputMaybe<PlayerUpdateManyWithoutTeamNestedInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutChampionshipSeasonTeamPlayersInput = {
  awayRealGames?: InputMaybe<RealGameUpdateManyWithoutHomeTeamNestedInput>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonUpdateManyWithoutTeamsNestedInput>;
  coachOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homeRealGames?: InputMaybe<RealGameUpdateManyWithoutAwayTeamNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  players?: InputMaybe<PlayerUpdateManyWithoutTeamNestedInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutChampionshipSeasonsInput = {
  awayRealGames?: InputMaybe<RealGameUpdateManyWithoutHomeTeamNestedInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerUpdateManyWithoutTeamNestedInput>;
  coachOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homeRealGames?: InputMaybe<RealGameUpdateManyWithoutAwayTeamNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  players?: InputMaybe<PlayerUpdateManyWithoutTeamNestedInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutHomeRealGamesInput = {
  awayRealGames?: InputMaybe<RealGameUpdateManyWithoutHomeTeamNestedInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerUpdateManyWithoutTeamNestedInput>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonUpdateManyWithoutTeamsNestedInput>;
  coachOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  players?: InputMaybe<PlayerUpdateManyWithoutTeamNestedInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutPlayersInput = {
  awayRealGames?: InputMaybe<RealGameUpdateManyWithoutHomeTeamNestedInput>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerUpdateManyWithoutTeamNestedInput>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonUpdateManyWithoutTeamsNestedInput>;
  coachOptaId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homeRealGames?: InputMaybe<RealGameUpdateManyWithoutAwayTeamNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  teamOptaId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TeamUpsertWithWhereUniqueWithoutChampionshipSeasonsInput = {
  create: TeamCreateWithoutChampionshipSeasonsInput;
  update: TeamUpdateWithoutChampionshipSeasonsInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpsertWithoutAwayRealGamesInput = {
  create: TeamCreateWithoutAwayRealGamesInput;
  update: TeamUpdateWithoutAwayRealGamesInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpsertWithoutChampionshipSeasonTeamPlayersInput = {
  create: TeamCreateWithoutChampionshipSeasonTeamPlayersInput;
  update: TeamUpdateWithoutChampionshipSeasonTeamPlayersInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpsertWithoutHomeRealGamesInput = {
  create: TeamCreateWithoutHomeRealGamesInput;
  update: TeamUpdateWithoutHomeRealGamesInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamUpsertWithoutPlayersInput = {
  create: TeamCreateWithoutPlayersInput;
  update: TeamUpdateWithoutPlayersInput;
  where?: InputMaybe<TeamWhereInput>;
};

export type TeamWhereInput = {
  AND?: InputMaybe<Array<TeamWhereInput>>;
  NOT?: InputMaybe<Array<TeamWhereInput>>;
  OR?: InputMaybe<Array<TeamWhereInput>>;
  awayRealGames?: InputMaybe<RealGameListRelationFilter>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerListRelationFilter>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonListRelationFilter>;
  coachOptaId?: InputMaybe<StringNullableFilter>;
  homeRealGames?: InputMaybe<RealGameListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  players?: InputMaybe<PlayerListRelationFilter>;
  teamOptaId?: InputMaybe<StringFilter>;
};

export type TeamWhereUniqueInput = {
  AND?: InputMaybe<Array<TeamWhereInput>>;
  NOT?: InputMaybe<Array<TeamWhereInput>>;
  OR?: InputMaybe<Array<TeamWhereInput>>;
  awayRealGames?: InputMaybe<RealGameListRelationFilter>;
  championshipSeasonTeamPlayers?: InputMaybe<ChampionshipSeasonTeamPlayerListRelationFilter>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonListRelationFilter>;
  coachOptaId?: InputMaybe<StringNullableFilter>;
  homeRealGames?: InputMaybe<RealGameListRelationFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<StringFilter>;
  players?: InputMaybe<PlayerListRelationFilter>;
  teamOptaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Tournament = {
  __typename?: "Tournament";
  _count: TournamentCount;
  championshipIds?: Maybe<Array<Scalars["Float"]["output"]>>;
  championshipSeasons?: Maybe<Array<ChampionshipSeason>>;
  id: Scalars["ID"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  name: Translatable;
  nameId: Scalars["Int"]["output"];
  tournamentChampionships?: Maybe<Array<TournamentChampionship>>;
  tournamentState: TournamentState;
  tournamentVariants?: Maybe<Array<TournamentVariant>>;
};

export type TournamentChampionship = {
  __typename?: "TournamentChampionship";
  championship: Championship;
  championshipId: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  tournament: Tournament;
  tournamentId: Scalars["Int"]["output"];
};

export type TournamentChampionshipCreateManyChampionshipInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tournamentId: Scalars["Int"]["input"];
};

export type TournamentChampionshipCreateManyChampionshipInputEnvelope = {
  data: Array<TournamentChampionshipCreateManyChampionshipInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TournamentChampionshipCreateManyTournamentInput = {
  championshipId: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TournamentChampionshipCreateManyTournamentInputEnvelope = {
  data: Array<TournamentChampionshipCreateManyTournamentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TournamentChampionshipCreateNestedManyWithoutChampionshipInput = {
  connect?: InputMaybe<Array<TournamentChampionshipWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentChampionshipCreateOrConnectWithoutChampionshipInput>>;
  create?: InputMaybe<Array<TournamentChampionshipCreateWithoutChampionshipInput>>;
  createMany?: InputMaybe<TournamentChampionshipCreateManyChampionshipInputEnvelope>;
};

export type TournamentChampionshipCreateNestedManyWithoutTournamentInput = {
  connect?: InputMaybe<Array<TournamentChampionshipWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentChampionshipCreateOrConnectWithoutTournamentInput>>;
  create?: InputMaybe<Array<TournamentChampionshipCreateWithoutTournamentInput>>;
  createMany?: InputMaybe<TournamentChampionshipCreateManyTournamentInputEnvelope>;
};

export type TournamentChampionshipCreateOrConnectWithoutChampionshipInput = {
  create: TournamentChampionshipCreateWithoutChampionshipInput;
  where: TournamentChampionshipWhereUniqueInput;
};

export type TournamentChampionshipCreateOrConnectWithoutTournamentInput = {
  create: TournamentChampionshipCreateWithoutTournamentInput;
  where: TournamentChampionshipWhereUniqueInput;
};

export type TournamentChampionshipCreateWithoutChampionshipInput = {
  tournament: TournamentCreateNestedOneWithoutTournamentChampionshipsInput;
};

export type TournamentChampionshipCreateWithoutTournamentInput = {
  championship: ChampionshipCreateNestedOneWithoutTournamentChampionshipsInput;
};

export type TournamentChampionshipListRelationFilter = {
  every?: InputMaybe<TournamentChampionshipWhereInput>;
  none?: InputMaybe<TournamentChampionshipWhereInput>;
  some?: InputMaybe<TournamentChampionshipWhereInput>;
};

export type TournamentChampionshipOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TournamentChampionshipScalarWhereInput = {
  AND?: InputMaybe<Array<TournamentChampionshipScalarWhereInput>>;
  NOT?: InputMaybe<Array<TournamentChampionshipScalarWhereInput>>;
  OR?: InputMaybe<Array<TournamentChampionshipScalarWhereInput>>;
  championshipId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  tournamentId?: InputMaybe<IntFilter>;
};

export type TournamentChampionshipUncheckedUpdateManyWithoutChampionshipInput = {
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  tournamentId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type TournamentChampionshipUncheckedUpdateManyWithoutTournamentInput = {
  championshipId?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type TournamentChampionshipUpdateManyWithWhereWithoutChampionshipInput = {
  data: TournamentChampionshipUncheckedUpdateManyWithoutChampionshipInput;
  where: TournamentChampionshipScalarWhereInput;
};

export type TournamentChampionshipUpdateManyWithWhereWithoutTournamentInput = {
  data: TournamentChampionshipUncheckedUpdateManyWithoutTournamentInput;
  where: TournamentChampionshipScalarWhereInput;
};

export type TournamentChampionshipUpdateManyWithoutChampionshipNestedInput = {
  connect?: InputMaybe<Array<TournamentChampionshipWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentChampionshipCreateOrConnectWithoutChampionshipInput>>;
  create?: InputMaybe<Array<TournamentChampionshipCreateWithoutChampionshipInput>>;
  createMany?: InputMaybe<TournamentChampionshipCreateManyChampionshipInputEnvelope>;
  delete?: InputMaybe<Array<TournamentChampionshipWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TournamentChampionshipScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TournamentChampionshipWhereUniqueInput>>;
  set?: InputMaybe<Array<TournamentChampionshipWhereUniqueInput>>;
  update?: InputMaybe<Array<TournamentChampionshipUpdateWithWhereUniqueWithoutChampionshipInput>>;
  updateMany?: InputMaybe<Array<TournamentChampionshipUpdateManyWithWhereWithoutChampionshipInput>>;
  upsert?: InputMaybe<Array<TournamentChampionshipUpsertWithWhereUniqueWithoutChampionshipInput>>;
};

export type TournamentChampionshipUpdateManyWithoutTournamentNestedInput = {
  connect?: InputMaybe<Array<TournamentChampionshipWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentChampionshipCreateOrConnectWithoutTournamentInput>>;
  create?: InputMaybe<Array<TournamentChampionshipCreateWithoutTournamentInput>>;
  createMany?: InputMaybe<TournamentChampionshipCreateManyTournamentInputEnvelope>;
  delete?: InputMaybe<Array<TournamentChampionshipWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TournamentChampionshipScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TournamentChampionshipWhereUniqueInput>>;
  set?: InputMaybe<Array<TournamentChampionshipWhereUniqueInput>>;
  update?: InputMaybe<Array<TournamentChampionshipUpdateWithWhereUniqueWithoutTournamentInput>>;
  updateMany?: InputMaybe<Array<TournamentChampionshipUpdateManyWithWhereWithoutTournamentInput>>;
  upsert?: InputMaybe<Array<TournamentChampionshipUpsertWithWhereUniqueWithoutTournamentInput>>;
};

export type TournamentChampionshipUpdateWithWhereUniqueWithoutChampionshipInput = {
  data: TournamentChampionshipUpdateWithoutChampionshipInput;
  where: TournamentChampionshipWhereUniqueInput;
};

export type TournamentChampionshipUpdateWithWhereUniqueWithoutTournamentInput = {
  data: TournamentChampionshipUpdateWithoutTournamentInput;
  where: TournamentChampionshipWhereUniqueInput;
};

export type TournamentChampionshipUpdateWithoutChampionshipInput = {
  tournament?: InputMaybe<TournamentUpdateOneRequiredWithoutTournamentChampionshipsNestedInput>;
};

export type TournamentChampionshipUpdateWithoutTournamentInput = {
  championship?: InputMaybe<ChampionshipUpdateOneRequiredWithoutTournamentChampionshipsNestedInput>;
};

export type TournamentChampionshipUpsertWithWhereUniqueWithoutChampionshipInput = {
  create: TournamentChampionshipCreateWithoutChampionshipInput;
  update: TournamentChampionshipUpdateWithoutChampionshipInput;
  where: TournamentChampionshipWhereUniqueInput;
};

export type TournamentChampionshipUpsertWithWhereUniqueWithoutTournamentInput = {
  create: TournamentChampionshipCreateWithoutTournamentInput;
  update: TournamentChampionshipUpdateWithoutTournamentInput;
  where: TournamentChampionshipWhereUniqueInput;
};

export type TournamentChampionshipWhereInput = {
  AND?: InputMaybe<Array<TournamentChampionshipWhereInput>>;
  NOT?: InputMaybe<Array<TournamentChampionshipWhereInput>>;
  OR?: InputMaybe<Array<TournamentChampionshipWhereInput>>;
  championship?: InputMaybe<ChampionshipRelationFilter>;
  championshipId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  tournament?: InputMaybe<TournamentRelationFilter>;
  tournamentId?: InputMaybe<IntFilter>;
};

export type TournamentChampionshipWhereUniqueInput = {
  AND?: InputMaybe<Array<TournamentChampionshipWhereInput>>;
  NOT?: InputMaybe<Array<TournamentChampionshipWhereInput>>;
  OR?: InputMaybe<Array<TournamentChampionshipWhereInput>>;
  championship?: InputMaybe<ChampionshipRelationFilter>;
  championshipId?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tournament?: InputMaybe<TournamentRelationFilter>;
  tournamentId?: InputMaybe<IntFilter>;
};

export type TournamentCount = {
  __typename?: "TournamentCount";
  championshipSeasons: Scalars["Int"]["output"];
  tournamentChampionships: Scalars["Int"]["output"];
  tournamentVariants: Scalars["Int"]["output"];
};

export type TournamentCreateInput = {
  baseName: Scalars["String"]["input"];
  championships?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  variants?: InputMaybe<Array<TournamentVariantRulesType>>;
};

export type TournamentCreateManyNameInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  tournamentState?: InputMaybe<TournamentState>;
};

export type TournamentCreateManyNameInputEnvelope = {
  data: Array<TournamentCreateManyNameInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TournamentCreateNestedManyWithoutChampionshipSeasonsInput = {
  connect?: InputMaybe<Array<TournamentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentCreateOrConnectWithoutChampionshipSeasonsInput>>;
  create?: InputMaybe<Array<TournamentCreateWithoutChampionshipSeasonsInput>>;
};

export type TournamentCreateNestedManyWithoutNameInput = {
  connect?: InputMaybe<Array<TournamentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentCreateOrConnectWithoutNameInput>>;
  create?: InputMaybe<Array<TournamentCreateWithoutNameInput>>;
  createMany?: InputMaybe<TournamentCreateManyNameInputEnvelope>;
};

export type TournamentCreateNestedOneWithoutTournamentChampionshipsInput = {
  connect?: InputMaybe<TournamentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TournamentCreateOrConnectWithoutTournamentChampionshipsInput>;
  create?: InputMaybe<TournamentCreateWithoutTournamentChampionshipsInput>;
};

export type TournamentCreateNestedOneWithoutTournamentVariantsInput = {
  connect?: InputMaybe<TournamentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TournamentCreateOrConnectWithoutTournamentVariantsInput>;
  create?: InputMaybe<TournamentCreateWithoutTournamentVariantsInput>;
};

export type TournamentCreateOrConnectWithoutChampionshipSeasonsInput = {
  create: TournamentCreateWithoutChampionshipSeasonsInput;
  where: TournamentWhereUniqueInput;
};

export type TournamentCreateOrConnectWithoutNameInput = {
  create: TournamentCreateWithoutNameInput;
  where: TournamentWhereUniqueInput;
};

export type TournamentCreateOrConnectWithoutTournamentChampionshipsInput = {
  create: TournamentCreateWithoutTournamentChampionshipsInput;
  where: TournamentWhereUniqueInput;
};

export type TournamentCreateOrConnectWithoutTournamentVariantsInput = {
  create: TournamentCreateWithoutTournamentVariantsInput;
  where: TournamentWhereUniqueInput;
};

export type TournamentCreateWithoutChampionshipSeasonsInput = {
  image?: InputMaybe<Scalars["String"]["input"]>;
  name: TranslatableCreateNestedOneWithoutTournamentsInput;
  tournamentChampionships?: InputMaybe<TournamentChampionshipCreateNestedManyWithoutTournamentInput>;
  tournamentState?: InputMaybe<TournamentState>;
  tournamentVariants?: InputMaybe<TournamentVariantCreateNestedManyWithoutTournamentInput>;
};

export type TournamentCreateWithoutNameInput = {
  championshipSeasons?: InputMaybe<ChampionshipSeasonCreateNestedManyWithoutTournamentsInput>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  tournamentChampionships?: InputMaybe<TournamentChampionshipCreateNestedManyWithoutTournamentInput>;
  tournamentState?: InputMaybe<TournamentState>;
  tournamentVariants?: InputMaybe<TournamentVariantCreateNestedManyWithoutTournamentInput>;
};

export type TournamentCreateWithoutTournamentChampionshipsInput = {
  championshipSeasons?: InputMaybe<ChampionshipSeasonCreateNestedManyWithoutTournamentsInput>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  name: TranslatableCreateNestedOneWithoutTournamentsInput;
  tournamentState?: InputMaybe<TournamentState>;
  tournamentVariants?: InputMaybe<TournamentVariantCreateNestedManyWithoutTournamentInput>;
};

export type TournamentCreateWithoutTournamentVariantsInput = {
  championshipSeasons?: InputMaybe<ChampionshipSeasonCreateNestedManyWithoutTournamentsInput>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  name: TranslatableCreateNestedOneWithoutTournamentsInput;
  tournamentChampionships?: InputMaybe<TournamentChampionshipCreateNestedManyWithoutTournamentInput>;
  tournamentState?: InputMaybe<TournamentState>;
};

export type TournamentGameWeek = {
  __typename?: "TournamentGameWeek";
  _count: TournamentGameWeekCount;
  championshipSeasonRounds?: Maybe<Array<Scalars["String"]["output"]>>;
  compositions?: Maybe<Array<Composition>>;
  compositionsCount: Scalars["Int"]["output"];
  endDate: Scalars["Timestamp"]["output"];
  gameWeekRewards?: Maybe<Array<GameWeekReward>>;
  id: Scalars["ID"]["output"];
  number: Scalars["Int"]["output"];
  realGames: Array<RealGame>;
  startDate: Scalars["Timestamp"]["output"];
  status: TournamentGameWeekStatus;
  tournamentGameWeeksRealGames?: Maybe<Array<TournamentGameWeekRealGame>>;
  variant: TournamentVariant;
  variantId: Scalars["Int"]["output"];
};

export type TournamentGameWeekCount = {
  __typename?: "TournamentGameWeekCount";
  compositions: Scalars["Int"]["output"];
  gameWeekRewards: Scalars["Int"]["output"];
  tournamentGameWeeksRealGames: Scalars["Int"]["output"];
};

export type TournamentGameWeekCreateInput = {
  compositions?: InputMaybe<Array<CompositionCreateInput>>;
  endDate: Scalars["Timestamp"]["input"];
  number: Scalars["Int"]["input"];
  startDate: Scalars["Timestamp"]["input"];
  status: TournamentGameWeekStatus;
  variantId: Scalars["Int"]["input"];
};

export type TournamentGameWeekCreateManyVariantInput = {
  endDate: Scalars["Timestamp"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  number: Scalars["Int"]["input"];
  startDate: Scalars["Timestamp"]["input"];
  status?: InputMaybe<TournamentGameWeekStatus>;
};

export type TournamentGameWeekCreateManyVariantInputEnvelope = {
  data: Array<TournamentGameWeekCreateManyVariantInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TournamentGameWeekCreateNestedManyWithoutVariantInput = {
  connect?: InputMaybe<Array<TournamentGameWeekWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentGameWeekCreateOrConnectWithoutVariantInput>>;
  create?: InputMaybe<Array<TournamentGameWeekCreateWithoutVariantInput>>;
  createMany?: InputMaybe<TournamentGameWeekCreateManyVariantInputEnvelope>;
};

export type TournamentGameWeekCreateNestedOneWithoutCompositionsInput = {
  connect?: InputMaybe<TournamentGameWeekWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TournamentGameWeekCreateOrConnectWithoutCompositionsInput>;
  create?: InputMaybe<TournamentGameWeekCreateWithoutCompositionsInput>;
};

export type TournamentGameWeekCreateNestedOneWithoutGameWeekRewardsInput = {
  connect?: InputMaybe<TournamentGameWeekWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TournamentGameWeekCreateOrConnectWithoutGameWeekRewardsInput>;
  create?: InputMaybe<TournamentGameWeekCreateWithoutGameWeekRewardsInput>;
};

export type TournamentGameWeekCreateNestedOneWithoutTournamentGameWeeksRealGamesInput = {
  connect?: InputMaybe<TournamentGameWeekWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TournamentGameWeekCreateOrConnectWithoutTournamentGameWeeksRealGamesInput>;
  create?: InputMaybe<TournamentGameWeekCreateWithoutTournamentGameWeeksRealGamesInput>;
};

export type TournamentGameWeekCreateOrConnectWithoutCompositionsInput = {
  create: TournamentGameWeekCreateWithoutCompositionsInput;
  where: TournamentGameWeekWhereUniqueInput;
};

export type TournamentGameWeekCreateOrConnectWithoutGameWeekRewardsInput = {
  create: TournamentGameWeekCreateWithoutGameWeekRewardsInput;
  where: TournamentGameWeekWhereUniqueInput;
};

export type TournamentGameWeekCreateOrConnectWithoutTournamentGameWeeksRealGamesInput = {
  create: TournamentGameWeekCreateWithoutTournamentGameWeeksRealGamesInput;
  where: TournamentGameWeekWhereUniqueInput;
};

export type TournamentGameWeekCreateOrConnectWithoutVariantInput = {
  create: TournamentGameWeekCreateWithoutVariantInput;
  where: TournamentGameWeekWhereUniqueInput;
};

export type TournamentGameWeekCreateWithoutCompositionsInput = {
  endDate: Scalars["Timestamp"]["input"];
  gameWeekRewards?: InputMaybe<GameWeekRewardCreateNestedManyWithoutGameWeekInput>;
  number: Scalars["Int"]["input"];
  startDate: Scalars["Timestamp"]["input"];
  status?: InputMaybe<TournamentGameWeekStatus>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameCreateNestedManyWithoutTournamentGameWeekInput>;
  variant: TournamentVariantCreateNestedOneWithoutTournamentGamesWeeksInput;
};

export type TournamentGameWeekCreateWithoutGameWeekRewardsInput = {
  compositions?: InputMaybe<CompositionCreateNestedManyWithoutGameWeekInput>;
  endDate: Scalars["Timestamp"]["input"];
  number: Scalars["Int"]["input"];
  startDate: Scalars["Timestamp"]["input"];
  status?: InputMaybe<TournamentGameWeekStatus>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameCreateNestedManyWithoutTournamentGameWeekInput>;
  variant: TournamentVariantCreateNestedOneWithoutTournamentGamesWeeksInput;
};

export type TournamentGameWeekCreateWithoutTournamentGameWeeksRealGamesInput = {
  compositions?: InputMaybe<CompositionCreateNestedManyWithoutGameWeekInput>;
  endDate: Scalars["Timestamp"]["input"];
  gameWeekRewards?: InputMaybe<GameWeekRewardCreateNestedManyWithoutGameWeekInput>;
  number: Scalars["Int"]["input"];
  startDate: Scalars["Timestamp"]["input"];
  status?: InputMaybe<TournamentGameWeekStatus>;
  variant: TournamentVariantCreateNestedOneWithoutTournamentGamesWeeksInput;
};

export type TournamentGameWeekCreateWithoutVariantInput = {
  compositions?: InputMaybe<CompositionCreateNestedManyWithoutGameWeekInput>;
  endDate: Scalars["Timestamp"]["input"];
  gameWeekRewards?: InputMaybe<GameWeekRewardCreateNestedManyWithoutGameWeekInput>;
  number: Scalars["Int"]["input"];
  startDate: Scalars["Timestamp"]["input"];
  status?: InputMaybe<TournamentGameWeekStatus>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameCreateNestedManyWithoutTournamentGameWeekInput>;
};

export type TournamentGameWeekListRelationFilter = {
  every?: InputMaybe<TournamentGameWeekWhereInput>;
  none?: InputMaybe<TournamentGameWeekWhereInput>;
  some?: InputMaybe<TournamentGameWeekWhereInput>;
};

export type TournamentGameWeekNullableRelationFilter = {
  is?: InputMaybe<TournamentGameWeekWhereInput>;
  isNot?: InputMaybe<TournamentGameWeekWhereInput>;
};

export type TournamentGameWeekOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TournamentGameWeekOrderByWithRelationAndSearchRelevanceInput = {
  compositions?: InputMaybe<CompositionOrderByRelationAggregateInput>;
  endDate?: InputMaybe<SortOrder>;
  gameWeekRewards?: InputMaybe<GameWeekRewardOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameOrderByRelationAggregateInput>;
  variant?: InputMaybe<TournamentVariantOrderByWithRelationAndSearchRelevanceInput>;
  variantId?: InputMaybe<SortOrder>;
};

export type TournamentGameWeekRealGame = {
  __typename?: "TournamentGameWeekRealGame";
  realGame: RealGame;
  realGameId: Scalars["Int"]["output"];
  tournamentGameWeek: TournamentGameWeek;
  tournamentGameWeekId: Scalars["Int"]["output"];
};

export type TournamentGameWeekRealGameCreateManyRealGameInput = {
  tournamentGameWeekId: Scalars["Int"]["input"];
};

export type TournamentGameWeekRealGameCreateManyRealGameInputEnvelope = {
  data: Array<TournamentGameWeekRealGameCreateManyRealGameInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TournamentGameWeekRealGameCreateManyTournamentGameWeekInput = {
  realGameId: Scalars["Int"]["input"];
};

export type TournamentGameWeekRealGameCreateManyTournamentGameWeekInputEnvelope = {
  data: Array<TournamentGameWeekRealGameCreateManyTournamentGameWeekInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TournamentGameWeekRealGameCreateNestedManyWithoutRealGameInput = {
  connect?: InputMaybe<Array<TournamentGameWeekRealGameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentGameWeekRealGameCreateOrConnectWithoutRealGameInput>>;
  create?: InputMaybe<Array<TournamentGameWeekRealGameCreateWithoutRealGameInput>>;
  createMany?: InputMaybe<TournamentGameWeekRealGameCreateManyRealGameInputEnvelope>;
};

export type TournamentGameWeekRealGameCreateNestedManyWithoutTournamentGameWeekInput = {
  connect?: InputMaybe<Array<TournamentGameWeekRealGameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentGameWeekRealGameCreateOrConnectWithoutTournamentGameWeekInput>>;
  create?: InputMaybe<Array<TournamentGameWeekRealGameCreateWithoutTournamentGameWeekInput>>;
  createMany?: InputMaybe<TournamentGameWeekRealGameCreateManyTournamentGameWeekInputEnvelope>;
};

export type TournamentGameWeekRealGameCreateOrConnectWithoutRealGameInput = {
  create: TournamentGameWeekRealGameCreateWithoutRealGameInput;
  where: TournamentGameWeekRealGameWhereUniqueInput;
};

export type TournamentGameWeekRealGameCreateOrConnectWithoutTournamentGameWeekInput = {
  create: TournamentGameWeekRealGameCreateWithoutTournamentGameWeekInput;
  where: TournamentGameWeekRealGameWhereUniqueInput;
};

export type TournamentGameWeekRealGameCreateWithoutRealGameInput = {
  tournamentGameWeek: TournamentGameWeekCreateNestedOneWithoutTournamentGameWeeksRealGamesInput;
};

export type TournamentGameWeekRealGameCreateWithoutTournamentGameWeekInput = {
  realGame: RealGameCreateNestedOneWithoutTournamentGameWeeksRealGamesInput;
};

export type TournamentGameWeekRealGameListRelationFilter = {
  every?: InputMaybe<TournamentGameWeekRealGameWhereInput>;
  none?: InputMaybe<TournamentGameWeekRealGameWhereInput>;
  some?: InputMaybe<TournamentGameWeekRealGameWhereInput>;
};

export type TournamentGameWeekRealGameOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TournamentGameWeekRealGameScalarWhereInput = {
  AND?: InputMaybe<Array<TournamentGameWeekRealGameScalarWhereInput>>;
  NOT?: InputMaybe<Array<TournamentGameWeekRealGameScalarWhereInput>>;
  OR?: InputMaybe<Array<TournamentGameWeekRealGameScalarWhereInput>>;
  realGameId?: InputMaybe<IntFilter>;
  tournamentGameWeekId?: InputMaybe<IntFilter>;
};

export type TournamentGameWeekRealGameTournamentGameWeekIdRealGameIdCompoundUniqueInput = {
  realGameId: Scalars["Int"]["input"];
  tournamentGameWeekId: Scalars["Int"]["input"];
};

export type TournamentGameWeekRealGameUncheckedUpdateManyWithoutRealGameInput = {
  tournamentGameWeekId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type TournamentGameWeekRealGameUncheckedUpdateManyWithoutTournamentGameWeekInput = {
  realGameId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type TournamentGameWeekRealGameUpdateManyWithWhereWithoutRealGameInput = {
  data: TournamentGameWeekRealGameUncheckedUpdateManyWithoutRealGameInput;
  where: TournamentGameWeekRealGameScalarWhereInput;
};

export type TournamentGameWeekRealGameUpdateManyWithWhereWithoutTournamentGameWeekInput = {
  data: TournamentGameWeekRealGameUncheckedUpdateManyWithoutTournamentGameWeekInput;
  where: TournamentGameWeekRealGameScalarWhereInput;
};

export type TournamentGameWeekRealGameUpdateManyWithoutRealGameNestedInput = {
  connect?: InputMaybe<Array<TournamentGameWeekRealGameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentGameWeekRealGameCreateOrConnectWithoutRealGameInput>>;
  create?: InputMaybe<Array<TournamentGameWeekRealGameCreateWithoutRealGameInput>>;
  createMany?: InputMaybe<TournamentGameWeekRealGameCreateManyRealGameInputEnvelope>;
  delete?: InputMaybe<Array<TournamentGameWeekRealGameWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TournamentGameWeekRealGameScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TournamentGameWeekRealGameWhereUniqueInput>>;
  set?: InputMaybe<Array<TournamentGameWeekRealGameWhereUniqueInput>>;
  update?: InputMaybe<Array<TournamentGameWeekRealGameUpdateWithWhereUniqueWithoutRealGameInput>>;
  updateMany?: InputMaybe<Array<TournamentGameWeekRealGameUpdateManyWithWhereWithoutRealGameInput>>;
  upsert?: InputMaybe<Array<TournamentGameWeekRealGameUpsertWithWhereUniqueWithoutRealGameInput>>;
};

export type TournamentGameWeekRealGameUpdateManyWithoutTournamentGameWeekNestedInput = {
  connect?: InputMaybe<Array<TournamentGameWeekRealGameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentGameWeekRealGameCreateOrConnectWithoutTournamentGameWeekInput>>;
  create?: InputMaybe<Array<TournamentGameWeekRealGameCreateWithoutTournamentGameWeekInput>>;
  createMany?: InputMaybe<TournamentGameWeekRealGameCreateManyTournamentGameWeekInputEnvelope>;
  delete?: InputMaybe<Array<TournamentGameWeekRealGameWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TournamentGameWeekRealGameScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TournamentGameWeekRealGameWhereUniqueInput>>;
  set?: InputMaybe<Array<TournamentGameWeekRealGameWhereUniqueInput>>;
  update?: InputMaybe<Array<TournamentGameWeekRealGameUpdateWithWhereUniqueWithoutTournamentGameWeekInput>>;
  updateMany?: InputMaybe<Array<TournamentGameWeekRealGameUpdateManyWithWhereWithoutTournamentGameWeekInput>>;
  upsert?: InputMaybe<Array<TournamentGameWeekRealGameUpsertWithWhereUniqueWithoutTournamentGameWeekInput>>;
};

export type TournamentGameWeekRealGameUpdateWithWhereUniqueWithoutRealGameInput = {
  data: TournamentGameWeekRealGameUpdateWithoutRealGameInput;
  where: TournamentGameWeekRealGameWhereUniqueInput;
};

export type TournamentGameWeekRealGameUpdateWithWhereUniqueWithoutTournamentGameWeekInput = {
  data: TournamentGameWeekRealGameUpdateWithoutTournamentGameWeekInput;
  where: TournamentGameWeekRealGameWhereUniqueInput;
};

export type TournamentGameWeekRealGameUpdateWithoutRealGameInput = {
  tournamentGameWeek?: InputMaybe<TournamentGameWeekUpdateOneRequiredWithoutTournamentGameWeeksRealGamesNestedInput>;
};

export type TournamentGameWeekRealGameUpdateWithoutTournamentGameWeekInput = {
  realGame?: InputMaybe<RealGameUpdateOneRequiredWithoutTournamentGameWeeksRealGamesNestedInput>;
};

export type TournamentGameWeekRealGameUpsertWithWhereUniqueWithoutRealGameInput = {
  create: TournamentGameWeekRealGameCreateWithoutRealGameInput;
  update: TournamentGameWeekRealGameUpdateWithoutRealGameInput;
  where: TournamentGameWeekRealGameWhereUniqueInput;
};

export type TournamentGameWeekRealGameUpsertWithWhereUniqueWithoutTournamentGameWeekInput = {
  create: TournamentGameWeekRealGameCreateWithoutTournamentGameWeekInput;
  update: TournamentGameWeekRealGameUpdateWithoutTournamentGameWeekInput;
  where: TournamentGameWeekRealGameWhereUniqueInput;
};

export type TournamentGameWeekRealGameWhereInput = {
  AND?: InputMaybe<Array<TournamentGameWeekRealGameWhereInput>>;
  NOT?: InputMaybe<Array<TournamentGameWeekRealGameWhereInput>>;
  OR?: InputMaybe<Array<TournamentGameWeekRealGameWhereInput>>;
  realGame?: InputMaybe<RealGameRelationFilter>;
  realGameId?: InputMaybe<IntFilter>;
  tournamentGameWeek?: InputMaybe<TournamentGameWeekRelationFilter>;
  tournamentGameWeekId?: InputMaybe<IntFilter>;
};

export type TournamentGameWeekRealGameWhereUniqueInput = {
  AND?: InputMaybe<Array<TournamentGameWeekRealGameWhereInput>>;
  NOT?: InputMaybe<Array<TournamentGameWeekRealGameWhereInput>>;
  OR?: InputMaybe<Array<TournamentGameWeekRealGameWhereInput>>;
  realGame?: InputMaybe<RealGameRelationFilter>;
  realGameId?: InputMaybe<IntFilter>;
  tournamentGameWeek?: InputMaybe<TournamentGameWeekRelationFilter>;
  tournamentGameWeekId?: InputMaybe<IntFilter>;
  tournamentGameWeekId_realGameId?: InputMaybe<TournamentGameWeekRealGameTournamentGameWeekIdRealGameIdCompoundUniqueInput>;
};

export type TournamentGameWeekRelationFilter = {
  is?: InputMaybe<TournamentGameWeekWhereInput>;
  isNot?: InputMaybe<TournamentGameWeekWhereInput>;
};

export enum TournamentGameWeekScalarFieldEnum {
  EndDate = "endDate",
  Id = "id",
  Number = "number",
  StartDate = "startDate",
  Status = "status",
  VariantId = "variantId",
}

export type TournamentGameWeekScalarWhereInput = {
  AND?: InputMaybe<Array<TournamentGameWeekScalarWhereInput>>;
  NOT?: InputMaybe<Array<TournamentGameWeekScalarWhereInput>>;
  OR?: InputMaybe<Array<TournamentGameWeekScalarWhereInput>>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  number?: InputMaybe<IntFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumTournamentGameWeekStatusFilter>;
  variantId?: InputMaybe<IntFilter>;
};

export enum TournamentGameWeekStatus {
  Computed = "COMPUTED",
  Created = "CREATED",
  Ended = "ENDED",
  Live = "LIVE",
}

export type TournamentGameWeekUpdateInput = {
  championshipSeasonRounds?: InputMaybe<StringArrayFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  rewards?: InputMaybe<RewardUpsertOperationInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTournamentGameWeekStatusFieldUpdateOperationsInput>;
};

export type TournamentGameWeekUpdateManyMutationInput = {
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTournamentGameWeekStatusFieldUpdateOperationsInput>;
};

export type TournamentGameWeekUpdateManyWithWhereWithoutVariantInput = {
  data: TournamentGameWeekUpdateManyMutationInput;
  where: TournamentGameWeekScalarWhereInput;
};

export type TournamentGameWeekUpdateManyWithoutVariantNestedInput = {
  connect?: InputMaybe<Array<TournamentGameWeekWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentGameWeekCreateOrConnectWithoutVariantInput>>;
  create?: InputMaybe<Array<TournamentGameWeekCreateWithoutVariantInput>>;
  createMany?: InputMaybe<TournamentGameWeekCreateManyVariantInputEnvelope>;
  delete?: InputMaybe<Array<TournamentGameWeekWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TournamentGameWeekScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TournamentGameWeekWhereUniqueInput>>;
  set?: InputMaybe<Array<TournamentGameWeekWhereUniqueInput>>;
  update?: InputMaybe<Array<TournamentGameWeekUpdateWithWhereUniqueWithoutVariantInput>>;
  updateMany?: InputMaybe<Array<TournamentGameWeekUpdateManyWithWhereWithoutVariantInput>>;
  upsert?: InputMaybe<Array<TournamentGameWeekUpsertWithWhereUniqueWithoutVariantInput>>;
};

export type TournamentGameWeekUpdateOneRequiredWithoutGameWeekRewardsNestedInput = {
  connect?: InputMaybe<TournamentGameWeekWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TournamentGameWeekCreateOrConnectWithoutGameWeekRewardsInput>;
  create?: InputMaybe<TournamentGameWeekCreateWithoutGameWeekRewardsInput>;
  update?: InputMaybe<TournamentGameWeekUpdateToOneWithWhereWithoutGameWeekRewardsInput>;
  upsert?: InputMaybe<TournamentGameWeekUpsertWithoutGameWeekRewardsInput>;
};

export type TournamentGameWeekUpdateOneRequiredWithoutTournamentGameWeeksRealGamesNestedInput = {
  connect?: InputMaybe<TournamentGameWeekWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TournamentGameWeekCreateOrConnectWithoutTournamentGameWeeksRealGamesInput>;
  create?: InputMaybe<TournamentGameWeekCreateWithoutTournamentGameWeeksRealGamesInput>;
  update?: InputMaybe<TournamentGameWeekUpdateToOneWithWhereWithoutTournamentGameWeeksRealGamesInput>;
  upsert?: InputMaybe<TournamentGameWeekUpsertWithoutTournamentGameWeeksRealGamesInput>;
};

export type TournamentGameWeekUpdateOneWithoutCompositionsNestedInput = {
  connect?: InputMaybe<TournamentGameWeekWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TournamentGameWeekCreateOrConnectWithoutCompositionsInput>;
  create?: InputMaybe<TournamentGameWeekCreateWithoutCompositionsInput>;
  delete?: InputMaybe<TournamentGameWeekWhereInput>;
  disconnect?: InputMaybe<TournamentGameWeekWhereInput>;
  update?: InputMaybe<TournamentGameWeekUpdateToOneWithWhereWithoutCompositionsInput>;
  upsert?: InputMaybe<TournamentGameWeekUpsertWithoutCompositionsInput>;
};

export type TournamentGameWeekUpdateToOneWithWhereWithoutCompositionsInput = {
  data: TournamentGameWeekUpdateWithoutCompositionsInput;
  where?: InputMaybe<TournamentGameWeekWhereInput>;
};

export type TournamentGameWeekUpdateToOneWithWhereWithoutGameWeekRewardsInput = {
  data: TournamentGameWeekUpdateWithoutGameWeekRewardsInput;
  where?: InputMaybe<TournamentGameWeekWhereInput>;
};

export type TournamentGameWeekUpdateToOneWithWhereWithoutTournamentGameWeeksRealGamesInput = {
  data: TournamentGameWeekUpdateWithoutTournamentGameWeeksRealGamesInput;
  where?: InputMaybe<TournamentGameWeekWhereInput>;
};

export type TournamentGameWeekUpdateWithWhereUniqueWithoutVariantInput = {
  data: TournamentGameWeekUpdateWithoutVariantInput;
  where: TournamentGameWeekWhereUniqueInput;
};

export type TournamentGameWeekUpdateWithoutCompositionsInput = {
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gameWeekRewards?: InputMaybe<GameWeekRewardUpdateManyWithoutGameWeekNestedInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTournamentGameWeekStatusFieldUpdateOperationsInput>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameUpdateManyWithoutTournamentGameWeekNestedInput>;
  variant?: InputMaybe<TournamentVariantUpdateOneRequiredWithoutTournamentGamesWeeksNestedInput>;
};

export type TournamentGameWeekUpdateWithoutGameWeekRewardsInput = {
  compositions?: InputMaybe<CompositionUpdateManyWithoutGameWeekNestedInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTournamentGameWeekStatusFieldUpdateOperationsInput>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameUpdateManyWithoutTournamentGameWeekNestedInput>;
  variant?: InputMaybe<TournamentVariantUpdateOneRequiredWithoutTournamentGamesWeeksNestedInput>;
};

export type TournamentGameWeekUpdateWithoutTournamentGameWeeksRealGamesInput = {
  compositions?: InputMaybe<CompositionUpdateManyWithoutGameWeekNestedInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gameWeekRewards?: InputMaybe<GameWeekRewardUpdateManyWithoutGameWeekNestedInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTournamentGameWeekStatusFieldUpdateOperationsInput>;
  variant?: InputMaybe<TournamentVariantUpdateOneRequiredWithoutTournamentGamesWeeksNestedInput>;
};

export type TournamentGameWeekUpdateWithoutVariantInput = {
  compositions?: InputMaybe<CompositionUpdateManyWithoutGameWeekNestedInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gameWeekRewards?: InputMaybe<GameWeekRewardUpdateManyWithoutGameWeekNestedInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTournamentGameWeekStatusFieldUpdateOperationsInput>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameUpdateManyWithoutTournamentGameWeekNestedInput>;
};

export type TournamentGameWeekUpsertWithWhereUniqueWithoutVariantInput = {
  create: TournamentGameWeekCreateWithoutVariantInput;
  update: TournamentGameWeekUpdateWithoutVariantInput;
  where: TournamentGameWeekWhereUniqueInput;
};

export type TournamentGameWeekUpsertWithoutCompositionsInput = {
  create: TournamentGameWeekCreateWithoutCompositionsInput;
  update: TournamentGameWeekUpdateWithoutCompositionsInput;
  where?: InputMaybe<TournamentGameWeekWhereInput>;
};

export type TournamentGameWeekUpsertWithoutGameWeekRewardsInput = {
  create: TournamentGameWeekCreateWithoutGameWeekRewardsInput;
  update: TournamentGameWeekUpdateWithoutGameWeekRewardsInput;
  where?: InputMaybe<TournamentGameWeekWhereInput>;
};

export type TournamentGameWeekUpsertWithoutTournamentGameWeeksRealGamesInput = {
  create: TournamentGameWeekCreateWithoutTournamentGameWeeksRealGamesInput;
  update: TournamentGameWeekUpdateWithoutTournamentGameWeeksRealGamesInput;
  where?: InputMaybe<TournamentGameWeekWhereInput>;
};

export type TournamentGameWeekWhereInput = {
  AND?: InputMaybe<Array<TournamentGameWeekWhereInput>>;
  NOT?: InputMaybe<Array<TournamentGameWeekWhereInput>>;
  OR?: InputMaybe<Array<TournamentGameWeekWhereInput>>;
  compositions?: InputMaybe<CompositionListRelationFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  gameWeekRewards?: InputMaybe<GameWeekRewardListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  number?: InputMaybe<IntFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumTournamentGameWeekStatusFilter>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameListRelationFilter>;
  variant?: InputMaybe<TournamentVariantRelationFilter>;
  variantId?: InputMaybe<IntFilter>;
};

export type TournamentGameWeekWhereUniqueInput = {
  AND?: InputMaybe<Array<TournamentGameWeekWhereInput>>;
  NOT?: InputMaybe<Array<TournamentGameWeekWhereInput>>;
  OR?: InputMaybe<Array<TournamentGameWeekWhereInput>>;
  compositions?: InputMaybe<CompositionListRelationFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  gameWeekRewards?: InputMaybe<GameWeekRewardListRelationFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  number?: InputMaybe<IntFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumTournamentGameWeekStatusFilter>;
  tournamentGameWeeksRealGames?: InputMaybe<TournamentGameWeekRealGameListRelationFilter>;
  variant?: InputMaybe<TournamentVariantRelationFilter>;
  variantId?: InputMaybe<IntFilter>;
};

export type TournamentListRelationFilter = {
  every?: InputMaybe<TournamentWhereInput>;
  none?: InputMaybe<TournamentWhereInput>;
  some?: InputMaybe<TournamentWhereInput>;
};

export type TournamentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TournamentOrderByWithRelationAndSearchRelevanceInput = {
  championshipSeasons?: InputMaybe<ChampionshipSeasonOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<TranslatableOrderByWithRelationAndSearchRelevanceInput>;
  nameId?: InputMaybe<SortOrder>;
  tournamentChampionships?: InputMaybe<TournamentChampionshipOrderByRelationAggregateInput>;
  tournamentState?: InputMaybe<SortOrder>;
  tournamentVariants?: InputMaybe<TournamentVariantOrderByRelationAggregateInput>;
};

export type TournamentRelationFilter = {
  is?: InputMaybe<TournamentWhereInput>;
  isNot?: InputMaybe<TournamentWhereInput>;
};

export enum TournamentScalarFieldEnum {
  Id = "id",
  Image = "image",
  NameId = "nameId",
  TournamentState = "tournamentState",
}

export type TournamentScalarWhereInput = {
  AND?: InputMaybe<Array<TournamentScalarWhereInput>>;
  NOT?: InputMaybe<Array<TournamentScalarWhereInput>>;
  OR?: InputMaybe<Array<TournamentScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<BytesNullableFilter>;
  nameId?: InputMaybe<IntFilter>;
  tournamentState?: InputMaybe<EnumTournamentStateFilter>;
};

export enum TournamentState {
  Closed = "CLOSED",
  Draft = "DRAFT",
  Published = "PUBLISHED",
}

export type TournamentUpdateInput = {
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameId?: InputMaybe<IntFieldUpdateOperationsInput>;
  tournamentState?: InputMaybe<EnumTournamentStateFieldUpdateOperationsInput>;
};

export type TournamentUpdateManyMutationInput = {
  image?: InputMaybe<NullableBytesFieldUpdateOperationsInput>;
  tournamentState?: InputMaybe<EnumTournamentStateFieldUpdateOperationsInput>;
};

export type TournamentUpdateManyWithWhereWithoutChampionshipSeasonsInput = {
  data: TournamentUpdateManyMutationInput;
  where: TournamentScalarWhereInput;
};

export type TournamentUpdateManyWithWhereWithoutNameInput = {
  data: TournamentUpdateManyMutationInput;
  where: TournamentScalarWhereInput;
};

export type TournamentUpdateManyWithoutChampionshipSeasonsNestedInput = {
  connect?: InputMaybe<Array<TournamentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentCreateOrConnectWithoutChampionshipSeasonsInput>>;
  create?: InputMaybe<Array<TournamentCreateWithoutChampionshipSeasonsInput>>;
  delete?: InputMaybe<Array<TournamentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TournamentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TournamentWhereUniqueInput>>;
  set?: InputMaybe<Array<TournamentWhereUniqueInput>>;
  update?: InputMaybe<Array<TournamentUpdateWithWhereUniqueWithoutChampionshipSeasonsInput>>;
  updateMany?: InputMaybe<Array<TournamentUpdateManyWithWhereWithoutChampionshipSeasonsInput>>;
  upsert?: InputMaybe<Array<TournamentUpsertWithWhereUniqueWithoutChampionshipSeasonsInput>>;
};

export type TournamentUpdateManyWithoutNameNestedInput = {
  connect?: InputMaybe<Array<TournamentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentCreateOrConnectWithoutNameInput>>;
  create?: InputMaybe<Array<TournamentCreateWithoutNameInput>>;
  createMany?: InputMaybe<TournamentCreateManyNameInputEnvelope>;
  delete?: InputMaybe<Array<TournamentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TournamentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TournamentWhereUniqueInput>>;
  set?: InputMaybe<Array<TournamentWhereUniqueInput>>;
  update?: InputMaybe<Array<TournamentUpdateWithWhereUniqueWithoutNameInput>>;
  updateMany?: InputMaybe<Array<TournamentUpdateManyWithWhereWithoutNameInput>>;
  upsert?: InputMaybe<Array<TournamentUpsertWithWhereUniqueWithoutNameInput>>;
};

export type TournamentUpdateOneRequiredWithoutTournamentChampionshipsNestedInput = {
  connect?: InputMaybe<TournamentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TournamentCreateOrConnectWithoutTournamentChampionshipsInput>;
  create?: InputMaybe<TournamentCreateWithoutTournamentChampionshipsInput>;
  update?: InputMaybe<TournamentUpdateToOneWithWhereWithoutTournamentChampionshipsInput>;
  upsert?: InputMaybe<TournamentUpsertWithoutTournamentChampionshipsInput>;
};

export type TournamentUpdateOneRequiredWithoutTournamentVariantsNestedInput = {
  connect?: InputMaybe<TournamentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TournamentCreateOrConnectWithoutTournamentVariantsInput>;
  create?: InputMaybe<TournamentCreateWithoutTournamentVariantsInput>;
  update?: InputMaybe<TournamentUpdateToOneWithWhereWithoutTournamentVariantsInput>;
  upsert?: InputMaybe<TournamentUpsertWithoutTournamentVariantsInput>;
};

export type TournamentUpdateToOneWithWhereWithoutTournamentChampionshipsInput = {
  data: TournamentUpdateWithoutTournamentChampionshipsInput;
  where?: InputMaybe<TournamentWhereInput>;
};

export type TournamentUpdateToOneWithWhereWithoutTournamentVariantsInput = {
  data: TournamentUpdateWithoutTournamentVariantsInput;
  where?: InputMaybe<TournamentWhereInput>;
};

export type TournamentUpdateWithWhereUniqueWithoutChampionshipSeasonsInput = {
  data: TournamentUpdateWithoutChampionshipSeasonsInput;
  where: TournamentWhereUniqueInput;
};

export type TournamentUpdateWithWhereUniqueWithoutNameInput = {
  data: TournamentUpdateWithoutNameInput;
  where: TournamentWhereUniqueInput;
};

export type TournamentUpdateWithoutChampionshipSeasonsInput = {
  image?: InputMaybe<NullableBytesFieldUpdateOperationsInput>;
  name?: InputMaybe<TranslatableUpdateOneRequiredWithoutTournamentsNestedInput>;
  tournamentChampionships?: InputMaybe<TournamentChampionshipUpdateManyWithoutTournamentNestedInput>;
  tournamentState?: InputMaybe<EnumTournamentStateFieldUpdateOperationsInput>;
  tournamentVariants?: InputMaybe<TournamentVariantUpdateManyWithoutTournamentNestedInput>;
};

export type TournamentUpdateWithoutNameInput = {
  championshipSeasons?: InputMaybe<ChampionshipSeasonUpdateManyWithoutTournamentsNestedInput>;
  image?: InputMaybe<NullableBytesFieldUpdateOperationsInput>;
  tournamentChampionships?: InputMaybe<TournamentChampionshipUpdateManyWithoutTournamentNestedInput>;
  tournamentState?: InputMaybe<EnumTournamentStateFieldUpdateOperationsInput>;
  tournamentVariants?: InputMaybe<TournamentVariantUpdateManyWithoutTournamentNestedInput>;
};

export type TournamentUpdateWithoutTournamentChampionshipsInput = {
  championshipSeasons?: InputMaybe<ChampionshipSeasonUpdateManyWithoutTournamentsNestedInput>;
  image?: InputMaybe<NullableBytesFieldUpdateOperationsInput>;
  name?: InputMaybe<TranslatableUpdateOneRequiredWithoutTournamentsNestedInput>;
  tournamentState?: InputMaybe<EnumTournamentStateFieldUpdateOperationsInput>;
  tournamentVariants?: InputMaybe<TournamentVariantUpdateManyWithoutTournamentNestedInput>;
};

export type TournamentUpdateWithoutTournamentVariantsInput = {
  championshipSeasons?: InputMaybe<ChampionshipSeasonUpdateManyWithoutTournamentsNestedInput>;
  image?: InputMaybe<NullableBytesFieldUpdateOperationsInput>;
  name?: InputMaybe<TranslatableUpdateOneRequiredWithoutTournamentsNestedInput>;
  tournamentChampionships?: InputMaybe<TournamentChampionshipUpdateManyWithoutTournamentNestedInput>;
  tournamentState?: InputMaybe<EnumTournamentStateFieldUpdateOperationsInput>;
};

export type TournamentUpsertWithWhereUniqueWithoutChampionshipSeasonsInput = {
  create: TournamentCreateWithoutChampionshipSeasonsInput;
  update: TournamentUpdateWithoutChampionshipSeasonsInput;
  where: TournamentWhereUniqueInput;
};

export type TournamentUpsertWithWhereUniqueWithoutNameInput = {
  create: TournamentCreateWithoutNameInput;
  update: TournamentUpdateWithoutNameInput;
  where: TournamentWhereUniqueInput;
};

export type TournamentUpsertWithoutTournamentChampionshipsInput = {
  create: TournamentCreateWithoutTournamentChampionshipsInput;
  update: TournamentUpdateWithoutTournamentChampionshipsInput;
  where?: InputMaybe<TournamentWhereInput>;
};

export type TournamentUpsertWithoutTournamentVariantsInput = {
  create: TournamentCreateWithoutTournamentVariantsInput;
  update: TournamentUpdateWithoutTournamentVariantsInput;
  where?: InputMaybe<TournamentWhereInput>;
};

export type TournamentVariant = {
  __typename?: "TournamentVariant";
  _count: TournamentVariantCount;
  id: Scalars["ID"]["output"];
  name: Translatable;
  nameId: Scalars["Int"]["output"];
  rules: TournamentVariantRulesType;
  rulesName: Scalars["String"]["output"];
  tournament: Tournament;
  tournamentGamesWeeks?: Maybe<Array<TournamentGameWeek>>;
  tournamentId: Scalars["Int"]["output"];
};

export type TournamentVariantCount = {
  __typename?: "TournamentVariantCount";
  tournamentGamesWeeks: Scalars["Int"]["output"];
};

export type TournamentVariantCreateInput = {
  nameId: Scalars["Int"]["input"];
  rules?: InputMaybe<TournamentVariantRulesType>;
  tournamentId: Scalars["Int"]["input"];
};

export type TournamentVariantCreateManyNameInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  rules?: InputMaybe<TournamentVariantRulesType>;
  tournamentId: Scalars["Int"]["input"];
};

export type TournamentVariantCreateManyNameInputEnvelope = {
  data: Array<TournamentVariantCreateManyNameInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TournamentVariantCreateManyTournamentInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  nameId: Scalars["Int"]["input"];
  rules?: InputMaybe<TournamentVariantRulesType>;
};

export type TournamentVariantCreateManyTournamentInputEnvelope = {
  data: Array<TournamentVariantCreateManyTournamentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TournamentVariantCreateNestedManyWithoutNameInput = {
  connect?: InputMaybe<Array<TournamentVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentVariantCreateOrConnectWithoutNameInput>>;
  create?: InputMaybe<Array<TournamentVariantCreateWithoutNameInput>>;
  createMany?: InputMaybe<TournamentVariantCreateManyNameInputEnvelope>;
};

export type TournamentVariantCreateNestedManyWithoutTournamentInput = {
  connect?: InputMaybe<Array<TournamentVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentVariantCreateOrConnectWithoutTournamentInput>>;
  create?: InputMaybe<Array<TournamentVariantCreateWithoutTournamentInput>>;
  createMany?: InputMaybe<TournamentVariantCreateManyTournamentInputEnvelope>;
};

export type TournamentVariantCreateNestedOneWithoutTournamentGamesWeeksInput = {
  connect?: InputMaybe<TournamentVariantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TournamentVariantCreateOrConnectWithoutTournamentGamesWeeksInput>;
  create?: InputMaybe<TournamentVariantCreateWithoutTournamentGamesWeeksInput>;
};

export type TournamentVariantCreateOrConnectWithoutNameInput = {
  create: TournamentVariantCreateWithoutNameInput;
  where: TournamentVariantWhereUniqueInput;
};

export type TournamentVariantCreateOrConnectWithoutTournamentGamesWeeksInput = {
  create: TournamentVariantCreateWithoutTournamentGamesWeeksInput;
  where: TournamentVariantWhereUniqueInput;
};

export type TournamentVariantCreateOrConnectWithoutTournamentInput = {
  create: TournamentVariantCreateWithoutTournamentInput;
  where: TournamentVariantWhereUniqueInput;
};

export type TournamentVariantCreateWithoutNameInput = {
  rules?: InputMaybe<TournamentVariantRulesType>;
  tournament: TournamentCreateNestedOneWithoutTournamentVariantsInput;
  tournamentGamesWeeks?: InputMaybe<TournamentGameWeekCreateNestedManyWithoutVariantInput>;
};

export type TournamentVariantCreateWithoutTournamentGamesWeeksInput = {
  name: TranslatableCreateNestedOneWithoutTournamentVariantsInput;
  rules?: InputMaybe<TournamentVariantRulesType>;
  tournament: TournamentCreateNestedOneWithoutTournamentVariantsInput;
};

export type TournamentVariantCreateWithoutTournamentInput = {
  name: TranslatableCreateNestedOneWithoutTournamentVariantsInput;
  rules?: InputMaybe<TournamentVariantRulesType>;
  tournamentGamesWeeks?: InputMaybe<TournamentGameWeekCreateNestedManyWithoutVariantInput>;
};

export type TournamentVariantGameWeekCreateInput = {
  championshipSeasonRounds: Array<Scalars["String"]["input"]>;
  endDate: Scalars["Timestamp"]["input"];
  number: Scalars["Float"]["input"];
  startDate: Scalars["Timestamp"]["input"];
};

export type TournamentVariantListRelationFilter = {
  every?: InputMaybe<TournamentVariantWhereInput>;
  none?: InputMaybe<TournamentVariantWhereInput>;
  some?: InputMaybe<TournamentVariantWhereInput>;
};

export type TournamentVariantOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TournamentVariantOrderByWithRelationAndSearchRelevanceInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<TranslatableOrderByWithRelationAndSearchRelevanceInput>;
  nameId?: InputMaybe<SortOrder>;
  rules?: InputMaybe<SortOrder>;
  tournament?: InputMaybe<TournamentOrderByWithRelationAndSearchRelevanceInput>;
  tournamentGamesWeeks?: InputMaybe<TournamentGameWeekOrderByRelationAggregateInput>;
  tournamentId?: InputMaybe<SortOrder>;
};

export type TournamentVariantRelationFilter = {
  is?: InputMaybe<TournamentVariantWhereInput>;
  isNot?: InputMaybe<TournamentVariantWhereInput>;
};

export type TournamentVariantReward = {
  displayedQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  pointsMin?: InputMaybe<Scalars["Int"]["input"]>;
  rankingFrom?: InputMaybe<Scalars["Int"]["input"]>;
  rankingTo?: InputMaybe<Scalars["Int"]["input"]>;
  rewardType?: InputMaybe<RewardType>;
  topPercent?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TournamentVariantRewardsUpdateInput = {
  remove?: InputMaybe<Scalars["Boolean"]["input"]>;
  rewards?: InputMaybe<Array<TournamentVariantReward>>;
};

export enum TournamentVariantRulesType {
  Common = "COMMON",
  Limited = "LIMITED",
  Rare = "RARE",
  SuperRare = "SUPER_RARE",
  Unique = "UNIQUE",
}

export enum TournamentVariantScalarFieldEnum {
  Id = "id",
  NameId = "nameId",
  Rules = "rules",
  TournamentId = "tournamentId",
}

export type TournamentVariantScalarWhereInput = {
  AND?: InputMaybe<Array<TournamentVariantScalarWhereInput>>;
  NOT?: InputMaybe<Array<TournamentVariantScalarWhereInput>>;
  OR?: InputMaybe<Array<TournamentVariantScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  nameId?: InputMaybe<IntFilter>;
  rules?: InputMaybe<EnumTournamentVariantRulesTypeFilter>;
  tournamentId?: InputMaybe<IntFilter>;
};

export type TournamentVariantTournamentIdRulesCompoundUniqueInput = {
  rules: TournamentVariantRulesType;
  tournamentId: Scalars["Int"]["input"];
};

export type TournamentVariantUpdateInput = {
  nameId?: InputMaybe<IntFieldUpdateOperationsInput>;
  rules?: InputMaybe<EnumTournamentVariantRulesTypeFieldUpdateOperationsInput>;
  rulesName?: InputMaybe<StringFieldUpdateOperationsInput>;
  tournamentId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type TournamentVariantUpdateManyMutationInput = {
  rules?: InputMaybe<EnumTournamentVariantRulesTypeFieldUpdateOperationsInput>;
};

export type TournamentVariantUpdateManyWithWhereWithoutNameInput = {
  data: TournamentVariantUpdateManyMutationInput;
  where: TournamentVariantScalarWhereInput;
};

export type TournamentVariantUpdateManyWithWhereWithoutTournamentInput = {
  data: TournamentVariantUpdateManyMutationInput;
  where: TournamentVariantScalarWhereInput;
};

export type TournamentVariantUpdateManyWithoutNameNestedInput = {
  connect?: InputMaybe<Array<TournamentVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentVariantCreateOrConnectWithoutNameInput>>;
  create?: InputMaybe<Array<TournamentVariantCreateWithoutNameInput>>;
  createMany?: InputMaybe<TournamentVariantCreateManyNameInputEnvelope>;
  delete?: InputMaybe<Array<TournamentVariantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TournamentVariantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TournamentVariantWhereUniqueInput>>;
  set?: InputMaybe<Array<TournamentVariantWhereUniqueInput>>;
  update?: InputMaybe<Array<TournamentVariantUpdateWithWhereUniqueWithoutNameInput>>;
  updateMany?: InputMaybe<Array<TournamentVariantUpdateManyWithWhereWithoutNameInput>>;
  upsert?: InputMaybe<Array<TournamentVariantUpsertWithWhereUniqueWithoutNameInput>>;
};

export type TournamentVariantUpdateManyWithoutTournamentNestedInput = {
  connect?: InputMaybe<Array<TournamentVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TournamentVariantCreateOrConnectWithoutTournamentInput>>;
  create?: InputMaybe<Array<TournamentVariantCreateWithoutTournamentInput>>;
  createMany?: InputMaybe<TournamentVariantCreateManyTournamentInputEnvelope>;
  delete?: InputMaybe<Array<TournamentVariantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TournamentVariantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TournamentVariantWhereUniqueInput>>;
  set?: InputMaybe<Array<TournamentVariantWhereUniqueInput>>;
  update?: InputMaybe<Array<TournamentVariantUpdateWithWhereUniqueWithoutTournamentInput>>;
  updateMany?: InputMaybe<Array<TournamentVariantUpdateManyWithWhereWithoutTournamentInput>>;
  upsert?: InputMaybe<Array<TournamentVariantUpsertWithWhereUniqueWithoutTournamentInput>>;
};

export type TournamentVariantUpdateOneRequiredWithoutTournamentGamesWeeksNestedInput = {
  connect?: InputMaybe<TournamentVariantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TournamentVariantCreateOrConnectWithoutTournamentGamesWeeksInput>;
  create?: InputMaybe<TournamentVariantCreateWithoutTournamentGamesWeeksInput>;
  update?: InputMaybe<TournamentVariantUpdateToOneWithWhereWithoutTournamentGamesWeeksInput>;
  upsert?: InputMaybe<TournamentVariantUpsertWithoutTournamentGamesWeeksInput>;
};

export type TournamentVariantUpdateToOneWithWhereWithoutTournamentGamesWeeksInput = {
  data: TournamentVariantUpdateWithoutTournamentGamesWeeksInput;
  where?: InputMaybe<TournamentVariantWhereInput>;
};

export type TournamentVariantUpdateWithWhereUniqueWithoutNameInput = {
  data: TournamentVariantUpdateWithoutNameInput;
  where: TournamentVariantWhereUniqueInput;
};

export type TournamentVariantUpdateWithWhereUniqueWithoutTournamentInput = {
  data: TournamentVariantUpdateWithoutTournamentInput;
  where: TournamentVariantWhereUniqueInput;
};

export type TournamentVariantUpdateWithoutNameInput = {
  rules?: InputMaybe<EnumTournamentVariantRulesTypeFieldUpdateOperationsInput>;
  tournament?: InputMaybe<TournamentUpdateOneRequiredWithoutTournamentVariantsNestedInput>;
  tournamentGamesWeeks?: InputMaybe<TournamentGameWeekUpdateManyWithoutVariantNestedInput>;
};

export type TournamentVariantUpdateWithoutTournamentGamesWeeksInput = {
  name?: InputMaybe<TranslatableUpdateOneRequiredWithoutTournamentVariantsNestedInput>;
  rules?: InputMaybe<EnumTournamentVariantRulesTypeFieldUpdateOperationsInput>;
  tournament?: InputMaybe<TournamentUpdateOneRequiredWithoutTournamentVariantsNestedInput>;
};

export type TournamentVariantUpdateWithoutTournamentInput = {
  name?: InputMaybe<TranslatableUpdateOneRequiredWithoutTournamentVariantsNestedInput>;
  rules?: InputMaybe<EnumTournamentVariantRulesTypeFieldUpdateOperationsInput>;
  tournamentGamesWeeks?: InputMaybe<TournamentGameWeekUpdateManyWithoutVariantNestedInput>;
};

export type TournamentVariantUpsertWithWhereUniqueWithoutNameInput = {
  create: TournamentVariantCreateWithoutNameInput;
  update: TournamentVariantUpdateWithoutNameInput;
  where: TournamentVariantWhereUniqueInput;
};

export type TournamentVariantUpsertWithWhereUniqueWithoutTournamentInput = {
  create: TournamentVariantCreateWithoutTournamentInput;
  update: TournamentVariantUpdateWithoutTournamentInput;
  where: TournamentVariantWhereUniqueInput;
};

export type TournamentVariantUpsertWithoutTournamentGamesWeeksInput = {
  create: TournamentVariantCreateWithoutTournamentGamesWeeksInput;
  update: TournamentVariantUpdateWithoutTournamentGamesWeeksInput;
  where?: InputMaybe<TournamentVariantWhereInput>;
};

export type TournamentVariantWhereInput = {
  AND?: InputMaybe<Array<TournamentVariantWhereInput>>;
  NOT?: InputMaybe<Array<TournamentVariantWhereInput>>;
  OR?: InputMaybe<Array<TournamentVariantWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<TranslatableRelationFilter>;
  nameId?: InputMaybe<IntFilter>;
  rules?: InputMaybe<EnumTournamentVariantRulesTypeFilter>;
  tournament?: InputMaybe<TournamentRelationFilter>;
  tournamentGamesWeeks?: InputMaybe<TournamentGameWeekListRelationFilter>;
  tournamentId?: InputMaybe<IntFilter>;
};

export type TournamentVariantWhereUniqueInput = {
  AND?: InputMaybe<Array<TournamentVariantWhereInput>>;
  NOT?: InputMaybe<Array<TournamentVariantWhereInput>>;
  OR?: InputMaybe<Array<TournamentVariantWhereInput>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<TranslatableRelationFilter>;
  nameId?: InputMaybe<IntFilter>;
  rules?: InputMaybe<EnumTournamentVariantRulesTypeFilter>;
  tournament?: InputMaybe<TournamentRelationFilter>;
  tournamentGamesWeeks?: InputMaybe<TournamentGameWeekListRelationFilter>;
  tournamentId?: InputMaybe<IntFilter>;
  tournamentId_rules?: InputMaybe<TournamentVariantTournamentIdRulesCompoundUniqueInput>;
};

export type TournamentWhereInput = {
  AND?: InputMaybe<Array<TournamentWhereInput>>;
  NOT?: InputMaybe<Array<TournamentWhereInput>>;
  OR?: InputMaybe<Array<TournamentWhereInput>>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<BytesNullableFilter>;
  name?: InputMaybe<TranslatableRelationFilter>;
  nameId?: InputMaybe<IntFilter>;
  tournamentChampionships?: InputMaybe<TournamentChampionshipListRelationFilter>;
  tournamentState?: InputMaybe<EnumTournamentStateFilter>;
  tournamentVariants?: InputMaybe<TournamentVariantListRelationFilter>;
};

export type TournamentWhereUniqueInput = {
  AND?: InputMaybe<Array<TournamentWhereInput>>;
  NOT?: InputMaybe<Array<TournamentWhereInput>>;
  OR?: InputMaybe<Array<TournamentWhereInput>>;
  championshipSeasons?: InputMaybe<ChampionshipSeasonListRelationFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<BytesNullableFilter>;
  name?: InputMaybe<TranslatableRelationFilter>;
  nameId?: InputMaybe<IntFilter>;
  tournamentChampionships?: InputMaybe<TournamentChampionshipListRelationFilter>;
  tournamentState?: InputMaybe<EnumTournamentStateFilter>;
  tournamentVariants?: InputMaybe<TournamentVariantListRelationFilter>;
};

export type Translatable = {
  __typename?: "Translatable";
  _count: TranslatableCount;
  code: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  questions?: Maybe<Array<Reward>>;
  rewards?: Maybe<Array<Reward>>;
  tournamentVariants?: Maybe<Array<TournamentVariant>>;
  tournaments?: Maybe<Array<Tournament>>;
  translations?: Maybe<Array<Translation>>;
};

export type TranslatableCount = {
  __typename?: "TranslatableCount";
  questions: Scalars["Int"]["output"];
  rewards: Scalars["Int"]["output"];
  tournamentVariants: Scalars["Int"]["output"];
  tournaments: Scalars["Int"]["output"];
  translations: Scalars["Int"]["output"];
};

export type TranslatableCreateInput = {
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
};

export type TranslatableCreateNestedOneWithoutQuestionsInput = {
  connect?: InputMaybe<TranslatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TranslatableCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<TranslatableCreateWithoutQuestionsInput>;
};

export type TranslatableCreateNestedOneWithoutRewardsInput = {
  connect?: InputMaybe<TranslatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TranslatableCreateOrConnectWithoutRewardsInput>;
  create?: InputMaybe<TranslatableCreateWithoutRewardsInput>;
};

export type TranslatableCreateNestedOneWithoutTournamentVariantsInput = {
  connect?: InputMaybe<TranslatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TranslatableCreateOrConnectWithoutTournamentVariantsInput>;
  create?: InputMaybe<TranslatableCreateWithoutTournamentVariantsInput>;
};

export type TranslatableCreateNestedOneWithoutTournamentsInput = {
  connect?: InputMaybe<TranslatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TranslatableCreateOrConnectWithoutTournamentsInput>;
  create?: InputMaybe<TranslatableCreateWithoutTournamentsInput>;
};

export type TranslatableCreateOrConnectWithoutQuestionsInput = {
  create: TranslatableCreateWithoutQuestionsInput;
  where: TranslatableWhereUniqueInput;
};

export type TranslatableCreateOrConnectWithoutRewardsInput = {
  create: TranslatableCreateWithoutRewardsInput;
  where: TranslatableWhereUniqueInput;
};

export type TranslatableCreateOrConnectWithoutTournamentVariantsInput = {
  create: TranslatableCreateWithoutTournamentVariantsInput;
  where: TranslatableWhereUniqueInput;
};

export type TranslatableCreateOrConnectWithoutTournamentsInput = {
  create: TranslatableCreateWithoutTournamentsInput;
  where: TranslatableWhereUniqueInput;
};

export type TranslatableCreateWithoutQuestionsInput = {
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  rewards?: InputMaybe<RewardCreateNestedManyWithoutNameInput>;
  tournamentVariants?: InputMaybe<TournamentVariantCreateNestedManyWithoutNameInput>;
  tournaments?: InputMaybe<TournamentCreateNestedManyWithoutNameInput>;
  translations?: InputMaybe<TranslationCreateNestedManyWithoutTranslatableInput>;
};

export type TranslatableCreateWithoutRewardsInput = {
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  questions?: InputMaybe<RewardCreateNestedManyWithoutQuestionInputInput>;
  tournamentVariants?: InputMaybe<TournamentVariantCreateNestedManyWithoutNameInput>;
  tournaments?: InputMaybe<TournamentCreateNestedManyWithoutNameInput>;
  translations?: InputMaybe<TranslationCreateNestedManyWithoutTranslatableInput>;
};

export type TranslatableCreateWithoutTournamentVariantsInput = {
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  questions?: InputMaybe<RewardCreateNestedManyWithoutQuestionInputInput>;
  rewards?: InputMaybe<RewardCreateNestedManyWithoutNameInput>;
  tournaments?: InputMaybe<TournamentCreateNestedManyWithoutNameInput>;
  translations?: InputMaybe<TranslationCreateNestedManyWithoutTranslatableInput>;
};

export type TranslatableCreateWithoutTournamentsInput = {
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  questions?: InputMaybe<RewardCreateNestedManyWithoutQuestionInputInput>;
  rewards?: InputMaybe<RewardCreateNestedManyWithoutNameInput>;
  tournamentVariants?: InputMaybe<TournamentVariantCreateNestedManyWithoutNameInput>;
  translations?: InputMaybe<TranslationCreateNestedManyWithoutTranslatableInput>;
};

export type TranslatableNullableRelationFilter = {
  is?: InputMaybe<TranslatableWhereInput>;
  isNot?: InputMaybe<TranslatableWhereInput>;
};

export enum TranslatableOrderByRelevanceFieldEnum {
  Code = "code",
  Description = "description",
}

export type TranslatableOrderByRelevanceInput = {
  fields: Array<TranslatableOrderByRelevanceFieldEnum>;
  search: Scalars["String"]["input"];
  sort: SortOrder;
};

export type TranslatableOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<TranslatableOrderByRelevanceInput>;
  code?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  questions?: InputMaybe<RewardOrderByRelationAggregateInput>;
  rewards?: InputMaybe<RewardOrderByRelationAggregateInput>;
  tournamentVariants?: InputMaybe<TournamentVariantOrderByRelationAggregateInput>;
  tournaments?: InputMaybe<TournamentOrderByRelationAggregateInput>;
  translations?: InputMaybe<TranslationOrderByRelationAggregateInput>;
};

export type TranslatableRelationFilter = {
  is?: InputMaybe<TranslatableWhereInput>;
  isNot?: InputMaybe<TranslatableWhereInput>;
};

export enum TranslatableScalarFieldEnum {
  Code = "code",
  Description = "description",
  Id = "id",
}

export type TranslatableUpdateInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TranslatableUpdateOneRequiredWithoutTournamentVariantsNestedInput = {
  connect?: InputMaybe<TranslatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TranslatableCreateOrConnectWithoutTournamentVariantsInput>;
  create?: InputMaybe<TranslatableCreateWithoutTournamentVariantsInput>;
  update?: InputMaybe<TranslatableUpdateToOneWithWhereWithoutTournamentVariantsInput>;
  upsert?: InputMaybe<TranslatableUpsertWithoutTournamentVariantsInput>;
};

export type TranslatableUpdateOneRequiredWithoutTournamentsNestedInput = {
  connect?: InputMaybe<TranslatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TranslatableCreateOrConnectWithoutTournamentsInput>;
  create?: InputMaybe<TranslatableCreateWithoutTournamentsInput>;
  update?: InputMaybe<TranslatableUpdateToOneWithWhereWithoutTournamentsInput>;
  upsert?: InputMaybe<TranslatableUpsertWithoutTournamentsInput>;
};

export type TranslatableUpdateOneWithoutQuestionsNestedInput = {
  connect?: InputMaybe<TranslatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TranslatableCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<TranslatableCreateWithoutQuestionsInput>;
  delete?: InputMaybe<TranslatableWhereInput>;
  disconnect?: InputMaybe<TranslatableWhereInput>;
  update?: InputMaybe<TranslatableUpdateToOneWithWhereWithoutQuestionsInput>;
  upsert?: InputMaybe<TranslatableUpsertWithoutQuestionsInput>;
};

export type TranslatableUpdateOneWithoutRewardsNestedInput = {
  connect?: InputMaybe<TranslatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TranslatableCreateOrConnectWithoutRewardsInput>;
  create?: InputMaybe<TranslatableCreateWithoutRewardsInput>;
  delete?: InputMaybe<TranslatableWhereInput>;
  disconnect?: InputMaybe<TranslatableWhereInput>;
  update?: InputMaybe<TranslatableUpdateToOneWithWhereWithoutRewardsInput>;
  upsert?: InputMaybe<TranslatableUpsertWithoutRewardsInput>;
};

export type TranslatableUpdateToOneWithWhereWithoutQuestionsInput = {
  data: TranslatableUpdateWithoutQuestionsInput;
  where?: InputMaybe<TranslatableWhereInput>;
};

export type TranslatableUpdateToOneWithWhereWithoutRewardsInput = {
  data: TranslatableUpdateWithoutRewardsInput;
  where?: InputMaybe<TranslatableWhereInput>;
};

export type TranslatableUpdateToOneWithWhereWithoutTournamentVariantsInput = {
  data: TranslatableUpdateWithoutTournamentVariantsInput;
  where?: InputMaybe<TranslatableWhereInput>;
};

export type TranslatableUpdateToOneWithWhereWithoutTournamentsInput = {
  data: TranslatableUpdateWithoutTournamentsInput;
  where?: InputMaybe<TranslatableWhereInput>;
};

export type TranslatableUpdateWithoutQuestionsInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rewards?: InputMaybe<RewardUpdateManyWithoutNameNestedInput>;
  tournamentVariants?: InputMaybe<TournamentVariantUpdateManyWithoutNameNestedInput>;
  tournaments?: InputMaybe<TournamentUpdateManyWithoutNameNestedInput>;
  translations?: InputMaybe<TranslationUpdateManyWithoutTranslatableNestedInput>;
};

export type TranslatableUpdateWithoutRewardsInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questions?: InputMaybe<RewardUpdateManyWithoutQuestionInputNestedInput>;
  tournamentVariants?: InputMaybe<TournamentVariantUpdateManyWithoutNameNestedInput>;
  tournaments?: InputMaybe<TournamentUpdateManyWithoutNameNestedInput>;
  translations?: InputMaybe<TranslationUpdateManyWithoutTranslatableNestedInput>;
};

export type TranslatableUpdateWithoutTournamentVariantsInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questions?: InputMaybe<RewardUpdateManyWithoutQuestionInputNestedInput>;
  rewards?: InputMaybe<RewardUpdateManyWithoutNameNestedInput>;
  tournaments?: InputMaybe<TournamentUpdateManyWithoutNameNestedInput>;
  translations?: InputMaybe<TranslationUpdateManyWithoutTranslatableNestedInput>;
};

export type TranslatableUpdateWithoutTournamentsInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questions?: InputMaybe<RewardUpdateManyWithoutQuestionInputNestedInput>;
  rewards?: InputMaybe<RewardUpdateManyWithoutNameNestedInput>;
  tournamentVariants?: InputMaybe<TournamentVariantUpdateManyWithoutNameNestedInput>;
  translations?: InputMaybe<TranslationUpdateManyWithoutTranslatableNestedInput>;
};

export type TranslatableUpsertWithoutQuestionsInput = {
  create: TranslatableCreateWithoutQuestionsInput;
  update: TranslatableUpdateWithoutQuestionsInput;
  where?: InputMaybe<TranslatableWhereInput>;
};

export type TranslatableUpsertWithoutRewardsInput = {
  create: TranslatableCreateWithoutRewardsInput;
  update: TranslatableUpdateWithoutRewardsInput;
  where?: InputMaybe<TranslatableWhereInput>;
};

export type TranslatableUpsertWithoutTournamentVariantsInput = {
  create: TranslatableCreateWithoutTournamentVariantsInput;
  update: TranslatableUpdateWithoutTournamentVariantsInput;
  where?: InputMaybe<TranslatableWhereInput>;
};

export type TranslatableUpsertWithoutTournamentsInput = {
  create: TranslatableCreateWithoutTournamentsInput;
  update: TranslatableUpdateWithoutTournamentsInput;
  where?: InputMaybe<TranslatableWhereInput>;
};

export type TranslatableWhereInput = {
  AND?: InputMaybe<Array<TranslatableWhereInput>>;
  NOT?: InputMaybe<Array<TranslatableWhereInput>>;
  OR?: InputMaybe<Array<TranslatableWhereInput>>;
  code?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  questions?: InputMaybe<RewardListRelationFilter>;
  rewards?: InputMaybe<RewardListRelationFilter>;
  tournamentVariants?: InputMaybe<TournamentVariantListRelationFilter>;
  tournaments?: InputMaybe<TournamentListRelationFilter>;
  translations?: InputMaybe<TranslationListRelationFilter>;
};

export type TranslatableWhereUniqueInput = {
  AND?: InputMaybe<Array<TranslatableWhereInput>>;
  NOT?: InputMaybe<Array<TranslatableWhereInput>>;
  OR?: InputMaybe<Array<TranslatableWhereInput>>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<RewardListRelationFilter>;
  rewards?: InputMaybe<RewardListRelationFilter>;
  tournamentVariants?: InputMaybe<TournamentVariantListRelationFilter>;
  tournaments?: InputMaybe<TournamentListRelationFilter>;
  translations?: InputMaybe<TranslationListRelationFilter>;
};

export type Translation = {
  __typename?: "Translation";
  id: Scalars["ID"]["output"];
  language: Language;
  languageId: Scalars["Int"]["output"];
  translatable: Translatable;
  translatableId: Scalars["Int"]["output"];
  value: Scalars["String"]["output"];
};

export type TranslationCreateInput = {
  languageId: Scalars["Int"]["input"];
  translatableId: Scalars["Int"]["input"];
  value: Scalars["String"]["input"];
};

export type TranslationCreateManyTranslatableInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  languageId: Scalars["Int"]["input"];
  value: Scalars["String"]["input"];
};

export type TranslationCreateManyTranslatableInputEnvelope = {
  data: Array<TranslationCreateManyTranslatableInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TranslationCreateNestedManyWithoutTranslatableInput = {
  connect?: InputMaybe<Array<TranslationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TranslationCreateOrConnectWithoutTranslatableInput>>;
  create?: InputMaybe<Array<TranslationCreateWithoutTranslatableInput>>;
  createMany?: InputMaybe<TranslationCreateManyTranslatableInputEnvelope>;
};

export type TranslationCreateOrConnectWithoutTranslatableInput = {
  create: TranslationCreateWithoutTranslatableInput;
  where: TranslationWhereUniqueInput;
};

export type TranslationCreateWithoutTranslatableInput = {
  language: LanguageCreateNestedOneWithoutTranslationsInput;
  value: Scalars["String"]["input"];
};

export type TranslationListRelationFilter = {
  every?: InputMaybe<TranslationWhereInput>;
  none?: InputMaybe<TranslationWhereInput>;
  some?: InputMaybe<TranslationWhereInput>;
};

export type TranslationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum TranslationOrderByRelevanceFieldEnum {
  Value = "value",
}

export type TranslationOrderByRelevanceInput = {
  fields: Array<TranslationOrderByRelevanceFieldEnum>;
  search: Scalars["String"]["input"];
  sort: SortOrder;
};

export type TranslationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<TranslationOrderByRelevanceInput>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<LanguageOrderByWithRelationAndSearchRelevanceInput>;
  languageId?: InputMaybe<SortOrder>;
  translatable?: InputMaybe<TranslatableOrderByWithRelationAndSearchRelevanceInput>;
  translatableId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum TranslationScalarFieldEnum {
  Id = "id",
  LanguageId = "languageId",
  TranslatableId = "translatableId",
  Value = "value",
}

export type TranslationScalarWhereInput = {
  AND?: InputMaybe<Array<TranslationScalarWhereInput>>;
  NOT?: InputMaybe<Array<TranslationScalarWhereInput>>;
  OR?: InputMaybe<Array<TranslationScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  languageId?: InputMaybe<IntFilter>;
  translatableId?: InputMaybe<IntFilter>;
  value?: InputMaybe<StringFilter>;
};

export type TranslationUpdateInput = {
  languageId?: InputMaybe<IntFieldUpdateOperationsInput>;
  translatableId?: InputMaybe<IntFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TranslationUpdateManyMutationInput = {
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TranslationUpdateManyWithWhereWithoutTranslatableInput = {
  data: TranslationUpdateManyMutationInput;
  where: TranslationScalarWhereInput;
};

export type TranslationUpdateManyWithoutTranslatableNestedInput = {
  connect?: InputMaybe<Array<TranslationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TranslationCreateOrConnectWithoutTranslatableInput>>;
  create?: InputMaybe<Array<TranslationCreateWithoutTranslatableInput>>;
  createMany?: InputMaybe<TranslationCreateManyTranslatableInputEnvelope>;
  delete?: InputMaybe<Array<TranslationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TranslationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TranslationWhereUniqueInput>>;
  set?: InputMaybe<Array<TranslationWhereUniqueInput>>;
  update?: InputMaybe<Array<TranslationUpdateWithWhereUniqueWithoutTranslatableInput>>;
  updateMany?: InputMaybe<Array<TranslationUpdateManyWithWhereWithoutTranslatableInput>>;
  upsert?: InputMaybe<Array<TranslationUpsertWithWhereUniqueWithoutTranslatableInput>>;
};

export type TranslationUpdateWithWhereUniqueWithoutTranslatableInput = {
  data: TranslationUpdateWithoutTranslatableInput;
  where: TranslationWhereUniqueInput;
};

export type TranslationUpdateWithoutTranslatableInput = {
  language?: InputMaybe<LanguageUpdateOneRequiredWithoutTranslationsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TranslationUpsertWithWhereUniqueWithoutTranslatableInput = {
  create: TranslationCreateWithoutTranslatableInput;
  update: TranslationUpdateWithoutTranslatableInput;
  where: TranslationWhereUniqueInput;
};

export type TranslationWhereInput = {
  AND?: InputMaybe<Array<TranslationWhereInput>>;
  NOT?: InputMaybe<Array<TranslationWhereInput>>;
  OR?: InputMaybe<Array<TranslationWhereInput>>;
  id?: InputMaybe<IntFilter>;
  language?: InputMaybe<LanguageRelationFilter>;
  languageId?: InputMaybe<IntFilter>;
  translatable?: InputMaybe<TranslatableRelationFilter>;
  translatableId?: InputMaybe<IntFilter>;
  value?: InputMaybe<StringFilter>;
};

export type TranslationWhereUniqueInput = {
  AND?: InputMaybe<Array<TranslationWhereInput>>;
  NOT?: InputMaybe<Array<TranslationWhereInput>>;
  OR?: InputMaybe<Array<TranslationWhereInput>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  language?: InputMaybe<LanguageRelationFilter>;
  languageId?: InputMaybe<IntFilter>;
  translatable?: InputMaybe<TranslatableRelationFilter>;
  translatableId?: InputMaybe<IntFilter>;
  value?: InputMaybe<StringFilter>;
};

export type User = {
  __typename?: "User";
  id: Scalars["ID"]["output"];
  oval3MarketPlaceUserId: Scalars["String"]["output"];
  referees?: Maybe<Array<User>>;
  referredBy?: Maybe<User>;
  referredById?: Maybe<Scalars["Int"]["output"]>;
  username: Scalars["String"]["output"];
};

export type CardsQueryVariables = Exact<{ [key: string]: never }>;

export type CardsQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "MarketPlaceUserType";
    wallet: {
      __typename?: "MarketPlaceWalletType";
      cards?: Array<{
        __typename?: "MarketPlaceCardType";
        tokenId?: string | null;
        rarity?: MarketPlaceRarity | null;
        edition?: string | null;
        image?: string | null;
        age?: number | null;
        xp?: number | null;
        optaId?: number | null;
        position?: { __typename?: "MarketPlacePositionType"; position?: string | null } | null;
        player?: {
          __typename?: "MarketPlacePlayerType";
          firstName?: string | null;
          lastName?: string | null;
          optaId?: string | null;
          currentNationality?: { __typename?: "MarketPlaceNationalityType"; nationality?: string | null } | null;
          currentCompetition?: { __typename?: "MarketPlaceCompetitionType"; name?: string | null } | null;
          currentPosition?: { __typename?: "MarketPlacePositionType"; position?: string | null } | null;
          playerStats?: {
            __typename?: "MarketPlacePlayerStatsType";
            attack?: number | null;
            defense?: number | null;
            impact?: number | null;
            scoring?: number | null;
            skills?: number | null;
            strength?: number | null;
          } | null;
        } | null;
      }> | null;
    };
  } | null;
};

export type AllChampionshipsQueryVariables = Exact<{ [key: string]: never }>;

export type AllChampionshipsQuery = {
  __typename?: "Query";
  allChampionships?: Array<{
    __typename?: "Championship";
    name: string;
    championshipOptaId: string;
    championshipSeasons?: Array<{ __typename?: "ChampionshipSeason"; id: string }> | null;
  }> | null;
};

export type CommonCardsByGameWeekQueryVariables = Exact<{
  gameWeekId: Scalars["Float"]["input"];
}>;

export type CommonCardsByGameWeekQuery = {
  __typename?: "Query";
  getCommonCards?: Array<{
    __typename?: "CommonCardHasPlayed";
    hasPlayed?: boolean | null;
    commonCard?: {
      __typename?: "CommonCard";
      id: string;
      age: number;
      championshipOptaId: string;
      playerId: number;
      playerOptaId: string;
      teamOptaId: string;
      seasonOptaId: string;
      image: string;
      player: {
        __typename?: "Player";
        firstname: string;
        lastname: string;
        position: PositionType;
        optaPosition: string;
        scoring: number;
        attack: number;
        impact: number;
        strength: number;
        skills: number;
      };
    } | null;
  }> | null;
};

export type AllCommonCardsQueryVariables = Exact<{ [key: string]: never }>;

export type AllCommonCardsQuery = {
  __typename?: "Query";
  allCommonCards: Array<{
    __typename?: "CommonCard";
    id: string;
    age: number;
    championshipOptaId: string;
    playerId: number;
    playerOptaId: string;
    teamOptaId: string;
    seasonOptaId: string;
    image: string;
    player: {
      __typename?: "Player";
      firstname: string;
      lastname: string;
      position: PositionType;
      optaPosition: string;
      scoring: number;
      attack: number;
      impact: number;
      strength: number;
      skills: number;
    };
  }>;
};

export type GetCompositionsForCurrentUserQueryVariables = Exact<{
  status?: InputMaybe<Array<TournamentGameWeekStatus> | TournamentGameWeekStatus>;
}>;

export type GetCompositionsForCurrentUserQuery = {
  __typename?: "Query";
  getCompositionsForCurrentUser: Array<{
    __typename?: "Composition";
    backPoints: number;
    halfPoints: number;
    firstRowPoints: number;
    secondRowPoints: number;
    extraPoints: number;
    id: string;
    captainCommonCardId?: number | null;
    captainMarketPlaceCardTokenId?: string | null;
    firstRowCommonCardId?: number | null;
    firstRowMarketPlaceCardTokenId?: string | null;
    secondRowCommonCardId?: number | null;
    secondRowMarketPlaceCardTokenId?: string | null;
    halfCommonCardId?: number | null;
    halfMarketPlaceCardTokenId?: string | null;
    backCommonCardId?: number | null;
    backMarketPlaceCardTokenId?: string | null;
    extraCommonCardId?: number | null;
    extraMarketPlaceCardTokenId?: string | null;
    canFirstRowBeChanged: boolean;
    canSecondRowBeChanged: boolean;
    canHalfBeChanged: boolean;
    canBackBeChanged: boolean;
    canExtraBeChanged: boolean;
    score?: number | null;
    ranking?: number | null;
    gameWeek: {
      __typename?: "TournamentGameWeek";
      id: string;
      status: TournamentGameWeekStatus;
      startDate: any;
      endDate: any;
      variantId: number;
      number: number;
      compositionsCount: number;
      realGames: Array<{
        __typename?: "RealGame";
        status: RealGameStatus;
        datetime?: any | null;
        awayTeamOptaId?: string | null;
        awayScore: number;
        awayTeamName?: string | null;
        homeTeamOptaId: string;
        homeScore: number;
        homeTeamName: string;
        winnerTeamOptaId?: string | null;
        round: number;
        championshipSeason: { __typename?: "ChampionshipSeason"; championship: { __typename?: "Championship"; name: string } };
      }>;
      gameWeekRewards?: Array<{
        __typename?: "GameWeekReward";
        reward: {
          __typename?: "Reward";
          criteria: RewardCriteria;
          rankingFrom: number;
          rankingTo: number;
          pointsMin: number;
          rewardType: RewardType;
          displayedQuantity?: number | null;
          name?: {
            __typename?: "Translatable";
            translations?: Array<{ __typename?: "Translation"; value: string; language: { __typename?: "Language"; code: string } }> | null;
          } | null;
        };
      }> | null;
    };
  }>;
};

export type CreateCompositionForCurrentUserMutationVariables = Exact<{
  arg: CompositionCreateInput;
}>;

export type CreateCompositionForCurrentUserMutation = {
  __typename?: "Mutation";
  createCompositionForCurrentUser: { __typename?: "Composition"; id: string };
};

export type HasPlayersPlayedDuringGameWeekQueryVariables = Exact<{
  playerOptaIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  gameWeekId: Scalars["Int"]["input"];
}>;

export type HasPlayersPlayedDuringGameWeekQuery = {
  __typename?: "Query";
  hasPlayersPlayedDuringGameWeek: Array<{ __typename?: "PlayerHasPlayed"; playerOptaId: string; hasPlayed: boolean }>;
};

export type GetLeaderboardQueryVariables = Exact<{
  id: Scalars["Float"]["input"];
  take: Scalars["Float"]["input"];
}>;

export type GetLeaderboardQuery = {
  __typename?: "Query";
  getLeaderboard: Array<{ __typename?: "Composition"; score?: number | null; ranking?: number | null; username?: string | null }>;
};

export type MarketPlaceCardPlayerCacheQueryVariables = Exact<{
  cardTokenId: Scalars["String"]["input"];
}>;

export type MarketPlaceCardPlayerCacheQuery = {
  __typename?: "Query";
  marketPlaceCardPlayerCache?: {
    __typename?: "MarketPlaceCardPlayerCache";
    cardTokenId: string;
    cardRarity?: ScarcityType | null;
    rarity: ScarcityType;
    optaId: string;
    tokenId: string;
    xp?: number | null;
    age?: number | null;
    nationality?: string | null;
    image?: string | null;
    edition?: string | null;
    updatedAt: any;
    playerFirstName?: string | null;
    playerOptaId: string;
    playerLastName?: string | null;
    position?: string | null;
    player: {
      __typename?: "MarketPlacePlayerType";
      firstName?: string | null;
      lastName?: string | null;
      optaId?: string | null;
      currentPosition?: { __typename?: "MarketPlacePositionType"; position?: string | null } | null;
      currentCompetition?: { __typename?: "MarketPlaceCompetitionType"; name?: string | null } | null;
      currentNationality?: { __typename?: "MarketPlaceNationalityType"; nationality?: string | null; codeIso?: string | null } | null;
      playerStats?: {
        __typename?: "MarketPlacePlayerStatsType";
        attack?: number | null;
        defense?: number | null;
        strength?: number | null;
        impact?: number | null;
        skills?: number | null;
        scoring?: number | null;
      } | null;
    };
  } | null;
};

export type PlayerScoreQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type PlayerScoreQuery = { __typename?: "Query"; Player: { __typename?: "Player"; livePoints?: number | null } };

export type PlayerScoringMarketPlaceQueryVariables = Exact<{
  optaId: Scalars["String"]["input"];
}>;

export type PlayerScoringMarketPlaceQuery = {
  __typename?: "Query";
  playerScoringMarketPlace: {
    __typename?: "PlayerScoringType";
    evolution?: string | null;
    minutes_played_total?: string | null;
    red_cards?: string | null;
    yellow_cards?: string | null;
    tries?: string | null;
    appearances?: string | null;
    nb_games?: Array<{ __typename?: "PlayerScoringGameType"; game_date?: string | null; metadata_total?: string | null }> | null;
  };
};

export type PlayerUpcomingHomeRealGamesQueryVariables = Exact<{
  playerOptaId: Scalars["String"]["input"];
  nowInMs: Scalars["Timestamp"]["input"];
}>;

export type PlayerUpcomingHomeRealGamesQuery = {
  __typename?: "Query";
  getManyRealGames?: Array<{
    __typename?: "RealGame";
    id: string;
    awayTeamName?: string | null;
    awayScore: number;
    awayTeamOptaId?: string | null;
    homeTeamName: string;
    homeScore: number;
    homeTeamOptaId: string;
    winnerTeamOptaId?: string | null;
    datetime?: any | null;
  }> | null;
};

export type PlayerUpcomingAwayRealGamesQueryVariables = Exact<{
  playerOptaId: Scalars["String"]["input"];
  nowInMs: Scalars["Timestamp"]["input"];
}>;

export type PlayerUpcomingAwayRealGamesQuery = {
  __typename?: "Query";
  getManyRealGames?: Array<{
    __typename?: "RealGame";
    id: string;
    awayTeamName?: string | null;
    awayScore: number;
    awayTeamOptaId?: string | null;
    homeTeamName: string;
    homeScore: number;
    homeTeamOptaId: string;
    winnerTeamOptaId?: string | null;
    datetime?: any | null;
  }> | null;
};

export type GetLivePlayerStatsQueryVariables = Exact<{
  optaId: Scalars["String"]["input"];
}>;

export type GetLivePlayerStatsQuery = {
  __typename?: "Query";
  getManyRealGamePlayers: Array<{
    __typename?: "RealGamePlayer";
    realGame: { __typename?: "RealGame"; status: RealGameStatus; datetime?: any | null };
    playerScore?: { __typename?: "PlayerScore"; playerStats?: string | null; playerPoints?: number | null } | null;
  }>;
};

export type RewardClaimsFromCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type RewardClaimsFromCurrentUserQuery = {
  __typename?: "Query";
  rewardClaimsFromCurrentUser?: Array<{
    __typename?: "RewardClaim";
    id: string;
    rewardClaimState: RewardClaimState;
    marketPlaceCardTokenId?: string | null;
    composition: { __typename?: "Composition"; ranking?: number | null };
    gameWeekReward: {
      __typename?: "GameWeekReward";
      gameWeekId: number;
      gameWeek: {
        __typename?: "TournamentGameWeek";
        startDate: any;
        endDate: any;
        number: number;
        variant: {
          __typename?: "TournamentVariant";
          rules: TournamentVariantRulesType;
          tournament: {
            __typename?: "Tournament";
            name: {
              __typename?: "Translatable";
              code: string;
              translations?: Array<{ __typename?: "Translation"; value: string; language: { __typename?: "Language"; code: string } }> | null;
            };
          };
        };
      };
      reward: {
        __typename?: "Reward";
        id: string;
        rewardType: RewardType;
        name?: {
          __typename?: "Translatable";
          translations?: Array<{ __typename?: "Translation"; value: string; language: { __typename?: "Language"; code: string } }> | null;
        } | null;
        questionInput?: {
          __typename?: "Translatable";
          translations?: Array<{ __typename?: "Translation"; value: string; language: { __typename?: "Language"; code: string } }> | null;
        } | null;
      };
    };
  }> | null;
};

export type ClaimRewardClaimFromCurrentUserMutationVariables = Exact<{
  id: Scalars["Float"]["input"];
  questionOutput?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ClaimRewardClaimFromCurrentUserMutation = {
  __typename?: "Mutation";
  claimRewardClaimFromCurrentUser?: { __typename?: "RewardClaim"; id: string } | null;
};

export type AllTeamsQueryVariables = Exact<{ [key: string]: never }>;

export type AllTeamsQuery = {
  __typename?: "Query";
  allTeams?: Array<{
    __typename?: "Team";
    name: string;
    teamOptaId: string;
    championshipSeasons?: Array<{ __typename?: "ChampionshipSeason"; seasonOptaId: string; championshipOptaId: string }> | null;
  }> | null;
};

export type AllTournamentGameWeeksQueryVariables = Exact<{
  filter?: InputMaybe<TournamentGameWeekWhereInput>;
}>;

export type AllTournamentGameWeeksQuery = {
  __typename?: "Query";
  allTournamentGameWeeks?: Array<{ __typename?: "TournamentGameWeek"; id: string; status: TournamentGameWeekStatus }> | null;
};

export type AllTournamentVariantsIdsQueryVariables = Exact<{
  filter?: InputMaybe<TournamentVariantWhereInput>;
}>;

export type AllTournamentVariantsIdsQuery = {
  __typename?: "Query";
  allTournamentVariants?: Array<{
    __typename?: "TournamentVariant";
    id: string;
    tournament: { __typename?: "Tournament"; tournamentState: TournamentState };
  }> | null;
};

export type DetailedTournamentVariantQueryVariables = Exact<{
  tournamentVariantId: Scalars["Float"]["input"];
  status?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type DetailedTournamentVariantQuery = {
  __typename?: "Query";
  DetailedTournamentVariant?: {
    __typename?: "TournamentVariant";
    id: string;
    rules: TournamentVariantRulesType;
    tournamentId: number;
    name: {
      __typename?: "Translatable";
      translations?: Array<{ __typename?: "Translation"; value: string; language: { __typename?: "Language"; code: string } }> | null;
    };
    tournament: {
      __typename?: "Tournament";
      id: string;
      tournamentState: TournamentState;
      name: {
        __typename?: "Translatable";
        code: string;
        translations?: Array<{ __typename?: "Translation"; value: string; language: { __typename?: "Language"; code: string } }> | null;
      };
    };
    tournamentGamesWeeks?: Array<{
      __typename?: "TournamentGameWeek";
      id: string;
      status: TournamentGameWeekStatus;
      startDate: any;
      endDate: any;
      compositionsCount: number;
      realGames: Array<{
        __typename?: "RealGame";
        status: RealGameStatus;
        datetime?: any | null;
        awayTeamOptaId?: string | null;
        awayScore: number;
        awayTeamName?: string | null;
        homeTeamOptaId: string;
        homeScore: number;
        homeTeamName: string;
        winnerTeamOptaId?: string | null;
        round: number;
        championshipSeasonId: number;
        championshipSeason: { __typename?: "ChampionshipSeason"; championship: { __typename?: "Championship"; name: string } };
      }>;
      gameWeekRewards?: Array<{
        __typename?: "GameWeekReward";
        reward: {
          __typename?: "Reward";
          criteria: RewardCriteria;
          rankingFrom: number;
          rankingTo: number;
          pointsMin: number;
          rewardType: RewardType;
          displayedQuantity?: number | null;
          name?: {
            __typename?: "Translatable";
            translations?: Array<{ __typename?: "Translation"; value: string; language: { __typename?: "Language"; code: string } }> | null;
          } | null;
        };
      }> | null;
    }> | null;
  } | null;
};

export type TournamentVariantQueryVariables = Exact<{
  tournamentVariantId: Scalars["Int"]["input"];
}>;

export type TournamentVariantQuery = {
  __typename?: "Query";
  TournamentVariant?: {
    __typename?: "TournamentVariant";
    id: string;
    rules: TournamentVariantRulesType;
    tournamentId: number;
    name: {
      __typename?: "Translatable";
      translations?: Array<{ __typename?: "Translation"; value: string; language: { __typename?: "Language"; code: string } }> | null;
    };
    tournament: {
      __typename?: "Tournament";
      id: string;
      tournamentState: TournamentState;
      name: {
        __typename?: "Translatable";
        code: string;
        translations?: Array<{ __typename?: "Translation"; value: string; language: { __typename?: "Language"; code: string } }> | null;
      };
    };
    tournamentGamesWeeks?: Array<{
      __typename?: "TournamentGameWeek";
      id: string;
      status: TournamentGameWeekStatus;
      startDate: any;
      endDate: any;
    }> | null;
  } | null;
};

export type UpdateCompositionForCurrentUserMutationVariables = Exact<{
  id: Scalars["Float"]["input"];
  data: CompositionCreateInput;
}>;

export type UpdateCompositionForCurrentUserMutation = {
  __typename?: "Mutation";
  updateCompositionForCurrentUser: { __typename?: "Composition"; id: string };
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "MarketPlaceUserType";
    id: string;
    username: string;
    wallet: { __typename?: "MarketPlaceWalletType"; address?: string | null };
  } | null;
};

export const CardsDocument = gql`
  query Cards {
    user {
      wallet {
        cards {
          tokenId
          rarity
          edition
          image
          age
          xp
          optaId
          position {
            position
          }
          competition {
            optaId
            name
          }
          player {
            firstName
            lastName
            optaId
            currentNationality {
              nationality
            }
            currentCompetition {
              name
            }
            currentPosition {
              position
            }
            playerStats {
              attack
              defense
              impact
              scoring
              skills
              strength
            }
          }
        }
      }
    }
  }
`;
export const AllChampionshipsDocument = gql`
  query AllChampionships {
    allChampionships {
      name
      championshipOptaId
      championshipSeasons {
        id
      }
    }
  }
`;
export const CommonCardsByGameWeekDocument = gql`
  query CommonCardsByGameWeek($gameWeekId: Float!) {
    getCommonCards(gameWeekId: $gameWeekId) {
      commonCard {
        id
        age
        championshipOptaId
        playerId
        playerOptaId
        teamOptaId
        seasonOptaId
        image
        player {
          firstname
          lastname
          position
          optaPosition
          scoring
          attack
          impact
          strength
          skills
        }
      }
      hasPlayed
    }
  }
`;
export const AllCommonCardsDocument = gql`
  query AllCommonCards {
    allCommonCards {
      id
      age
      championshipOptaId
      playerId
      playerOptaId
      teamOptaId
      seasonOptaId
      image
      player {
        firstname
        lastname
        position
        optaPosition
        scoring
        attack
        impact
        strength
        skills
      }
    }
  }
`;
export const GetCompositionsForCurrentUserDocument = gql`
  query getCompositionsForCurrentUser($status: [TournamentGameWeekStatus!]) {
    getCompositionsForCurrentUser(status: $status) {
      backPoints
      halfPoints
      firstRowPoints
      secondRowPoints
      extraPoints
      gameWeek {
        id
        status
        startDate
        endDate
        variantId
        number
        realGames {
          status
          datetime
          awayTeamOptaId
          awayScore
          awayTeamName
          homeTeamOptaId
          homeScore
          homeTeamName
          winnerTeamOptaId
          round
          championshipSeason {
            championship {
              name
            }
          }
        }
        gameWeekRewards {
          reward {
            criteria
            rankingFrom
            rankingTo
            pointsMin
            name {
              translations {
                language {
                  code
                }
                value
              }
            }
            rewardType
            displayedQuantity
          }
        }
        compositionsCount
      }
      id
      captainCommonCardId
      captainMarketPlaceCardTokenId
      firstRowCommonCardId
      firstRowMarketPlaceCardTokenId
      secondRowCommonCardId
      secondRowMarketPlaceCardTokenId
      halfCommonCardId
      halfMarketPlaceCardTokenId
      backCommonCardId
      backMarketPlaceCardTokenId
      extraCommonCardId
      extraMarketPlaceCardTokenId
      canFirstRowBeChanged
      canSecondRowBeChanged
      canHalfBeChanged
      canBackBeChanged
      canExtraBeChanged
      score
      ranking
    }
  }
`;
export const CreateCompositionForCurrentUserDocument = gql`
  mutation CreateCompositionForCurrentUser($arg: CompositionCreateInput!) {
    createCompositionForCurrentUser(data: $arg) {
      id
    }
  }
`;
export const HasPlayersPlayedDuringGameWeekDocument = gql`
  query hasPlayersPlayedDuringGameWeek($playerOptaIds: [String!]!, $gameWeekId: Int!) {
    hasPlayersPlayedDuringGameWeek(playerOptaIds: $playerOptaIds, gameWeekId: $gameWeekId) {
      playerOptaId
      hasPlayed
    }
  }
`;
export const GetLeaderboardDocument = gql`
  query getLeaderboard($id: Float!, $take: Float!) {
    getLeaderboard(id: $id, take: $take) {
      score
      ranking
      username
    }
  }
`;
export const MarketPlaceCardPlayerCacheDocument = gql`
  query MarketPlaceCardPlayerCache($cardTokenId: String!) {
    marketPlaceCardPlayerCache(cardTokenId: $cardTokenId) {
      cardTokenId
      cardRarity
      rarity
      player {
        firstName
        lastName
        currentPosition {
          position
        }
        currentCompetition {
          name
        }
        currentNationality {
          nationality
          codeIso
        }
        optaId
        playerStats {
          attack
          defense
          strength
          impact
          skills
          scoring
        }
      }
      optaId
      tokenId
      xp
      age
      nationality
      image
      edition
      updatedAt
      playerFirstName
      playerOptaId
      playerLastName
      position
    }
  }
`;
export const PlayerScoreDocument = gql`
  query PlayerScore($id: Int!) {
    Player(where: { id: $id }) {
      livePoints
    }
  }
`;
export const PlayerScoringMarketPlaceDocument = gql`
  query PlayerScoringMarketPlace($optaId: String!) {
    playerScoringMarketPlace(playerId: $optaId) {
      evolution
      minutes_played_total
      red_cards
      yellow_cards
      tries
      appearances
      nb_games {
        game_date
        metadata_total
      }
    }
  }
`;
export const PlayerUpcomingHomeRealGamesDocument = gql`
  query PlayerUpcomingHomeRealGames($playerOptaId: String!, $nowInMs: Timestamp!) {
    getManyRealGames(
      where: { homeTeam: { is: { players: { some: { playerOptaId: { equals: $playerOptaId } } } } }, datetime: { gt: $nowInMs } }
      orderBy: { id: desc }
    ) {
      id
      awayTeamName
      awayScore
      awayTeamOptaId
      homeTeamName
      homeScore
      homeTeamOptaId
      winnerTeamOptaId
      datetime
    }
  }
`;
export const PlayerUpcomingAwayRealGamesDocument = gql`
  query PlayerUpcomingAwayRealGames($playerOptaId: String!, $nowInMs: Timestamp!) {
    getManyRealGames(
      where: { awayTeam: { is: { players: { some: { playerOptaId: { equals: $playerOptaId } } } } }, datetime: { gt: $nowInMs } }
      orderBy: { id: desc }
    ) {
      id
      awayTeamName
      awayScore
      awayTeamOptaId
      homeTeamName
      homeScore
      homeTeamOptaId
      winnerTeamOptaId
      datetime
    }
  }
`;
export const GetLivePlayerStatsDocument = gql`
  query getLivePlayerStats($optaId: String!) {
    getManyRealGamePlayers(where: { playerOptaId: { equals: $optaId } }) {
      realGame {
        status
        datetime
      }
      playerScore {
        playerStats
        playerPoints
      }
    }
  }
`;
export const RewardClaimsFromCurrentUserDocument = gql`
  query rewardClaimsFromCurrentUser {
    rewardClaimsFromCurrentUser {
      id
      rewardClaimState
      composition {
        ranking
      }
      marketPlaceCardTokenId
      gameWeekReward {
        gameWeek {
          startDate
          endDate
          number
          variant {
            rules
            tournament {
              name {
                code
                translations {
                  language {
                    code
                  }
                  value
                }
              }
            }
          }
        }
        reward {
          id
          rewardType
          name {
            translations {
              language {
                code
              }
              value
            }
          }
          questionInput {
            translations {
              language {
                code
              }
              value
            }
          }
        }
        gameWeekId
      }
    }
  }
`;
export const ClaimRewardClaimFromCurrentUserDocument = gql`
  mutation claimRewardClaimFromCurrentUser($id: Float!, $questionOutput: String) {
    claimRewardClaimFromCurrentUser(id: $id, questionOutput: $questionOutput) {
      id
    }
  }
`;
export const AllTeamsDocument = gql`
  query AllTeams {
    allTeams {
      name
      teamOptaId
      championshipSeasons {
        seasonOptaId
        championshipOptaId
      }
    }
  }
`;
export const AllTournamentGameWeeksDocument = gql`
  query allTournamentGameWeeks($filter: TournamentGameWeekWhereInput) {
    allTournamentGameWeeks(filter: $filter) {
      id
      status
    }
  }
`;
export const AllTournamentVariantsIdsDocument = gql`
  query AllTournamentVariantsIds($filter: TournamentVariantWhereInput) {
    allTournamentVariants(filter: $filter) {
      id
      tournament {
        tournamentState
      }
    }
  }
`;
export const DetailedTournamentVariantDocument = gql`
  query DetailedTournamentVariant($tournamentVariantId: Float!, $status: String) {
    DetailedTournamentVariant(tournamentVariantId: $tournamentVariantId, status: $status) {
      id
      name {
        translations {
          language {
            code
          }
          value
        }
      }
      rules
      tournament {
        id
        name {
          code
          translations {
            language {
              code
            }
            value
          }
        }
        tournamentState
      }
      tournamentId
      tournamentGamesWeeks {
        id
        status
        startDate
        endDate
        compositionsCount
        realGames {
          status
          datetime
          awayTeamOptaId
          awayScore
          awayTeamName
          homeTeamOptaId
          homeScore
          homeTeamName
          winnerTeamOptaId
          round
          championshipSeasonId
          championshipSeason {
            championship {
              name
            }
          }
        }
        gameWeekRewards {
          reward {
            criteria
            rankingFrom
            rankingTo
            pointsMin
            name {
              translations {
                language {
                  code
                }
                value
              }
            }
            rewardType
            displayedQuantity
          }
        }
      }
    }
  }
`;
export const TournamentVariantDocument = gql`
  query TournamentVariant($tournamentVariantId: Int!) {
    TournamentVariant(where: { id: $tournamentVariantId }) {
      id
      name {
        translations {
          language {
            code
          }
          value
        }
      }
      rules
      tournament {
        id
        name {
          code
          translations {
            language {
              code
            }
            value
          }
        }
        tournamentState
      }
      tournamentId
      tournamentGamesWeeks {
        id
        status
        startDate
        endDate
      }
    }
  }
`;
export const UpdateCompositionForCurrentUserDocument = gql`
  mutation UpdateCompositionForCurrentUser($id: Float!, $data: CompositionCreateInput!) {
    updateCompositionForCurrentUser(id: $id, data: $data) {
      id
    }
  }
`;
export const UserDocument = gql`
  query User {
    user {
      id
      username
      wallet {
        address
      }
    }
  }
`;
