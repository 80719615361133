import { useApolloOperations } from "@/composables/useApolloOperations";
import { Championship } from "@/generated/graphqlLoginLineOperations";
import { defineStore } from "pinia";
import TypedLocalStore from "typed-local-store";

const apolloOperations = useApolloOperations();

export type ChampionshipsStore = {
  championships: Array<Championship>;
};

interface PersistSchema {
  "oval3-championships": {
    expires: number;
    championships: Array<Championship>;
  };
}

export const useChampionshipsStore = defineStore("championships", {
  state: (): ChampionshipsStore => ({
    championships: [],
  }),
  actions: {
    clear() {
      this.$reset();
    },
    async fetchChampionships(refresh: boolean = false) {
      let championships = [];

      if (this.championships.length !== 0 && !refresh) {
        return;
      }

      const typedStorage = new TypedLocalStore<PersistSchema>();

      const championshipsCache = typedStorage.getItem("oval3-championships");

      // Check if championships cache exists, and is still valid
      if (championshipsCache && championshipsCache.expires > Date.now()) {
        championships = championshipsCache.championships;
      } else {
        const response = await apolloOperations.getAllChampionships();
        championships = (response?.data.allChampionships ?? []) as Array<Championship>;

        typedStorage.setItem("oval3-championships", {
          expires: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).getTime(), // 2 days
          championships: championships,
        });
      }

      this.championships = championships;
    },
  },
});
