import { useUserStore } from "@/stores/userStore";
import { displayError } from "@/utils/errors";
import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useRoute } from "vue-router";

export function useApolloClient(apolloUrl: string): ApolloClient<NormalizedCacheObject> {
  const enhancedFetch = async (input: RequestInfo, init: RequestInit) => {
    return await fetch(input, {
      ...init,
      headers: {
        ...init.headers,
        "Access-Control-Allow-Origin": "*",
      },
      mode: "cors",
    });
  };

  const httpLink = new HttpLink({
    uri: apolloUrl,
    fetch: enhancedFetch,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("oval3-auth"),
        ...headers,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

        if (message === "Unauthorized") {
          localStorage.removeItem("oval3-auth");
          localStorage.removeItem("oval3-user");

          useUserStore().$reset();
        }
      });
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      displayError(networkError);
    }
  });

  const cache = new InMemoryCache({
    addTypename: false,
  });

  return new ApolloClient({
    cache,
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
    link: from([errorLink, authLink.concat(httpLink)]),
  });
}
