<template>
  <section v-if="$route.name !== 'referrals'" class="relative w-full max-md:flex max-md:flex-col bg-gray-900">
    <img class="max-md:hidden absolute h-full w-full object-cover object-left" src="/backgrounds/referral.jpg" alt=" " />

    <img class="md:hidden w-full" src="/backgrounds/referral-mobile.png" alt=" " />
    <div class="md:hidden absolute inset-0 bg-gradient-to-t from-gray-900 from-60% to-transparent to-80%"></div>

    <div
      class="z-10 relative flex flex-col justify-center max-lg:px-4 max-lg:pb-6 max-lg:pt-20 max-md:-mt-20 max-lg:gap-4 md:mx-[10%] lg:ml-auto lg:w-1/2 lg:py-12 xl:py-20 2xl:py-28 2xl:w-2/5"
    >
      <h2 class="text-libre-h2">{{ $t("referral.referral_rewards") }}</h2>

      <p class="text-p1">{{ $t("referral.invite_your_friends") }}</p>

      <nav class="flex max-md:flex-col items-center gap-4 lg:mt-8">
        <router-link
          :to="{ name: 'referrals' }"
          class="btn-base border-primary text-primary backdrop-blur-xl hover:bg-primary hover:text-white w-full"
        >
          {{ $t("referral.check_our_rewards") }}
        </router-link>
        <router-link :to="{ name: 'referrals' }" class="btn-primary w-full">{{ $t("referral.refer_now") }}</router-link>
      </nav>
    </div>
  </section>
</template>
<style scoped>
.card {
  background: #131221;
}
</style>
