import { useApolloOperations } from "@/composables/useApolloOperations";
import { Team } from "@/generated/graphqlLoginLineOperations";
import { defineStore } from "pinia";
import TypedLocalStore from "typed-local-store";

const apolloOperations = useApolloOperations();

export type TeamsStore = {
  teams: Array<Team>;
};

interface PersistSchema {
  "oval3-teams": {
    expires: number;
    teams: Array<Team>;
  };
}

export const useTeamsStore = defineStore("teams", {
  state: (): TeamsStore => ({
    teams: [],
  }),
  actions: {
    clear() {
      this.$reset();
    },
    async fetchTeams(refresh: boolean = false) {
      if (this.teams.length !== 0 && !refresh) {
        return;
      }

      const typedStorage = new TypedLocalStore<PersistSchema>();
      const teamsCache = typedStorage.getItem("oval3-teams");

      if (teamsCache && teamsCache.expires > Date.now()) {
        this.teams = teamsCache.teams;
        return;
      }

      const response = await apolloOperations.getAllTeams();
      this.teams = (response?.data.allTeams ?? []) as Array<Team>;

      this.cacheTeams(this.teams);
    },
    cacheTeams(teamsToCache: Array<Team>) {
      const typedStorage = new TypedLocalStore<PersistSchema>();

      const twoDaysAfter = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).getTime();
      typedStorage.setItem("oval3-teams", {
        expires: twoDaysAfter,
        teams: teamsToCache,
      });
    },
  },
});
