import { PRODUCT_FRUITS_WORKSPACE_CODE } from "@/application/config";
import { productFruits } from "product-fruits";
import i18n from "@/modules/i18n";

class ProductFruitsService {
  private workspaceCode: string;
  private locale: string;
  private isProd: boolean;

  constructor(workspaceCode: string, isProd: boolean) {
    this.workspaceCode = workspaceCode;
    this.locale = i18n.global.locale.value || "en";
    this.isProd = isProd;
  }

  public init(username: string, email: string) {
    if (this.isProd) {
      productFruits.init(this.workspaceCode, this.locale, {
        username,
        email,
      });
    }
  }
}

// Singleton instance
const isProd = process.env.NODE_ENV === "production";
const productFruitsService = new ProductFruitsService(PRODUCT_FRUITS_WORKSPACE_CODE, isProd);
export default productFruitsService;
