import { MARKETPLACE_BASE_URL } from "@/application/config";

const marketplaceRoutes = {
  trade: `${MARKETPLACE_BASE_URL}/trade`,
  mycards: `${MARKETPLACE_BASE_URL}/my-cards`,
  newcards: `${MARKETPLACE_BASE_URL}/newcards`,
  token: `${MARKETPLACE_BASE_URL}/token`,
};

export default marketplaceRoutes;
