import messages from "@intlify/unplugin-vue-i18n/messages";
import Cookies from "js-cookie";
import { createI18n } from "vue-i18n";

const supportedLocales: Array<string> = ["en", "fr"];
let appLocale;

if (Cookies.get("oval3-locale") && supportedLocales.includes(Cookies.get("oval3-locale"))) {
  appLocale = Cookies.get("oval3-locale");
} else {
  let navigatorLanguage = navigator.language;

  // Handle Chromium / Safari locale format (fr-FR instead of fr)
  if (navigatorLanguage.includes("-")) {
    navigatorLanguage = navigatorLanguage.split("-")[0];
  }

  if (!supportedLocales.includes(navigatorLanguage)) {
    navigatorLanguage = "en";
  }

  appLocale = navigatorLanguage;
}

const i18n = createI18n({
  locale: appLocale ?? "en",
  fallbackLocale: "en",
  messages,
});

export default i18n;

export const t = (i18n.global as any).t;
