import { MARKETPLACE_BASE_URL } from "@/application/config";
import { useApolloOperations } from "@/composables/useApolloOperations";
import mixpanelService from "@/services/mixpanelService";
import productFruitsService from "@/services/productFruitsService";
import { defineStore } from "pinia";
import TypedLocalStore from "typed-local-store";

const apolloOperations = useApolloOperations();

interface PersistSchema {
  "oval3-user": {
    id: string;
    username: string;
    wallet_address: string;
  };
}

const typedStorage = new TypedLocalStore<PersistSchema>();

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      id: null,
      username: null,
      wallet_address: null,
    };
  },
  actions: {
    async login() {
      const response = await apolloOperations.getUser();

      if (response?.data && response.data.user) {
        this.id = response.data.user.id;
        this.username = response.data.user.username;
        this.wallet_address = response.data.user.wallet.address;

        this.saveState();

        productFruitsService.init(this.username, this.wallet_address);
        mixpanelService.login(this.wallet_address);
      }
    },
    async logout(redirect: boolean = false) {
      typedStorage.removeItem("oval3-user");
      localStorage.removeItem("oval3-auth");

      this.$reset();
      mixpanelService.logout();

      if (redirect) {
        window.location.href = MARKETPLACE_BASE_URL + "/logout";
      }
    },
    saveState() {
      typedStorage.setItem("oval3-user", {
        id: this.id,
        username: this.username,
        wallet_address: this.wallet_address,
      });
    },
    async restoreState() {
      // If the JWT has expired, do not restore state. Instead, logout.
      // TODO: Waiting API fix
      /*if (localStorage.getItem("oval3-auth")) {
        const decodedJWT: any = jwtDecode(localStorage.getItem("oval3-auth"));
        const currentUnixTimestamp: number = Math.floor(Date.now() / 1000);

        if (decodedJWT.exp < currentUnixTimestamp) {
          await this.logout();
          return;
        }
      }*/

      const saved = typedStorage.getItem("oval3-user");

      if (saved) {
        this.id = saved.id;
        this.username = saved.username;
        this.wallet_address = saved.wallet_address;
        mixpanelService.login(this.wallet_address);
        productFruitsService.init(this.username, this.wallet_address);
      }
    },
  },
  getters: {
    isConnected: () => {
      return localStorage.getItem("oval3-auth") !== null && localStorage.getItem("oval3-user") !== null;
    },
  },
});
