<template>
  <div class="flex items-center">
    <button class="flex items-center btn-text p-2 uppercase gap-1" @click="showDropdown = true">
      <div class="w-4">
        <img src="/icons/common/world.svg" class="inline-icon mx-1" alt=" " />
      </div>
      <span>{{ locale }}</span>
    </button>
    <div v-if="showDropdown" v-on-click-outside="dropdownHandler" class="relative">
      <div class="right-0 top-5 z-50 absolute bg-greyDark flex flex-col items-start w-12 gap-2 py-2">
        <button
          v-for="l in availableLocales"
          :key="`locale-${l}`"
          :value="l"
          class="h-5 btn-text w-full uppercase justify-center text-[9px]"
          @click="
            () => {
              saveLocalePreference(l);
              locale = l;
              showDropdown = false;
            }
          "
        >
          {{ l }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components";
import type { OnClickOutsideHandler } from "@vueuse/core";
import Cookies from "js-cookie";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { availableLocales, locale } = useI18n();
const showDropdown = ref(false);

function saveLocalePreference(locale) {
  Cookies.set("oval3-locale", locale);
}

const dropdownHandler: OnClickOutsideHandler = () => {
  showDropdown.value = false;
};
</script>
