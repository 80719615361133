import { fireToast, ToastType } from "@/utils/toast";
import type { GraphQLError } from "graphql";

export interface INormalizedError {
  // Original error.
  err: unknown;

  // Is error instance?
  isError: boolean;

  // Error object.
  error?: Error;

  // Call stack.
  stack?: Error["stack"];

  // Error message.
  message: string;

  toString(): string;
}

export const extractApolloDeepError = (errors: readonly GraphQLError[] | undefined): string => {
  return errors?.length
    ? errors
        .map((e) => {
          let msg = e.message;
          if (e.extensions) {
            const ext = e.extensions as any;
            const deepmsg = ext.response?.message || "";
            msg = deepmsg;
          }
          return msg;
        })
        .join(", ")
    : "";
};
/**
 * Normalize error.
 *
 * @param err Error instance.
 * @returns Normalized error object.
 */
export const normalizeError = (err: unknown): Readonly<INormalizedError> => {
  const result: INormalizedError = {
    err,
    message: "",
    isError: false,
    toString() {
      return this.message;
    },
  };

  const aErr = err as any;
  // handle ethers.errors
  if (aErr?.reason) return normalizeError(aErr.reason);
  if (aErr?.data) return normalizeError(aErr.data);

  if (err instanceof Error) {
    result.error = err;
    result.message = err.message;
    result.stack = err.stack;
    result.isError = true;
    result.toString = () => err.toString();
  } else if (typeof err === "string") {
    result.error = new Error(err);
    result.message = err;
    result.stack = result.error.stack;
  } else {
    if (typeof err === "object") {
      result.message = aErr?.message ? aErr.message : String(aErr);
      result.toString = () => {
        const m = typeof err?.toString === "function" ? err.toString() : result.message;
        return m === "[object Object]" ? result.message : m;
      };
    } else if (typeof err === "function") {
      return normalizeError(err());
    } else {
      result.message = String(`[${typeof err}] ${aErr}`);
    }

    result.error = new Error(result.message);
    result.stack = aErr?.stack ? aErr.stack : result.error.stack;
  }

  return result;
};

export const displayError = (err: unknown) => {
  console.error(err);
  fireToast(normalizeError(err).message, ToastType.Danger);
};
