/*
 * Public: Can be accessed by logged-in users and visitors
 * StrictlyPublic: Can be accessed only by visitors
 * Private: Can be accessed only by logged-in users
 *
 * Routes that do not define a security parameter are assumed 'Private'.
 */
export enum RouteSecurity {
  Public,
  StrictlyPublic,
  Private,
}
