<template>
  <div v-if="userStore.username && userStore.wallet_address" class="flex items-center text-black">
    <div class="flex flex-col mr-3">
      <span class="font-bold uppercase text-sm">{{ userStore.username }}</span>
      <span class="text-xs text-right">{{ truncateAddress(userStore.wallet_address) }}</span>
    </div>
    <AccountAvatar :address="userStore.wallet_address" class="w-6 h-6" />
    <OnClickOutside @trigger="showDropdown = false" class="flex relative group">
      <button
        class="ml-2 transition-transform text-black"
        :class="showDropdown && 'rotate-180'"
        @click.prevent="showDropdown = !showDropdown"
      >
        <icon-ov-chevron class="w-6 h-6" />
      </button>
      <div v-if="showDropdown">
        <div
          class="w-[200px] -mr-10 right-10 top-11 z-50 absolute bg-black/60 backdrop-blur-sm text-white flex flex-col items-start drop-shadow-md border border-primary/50"
        >
          <router-link v-if="REFERRAL_ENABLED" :to="{ name: 'referrals' }" class="btn-text-sm p-4 w-full text-left hover:bg-primary/50">
            {{ $t("referral.refer_now") }}
          </router-link>
          <button class="btn-text-sm p-4 w-full text-left hover:bg-primary/50" @click="copyText">
            {{ $t("account.copy_address") }}
          </button>
          <div class="border-greyLight border-t-1 w-full" />
          <button class="btn-text-sm p-4 w-full text-left hover:bg-primary/50" @click="userStore.logout(true)">
            {{ $t("account.disconnect") }}
          </button>
          <button class="btn-text-sm p-4 w-full text-left hover:bg-primary/50" @click="clearCache()">
            {{ $t("debug.clear_cache") }}
          </button>
        </div>
      </div>
    </OnClickOutside>
  </div>
</template>

<script setup lang="ts">
import { REFERRAL_ENABLED } from "@/application/config";
import { useUserStore } from "@/stores/userStore";
import { truncateAddress } from "@/utils/address";
import { ToastType, fireToast } from "@/utils/toast";
import { OnClickOutside } from "@vueuse/components";
import { useClipboard } from "@vueuse/core";
import { Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const userStore = useUserStore();

const showDropdown: Ref<boolean> = ref(false);
const { copy } = useClipboard();

function copyText() {
  copy(userStore.wallet_address);
  fireToast(t("message.copied_address"), ToastType.Success);
  showDropdown.value = false;
}

function clearCache() {
  localStorage.removeItem("oval3-championships");
  localStorage.removeItem("oval3-common-cards");
  localStorage.removeItem("oval3-teams");
  showDropdown.value = false;
  window.location.href = "/my-club";
}
</script>
