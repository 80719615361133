import { onSnapshot, doc, Firestore, getDoc } from "firebase/firestore";
import { initializeApp, type FirebaseApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { useMaintenanceStore } from "@/stores/maintenanceStore";
import { useUserStore } from "@/stores/userStore";

// https://firebase.google.com/docs/projects/learn-more#:~:text=The%20content%20of%20the%20Firebase,and%20Cloud%20Storage%20bucket%20name.
// This is for the prod project, we need to make thie env based.
const firebaseConfig = {
  apiKey: "AIzaSyA-3y0OwFoA0Yqoi_t5E7hWdUhExmaLU5g",
  authDomain: "oval3-371114.firebaseapp.com",
  projectId: "oval3-371114",
  storageBucket: "oval3-371114.appspot.com",
  messagingSenderId: "197581034483",
  appId: "1:197581034483:web:cc296ed85b75631a68b886",
  measurementId: "G-9HJBWTDKNT",
};

export class FirestoreService {
  private unsubscribeMaintenance: () => void | null;
  private firebaseApp: FirebaseApp;
  private db: Firestore;

  constructor() {
    this.unsubscribeMaintenance = () => null;
    this.firebaseApp = initializeApp(firebaseConfig);
    this.db = getFirestore(this.firebaseApp);
  }

  async fetchWhitelist() {
    const whitelistDocId = "MhpC7SScYYMXXkql8hvj";
    const docRef = doc(this.db, "whitelistWallets", whitelistDocId);
    const maintenanceStore = useMaintenanceStore();

    try {
      const docSnapshot = await getDoc(docRef);
      const data = docSnapshot.data();
      if (data) {
        const { whitelistWallets } = data;
        maintenanceStore.setWalletWhitelist(whitelistWallets);
      }
    } catch (error) {
      console.error("Error fetching wallet whitelist from Firestore: ", error);
    }
  }

  subscribeToMaintenanceMode() {
    const documentId = "5C8kYA2LNkmzldEmYK8b";
    const docRef = doc(this.db, "maintenanceModes", documentId);
    const maintenanceStore = useMaintenanceStore();

    this.unsubscribeMaintenance = onSnapshot(
      docRef,
      (docSnapshot) => {
        const data = docSnapshot.data();
        if (data) {
          const { game } = data;
          maintenanceStore.setMyClubMaintenanceMode(game?.showMyClubMaintenanceMode || false);
          maintenanceStore.setPlayMaintenanceMode(game?.showPlayMaintenanceMode || false);
        }
      },
      (error) => {
        console.error("Error listening to Firestore: ", error);
      }
    );
  }

  unsubscribeFromFirestore() {
    if (this.unsubscribeMaintenance) {
      this.unsubscribeMaintenance();
      this.unsubscribeMaintenance = () => null;
    }
  }

  isWalletWhitelisted() {
    const userStore = useUserStore();
    const wallet = userStore.wallet_address?.toLowerCase();
    if (!wallet) return false;
    const maintenanceStore = useMaintenanceStore();
    const isWalletWhitelisted = maintenanceStore.walletWhitelist.includes(wallet);
    return isWalletWhitelisted;
  }
}
