import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type ArgBuyParameters = {
  tokenId: Scalars['String']['input'];
};

export type ArgListingCards = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Sort_Type>;
  where?: InputMaybe<WhereMarketplaceArg>;
};

export type ArgMyCard = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CardSortEnum>;
  status?: InputMaybe<MyClubCardStatus>;
  where?: InputMaybe<WhereMyCardArg>;
};

export type ArgOrderListParameters = {
  price: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
};

export type ArgOrderParameters = {
  price: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
};

export type ArgPrimaryOrder = {
  parameters: PrimaryOrderObjetcType;
  signature: Scalars['String']['input'];
};

export type ArgTypeMarket = {
  type: Type_Market;
};

export enum CardMarketplaceStatus {
  ListedOnPrimary = 'LISTED_ON_PRIMARY',
  ListedOnSecondaryAuction = 'LISTED_ON_SECONDARY_AUCTION',
  ListedOnSecondaryClassic = 'LISTED_ON_SECONDARY_CLASSIC',
  NotListed = 'NOT_LISTED'
}

export type CardPlayerEntity = {
  __typename?: 'CardPlayerEntity';
  academy: Scalars['Boolean']['output'];
  age: Scalars['String']['output'];
  amount?: Maybe<Scalars['String']['output']>;
  bidCount?: Maybe<Scalars['Int']['output']>;
  club?: Maybe<TeamEntity>;
  competition?: Maybe<Scalars['String']['output']>;
  display: Scalars['Boolean']['output'];
  edition: Scalars['String']['output'];
  endTime?: Maybe<Scalars['String']['output']>;
  firstname: Scalars['String']['output'];
  has_img_on_bucket: Scalars['Boolean']['output'];
  image: Scalars['String']['output'];
  international: Scalars['Boolean']['output'];
  lastOffer?: Maybe<LastOffer>;
  lastname: Scalars['String']['output'];
  listingStatus?: Maybe<CardMarketplaceStatus>;
  nationality?: Maybe<Scalars['String']['output']>;
  optaId?: Maybe<Scalars['Int']['output']>;
  owner?: Maybe<WalletEntity>;
  position?: Maybe<Scalars['String']['output']>;
  rarity: Scalars['String']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  season: Scalars['String']['output'];
  similarPlayers?: Maybe<Array<CardPlayerEntity>>;
  startTime?: Maybe<Scalars['String']['output']>;
  tokenId: Scalars['String']['output'];
};

export type CardPlayerTransferEntity = {
  __typename?: 'CardPlayerTransferEntity';
  blockNum: Scalars['Float']['output'];
  card: CardPlayerEntity;
  createdAt: Scalars['DateTime']['output'];
  from: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  hashUnique: Scalars['String']['output'];
  to: Scalars['String']['output'];
  transactionPosition: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum CardSortEnum {
  HighestScore = 'HIGHEST_SCORE',
  LowestScore = 'LOWEST_SCORE',
  Newest = 'NEWEST',
  Rarity = 'RARITY'
}

export type ClubParam = {
  __typename?: 'ClubParam';
  code: Scalars['String']['output'];
  league: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CompetitionParam = {
  __typename?: 'CompetitionParam';
  name: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type Consideration = {
  endAmount: Scalars['String']['input'];
  identifierOrCriteria: Scalars['String']['input'];
  itemType: Scalars['Float']['input'];
  recipient: Scalars['String']['input'];
  startAmount: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ConsiderationBuyParameters = {
  __typename?: 'ConsiderationBuyParameters';
  endAmount: Scalars['String']['output'];
  identifierOrCriteria: Scalars['String']['output'];
  itemType: Scalars['Int']['output'];
  recipient: Scalars['String']['output'];
  startAmount: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type ConsiderationListParameters = {
  __typename?: 'ConsiderationListParameters';
  amount: Scalars['String']['output'];
  recipient: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type ConsiderationParametersInputType = {
  amount?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  itemType?: InputMaybe<Scalars['Int']['input']>;
  recipient: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type ConsiderationParametersObjectType = {
  __typename?: 'ConsiderationParametersObjectType';
  amount?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  itemType?: Maybe<Scalars['Int']['output']>;
  recipient: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type LastOffer = {
  __typename?: 'LastOffer';
  address: Scalars['String']['output'];
  date: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type ListingCards = {
  __typename?: 'ListingCards';
  data?: Maybe<Array<CardPlayerEntity>>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalPage: Scalars['Int']['output'];
};

export type LoginDto = {
  address: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type MarketplaceParams = {
  __typename?: 'MarketplaceParams';
  club?: Maybe<Array<ClubParam>>;
  competition?: Maybe<Array<CompetitionParam>>;
  nationality?: Maybe<Array<NationalityParam>>;
  position?: Maybe<Array<PositionParam>>;
  priceRange?: Maybe<RangeParam>;
};

export type MessageSignUpDto = {
  address: Scalars['String']['input'];
  email: Scalars['String']['input'];
  optin: Scalars['Boolean']['input'];
  username: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  AuthSignUp: Scalars['String']['output'];
  PrimaryOrder: Scalars['Boolean']['output'];
  SecondaryListOrder: Scalars['Boolean']['output'];
  SecondaryUnlistOrder: Scalars['Boolean']['output'];
  UpdateUsername: UserEntity;
};


export type MutationAuthSignUpArgs = {
  arg: SignUpDto;
};


export type MutationPrimaryOrderArgs = {
  arg: ArgPrimaryOrder;
};


export type MutationSecondaryListOrderArgs = {
  arg: ArgPrimaryOrder;
};


export type MutationSecondaryUnlistOrderArgs = {
  tokenId: Scalars['String']['input'];
};


export type MutationUpdateUsernameArgs = {
  arg: UpdateUsernameDto;
};

export type MyCards = {
  __typename?: 'MyCards';
  data?: Maybe<Array<CardPlayerEntity>>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalPage: Scalars['Int']['output'];
};

export enum MyClubCardStatus {
  ListedOnSecondaryClassic = 'LISTED_ON_SECONDARY_CLASSIC'
}

export type NationalityParam = {
  __typename?: 'NationalityParam';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Offer = {
  endAmount: Scalars['String']['input'];
  identifierOrCriteria: Scalars['String']['input'];
  itemType: Scalars['Float']['input'];
  startAmount: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type OfferBuyParameters = {
  __typename?: 'OfferBuyParameters';
  endAmount: Scalars['String']['output'];
  identifierOrCriteria: Scalars['String']['output'];
  itemType: Scalars['Int']['output'];
  startAmount: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type OfferListParameters = {
  __typename?: 'OfferListParameters';
  identifier: Scalars['String']['output'];
  itemType: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export type OfferParametersInputType = {
  amount: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type OfferParametersObjectType = {
  __typename?: 'OfferParametersObjectType';
  amount: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type OrderBuyObject = {
  __typename?: 'OrderBuyObject';
  parameters: OrderBuyParameters;
  signature: Scalars['String']['output'];
};

export type OrderBuyParameters = {
  __typename?: 'OrderBuyParameters';
  conduitKey: Scalars['String']['output'];
  consideration: Array<ConsiderationBuyParameters>;
  counter: Scalars['Int']['output'];
  endTime: Scalars['String']['output'];
  offer: Array<OfferBuyParameters>;
  offerer: Scalars['String']['output'];
  orderType: Scalars['Int']['output'];
  salt: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  totalOriginalConsiderationItems: Scalars['Int']['output'];
  zone: Scalars['String']['output'];
  zoneHash: Scalars['String']['output'];
};

export type OrderListingParameters = {
  __typename?: 'OrderListingParameters';
  conduitKey: Scalars['String']['output'];
  consideration: Array<ConsiderationListParameters>;
  endTime: Scalars['String']['output'];
  offer: Array<OfferListParameters>;
  startTime: Scalars['String']['output'];
  zone: Scalars['String']['output'];
};

export type OrderParameters = {
  __typename?: 'OrderParameters';
  conduitKey: Scalars['String']['output'];
  consideration: Array<ConsiderationParametersObjectType>;
  endTime: Scalars['String']['output'];
  offer: Array<OfferParametersObjectType>;
  startTime: Scalars['String']['output'];
  zone: Scalars['String']['output'];
};

export enum PlayerPositionEnum {
  Centre = 'CENTRE',
  Flanker = 'FLANKER',
  FullBack = 'FULL_BACK',
  Hooker = 'HOOKER',
  LeftWing = 'LEFT_WING',
  Lock = 'LOCK',
  Number_8 = 'NUMBER_8',
  OutsideHalf = 'OUTSIDE_HALF',
  Prop = 'PROP',
  RightWing = 'RIGHT_WING',
  ScrumHalf = 'SCRUM_HALF'
}

export type PositionParam = {
  __typename?: 'PositionParam';
  name: Scalars['String']['output'];
};

export type PrimaryOrderObjetcType = {
  conduitKey: Scalars['String']['input'];
  consideration: Array<Consideration>;
  counter: Scalars['String']['input'];
  endTime: Scalars['String']['input'];
  offer: Array<Offer>;
  offerer: Scalars['String']['input'];
  orderType: Scalars['Float']['input'];
  salt: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
  totalOriginalConsiderationItems: Scalars['Float']['input'];
  zone: Scalars['String']['input'];
  zoneHash: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  Account: UserEntity;
  AuthLogin: Scalars['String']['output'];
  AuthLoginMessage: Scalars['String']['output'];
  AuthSignUpMessage: Scalars['String']['output'];
  Card: CardPlayerEntity;
  MarketplaceParams: MarketplaceParams;
  MyCards?: Maybe<MyCards>;
  PrimaryMarketplace: ListingCards;
  PrimaryOrderParameters: OrderParameters;
  SecondaryBuyParameters: OrderBuyObject;
  SecondaryListParameters: OrderListingParameters;
  SecondaryMarketplace: ListingCards;
  Transfers: Transfers;
  referralInfo: ReferralInformation;
};


export type QueryAuthLoginArgs = {
  arg: LoginDto;
};


export type QueryAuthLoginMessageArgs = {
  address: Scalars['String']['input'];
};


export type QueryAuthSignUpMessageArgs = {
  arg: MessageSignUpDto;
};


export type QueryCardArgs = {
  similarCard?: InputMaybe<Scalars['Boolean']['input']>;
  tokenId: Scalars['String']['input'];
};


export type QueryMarketplaceParamsArgs = {
  arg: ArgTypeMarket;
};


export type QueryMyCardsArgs = {
  arg?: InputMaybe<ArgMyCard>;
};


export type QueryPrimaryMarketplaceArgs = {
  arg?: InputMaybe<ArgListingCards>;
};


export type QueryPrimaryOrderParametersArgs = {
  arg: ArgOrderParameters;
};


export type QuerySecondaryBuyParametersArgs = {
  arg: ArgBuyParameters;
};


export type QuerySecondaryListParametersArgs = {
  arg: ArgOrderListParameters;
};


export type QuerySecondaryMarketplaceArgs = {
  arg?: InputMaybe<ArgListingCards>;
};


export type QueryTransfersArgs = {
  arg?: InputMaybe<ArgMyCard>;
};

export enum Rarity {
  Common = 'COMMON',
  Limited = 'LIMITED',
  Rare = 'RARE',
  SuperRare = 'SUPER_RARE',
  Unique = 'UNIQUE'
}

export type RangeArg = {
  from?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['Int']['input']>;
};

export type RangeParam = {
  __typename?: 'RangeParam';
  from?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type RangePriceArg = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type ReferralInformation = {
  __typename?: 'ReferralInformation';
  invitation_accepted: Scalars['Float']['output'];
  invitation_completed: Scalars['Float']['output'];
  level: Scalars['String']['output'];
  referralCode: Scalars['String']['output'];
  referralUrl: Scalars['String']['output'];
};

export enum Sort_Type {
  BidderAZ = 'BIDDER_A_Z',
  EndingSoon = 'ENDING_SOON',
  HighestPrice = 'HIGHEST_PRICE',
  LowestPrice = 'LOWEST_PRICE',
  NewlyListed = 'NEWLY_LISTED'
}

export type SignUpDto = {
  address: Scalars['String']['input'];
  email: Scalars['String']['input'];
  optin: Scalars['Boolean']['input'];
  provider: Scalars['String']['input'];
  referral?: InputMaybe<Scalars['String']['input']>;
  signature: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export enum Type_Market {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

export type TeamEntity = {
  __typename?: 'TeamEntity';
  clubCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Transfers = {
  __typename?: 'Transfers';
  data?: Maybe<Array<CardPlayerTransferEntity>>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalPage: Scalars['Int']['output'];
};

export type UpdateUsernameDto = {
  username: Scalars['String']['input'];
};

export type UserEntity = {
  __typename?: 'UserEntity';
  email: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  referralApprovalStatus: Scalars['String']['output'];
  referralCode: Scalars['String']['output'];
  referralLevel: Scalars['String']['output'];
  referralUrl: Scalars['String']['output'];
  spendAmount?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
  wallet: WalletEntity;
};

export type WalletEntity = {
  __typename?: 'WalletEntity';
  address: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type WhereMarketplaceArg = {
  age?: InputMaybe<RangeArg>;
  attack?: InputMaybe<RangeArg>;
  club?: InputMaybe<Array<Scalars['String']['input']>>;
  defense?: InputMaybe<RangeArg>;
  impact?: InputMaybe<RangeArg>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  league?: InputMaybe<Array<Scalars['String']['input']>>;
  nationality?: InputMaybe<Array<Scalars['String']['input']>>;
  playerName?: InputMaybe<Scalars['String']['input']>;
  positionFilter?: InputMaybe<Array<PlayerPositionEnum>>;
  price?: InputMaybe<RangePriceArg>;
  scarcity?: InputMaybe<Array<Rarity>>;
  score?: InputMaybe<RangeArg>;
  skills?: InputMaybe<RangeArg>;
  strength?: InputMaybe<RangeArg>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type WhereMyCardArg = {
  age?: InputMaybe<RangeArg>;
  attack?: InputMaybe<RangeArg>;
  club?: InputMaybe<Array<Scalars['String']['input']>>;
  defense?: InputMaybe<RangeArg>;
  impact?: InputMaybe<RangeArg>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  league?: InputMaybe<Array<Scalars['String']['input']>>;
  nationality?: InputMaybe<Array<Scalars['String']['input']>>;
  playerName?: InputMaybe<Scalars['String']['input']>;
  positionFilter?: InputMaybe<Array<PlayerPositionEnum>>;
  scarcity?: InputMaybe<Array<Rarity>>;
  score?: InputMaybe<RangeArg>;
  skills?: InputMaybe<RangeArg>;
  strength?: InputMaybe<RangeArg>;
};

export type ReferralInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type ReferralInformationQuery = { __typename?: 'Query', referralInfo: { __typename?: 'ReferralInformation', referralCode: string, referralUrl: string, level: string, invitation_accepted: number, invitation_completed: number } };


export const ReferralInformationDocument = gql`
    query ReferralInformation {
  referralInfo {
    referralCode
    referralUrl
    level
    invitation_accepted
    invitation_completed
  }
}
    `;