<template>
  <span class="inline-block align-middle rounded-full min-w-[1em] min-h-[1em]" :style="{ 'background-color': color }" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { stringToHslColor } from "@/utils/color";

const props = defineProps({
  address: {
    type: String,
    required: false,
    default: "",
  },
});

const color = computed((): string => {
  return props.address ? stringToHslColor(props.address) : "rgba(0,0,0,0)";
});
</script>
