import App from "@/App.vue";
import router from "@/router/router";
import i18n from "@/modules/i18n";
import * as Sentry from "@sentry/vue";
import FloatingVue from "floating-vue";
import { createPinia } from "pinia";
import { createApp } from "vue";

import "@/assets/styles/style.css";
import "floating-vue/dist/style.css";
import "mosha-vue-toastify/dist/style.css";

import EmptyLayout from "@/layouts/EmptyLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import { SENTRY_DSN } from "./application/config";
import { FirestoreService } from "./services/FirebaseService";
import { MaintenanceService } from "./services/MaintenanceService";

const app = createApp(App);
const pinia = createPinia();
export const firestoreService = new FirestoreService();
export const maintenanceService = new MaintenanceService(firestoreService);

if (SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "https://game-api.oval3.game/graphql"],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.8, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(FloatingVue);

app.component("MainLayout", MainLayout);
app.component("EmptyLayout", EmptyLayout);
app.mount("#app");
