import { createToast } from "mosha-vue-toastify";
import type { FirestoreService } from "./FirebaseService";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useMaintenanceStore } from "@/stores/maintenanceStore";

export class MaintenanceService {
  private firestoreService: FirestoreService;

  constructor(firestoreService: FirestoreService) {
    this.firestoreService = firestoreService;
  }

  isSiteUnderMaintenance = async (to: RouteLocationNormalized, next: NavigationGuardNext) => {
    const maintenanceStore = useMaintenanceStore();

    const maintenanceRoutes = [
      { mode: maintenanceStore.showMyClubMaintenanceMode, route: "my-club", path: "/my-club" },
      { mode: maintenanceStore.showPlayMaintenanceMode, route: "play", path: "/play" },
    ];

    const activeMaintenance = maintenanceRoutes.find((entry) => entry.mode && (to?.name === entry.route || to?.path === entry.path));

    const isWalletWhitelisted = this.firestoreService.isWalletWhitelisted();
    if (isWalletWhitelisted && activeMaintenance) {
      createToast("With great power comes great responsibility. Your wallet is whitelisted, you can continue.", {
        showIcon: true,
        transition: "bounce",
      });
      return next();
    }

    if (!isWalletWhitelisted && activeMaintenance) {
      return next({ name: "Maintenance", query: { page: activeMaintenance.route } });
    }

    return next();
  };
}
