<template>
  <footer class="lg:flex flex-col w-full bg-primary text-black pt-8 pb-6 px-4 md:px-8">
    <section class="flex max-md:flex-col justify-between items-center gap-x-12 gap-y-8">
      <article class="flex md:basis-1/3 lg:basis-2/5 flex-col gap-4 md:max-w-[39rem]">
        <div>
          <img src="/logos/full.svg" width="200" height="50" alt="Oval3" />
        </div>
        <!--        <span class="font-semibold w-full">{{ $t("footer.join_newsletter") }}</span>
        <div class="flex items-center justify-between bg-white gap-1 p-1 pl-2 w-full">
          <input
            v-model="email"
            type="text"
            :placeholder="$t('footer.your_email')"
            class="min-w-0 flex-1 focus:border-none focus:outline-none"
          />
          <button
            @click="subscribeToNewsletter()"
            class="btn-base bg-black text-primary hover:bg-primary hover:text-black text-orbitron-h3 md:text-orbitron-h2 py-3"
          >
            {{ $t("footer.subscribe_now") }}
          </button>
        </div>
        <span class="text-sm">{{ $t("footer.newsletter_disclaimer") }}</span>-->
      </article>

      <article class="text-p4 uppercase grid max-md:w-full grid-cols-1 gap-y-8 md:grid-cols-2 gap-x-6">
        <div class="grid grid-cols-1 gap-y-8 md:grid-cols-2 gap-x-6">
          <div class="flex flex-col gap-y-4">
            <router-link :to="{ name: 'my-club' }">{{ $t("nav.my_club") }}</router-link>
            <a :href="marketplaceRoutes.mycards">{{ $t("nav.my_cards") }}</a>
            <router-link :to="{ name: 'play' }">{{ $t("nav.play") }}</router-link>
            <router-link to="/onboarding">{{ $t("onboarding") }}</router-link>
          </div>

          <div class="flex flex-col gap-y-4">
            <a :href="marketplaceRoutes.newcards">{{ $t("nav.new_cards") }}</a>
            <a :href="marketplaceRoutes.trade">{{ $t("nav.trade") }}</a>
            <a :href="localizedHelpUrl" target="_blank">{{ $t("nav.support") }}</a>
            <a :href="localizedFaqUrl" target="_blank">{{ $t("nav.faq") }}</a>
          </div>
        </div>

        <div class="flex items-center gap-x-4">
          <a href="https://discord.com/invite/oval3" target="_blank">
            <img src="/icons/socials/discord.png" alt="Discord" />
          </a>
          <a href="https://www.instagram.com/oval3_game" target="_blank">
            <img src="/icons/socials/instagram.png" alt="Instagram" />
          </a>
          <a href="https://x.com/Oval3_game" target="_blank">
            <img src="/icons/socials/x.png" alt="X" />
          </a>
          <a href="https://www.facebook.com/oval3.game/" target="_blank">
            <img src="/icons/socials/facebook.png" alt="Facebook" />
          </a>
          <a href="https://oval3.medium.com/" target="_blank">
            <img src="/icons/socials/medium.png" alt="Medium" />
          </a>
          <a href="https://www.tiktok.com/@oval3_game" target="_blank">
            <img src="/icons/socials/tiktok.png" alt="Tiktok" />
          </a>
        </div>
      </article>
    </section>

    <div class="w-full h-[1px] bg-black/10 mt-8 mb-6"></div>

    <section class="flex max-md:flex-col-reverse md:items-center justify-between gap-y-4">
      <span class="text-p3">{{ new Date().getFullYear() }} BAMG - {{ $t("footer.all_rights_reserved") }}</span>

      <div class="flex items-center gap-x-4 md:gap-x-8 uppercase text-p4">
        <a :href="PRIVACY_POLICIES_URL" target="_blank">{{ $t("nav.privacy_policy") }}</a>
        <a :href="TOS_URL" target="_blank">{{ $t("nav.terms_of_service") }}</a>
        <!--        <router-link to="/">{{ $t("nav.cookies_settings") }}</router-link>-->
      </div>
    </section>
  </footer>
</template>

<script setup lang="ts">
import { FAQ_URL, FAQ_URL_FR, HELP_URL, HELP_URL_FR, PRIVACY_POLICIES_URL, TOS_URL } from "@/application/config";
import marketplaceRoutes from "@/router/marketplaceRoutes";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const email = ref();

function subscribeToNewsletter() {
  email.value = "";
  return;
}

const { locale } = useI18n();

const localizedHelpUrl = computed(() => {
  if (locale.value === "fr") {
    return HELP_URL_FR;
  }
  return HELP_URL;
});
const localizedFaqUrl = computed(() => {
  if (locale.value === "fr") {
    return FAQ_URL_FR;
  }
  return FAQ_URL;
});
</script>
