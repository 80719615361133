<template>
  <div class="flex flex-row h-[32px] gap-2 sm:gap-3 bg-black text-greyLight w-full justify-between items-center px-6">
    <!--
    <span class="text-primary uppercase font-display text-xs font-bold">{{ $t("head.live_auctions") }}</span>
-->
    <p class="text-primary uppercase font-display text-xs font-bold">{{ $t("main_header.welcome") }}</p>
    <div class="flex items-center gap-2 text-[9px] text-greyLight uppercase font-display font-bold">
      <a :href="localizedHelpUrl" class="btn-text" target="_blank">
        <span class="flex items-center">
          <img src="/icons/common/help.svg" class="inline-icon mx-1 w-3 h-3" alt="help" />
          {{ $t("head.help") }}
        </span>
      </a>
      <TheLocalesSwitcher />
      <!-- <NetworkStatus />-->
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { HELP_URL, HELP_URL_FR } from "@/application/config";

const { locale } = useI18n();

const localizedHelpUrl = computed(() => {
  if (locale.value === "fr") {
    return HELP_URL_FR;
  }
  return HELP_URL;
});
</script>
