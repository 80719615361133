<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="section" class="relative z-slideover" @close="slideOverStore.close()">
      <div class="fixed inset-0 bg-black/20"></div>

      <div class="fixed inset-y-0 right-0 overflow-hidden pointer-events-none flex max-w-full">
        <TransitionChild
          as="template"
          enter="transform transition ease-in-out duration-500 sm:duration-600"
          enter-from="translate-x-full"
          enter-to="translate-x-0"
          leave="transform transition ease-in-out duration-400 sm:duration-500"
          leave-from="translate-x-0"
          leave-to="translate-x-full"
        >
          <DialogPanel
            as="article"
            class="pointer-events-auto w-screen md:w-[80vw] lg:w-[70vw] xl:w-[60vw] 2xl:w-[50vw] overflow-hidden flex h-full flex-col bg-black shadow-xl"
          >
            <header class="py-2 px-4 sm:px-6 flex items-end justify-between">
              <button
                v-if="slideOverStore.canCloseStack"
                @click="slideOverStore.closeStack()"
                class="flex items-center gap-x-1.5 px-3 py-2 -ml-4 text-greyLight"
              >
                <icon-ov-chevron class="w-5 rotate-90" />
                <p class="uppercase text-orbitron-h3">{{ $t("button.back") }}</p>
              </button>

              <button @click="slideOverStore.close()" class="flex items-center gap-x-1.5 px-3 py-4 ml-auto -mr-4 text-greyLight">
                <p class="uppercase text-orbitron-h3">{{ $t("button.close") }}</p>
                <icon-ov-close class="w-5" />
              </button>
            </header>
            <template v-for="(stackedView, index) in viewsStack" :key="index">
              <component
                v-show="index === viewsStack.length - 1"
                :is="stackedView.view"
                v-if="stackedView.view"
                :modelValue="stackedView.props"
              />
            </template>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { useSlideoverStore } from "@/stores/slideoverStore";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";

const slideOverStore = useSlideoverStore();

const { isOpen, viewsStack } = storeToRefs(slideOverStore);
</script>
