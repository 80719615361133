<template>
  <div id="app">
    <component :is="layout">
      <router-view></router-view>
    </component>
    <TheModalPortal />
    <BaseSlideover />
  </div>
</template>

<script setup lang="ts">
import router from "@/router/router";
import { useChampionshipsStore } from "@/stores/championshipsStore";
import { useTeamsStore } from "@/stores/teamsStore";
import { ApolloClients } from "@vue/apollo-composable";
import { computed, onMounted, provide } from "vue";
import { eraApolloClient } from "./clients/eraApollo";
import { loginLineApolloClient } from "./clients/loginLineApollo";
import { useApolloOperations } from "./composables/useApolloOperations";
import mixpanelService from "./services/mixpanelService";
import productFruitsService from "./services/productFruitsService";

// prettier-ignore
const mixpanelServiceHook = mixpanelService;
const productFruitsHook = productFruitsService;

const defaultLayout: string = "empty-layout";

const layout = computed(() => {
  return router?.currentRoute?.value?.meta?.layout ?? defaultLayout;
});

const apolloOps = useApolloOperations();
apolloOps.composables.provideApolloClient(loginLineApolloClient);
provide(ApolloClients, {
  era: eraApolloClient,
});

onMounted(() => {
  const championShipsStore = useChampionshipsStore();
  const teamsStore = useTeamsStore();

  championShipsStore.fetchChampionships();
  teamsStore.fetchTeams();
});
</script>
